import React from 'react'
import { connect } from 'react-redux'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import ReactTable from 'react-table'
import styled from 'styled-components'
import _ from 'lodash'
import fb from '../../firebase'
import { Panel } from '../../components/ui/'
import Input from '../../components/ui/Input'
import Modal from '../../components/Modal'
import { Row, Col, Page } from '../../components/ui/Layout'
import {
  EditSettingsAction,
  bindOrders,
  fetchRiders,
  editClientDataOrder
} from './actions'
import Swal from 'sweetalert2'
import Loader from '../../components/loading'
import { DiffTimeWithCurrent } from '../.../../../helpers/times'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Box from '@material-ui/core/Box'
import { Formik, Form } from 'formik'
import FormInput from '../../components/FormInput'
import SelectRider from './SelectRider'
import { GetCities } from '../adminSettings/actions'
import { editUser } from '../users/actions'
import UserInfo from '../../components/userInfo'
import { createOrderTest } from './helpers'
import getEndpoint from '../../helpers/getEndpoint'

const URL = getEndpoint('REACT_APP_ENDPOINT_API')
const BE_URL = getEndpoint('REACT_APP_DATABASE_URL')
const URL_API = getEndpoint('REACT_APP_ENDPOINT')
const database = fb.database().ref()
const dbTestingOrders = fb.database().ref('testing_orders/orders')
const dbHistory = fb.database().ref('historyOrders')
const dbPartners = fb.database().ref('partners')
const dbScheduled = fb.database().ref('scheduledOrders')
const citiesDB = fb.database().ref('cities')

const moment = extendMoment(Moment)

const ButtonNotify = styled(Button)``
const ButtonAction = styled(IconButton)``
const ButtonEditUser = styled(Button)``
const ButtonCreateOrder = styled(Button)``
const IconAction = styled(Icon)`
  color: ${props =>
    props.disabled
      ? 'gray'
      : props.type === 'success'
        ? 'green'
        : props.type === 'cancel'
          ? 'red'
          : props.type === 'rider'
            ? 'Chocolate'
            : props.type === 'cook'
              ? 'GoldenRod '
              : props.type === 'delivered' ? 'DarkGreen' : 'orange'};
`

const WrapperActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`

const OrderContent = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 15px;
  text-align: center;
  color: ${props => props.color}
  background-color: ${props => props.backgroundColor}
`

const ButtonAlarm = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 5px;
  width: 100%;
`

class Orders extends React.Component {
  constructor (props) {
    super(props)
    const { user: { city, selectCity, name, lastName, uid, superUser } } = props
    this.state = {
      adminProps: {
        adminName: name,
        adminLN: lastName,
        adminID: uid,
        superUser: superUser,
        adminCity: city
      },
      availableRiders: [],
      city: selectCity || city,
      riderActives: 0,
      isLodaingInput: false,
      statusList: [
        {
          id: 'pending_to_delivery',
          value: 'Pendiente de repartidor'
        },
        {
          id: 'take_some_time',
          value: 'En preparación'
        },
        {
          id: 'ready_for_rider',
          value: 'Listo para el repartidor'
        },
        {
          id: 'preparation_time',
          value: 'Preparado'
        },
        {
          id: 'on_way_to_delivery_the_order',
          value: 'En camino a repartir'
        },
        {
          id: 'delivered',
          value: 'Por calificar'
        },
        {
          id: 'order_cancelled',
          value: 'Orden Cancelada'
        }
      ],
      modal: {
        show: false,
        showNotify: false,
        content: <p>todo</p>
      },
      editModal: {
        onSave: null,
        onCancel: null,
        showModal: false,
        modalUserContent: <p>todo</p>
      },
      orders: {},
      alertOrders: {},
      isLoading: false,
      riders: []
    }
    this.tracking = false
    this.assignRider = this.assignRider.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.setSetting()
  }

  setSetting = () => {
    if (this.state.city) {
      var getSettings = new Promise((resolve, reject) => {
        var get = this.GetCity(this.state.city)
        if (get) {
          resolve(get)
        } else {
          reject(
            Error(
              'No se pudo cargar datos de whatsapp, el usuario no tiene ciudad asignada'
            )
          )
        }
      })
      getSettings
        .then(res => {
          const data = res.val()
          this.setState({
            whatsapp: data.settings ? data.settings.phoneSupport : '',
            riderActives: data.riders ? data.riders.actives : ''
          })
        })
        .catch(err => {
          Swal.fire({
            position: 'center',
            type: 'error',
            title: `${err.message}`,
            showConfirmButton: false,
            timer: 2500
          })
        })
    }
  }

  setOrders = (orders, alertOrders, keyRemoved = false) => {
    if (alertOrders && alertOrders.length > 0) {
      const interval = setInterval(() => {
        const audio = document.getElementById('alertPlayer')
        audio
          .play()
          .then(() => {
            // Autoplay started!
          })
          .catch(error => {
            // Autoplay was prevented.
            // Show a "Play" button so that user can start playback.
          })
        audio.loop = true
        clearInterval(interval)
      }, 3000)
    }
    if (orders === null) {
      if (keyRemoved) {
        this.setState((prevState, props) => {
          const { orders } = prevState
          delete orders[keyRemoved]
          var isLoading = false
          return {
            orders: orders,
            isLoading
          }
        })
      } else {
        return this.setState({
          orders: {},
          alertOrders: {},
          isLoading: false
        })
      }
    }
    this.setState({
      orders: { ...this.state.orders, ...orders },
      alertOrders: { ...this.state.alertOrders, alertOrders },
      isLoading: false
    })
  }

  setRiders = riders => {
    if (riders === null) {
      return this.setState({
        riders: [],
        isLoading: false
      })
    }

    this.setState({
      riders: _.concat(this.state.riders, riders),
      isLoading: false
    })
  }

  componentDidMount () {
    const { user: { city, selectCity } } = this.props
    bindOrders(selectCity || city, this.state.orders, this.setOrders)
    fetchRiders(selectCity || city, this.setRiders)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const { user: { selectCity } } = this.props
    const { user: { selectCity: newSelectCity } } = nextProps
    if (selectCity !== newSelectCity) {
      this.setState(
        {
          orders: {},
          alertOrders: {},
          riders: [],
          isLoading: false,
          city: newSelectCity,
          whatsapp: '',
          riderActives: ''
        },
        () => {
          bindOrders(newSelectCity, this.state.orders, this.setOrders)
          fetchRiders(newSelectCity, this.setRiders)
          this.setSetting()
        }
      )
    }
  }

  componentWillUnmount () {
    dbTestingOrders.off()
    dbPartners.off()
    dbScheduled.off()
  }

  GetCity = city => {
    try {
      return citiesDB.child(city).once('value')
    } catch (error) {
      Swal.fire({
        position: 'center',
        type: 'error',
        title: `${error.message}`,
        showConfirmButton: false,
        timer: 2500
      })
      return false
    }
  }

  async assignRider (orderId, status, riderId) {
    const { user } = this.props
    const adminData = {
      id: user.uid,
      name: user.name,
      lastName: user.lastName
    }
    Swal.fire({
      title: 'Procesando...',
      html: 'Cargando datos... espere un momento por favor.',
      timer: 2000,
      onBeforeOpen: () => {
        Swal.showLoading()
      }
    })
    try {
      const body = JSON.stringify({
        id: orderId,
        riderData: {
          deliveryId: riderId
        },
        adminData
      })
      var res = await fetch(`${URL_API}/riders/update_order/`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body
      })
      const data = await res.json()
      data.success
        ? Swal.fire({
          position: 'center',
          type: 'success',
          title: `Repartidor asignado con éxito.`,
          showConfirmButton: true,
          timer: 2500
        })
        : Swal.fire({
          position: 'center',
          type: 'error',
          title: `${data.message}`,
          showConfirmButton: false,
          timer: 2500
        })
      data.error &&
        Swal.fire({
          position: 'center',
          type: 'error',
          title: `${data.message}`,
          showConfirmButton: false,
          timer: 2500
        })
    } catch (error) {
      Swal.fire({
        position: 'center',
        type: 'error',
        title: `${error.message}`,
        showConfirmButton: false,
        timer: 2500
      })
    }
  }

  async modifiedOrder (props, type) {
    const { statusList } = this.state
    const { row } = props
    const { idOrder, rider } = row
    const { user } = this.props
    const timestamp = moment().valueOf()
    const adminData = {
      id: user.uid,
      name: user.name,
      lastName: user.lastName
    }

    const dataUpdate = { status: type }
    var typeString
    for (let i = 0; i < statusList.length; i++) {
      if (statusList[i].id === type) {
        typeString = statusList[i].value
      }
    }
    Swal.fire({
      title: '¿Estas seguro de cambiar el estado?',
      text: `Se cambiara el estado a ${typeString}.`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Cambiarlo!'
    }).then(async result => {
      if (result.value && idOrder) {
        if (type === 'take_some_time') {
          await this.timeModal(idOrder, adminData)
        }
        try {
          const body = JSON.stringify(dataUpdate)
          const res = await fetch(`${URL}/orders/take/${idOrder}`, {
            method: 'PUT',
            headers: {
              'content-type': 'application/json'
            },
            body
          })
          const data = await res.json()
          if (data.success) {
            if (user.type === 'admin') {
              let updates = {}
              if (type === 'preparation_time') {
                updates[
                  `testing_orders/orders/${idOrder}/acceptedByAdmin/acceptedTime`
                ] = timestamp
                updates[
                  `testing_orders/orders/${idOrder}/acceptedByAdmin/uid/`
                ] =
                  adminData.id
                updates[
                  `testing_orders/orders/${idOrder}/acceptedByAdmin/name/`
                ] = `${adminData.name} ${adminData.lastName}`
              } else if (type === 'on_way_to_delivery_the_order') {
                updates[
                  `testing_orders/orders/${idOrder}/receivedBy/receivedTime/`
                ] = timestamp
                updates[`testing_orders/orders/${idOrder}/receivedBy/uid/`] =
                  adminData.id
                updates[
                  `testing_orders/orders/${idOrder}/receivedBy/name/`
                ] = `${adminData.name} ${adminData.lastName}`
              }
              if (type === 'delivered' || type === 'order_cancelled') {
                updates[
                  `testing_orders/orders/${idOrder}/finishedBy/finishedTime/`
                ] = timestamp
                updates[`testing_orders/orders/${idOrder}/finishedBy/uid/`] =
                  adminData.id
                updates[
                  `testing_orders/orders/${idOrder}/finishedBy/name/`
                ] = `${adminData.name} ${adminData.lastName}`
                // Remover ordenes a Rider
                if (rider.dataRider) {
                  updates[
                    `partners/${
                      rider.dataRider.deliveryId
                    }/assignedOrders/${idOrder}`
                  ] = null
                }
              } else {
                // Asignacion de ordenes a Rider
                if (rider.dataRider) {
                  updates[
                    `partners/${
                      rider.dataRider.deliveryId
                    }/assignedOrders/${idOrder}`
                  ] = true
                }
              }

              database.update(updates).then(
                Swal.fire({
                  position: 'center',
                  type: 'success',
                  title: `Datos actualizados con éxito.`,
                  showConfirmButton: true,
                  timer: 1500
                })
              )
            }
          } else {
            Swal.fire({
              position: 'center',
              type: 'error',
              title: `${data.data}`,
              showConfirmButton: false,
              timer: 2500
            })
          }
        } catch (error) {
          Swal.fire({
            position: 'center',
            type: 'error',
            title: `${error.message}`,
            showConfirmButton: false,
            timer: 2500
          })
        }
      }
    })
  }

  removeRider (props) {
    const { row } = props
    const { idOrder, rider } = row
    const { deliveryId } = rider.dataRider
    Swal.fire({
      title: '¿Deseas remover el repartidor?',
      text: ``,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Cambiarlo!'
    }).then(async result => {
      if (result.value) {
        let update = {}
        update[`testing_orders/orders/${idOrder}/attendedBy`] = null
        update[`partners/${deliveryId}/assignedOrders/${idOrder}`] = null
        Swal.fire({
          title: 'Procesando...',
          html: 'Cargando datos... espere un momento por favor.',
          timer: 2000,
          onBeforeOpen: () => {
            Swal.showLoading()
          }
        })
        database
          .update(update)
          .then(() => {
            Swal.fire({
              position: 'center',
              type: 'success',
              title: `Datos actualizados con éxito.`,
              showConfirmButton: true,
              timer: 1500
            })
          })
          .catch(err => {
            Swal.fire({
              position: 'center',
              type: 'error',
              title: `${err.message}`,
              showConfirmButton: false,
              timer: 2500
            })
          })
      }
    })
  }

  _finishOrderHistory = props => {
    const { row } = props
    const { user } = this.props
    const { idOrder, _original, dishes, rider } = row
    const timestamp = moment().valueOf()
    console.log('finishOrderHistory idOrder', idOrder)
    console.log('finishOrderHistory _original', _original)
    console.log('finishOrderHistory dishes', dishes)
    console.log('finishOrderHistory rider', rider)

    const body = {}

    body['attendedBy'] = rider.dataRider.deliveryId || rider.dataRider.msg
    body['client'] = _original.client.clientId
    if (_original.acceptedBy) {
      body['acceptedBy'] = _original.acceptedBy.uid
      body['acceptedTime'] = _original.acceptedBy.acceptedTime
      body['acceptedName'] = _original.acceptedBy.name
    }
    body['restaurant'] = _original
    body['acceptedTimeByRestaurant'] = _original
    if (_original.finishedBy) {
      // body['finishedBy'] = _original
      // body['finishedTime'] = _original
      // body['finishedName'] = _original
    }
    body['completedBy'] = user.uid
    body['completedName'] = `${user.name} ${user.lastName}`
    body['completedTime'] = timestamp

    // body['preparationTimeByRestaurant'] = _original
    if (_original.receivedBy) {
      body['receivedBy'] = _original.receivedBy.uid
      body['receivedTime'] = _original.receivedBy.receivedTime
      body['receivedName'] = _original.receivedBy.name
    }
    body['createdAt'] = _original.createdAt
    // body['dishes'] = _original
    // body['cancelledComment'] = _original.cancelledComment
    body['orderNumber'] = _original.orderNumber
    body['orderTotal'] = _original.orderTotal
    body['payType'] = _original.payType

    body['paypalId'] = user.uid
    body['paypalEmail'] = user.uid
    body['paycodeReferenceNumber'] = user.uid
    body['paycodeTrackCode'] = user.uid
    body['paycodeCardholderName'] = user.uid
    body['status'] = user.uid
    body['isRefund'] = user.uid
    body['takeSomeTime'] = user.uid
    body['pickup'] = user.uid
    body['discount'] = {
      amount: 0,
      deliveryCost: 0,
      discountValue: 0,
      foodCost: 0
    }
    body['coupon'] = user.uid

    // _original['createdAt'] =
    //   _original.createdAt && _original.createdAt.createdAt
    //     ? _original.createdAt.createdAt
    //     : null
    // _original['id'] = _original.idOrder
    // _original['rider'] = null
    // _original.attendedBy['location'] = null
    // _original['idOrder'] = null
    // _original['location'] = _original.client && _original.client.location
    // _original.client['location'] = null
    // _original['addressRestaurant'] = null
    // _original['orderNumber'] = idOrder
    // _original['service'] = _original.service ? _original.service : null
    // _original['restaurantId'] = _original.restaurant
    //   ? _original.restaurant.id
    //   : null
    // _original['amount'] = dishes.amount
    // _original['dishes'] = dishes.dishes
    // _original['status'] = 'delivered'
  }

  finishOrderHistory (props) {
    const { row } = props
    const { user } = this.props
    const { idOrder, _original, dishes, rider } = row
    const timestamp = moment().valueOf()
    const adminData = {
      uid: user.uid,
      name: `${user.name} ${user.lastName}`,
      completedTime: timestamp
    }
    _original['attendedBy'] = _original.rider ? _original.rider.dataRider : null
    _original['createdAt'] =
      _original.createdAt && _original.createdAt.createdAt
        ? _original.createdAt.createdAt
        : null
    _original['id'] = _original.idOrder
    _original['rider'] = null
    _original.attendedBy['location'] = null
    _original['idOrder'] = null
    _original['location'] = _original.client && _original.client.location
    _original.client['location'] = null
    _original['addressRestaurant'] = null
    _original['orderNumber'] = idOrder
    _original['service'] = _original.service ? _original.service : null
    _original['restaurantId'] = _original.restaurant
      ? _original.restaurant.id
      : null
    _original['amount'] = dishes.amount
    _original['dishes'] = dishes.dishes
    _original['status'] = 'delivered'
    _original['completedBy'] = { ...adminData }
    Swal.fire({
      title: '¿La orden ya fue entregada al usuario?',
      text: ``,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Cambiarlo!'
    }).then(async result => {
      if (result.value) {
        dbHistory.child(idOrder).update({ ..._original }, () => {
          // Remover ordenes a Rider
          if (rider.dataRider) {
            let updates = {}
            updates[
              `partners/${rider.dataRider.deliveryId}/assignedOrders/${idOrder}`
            ] = null
            database.update(updates).then(() => {
              Swal.fire({
                position: 'center',
                type: 'success',
                title: `Datos actualizados con éxito.`,
                showConfirmButton: true,
                timer: 1500
              })
            })
          }
          _original.client && _original.client.uid
            ? dbPartners
              .child(_original.client.uid)
              .child('order')
              .remove()
              .then(() => {
                dbTestingOrders.child(idOrder).remove()
              })
            : dbTestingOrders.child(idOrder).remove()
        })
      }
    })
  }

  async cancelOrder (props) {
    const { row } = props
    const { idOrder, _original, dishes, rider } = row
    const { value: text } = await Swal.fire({
      input: 'textarea',
      inputPlaceholder: 'Escribe el motivo aqui...',
      inputAttributes: {
        'aria-label': '¿Desea cancelar la orden?, ¿Cual fue el motivo?'
      },
      showCancelButton: true
    })

    _original['createdAt'] =
      _original.createdAt && _original.createdAt.createdAt
        ? _original.createdAt.createdAt
        : null
    _original['id'] = _original.idOrder
    _original['rider'] = null
    _original['idOrder'] = null
    _original['location'] = _original.client && _original.client.location
    _original.client['location'] = null
    _original['addressRestaurant'] = null
    _original['orderNumber'] = idOrder
    _original['service'] = (_original.service && _original.service) || null
    _original['restaurantId'] = _original.restaurant
      ? _original.restaurant.id
      : null
    _original['amount'] = dishes.amount
    _original['dishes'] = dishes.dishes
    _original['status'] = 'order_cancelled'
    _original['cancelledComment'] = text
    delete _original['times']
    const fixedIdOrder = _original['version'] ? `-${idOrder}` : `${idOrder}`
    if (text) {
      Swal.fire(text)
      dbHistory.child(fixedIdOrder).update(
        {
          ..._original
        },
        () => {
          // Remover ordenes a Rider
          if (rider.dataRider) {
            let updates = {}
            updates[
              `partners/${rider.dataRider.deliveryId}/assignedOrders/${idOrder}`
            ] = null
            database.update(updates).then(() => {
              Swal.fire({
                position: 'center',
                type: 'success',
                title: `Datos actualizados con éxito.`,
                showConfirmButton: true,
                timer: 1500
              })
            })
          }
          _original.client && _original.client.uid
            ? dbPartners
              .child(_original.client.uid)
              .child('order')
              .remove()
              .then(() => {
                dbTestingOrders.child(fixedIdOrder).remove()
              })
            : dbTestingOrders.child(fixedIdOrder).remove()
        }
      )
    }
  }
  _addWhatsapp = e => {
    const phoneRegExp = /^[0][1-9]\d{9}$|^[1-9]\d{9}$/
    const { city } = this.state
    var whatsapp = e.target.value
    this.setState({ isLodaingInput: true })
    var validNumber = whatsapp.match(phoneRegExp)
    if (validNumber) {
      this.props.EditSettingsAction(
        'whatsapp',
        whatsapp,
        city,
        this._onCancel,
        () => {
          this.setState({ isLodaingInput: false })
        }
      )
    } else {
      this.setState({ isLodaingInput: false })
      Swal.fire({
        position: 'center',
        type: 'error',
        title: `El número no es válido.`,
        showConfirmButton: false,
        timer: 2500
      })
    }
  }
  _addRider = e => {
    const { city } = this.state
    var ridersValue = parseInt(e.target.value, 10)
    this.setState({ isLodaingInput: true })
    if (ridersValue) {
      this.props.EditSettingsAction(
        'riderActives',
        ridersValue,
        city,
        this._onCancel,
        () => {
          this.setState({ isLodaingInput: false })
        }
      )
    } else {
      this.setState({ isLodaingInput: false })
      Swal.fire({
        position: 'center',
        type: 'error',
        title: `Hubo un error.`,
        showConfirmButton: false,
        timer: 2500
      })
    }
  }
  _onChageValue = (input, e) => {
    this.setState({ [input]: e.target.value })
  }

  _onCancel = () => {
    this.setState({
      modal: {
        show: false
      },
      editModal: {
        show: false
      }
    })
  }

  render () {
    const {
      orders,
      modal: { show, content, showNotify },
      editModal: { title, showModal, modalUserContent },
      isLodaingInput
    } = this.state
    const arrayOrders = orders ? Object.values(orders) : []
    this.addStates(arrayOrders)
    const data = this.renderOrder(arrayOrders).reverse()
    const columns = this.renderHeader()
    return (
      <Page>
        <Row>
          <Col size={12}>
            <Panel
              title='Ordenes activas'
              options={
                <Row>
                  <Col size={2} offset={7}>
                    <label className='col-sm-2 control-label'>Whatsapp</label>
                    <Input
                      name='whatsapp'
                      classes={'col-md-12'}
                      placeholder={'whatsapp'}
                      onBlur={this._addWhatsapp}
                      onChange={e => this._onChageValue('whatsapp', e)}
                      value={this.state.whatsapp}
                    />
                  </Col>
                  <Col size={2}>
                    <Row>
                      <Col size={12}>
                        <label className='col-sm-12 control-label'>
                          Riders Activos
                        </label>
                      </Col>
                      <Col size={7}>
                        <Input
                          name='riderActives'
                          classes={'col-md-12'}
                          placeholder={''}
                          onBlur={this._addRider}
                          onChange={e => this._onChageValue('riderActives', e)}
                          value={this.state.riderActives}
                        />
                      </Col>
                      <Col size={2}>
                        {isLodaingInput && (
                          <Loader style={{ fontSize: 15, top: 5 }} />
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col size={12}>
                    {process.env.NODE_ENV === 'development' && (
                      <Col size={2} offset={1}>
                        <ButtonCreateOrder
                          style={{ backgroundColor: '#03A9F4', color: '#FFF' }}
                          variant='contained'
                          onClick={e => {
                            createOrderTest()
                          }}
                        >
                          Crear Orden Test
                        </ButtonCreateOrder>
                      </Col>
                    )}
                  </Col>
                </Row>
              }
            >
              <ButtonAlarm>
                <Button
                  variant='contained'
                  color='secondary'
                  endIcon={<Icon>volume_off</Icon>}
                  onClick={() =>
                    (document.getElementById('alertPlayer').loop = false)
                  }
                >
                  Detener alarma
                </Button>
              </ButtonAlarm>
              <ReactTable
                data={data}
                columns={columns}
                loading={this.state.isLoading}
                defaultPageSize={5}
                className='-striped'
                getTheadThProps={() => {
                  return {
                    style: {
                      outline: 0,
                      whiteSpace: 'pre-line',
                      wordWrap: 'break-word'
                    }
                  }
                }}
                getTdProps={() => {
                  return {
                    style: { whiteSpace: 'pre-line', wordWrap: 'break-word' }
                  }
                }}
              />
            </Panel>
          </Col>
        </Row>
        <Modal
          title='Detalles del platillo'
          onCancel={this.onCancel}
          show={show}
          content={content}
        />
        <Modal
          title='Enviar notificación'
          onCancel={this.onCancel}
          show={showNotify}
          content={content}
        />
        <Modal
          title={title}
          onCancel={this.onCancel}
          show={showModal}
          content={modalUserContent}
        />
      </Page>
    )
  }

  renderOrder (orderArray) {
    const { statusList, riders } = this.state
    return orderArray.map((order, i) => {
      var status
      const date = moment(order.createdAt).format('DD-MM-YYYY, HH:MM')
      const objDate = { date: date, createdAt: order.createdAt }
      const dishes = order.dishes ? order.dishes : null
      const amount = order.amount ? order.amount : null
      const { discount, orderTotal } = order

      const objDishes = { dishes, amount, orderTotal, discount }
      const diffTimeWithCurrent = DiffTimeWithCurrent(order.createdAt)
      const acceptedTime =
        order.acceptedBy && order.acceptedBy.acceptedTime
          ? order.acceptedBy.acceptedTime
          : false
      const acceptedTimeByRestaurant =
        order.acceptedByRestaurant && order.acceptedByRestaurant.acceptedTime
          ? order.acceptedByRestaurant.acceptedTime
          : false
      const receivedTime =
        order.receivedBy && order.receivedBy.receivedTime
          ? order.receivedBy.receivedTime
          : false
      const finishedTime =
        order.finishedBy && order.finishedBy.finishedTime
          ? order.finishedBy.finishedTime
          : false
      var objTime = {
        diffTimeWithCurrent,
        acceptedTime,
        acceptedTimeByRestaurant,
        receivedTime,
        finishedTime
      }
      const dataRider = (order.attendedBy && !order.pickUp
        ? {
          ...order.attendedBy
        }
        : order.pickUp && { msg: 'Lo recoge el cliente' }) || {
        msg: 'Sin rider asignado'
      }
      var statusSelected
      for (let i = 0; i < statusList.length; i++) {
        if (statusList[i].id === order.status) {
          status = statusList[i].value
          statusSelected = statusList[i].id
        }
      }
      return {
        ...order,
        idOrder: order.orderNumber,
        times: objTime,
        createdAt: objDate,
        client: {
          clientData: order.client,
          clientId: order.client && order.clientId ? order.clientId : null,
          location: {
            ...order.location
          }
        },
        location: {
          ...order.location,
          tracking: order.tracking ? order.tracking : null
        },
        dishes: objDishes,
        restaurant: order.restaurant,
        rider: {
          dataRider,
          riders
        },
        addressRestaurant: order.restaurant && {
          ...order.restaurant.address,
          tracking: order.tracking
        },
        status: {
          status,
          statusList,
          selected: statusSelected
        },
        service: order.service ? order.service : null
      }
    })
  }

  renderHeader () {
    return [
      {
        Header: '# Orden',
        accessor: 'idOrder',
        Cell: props => {
          const timeover =
            props.original.times && props.original.times.diffTimeWithCurrent
              ? props.original.times.diffTimeWithCurrent > 40
              : false
          const acceptedTime = props.original.times
            ? props.original.times.acceptedTime
            : false
          const acceptedTimeRestaurant = props.original.times
            ? props.original.times.acceptedTimeByRestaurant
            : null
          const receivedTime = props.original.times
            ? props.original.times.receivedTime
            : false
          const finishedTime = props.original.times
            ? props.original.times.finishedTime
            : false
          const timeMinutes = props.original.times
            ? props.original.times.diffTimeWithCurrent
            : false

          function diffTime (x, y) {
            let accepted = moment(x)
            let created = moment(y)

            let diff = accepted.diff(created, 'minutes')
            return diff
          }
          const acceptedRestaurantTime = acceptedTimeRestaurant
            ? diffTime(
              acceptedTimeRestaurant,
              props.original.createdAt.createdAt
            )
            : null
          const acceptedRiderTime = acceptedTime
            ? diffTime(acceptedTime, props.original.createdAt.createdAt)
            : null
          const receivedRiderTime = receivedTime
            ? diffTime(receivedTime, props.original.createdAt.createdAt)
            : null
          const finishedRiderTime = finishedTime
            ? diffTime(finishedTime, props.original.createdAt.createdAt)
            : null
          const assignedCurrentTime = acceptedTime
            ? diffTime(moment().valueOf(), acceptedTime)
            : null
          const acceptedCurrentTime = receivedTime
            ? diffTime(moment().valueOf(), receivedTime)
            : null
          const acceptedRestCurrentTime = acceptedTimeRestaurant
            ? diffTime(moment().valueOf(), acceptedTimeRestaurant)
            : null
          let bgColor =
            props.original.status.status === 'Por calificar'
              ? 'DarkGreen'
              : timeover ? 'tomato' : 'MediumSeaGreen'
          let color =
            timeover || props.original.status.status === 'Por calificar'
              ? 'white'
              : 'black'
          return (
            <OrderContent
              backgroundColor={bgColor}
              color={color}
              className={`animated fadeInRight`}
            >
              <a
                target='_blank'
                rel='noopener noreferrer'
                href={`${BE_URL}/testing_orders/orders/${props.value}`}
              >
                <span style={{ color: color }}>
                  {(props.value && props.value) || ''}
                </span>
              </a>

              <h4>{props.original && props.original.payType}</h4>
              <h4>
                {props.original.pickUp && props.original.pickUp && 'PickUp'}
              </h4>
              <span>
                {(props.original &&
                  moment(props.original.createdAt.createdAt).format(
                    'DD/MM/YY HH:mm'
                  )) ||
                  ''}
              </span>

              {timeMinutes && (
                <span>
                  <b>Tiempo transcurrido: </b>
                  <br />
                  {timeMinutes} min.
                </span>
              )}
              {acceptedTimeRestaurant && (
                <span>
                  <b>Hora Aceptado: </b>
                  <br />
                  {moment(acceptedTimeRestaurant).format('HH:mm')}
                </span>
              )}
              {acceptedRestaurantTime && (
                <span>
                  {acceptedRestaurantTime} min. - {acceptedRestCurrentTime} min.
                </span>
              )}
              {acceptedTime && (
                <span>
                  <b>Hora Asignado: </b>
                  <br />
                  {moment(acceptedTime).format('HH:mm')}
                </span>
              )}
              {acceptedRiderTime && (
                <span>
                  {acceptedRiderTime} min. - {assignedCurrentTime} min.
                </span>
              )}
              {receivedTime && (
                <span>
                  <b>Hora recibido: </b>
                  <br />
                  {moment(receivedTime).format('HH:mm')}
                </span>
              )}
              {receivedRiderTime && (
                <span>
                  {receivedRiderTime} min. - {acceptedCurrentTime} min.{' '}
                </span>
              )}
              {finishedTime && (
                <span>
                  <b>Hora finalizado: </b>
                  <br />
                  {moment(finishedTime).format('HH:mm')}
                </span>
              )}
              {finishedRiderTime && <span>{finishedRiderTime} min.</span>}
              {props.original.discount && (
                <React.Fragment>
                  <br />
                  <span>
                    <b>Descuento: </b>
                    {props.original.discount.type}
                  </span>
                </React.Fragment>
              )}
              {props.original.scheduled && (
                <React.Fragment>
                  <br />
                  <span>
                    <b>Programado: </b>
                    {Moment(props.original.scheduled).format('h:mm a')}
                  </span>
                </React.Fragment>
              )}
            </OrderContent>
          )
        }
      },
      {
        Header: 'Datos de Cliente',
        accessor: 'client',
        Cell: props =>
          props.value &&
          props.value.clientData && (
            <div className={`animated fadeInRight`}>
              <span>
                <b>Nombre: </b>
                {`${props.value.clientData.name || ''} ${props.value.clientData
                  .lastName || ''} ${props.value.clientData.motherLastName ||
                  ''}`}
              </span>
              <br />
              <br />
              {props.value.clientData.uid && (
                <ButtonEditUser
                  style={{ backgroundColor: 'GoldenRod', color: '#FFF' }}
                  variant='contained'
                  endIcon={<Icon>edit</Icon>}
                  onClick={() => this._editUserModal(props.row)}
                >
                  Editar Usuario
                </ButtonEditUser>
              )}
              <br />
              <br />
              <span>
                <b>Email: </b> {props.value.clientData.email}
              </span>
              <br />
              <br />
              <span>
                <b>Teléfono: </b> {props.value.clientData.phone}
              </span>
              <br />
              <br />
              {props.value.clientId && (
                <span>
                  <b>ID: </b>
                  <a
                    href={`${BE_URL}/partners/${props.value.clientId}`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {props.value.clientId.slice(-4)}
                  </a>
                </span>
              )}
              <br />
              <br />
              <b>Dirección: </b>
              <span>
                <a
                  href={
                    props.value.location && props.value.location.coords
                      ? `https://www.google.com/maps/place/${
                        props.value.location.coords.latitude
                      },${props.value.location.coords.longitude}/@${
                        props.value.location.coords.latitude
                      },${props.value.location.coords.longitude},17`
                      : `#`
                  }
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {(props.value.location &&
                    props.value.location.formatted_address) ||
                    ''}
                </a>
              </span>
              <br />
              <br />
              {props.value.location &&
                props.value.location.additional_information && (
                <React.Fragment>
                  <b>Información adicional: </b>
                  <span>{props.value.location.additional_information}</span>
                  <br />
                  <br />
                </React.Fragment>
              )}
              {props.value.clientData.fcmToken && (
                <ButtonNotify
                  style={{ backgroundColor: '#03A9F4', color: '#FFF' }}
                  variant='contained'
                  endIcon={<Icon>notifications</Icon>}
                  onClick={e => {
                    e.preventDefault()
                    this.sendNotificationModal(props.value.clientData.fcmToken)
                  }}
                >
                  Enviar notificación
                </ButtonNotify>
              )}
              <br />
            </div>
          )
      },
      {
        Header: 'Platillos',
        accessor: 'dishes',
        Cell: props => {
          return (
            <div className={`animated fadeInRight`}>
              <span>
                {props.value.discount ? (
                  <React.Fragment>
                    <b>Costo envió: </b>
                    {props.value.orderTotal
                      ? `$ ${props.value.discount.deliveryCost}`
                      : ''}
                    <br />
                    <b>Costo producto: </b>
                    {props.value.orderTotal
                      ? `$ ${props.value.discount.foodCost}`
                      : ''}
                    <br />
                    <b>Subtotal: </b>
                    {props.value.orderTotal
                      ? `$ ${props.value.discount.deliveryCost +
                          props.value.discount.foodCost}`
                      : ''}
                    <br />
                    <b>Cupón: </b>
                    {props.value.discount.coupon}
                    <br />
                    <b>
                      Descuento{' '}
                      {props.value.discount.type === 'shipping'
                        ? 'envió'
                        : 'producto'}:{' '}
                    </b>
                    {`$ -${props.value.discount.discountValue}`}
                    <br />
                    <b>Total: </b>
                    {props.value.orderTotal
                      ? `$ ${props.value.orderTotal.total}`
                      : ''}
                    <br />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <b>Costo envió: </b>
                    {props.value.orderTotal
                      ? `$ ${props.value.orderTotal.deliveryCost}`
                      : ''}
                    <br />
                    <b>Costo producto: </b>
                    {props.value.orderTotal
                      ? `$ ${props.value.orderTotal.subtotal}`
                      : ''}
                    <br />
                    <b>Total: </b>
                    {props.value.orderTotal
                      ? `$ ${props.value.orderTotal.total}`
                      : ''}
                    <br />
                  </React.Fragment>
                )}
              </span>
              <a
                onClick={e => {
                  e.preventDefault()
                  this.contentModal(props.value.dishes)
                }}
              >
                <span>
                  <b>Ver detalles de la orden</b>
                  <i className='fa fa-plus-circle btn-sm m-r-sm' />
                </span>
              </a>
            </div>
          )
        }
      },
      {
        Header: 'Restaurante / Teléfono',
        accessor: 'restaurant',
        Cell: props =>
          props.value && (
            <div className={`animated fadeInRight`}>
              <span>
                <b>Restaurante:</b>
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href={`${BE_URL}/restaurants/${props.value.id}`}
                >
                  {props.value.name}
                </a>
              </span>
              <br />
              <br />
              <span>
                <b>Telefono:</b> {props.value.phone}
              </span>
              <br />
              <br />
              <span>
                <b>Dirección: </b>{' '}
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href={
                    props.value.address && props.value.address.coords
                      ? `http://www.google.com/maps/place/${
                        props.value.address.coords.latitude
                      },
                      ${props.value.address.coords.longitude}/@${
              props.value.address.coords.latitude
            },
                      ${props.value.address.coords.longitude},17z`
                      : `#`
                  }
                >
                  {props.value.address
                    ? props.value.address.formatted
                    : `Sin dirección`}
                </a>
              </span>
            </div>
          )
      },
      {
        Header: 'Repartidor',
        accessor: 'rider',
        Cell: props => {
          const {
            value: { dataRider: { name, lastName, phone, deliveryId, msg } },
            row,
            original: { pickUp }
          } = props
          const timeMinutes = props.original.times
            ? props.original.times.diffTimeWithCurrent
            : false
          let bgColor = timeMinutes > 15 && !deliveryId ? 'tomato' : null
          let color = timeMinutes > 15 && !deliveryId ? 'white' : null
          return (
            <div className={`animated fadeInRight`}>
              {deliveryId ? (
                <div>
                  <span>
                    <b>Nombre:</b>
                    {` ${name} ${lastName}`}
                  </span>
                  <br />
                  <span>
                    <b>Teléfono:</b> {phone}
                  </span>
                  <br />
                  <span>
                    <b>Id:</b>
                    <a
                      href={`${BE_URL}/partners/${deliveryId}`}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {deliveryId.slice(-4)}
                    </a>
                  </span>
                  <br />
                </div>
              ) : (
                <div style={{ backgroundColor: bgColor, color: color }}>
                  <span>{msg}</span>
                </div>
              )}
              <br />
              {!pickUp && (
                <div style={{ flex: 1 }}>
                  <SelectRider
                    availableRiders={
                      this.state.availableRiders
                        ? this.state.availableRiders.results
                        : []
                    }
                    status={row.status.selected}
                    orderId={row.idOrder}
                    selectedRider={row.rider}
                    assignRider={this.assignRider}
                  />
                </div>
              )}
            </div>
          )
        }
      },
      {
        Header: 'Estado',
        accessor: 'status',
        Cell: props => {
          const { status, statusList, selected } = props.value
          const { row } = props
          const { rider, _original } = row
          const service = _original.service
          const statusRemoveRider =
            status === 'Por calificar' || rider === 'Sin rider asignado'
          const statusHistoryDisabled = rider === 'Sin rider asignado'
          const statusDelivered =
            status === 'Por calificar' ||
            rider === 'Sin rider asignado' ||
            status === 'Pendiente de repartidor'
          const statusPreparation =
            status === 'Por calificar' || status === 'En preparación' || service
          const statusRider =
            rider === 'Sin rider asignado' || status === 'En camino a repartir'

          let statusObj = {
            'Pendiente de repartidor': 'tomato',
            'En preparación': 'gray',
            'Listo para el repartidor': '#03a9f4',
            Preparado: 'MediumSeaGreen',
            'En camino a repartir': 'GoldenRod',
            'Orden Cancelada': 'red'
          }
          let bgColor = statusObj[status] || 'DarkGreen'

          return (
            <div className={`animated fadeInRight`}>
              <div
                style={{ backgroundColor: bgColor }}
                className={`animated fadeInRight`}
              >
                <span
                  style={{
                    color: 'white',
                    fontSize: 16
                  }}
                >
                  {status}
                </span>
                <br />
                <hr style={{ marginTop: 0 }} />
              </div>
              <Box justifyContent='center'>
                <FormControl fullWidth>
                  <InputLabel style={{ fontSize: 13 }} id='simple-select-label'>
                    Cambiar estado:
                  </InputLabel>
                  <Select
                    labelId='simple-select-label'
                    id='simple-select'
                    value={selected}
                    onChange={value =>
                      this.modifiedOrder(props, value.target.value)
                    }
                    disabled={props.original.scheduled}
                    style={{ fontSize: 12 }}
                  >
                    {statusList.map((item, i) => {
                      return (
                        <MenuItem value={item.id} key={i}>
                          {item.value}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Box>
              <hr />
              {props.original.takeSomeTime && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column'
                  }}
                  className={`animated fadeInRight`}
                >
                  <span style={{ fontWeight: 'bold' }}>
                    Tiempo de preparación:
                  </span>
                  <span>{props.original.takeSomeTime} min.</span>
                  <hr style={{ marginTop: 0 }} />
                </div>
              )}
              <WrapperActions
                style={{ marginBottom: 10 }}
                className={`animated fadeInRight`}
              >
                <ButtonAction
                  variant='contained'
                  disabled={statusPreparation || props.original.isScheduled}
                  onClick={e => this.modifiedOrder(props, 'preparation_time')}
                >
                  <IconAction
                    fontSize={'large'}
                    disabled={statusPreparation || props.original.isScheduled}
                    title={'En preparación'}
                    type={'cook'}
                  >
                    restaurant
                  </IconAction>
                </ButtonAction>
                <ButtonAction
                  variant='contained'
                  disabled={statusRider || props.original.isScheduled}
                  onClick={e =>
                    this.modifiedOrder(props, 'on_way_to_delivery_the_order')
                  }
                >
                  <IconAction
                    fontSize={'large'}
                    disabled={statusRider || props.original.isScheduled}
                    title={'En camino a repartir'}
                    type={'rider'}
                  >
                    two_wheeler
                  </IconAction>
                </ButtonAction>
                <ButtonAction
                  variant='contained'
                  disabled={statusDelivered || props.original.isScheduled}
                  onClick={e => this.modifiedOrder(props, 'delivered')}
                >
                  <IconAction
                    fontSize={'large'}
                    disabled={statusDelivered || props.original.isScheduled}
                    title={'Entregado'}
                    type={'delivered'}
                  >
                    done
                  </IconAction>
                </ButtonAction>
              </WrapperActions>
              <WrapperActions>
                <ButtonAction
                  variant='contained'
                  disabled={statusRemoveRider || props.original.isScheduled}
                  onClick={e => this.removeRider(props)}
                >
                  <IconAction
                    fontSize={'large'}
                    disabled={statusRemoveRider || props.original.isScheduled}
                    title={'Remover Repartidor'}
                    type={'rider'}
                  >
                    person_remove
                  </IconAction>
                </ButtonAction>
                <ButtonAction
                  variant='contained'
                  disabled={statusHistoryDisabled || props.original.isScheduled}
                  onClick={e => this.finishOrderHistory(props)}
                  // onClick={e => this._finishOrderHistory(props)}
                >
                  <IconAction
                    fontSize={'large'}
                    disabled={
                      statusHistoryDisabled || props.original.isScheduled
                    }
                    title={'Enviar al historial'}
                    type={'success'}
                  >
                    how_to_reg
                  </IconAction>
                </ButtonAction>
                <ButtonAction
                  variant='contained'
                  disabled={props.original.isScheduled}
                  onClick={e => this.cancelOrder(props)}
                >
                  <IconAction
                    fontSize={'large'}
                    disabled={props.original.isScheduled}
                    title={'Cancelar orden'}
                    type={'cancel'}
                  >
                    cancel
                  </IconAction>
                </ButtonAction>
              </WrapperActions>
            </div>
          )
        }
      }
    ]
  }

  addStates (orders) {
    let states = {}
    for (let i = 0; i < orders.length; i++) {
      states[`description${i}`] = false
    }
    // this.setState({...states})
  }

  async timeModal (id, adminData) {
    const { value: minutes } = await Swal.fire({
      title: 'Tiempo de preparación',
      input: 'radio',
      inputOptions: {
        '15': '15 min',
        '30': '30 min',
        '45': '45 min'
      },
      showCancelButton: true,
      inputValidator: value => {
        if (!value) {
          return 'Marque una opción'
        }
      }
    })
    if (minutes) {
      let updates = {}
      const timestamp = moment().valueOf()
      updates[`/${id}/takeSomeTime`] = minutes
      updates[`/${id}/acceptedByAdmin/acceptedTime`] = timestamp
      updates[`/${id}/acceptedByAdmin/uid/`] = adminData.id
      updates[`/${id}/acceptedByAdmin/name/`] = `${adminData.name} ${
        adminData.lastName
      }`
      dbTestingOrders
        .update(updates)
        .then(() =>
          Swal.fire({
            position: 'center',
            type: 'success',
            title: `Tiempo de preparacion añadido`,
            showConfirmButton: false,
            timer: 1500
          })
        )
        .catch(error => {
          Swal.fire({
            position: 'center',
            type: 'error',
            title: 'Ha ocurrido un error',
            text: `Error: ${error}`,
            showConfirmButton: false,
            timer: 1500
          })
        })
    }
  }

  sendNotification (values) {
    const { user: { city, selectCity } } = this.props
    const currentCity = selectCity || city
    var url = `${BE_URL}/apiAdmin/sendNotification/${currentCity}`
    Swal.fire({
      position: 'center',
      title: 'Your request is sending',
      showConfirmButton: false,
      allowOutsideClick: () => !Swal.isLoading(),
      onBeforeOpen: () => {
        Swal.showLoading()
      }
    })
    const body = JSON.stringify({ data: { ...values } })
    fetch(url, {
      method: 'POST',
      body
    })
      .then(response => {
        if (response.status !== 200) {
          return
        }
        return response.json()
      })
      .then(res => {
        Swal.close()
        if (res) {
          if (res.error) {
            Swal.fire({
              position: 'center',
              type: 'error',
              title: `${res.message}`,
              showConfirmButton: false,
              timer: 2500
            })
          }
          if (!res.failure) {
            Swal.fire({
              position: 'center',
              type: 'success',
              title: `${res.message}`,
              showConfirmButton: true,
              timer: 1500
            })
          }
        }
        this.setState({
          modal: {
            show: false,
            content: null
          }
        })
      })
      .catch(err => {
        Swal.close()
        Swal.fire({
          position: 'center',
          type: 'error',
          title: `${err.message}`,
          showConfirmButton: false,
          timer: 2500
        })
      })
  }

  sendNotificationModal (token) {
    const showNotify = 'show'
    const content = (
      <ModalContent>
        <Formik
          onSubmit={(values, actions) => {
            if (values) {
              values['token'] = token
              values['personal'] = true

              this.sendNotification(values)
            }
          }}
          render={({
            values,
            errors,
            handleBlur,
            handleChange,
            handleReset,
            loading
          }) => (
            <Form>
              <div className='modal-body'>
                <FormInput
                  placeholder='Titulo...'
                  name='title'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.title}
                  value={values.title}
                />
                <FormInput
                  placeholder='Mensaje...'
                  name='message'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.message}
                  value={values.message}
                />
              </div>
              <div className='pull-right'>
                <Button
                  style={{ backgroundColor: '#03A9F4', color: '#FFF' }}
                  variant='contained'
                  type='submit'
                  endIcon={<Icon>send</Icon>}
                >
                  Enviar
                </Button>
              </div>
            </Form>
          )}
        />
      </ModalContent>
    )
    this.setState({
      modal: {
        showNotify,
        content
      }
    })
  }
  contentModal (dishes) {
    const show = 'show'
    const content = dishes.map((dish, i) => {
      const { name, count, description, extras, indications, price } = dish
      const extrasList = extras && Object.values(extras)
      const hasExtras = !_.isEmpty(extrasList) && Array.isArray(extrasList[0])
      return (
        <ModalContent key={i}>
          <b>Platillo {i + 1}</b>
          <span>{name}</span>
          <span>
            <b>Cantidad: </b>
            {count}
          </span>
          <b>Descripción:</b>
          <span>{description}</span>
          <b>Costo:</b>
          <span>{price ? `$${price}` : ''}</span>
          {hasExtras && (
            <div>
              <b>Extra</b>
              <ol>
                {extrasList.map((extra, index) => (
                  <li key={index}>
                    <hr />
                    <ul>
                      {extra.map((item, j) => (
                        <li key={j}>
                          {item.name}
                          {item.price > 0 ? `- $${item.price}` : ''}
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ol>
            </div>
          )}
          {indications && (
            <div>
              <b>Indicaciones</b>
              <ul>
                <li>{indications}</li>
              </ul>
            </div>
          )}
          <hr />
        </ModalContent>
      )
    })
    this.setState({
      modal: {
        show,
        content
      }
    })
  }

  onCancel = () => {
    this.setState({
      modal: {
        show: false,
        content: null
      },
      editModal: {
        showModal: false,
        modalUserContent: null
      }
    })
  }

  _editUserModal = data => {
    let timerInterval
    Swal.fire({
      title: 'Procesando...',
      html: 'Cargando datos... espere un momento por favor.',
      timer: 2000,
      onBeforeOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          Swal.getContent()
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
      }
    }).then(() => {
      this.setState({
        editModal: {
          showModal: 'show',
          title: 'Editar Usuario',
          onEdit: this._onEdit,
          onCancel: this._onCancel,
          modalUserContent: (
            <UserInfo
              onEdit={this._onEdit}
              editOnOrder={data}
              adminProps={this.state.adminProps}
              cities={this.props.cities}
              close={this._onCancel}
            />
          )
        }
      })
    })
  }
  _onEdit = data => {
    this.props.editUser(data, this._onSaveCB, null)
    this.props.editClientDataOrder(data)
    this.setState({
      editModal: {
        show: false
      }
    })
  }
  _onSaveCB = payload => {
    if (payload.error) {
      this.setState({
        editModal: {
          ...this.state.editModal,
          error: payload.error,
          showModal: 'show'
        }
      })
    }
  }
}

function mapStateToProps (state) {
  return {
    user: state.profile,
    cities: state.data.settings.cities
  }
}

const mapDispatchToProps = dispatch => {
  return {
    EditSettingsAction: (type, whatsapp, cityId, cb, loader) =>
      dispatch(EditSettingsAction(type, whatsapp, cityId, cb, loader)),
    GetCities: cb => dispatch(GetCities(cb, false)),
    editUser: (user, CB, history) => dispatch(editUser(user, CB, history)),
    editClientDataOrder: data => dispatch(editClientDataOrder(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Orders)
