import React from 'react'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import _ from 'lodash'
import FormInput from '../../components/FormInput'
import Switch from '../../components/ui/Switch.js'
import { FormLayout, TimeGroup, ScheduleElement } from './styled'

const PromotionForm = props => {
  const { handleSubmit, initialValues } = props
  return (
    <Formik
      enableReinitialize
      initialValues={{
        top_position: initialValues.top_position || 0,
        is_top: initialValues.is_top || false
      }}
      onSubmit={handleSubmit}
      render={({
        values,
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        isValid
      }) => (
        <Form
          style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
        >
          <FormLayout isWidth={true}>
            <TimeGroup justify='center'>
              <ScheduleElement>
                <label>Activo</label>
                <Switch
                  on={values.is_top || false}
                  onClick={() => setFieldValue('is_top', !values.is_top)}
                />
              </ScheduleElement>
              <ScheduleElement>
                <FormInput
                  label='Posición'
                  placeholder='0'
                  name='top_position'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.top_position}
                  value={values.top_position}
                  style={{ width: 50 }}
                />
              </ScheduleElement>
            </TimeGroup>
            <TimeGroup justify='center'>
              <button
                type='submit'
                className='btn btn-primary'
                disabled={!isValid}
              >
                {'Guardar'}
              </button>
            </TimeGroup>
          </FormLayout>
        </Form>
      )}
    />
  )
}

const mapStateToProps = ({ data: { currentRestaurant } }) => {
  return {
    currentRestaurant: _.isEmpty(Object.keys(currentRestaurant))
      ? {}
      : currentRestaurant[Object.keys(currentRestaurant)[0]]
  }
}

export default connect(mapStateToProps, null)(PromotionForm)
