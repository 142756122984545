import React from 'react'
import ReactTable from 'react-table'
import { Row, Col } from '../ui/Layout'
import { Panel } from '../ui/'
import Icon from '@material-ui/core/Icon'

import moment from 'moment'

const ReportTable = ({ data }) => {
  const dataRiders = renderOrder(Object.values(data.orders).reverse())
  const columns = renderHeader()
  return (
    <ReactTable
      data={dataRiders}
      columns={columns}
      defaultPageSize={10}
      filterable
      loading={data.length === 0}
      getTheadThProps={() => {
        return {
          style: {
            outline: 0,
            whiteSpace: 'pre-line',
            wordWrap: 'break-word'
          }
        }
      }}
      getTdProps={() => {
        return {
          style: {
            whiteSpace: 'pre-line',
            wordWrap: 'break-word',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }
        }
      }}
    />
  )
}

const renderOrder = orders => {
  return orders.map((order, i) => {
    let keyOrder = order.orderId
    let date = moment(order.createdAt).format('DD/MM/YYYY')
    const { typePayment, deliveryCost } = order
    var discount = 0
    var typeDiscount = 0
    var totalOrder = 0
    var totalToPay = 0
    var deliveryCostOrder = 0
    var haveDiscount = false
    if (order.discount && order.discount.type === 'shipping') {
      const {
        discount: { discountValue, deliveryCost: cost, credit, percentage }
      } = order
      // Valor del culpon
      typeDiscount = credit
        ? percentage ? `${credit}%` : `$${credit.toFixed(2)}`
        : 0
      // Descuento
      discount = discountValue ? discountValue.toFixed(2) : 0
      // Valor del envio dentro de la orden(costo que se cobro a usuario)
      totalOrder = deliveryCost ? deliveryCost.toFixed(2) : 0
      // Valor a pagarla al repartidor.
      var getComissionPaypal = deliveryCost * 6 / 100
      if (typePayment === 'Paypal') {
        totalToPay = cost ? (cost - getComissionPaypal).toFixed(2) : 0
      } else {
        totalToPay = discountValue ? discountValue.toFixed(2) : 0
      }
      deliveryCostOrder = cost.toFixed(2)
      haveDiscount = true
    } else {
      totalOrder = deliveryCost ? deliveryCost.toFixed(2) : 0
    }
    var orderCell = {
      keyOrder,
      haveDiscount
    }
    return {
      date,
      keyOrder: orderCell,
      typeDiscount,
      discount,
      deliveryCostOrder,
      totalOrder,
      totalToPay,
      typePayment
    }
  })
}

const renderHeader = () => {
  return [
    {
      Header: 'Fecha',
      accessor: 'date',
      filterAll: true,
      width: 70,
      maxWidth: 70
    },
    {
      Header: 'No. Orden/Pedido',
      accessor: 'keyOrder',
      filterAll: true,
      Cell: props => (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {props.value.haveDiscount && (
            <div style={{ flex: 1, marginRight: '10px' }}>
              <Icon
                fontSize={'default'}
                style={{ verticalAlign: 'top-bottom' }}
              >
                confirmation_number_icon
              </Icon>
            </div>
          )}
          <div style={{ flex: 3 }}>{props.value.keyOrder}</div>
        </div>
      )
    },
    {
      Header: 'Tipo de Pago',
      accessor: 'typePayment',
      filterAll: true,
      width: 100,
      maxWidth: 100
    },
    {
      Header: 'Tipo Descuento',
      accessor: 'typeDiscount',
      width: 150,
      maxWidth: 150
    },
    {
      Header: 'Costo de envió',
      accessor: 'deliveryCostOrder',
      width: 150,
      maxWidth: 150
    },
    {
      Header: 'Descuento',
      accessor: 'discount',
      width: 150,
      maxWidth: 150
    },
    {
      Header: 'Costo pagado',
      accessor: 'totalOrder',
      width: 200,
      maxWidth: 200
    },
    {
      Header: 'Monto a pagar',
      accessor: 'totalToPay',
      width: 200,
      maxWidth: 200
    }
  ]
}

const DataInfo = ({ data }) => {
  return (
    <React.Fragment>
      <Row>
        <Col size={6}>
          <h3 style={{ color: 'black' }}>Detalles</h3>
        </Col>
      </Row>
      <Row>
        <Col size={2}>
          <span>Nombre del repartidor</span>
        </Col>
        <Col size={2}>
          <strong>
            {data.attendedBy.name} {data.attendedBy.lastName || ''}{' '}
            {data.attendedBy.motherLastName || ''}
          </strong>
        </Col>
      </Row>
      <Row>
        <Col size={2}>
          <span>Cuenta Firebase</span>
        </Col>
        <Col size={2}>
          <strong>{data.attendedBy.deliveryId || ''}</strong>
        </Col>
      </Row>
      <Row>
        <Col size={2}>
          <span>Correo electronico</span>
        </Col>
        <Col size={2}>
          <strong>{data.attendedBy.email || ''}</strong>
        </Col>
      </Row>
      <Row>
        <Col size={2}>
          <span>Teléfono</span>
        </Col>
        <Col size={2}>
          <strong>{data.attendedBy.phone}</strong>
        </Col>
      </Row>
      <Row>
        <Col size={2}>
          <span>Nombre del banco</span>
        </Col>
        <Col size={2}>
          <strong>{data.attendedBy.bank}</strong>
        </Col>
        <Col size={3} offset={4}>
          <span style={{ color: 'black' }}>RFC: </span>
          <strong>{data.attendedBy.rfc}</strong>
        </Col>
      </Row>
      <Row>
        <Col size={2}>
          <span>Cuenta</span>
        </Col>
        <Col size={2}>
          <strong>{data.attendedBy.bankAccount}</strong>
        </Col>
        <Col size={3} offset={4}>
          <span style={{ color: 'black' }}>Saldo Anterior </span>
          <strong>{`$ ${data.debt || 0}`}</strong>
        </Col>
      </Row>
      <Row>
        <Col size={3} offset={8}>
          <span style={{ color: 'black' }}>Total Paypal </span>
          <strong>{`$ ${(data.paypalAll && data.paypalAll.toFixed(2)) ||
            0}`}</strong>
        </Col>
      </Row>
      <Row>
        <Col size={2}>
          <span>CLABE</span>
        </Col>
        <Col size={2}>
          <strong>{data.attendedBy.clabe}</strong>
        </Col>
        <Col size={3} offset={4}>
          <span style={{ color: 'black' }}>Total Efectivo </span>
          <strong>{`$ ${(data.cashAll && data.cashAll.toFixed(2)) ||
            0}`}</strong>
        </Col>
      </Row>
      <Row>
        <Col size={3} offset={8}>
          <span style={{ color: 'black' }}>Total Tarjeta(Paycode) </span>
          <strong>{`$ ${(data.cardAll && data.cardAll.toFixed(2)) ||
            0}`}</strong>
        </Col>
      </Row>
      <Row>
        <Col size={3} offset={8}>
          <span style={{ color: 'black' }}>Total Descuentos </span>
          <strong>{`$ ${(data.discountAll && data.discountAll.toFixed(2)) ||
            0}`}</strong>
        </Col>
      </Row>
      <Row>
        <Col size={3} offset={9}>
          <h4 style={{ color: 'black' }}>Saldo Actual </h4>
          <strong
            style={{
              fontSize: 34,
              color: `${data.yumDebt ? 'green' : 'tomato'}`
            }}
          >{`$ ${(data.total && data.total.toFixed(2)) || 0}`}</strong>
          {!data.yumDebt ? <h5>*Saldo en contra</h5> : <h5>*Saldo a favor</h5>}
        </Col>
      </Row>
    </React.Fragment>
  )
}

const ReportTableRiders = ({ data }) => {
  return (
    <Panel title={`${data.attendedBy.name} ${data.attendedBy.lastName || ''}`}>
      <DataInfo data={data} />
      <ReportTable data={data} />
      <Col size={2} offset={10}>
        <h4>{`Total: $ ${data.sumDeliveryTotal + data.discountAll || ''}`}</h4>
      </Col>
    </Panel>
  )
}

export default ReportTableRiders
