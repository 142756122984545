import React, { Component } from 'react'
import { connect } from 'react-redux'
import fb from '../../firebase'
import { Panel } from '../../components/ui/'
import { Row, Col, Page } from '../../components/ui/Layout'
import RiderForm from './riderForm'
import { updateRiderInfo, deleteRider } from './actions'
import { initialize, reset } from 'redux-form'
import { setRiderDataToEdit } from './actions'

const ridersDB = fb.database().ref('partners')

class Home extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    const { currentRider, deleteRider, history } = this.props
    return (
      <Page>
        <Row>
          <Col size={9}>
            <Panel title='Generales del repartidor'>
              <RiderForm
                initialValues={currentRider}
                handleSubmit={e => updateRiderInfo(e, history)}
                enableReinitialize
                deleteRider={() => {
                  deleteRider(currentRider.id, history)
                }}
              />
            </Panel>
          </Col>
        </Row>
      </Page>
    )
  }
  //= ====================
  // Extra methods
  selectDish = key => {
    const { currentDish } = this.state
    const { initialize } = this.props
    if (currentDish === key) {
      initialize('dish', {})
    }
    this.setState({
      currentDish: currentDish === key ? null : key
    })
  }
}

const mapStateToProps = ({ profile, data: { currentRider } }) => ({
  profile,
  currentRider
})

const mapDispatchToProps = dispatch => ({
  // addDish: (toEdit, typeUser) => dispatch(addDish(toEdit, typeUser)),
  // deleteDish: (key, typeUser) => dispatch(deleteDish(key, typeUser)),
  reset: form => dispatch(reset(form)),
  initialize: (form, data) => dispatch(initialize(form, data)),
  setRiderDataToEdit: payload => dispatch(setRiderDataToEdit(payload)),
  deleteRider: (id, history) => dispatch(deleteRider(id, history))
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
