import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import session from 'redux-persist/lib/storage/session'
import { reducer as formReducer } from 'redux-form'
import { combineReducers } from 'redux'
import { logger } from 'redux-logger'

import {
  prospects,
  ridersProspects,
  restaurants,
  profile,
  dealers,
  geofence,
  currentRestaurant,
  currentRider,
  extrasList,
  ui as loadingUi,
  users,
  currentDish,
  indicators,
  settings,
  availableRiders,
  coupons
} from './reducers'

const storageReducers = combineReducers({
  prospects,
  ridersProspects,
  restaurants,
  currentRestaurant,
  dealers,
  currentRider,
  extrasList,
  ui: loadingUi,
  users,
  currentDish,
  indicators,
  settings,
  availableRiders,
  coupons
})

const persistedReducer = persistReducer(
  {
    key: 'data',
    blacklist: [
      'prospects',
      'currentRestaurant',
      'restaurants',
      'ui',
      'dealers',
      'users',
      'availableRiders',
      'coupons'
    ],
    storage
  },
  storageReducers
)

const sessionReducer = persistReducer(
  {
    key: 'root',
    blacklist: [
      'prospects',
      'currentRestaurant',
      'restaurants',
      'ui',
      'dealers',
      'permissions'
    ],
    storage: session
  },
  profile
)

// Add the reducer to your store on the `routing` key

const finalStore = combineReducers({
  data: persistedReducer,
  profile: sessionReducer,
  form: formReducer,
  ui: loadingUi,
  geofence
})

export default createStore(
  finalStore,
  process.env.NODE_ENV === 'development'
    ? applyMiddleware(thunk)
    : applyMiddleware(thunk)
)
