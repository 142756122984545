import fb from '../../firebase'
import _ from 'lodash'
import { stopLoading } from '../../actions/ui'
import Swal from 'sweetalert2'
import getEndpoint from '../../helpers/getEndpoint'
import { reset } from 'redux-form'

const ridersDB = fb.database().ref('partners')
// const dishesDB = fb.database().ref('dishes')
const URL_AWS = getEndpoint('REACT_APP_ENDPOINT_AWS')
const DJANGO_TOKEN = getEndpoint('REACT_APP_DJANGO_TOKEN')

export const initialScript = () => {
  // dishesDB.on('value', (snapshot) => {
  //   const dishes = snapshot.val()
  //   Object.keys(dishes).forEach(dishKey => {
  //     dishesDB.child(`${dishKey}`).update({active: true})
  //   })
  // })
}

export const updateRiderInfo = (data, history) => {
  var updateData = new FormData()
  if (!_.isEmpty(data.avatar) && !_.isEmpty(data.avatar.file)) {
    updateData.append('localFile', data.avatar.file)
    updateData.append('folder', data.avatar.folder)
    updateData.append('width', data.avatar.width)
    updateData.append('height', data.avatar.height)
    updateData.append('namePhoto', Date.now())
  }
  _.hasIn(data, 'name') && updateData.append('name', data.name)
  _.hasIn(data, 'last_name') && updateData.append('lastName', data.last_name)
  _.hasIn(data, 'mother_last_name') &&
    updateData.append('motherLastName', data.mother_last_name)
  _.hasIn(data, 'phone') && updateData.append('phone', data.phone)
  _.hasIn(data, 'email') && updateData.append('email', data.email)
  _.hasIn(data, 'banned') && updateData.append('banned', data.banned)
  _.hasIn(data, 'in_service') && updateData.append('inService', data.in_service)
  // >>>>>>????? Why this data ??
  updateData.append('isVisible', true)
  const url = `${URL_AWS}v3/riders/${data.foreign_key}/`
  fetch(url, {
    method: 'PATCH',
    headers: {
      Authorization: `token ${DJANGO_TOKEN}`
    },
    body: updateData
  })
    .then(res => {
      return res.json()
    })
    .then(result => {
      if (result.detail) {
        return Swal.fire({
          position: 'center',
          type: 'error',
          title: 'Ha ocurrido un error al actualizar',
          text: `Error: ${result.detail}`,
          showConfirmButton: true
        })
      }
      if (result.success === false) {
        return Swal.fire({
          position: 'center',
          type: 'error',
          title: 'Ha ocurrido un error al actualizar',
          text: `Error: ${result.message}`,
          showConfirmButton: true
        })
      }
      history && history.push('/home')
      if (_.hasIn(data, 'banned') || _.hasIn(data, 'in_service')) {
        return
      }
      return Swal.fire({
        position: 'center',
        type: 'success',
        title: 'Datos Actualizados correctamente',
        showConfirmButton: false,
        timer: 2500
      })
    })
    .catch(e => {
      Swal.fire({
        position: 'center',
        type: 'error',
        title: 'Ha ocurrido un error al actualizar',
        text: `Error: ${e}`,
        showConfirmButton: false,
        timer: 1500
      })
    })
}

// clean assignedOrders from riders
export const cleanRider = payload => {
  return dispatch => {
    ridersDB
      .child(payload)
      .update({ assignedOrders: null })
      .then(() => {
        Swal.fire({
          position: 'center',
          type: 'success',
          title: 'Datos de ordenes se han limpiado',
          showConfirmButton: false,
          timer: 2500
        })
      })
  }
}

// Update current restaurant when edit as admin
export const setRiderDataToEdit = payload => {
  return dispatch => {
    dispatch({ type: 'GET_CURRENT_RIDER', payload: payload })
    if (payload) {
      ridersDB.child(payload.id).on('child_changed', snp => {
        const data = snp.val()
        const dataKey = snp.key
        dispatch({
          type: 'EDIT_CURRENT_RIDER',
          key: dataKey,
          payload: data
        })
        dispatch(stopLoading())
        Swal.fire({
          position: 'center',
          type: 'success',
          title: 'Datos Actualizados',
          showConfirmButton: false,
          timer: 1500
        })
      })
    }
  }
}

export const activateRider = (id, inService) => {
  return dispatch => {
    Swal.fire({
      title: inService
        ? '¿Estás seguro de deshabilitar del servicio a este Rider?'
        : '¿Estás seguro de poner En Servicio a este Rider?',
      type: 'warning',
      showCancelButton: true
    }).then(result => {
      if (result.value) {
        const data = {}
        data['in_service'] = !inService
        data['foreign_key'] = id
        updateRiderInfo(data)
        window.location.reload()
      }
    })
  }
}

export const deleteRider = (id, history) => {
  return dispatch => {
    Swal.fire({
      title: '¿Estás seguro de eliminar a este Rider?',
      type: 'warning',
      showCancelButton: true
    }).then(result => {
      if (result.value) {
        ridersDB
          .child(id)
          .remove(() => {
            Swal.fire({
              position: 'center',
              type: 'success',
              title: 'Rider eliminado con exito',
              showConfirmButton: false,
              timer: 1500,
              onClose: () => {
                history.goBack()
              }
            })
          })
          .catch(e => {
            Swal.fire({
              position: 'center',
              type: 'error',
              title: 'Ha ocurrido un error',
              text: `Error: ${e}`,
              showConfirmButton: false,
              timer: 1500
            })
          })
      }
    })
  }
}

export const bannedRider = data => {
  return distpatch => {
    const {
      id,
      timestamp,
      subject,
      adminName,
      adminLN,
      adminID,
      bannedCount
    } = data
    let updates = {}

    updates[
      `/${id}/bannedInfo/${timestamp}/admin/name`
    ] = `${adminName} ${adminLN || ''}`
    updates[`/${id}/bannedInfo/${timestamp}/admin/key`] = adminID
    updates[`/${id}/bannedInfo/${timestamp}/subject`] = subject
    updates[`/${id}/bannedNumber`] = bannedCount + 1
    updates[`/${id}/banned`] = true
    let dataBanned = {}
    dataBanned['banned'] = true
    dataBanned['foreign_key'] = id
    updateRiderInfo(dataBanned)
    ridersDB.update(updates).then(() => {
      let timerInterval
      Swal.fire({
        title: 'Procesando...',
        html: 'Espere un momento por favor, los datos estan siendo procesados.',
        timer: 2000,
        onBeforeOpen: () => {
          Swal.showLoading()
          timerInterval = setInterval(() => {
            Swal.getContent()
          }, 100)
        },
        onClose: () => {
          clearInterval(timerInterval)
        }
      })
        .then(() => {
          Swal.fire({
            position: 'center',
            type: 'success',
            title: `Rider bloqueado, es necesario recargar la pagina para ver los cambios`,
            showConfirmButton: true
          })
        })
        .catch(error => {
          Swal.fire({
            position: 'center',
            type: 'error',
            title: 'Ha ocurrido un error',
            text: `Error: ${error}`,
            showConfirmButton: false,
            timer: 1500
          })
        })
    })
  }
}

export const desbanRider = id => {
  return dispatch => {
    Swal.fire({
      title: '¿Estás seguro de desbloquear a este Rider?',
      type: 'warning',
      showCancelButton: true
    }).then(result => {
      if (result.value) {
        let dataBanned = {}
        dataBanned['banned'] = false
        dataBanned['foreign_key'] = id
        updateRiderInfo(dataBanned)
        ridersDB
          .child(id)
          .update({ banned: false })
          .then(() => {
            let timerInterval
            Swal.fire({
              title: 'Procesando...',
              html:
                'Espere un momento por favor, los datos estan siendo procesados.',
              timer: 2000,
              onBeforeOpen: () => {
                Swal.showLoading()
                timerInterval = setInterval(() => {
                  Swal.getContent()
                }, 100)
              },
              onClose: () => {
                clearInterval(timerInterval)
              }
            })
              .then(() => {
                Swal.fire({
                  position: 'center',
                  type: 'success',
                  title: `Rider desbloqueado, es necesario recargar la pagina para ver los cambios`,
                  showConfirmButton: true
                })
              })
              .catch(error => {
                Swal.fire({
                  position: 'center',
                  type: 'error',
                  title: 'Ha ocurrido un error',
                  text: `Error: ${error}`,
                  showConfirmButton: false,
                  timer: 1500
                })
              })
          })
      }
    })
  }
}
