import React, { useEffect, useState } from 'react'
import ReactTable from 'react-table'
import InputLabel from '@material-ui/core/InputLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import { TextField, Checkbox, Select, MenuItem } from '@material-ui/core'
import axios from 'axios'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { Panel } from '../../components/ui/'
import { Row, Col, Page } from '../../components/ui/Layout'
import Switch from '../../components/switch'
import { couponRegister, searchCoupon } from '../../actions/coupons'
import getEndpoint from '../../helpers/getEndpoint'
import { Button } from '../../components/ui'
import ExportDataCoupons from '../../components/exportDataCoupons'
import _ from 'lodash'
import moment from 'moment'
moment.locale('es')

const BE_URL = getEndpoint('REACT_APP_ENDPOINT_AWS')

const CouponsSchema = Yup.object().shape({
  description: Yup.string().required('Campo requerido'),
  credit: Yup.number().required('Campo requerido'),
  type: Yup.string().required('Campo requerido'),
  reference: Yup.string()
    .required('La "referencia" debe ser de al menos 8 caracteres.')
    .min(8)
    .max(8)
})

function Coupons (props) {
  let city = props.selectCity || props.city
  const [initialValues, setInitialValues] = useState({
    type: '',
    reference: '',
    credit: '',
    description: '',
    isPercentage: false,
    numberOfCoupons: 1
  })
  const [pages, setPages] = useState(0)
  const [couponsData, setCouponsData] = useState([])
  const [newCouponsData, setNewCouponsData] = useState([])
  const [couponFoundData, setCouponFoundData] = useState([])

  const { couponRegister, searchCoupon } = props

  useEffect(
    () => {
      setNewCouponsData(props.newCoupons)
      return () => {
        setNewCouponsData([])
      }
    },
    [props.newCoupons]
  )

  useEffect(
    () => {
      setCouponFoundData(props.couponFound)
      return () => {
        setCouponFoundData([])
      }
    },
    [props.couponFound]
  )

  const renderCouponFound = orders => {
    if (_.isEmpty(orders)) return []
    return orders.map((order, i) => {
      const displayName = `${order.client.clientData.name || ''} ${order.client
        .clientData.lastName || ''} ${order.client.clientData.motherLastName ||
        ''}`
      const date = moment(order.createdAt).format('DD-MM-YYYY')
      return {
        restaurant: order.restaurant.name,
        createdAt: date,
        redeemed: displayName,
        coupon: order.discount.coupon,
        order: order.orderNumber,
        discount: order.discount
      }
    })
  }

  const columnsCoupons = [
    {
      Header: 'Cupon',
      accessor: 'coupon',
      filterable: false,
      width: 150,
      style: {
        display: 'flex',
        justifyContent: 'center'
      }
    },
    {
      Header: 'Orden',
      accessor: 'order',
      filterable: false,
      width: 200,
      style: {
        display: 'flex',
        justifyContent: 'center'
      }
    },
    {
      Header: 'Fecha de de orden',
      accessor: 'createdAt',
      filterable: false,
      sortable: false,
      width: 150,
      style: {
        display: 'flex',
        justifyContent: 'center'
      }
    },
    {
      Header: 'Redimido por',
      accessor: 'redeemed',
      filterable: false,
      sortable: false,
      width: 250,
      style: {
        display: 'flex',
        justifyContent: 'center'
      }
    },
    {
      Header: 'Restaurant',
      accessor: 'restaurant',
      filterable: false,
      sortable: false,
      width: 200,
      style: {
        display: 'flex',
        justifyContent: 'center'
      }
    },
    {
      Header: 'Costos',
      accessor: 'discount',
      filterable: false,
      sortable: false,
      width: 200,
      style: {
        display: 'flex',
        justifyContent: 'center'
      },
      Cell: props => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ flex: 1 }}>
            <span>{`Credito: ${props.value.credit}${
              props.value.percentage ? '%' : ''
            }`}</span>
          </div>
          <div style={{ flex: 1 }}>
            <span>{`Tipo: ${props.value.type}`}</span>
          </div>
          {props.value.type === 'food' ? (
            <div style={{ flex: 1 }}>
              <span>{`Costo de Producto: ${props.value.foodCost}`}</span>
            </div>
          ) : (
            <div style={{ flex: 1 }}>
              <span>{`Costo de Envio: ${props.value.deliveryCost}`}</span>
            </div>
          )}
          <div style={{ flex: 1 }}>
            <span>{`Descuento total: ${props.value.discountValue}`}</span>
          </div>
          <div style={{ flex: 1 }}>
            <span>{`Pagado con descuento: ${props.value.amount}`}</span>
          </div>
        </div>
      )
    }
  ]

  const columns = [
    {
      Header: 'Cupon',
      accessor: 'coupon',
      sortable: false,
      filterable: true
    },
    {
      Header: 'Credito',
      accessor: 'credit',
      filterable: false,
      sortable: false
    },
    {
      Header: 'Porcentaje',
      accessor: 'percentage',
      filterable: false,
      Cell: props => {
        return <Switch on={props.value} />
      }
    },
    {
      Header: 'Descripción',
      accessor: 'description',
      filterable: false,
      sortable: false
    },
    {
      Header: 'Fecha de expiración',
      accessor: 'expired_at',
      filterable: false,
      sortable: false
    },
    {
      Header: 'Redimido',
      accessor: 'redeemed',
      filterable: false,
      Cell: props => {
        return <Switch on={props.value} />
      }
    },
    {
      Header: 'Expirado/Usado',
      accessor: 'expired',
      filterable: false,
      Cell: props => {
        return <Switch on={props.value} />
      }
    },
    {
      Header: 'Redimido por',
      accessor: 'redeemed_by',
      filterable: true
    },
    {
      Header: 'Tipo',
      accessor: 'type',
      filterable: true,
      sortable: false
    }
  ]

  const renderSearchTable = renderCouponFound(couponFoundData)
  return (
    <Page>
      <Row classes={'m-b-sm'}>
        <Col size={6}>
          <Formik
            validationSchema={CouponsSchema}
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              const cb = () => {
                setInitialValues({
                  type: '',
                  reference: '',
                  credit: '',
                  description: '',
                  isPercentage: false,
                  numberOfCoupons: 1,
                  isGenerator: false
                })
              }
              couponRegister({ ...values, city }, cb)
            }}
            validateOnChange={false}
            validateOnBlur={false}
            render={({ values, errors, handleBlur, handleChange, loading }) => (
              <Row>
                <Col size={12} offset={0}>
                  <Form>
                    <Panel title='Crear cupones.'>
                      <FormControl fullWidth>
                        <TextField
                          label={errors.reference || 'Referencia'}
                          id='reference-basic'
                          placeholder='YUM...'
                          name='reference'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.reference}
                          value={(values && values.reference) || ''}
                          inputProps={{
                            maxLength: 8
                          }}
                        />

                        <FormGroup>
                          <TextField
                            label='Credito'
                            id='credit-basic'
                            placeholder='25...'
                            name='credit'
                            type='number'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.credit}
                            value={values && values.credit}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.isPercentage}
                                onChange={handleChange}
                                name='isPercentage'
                                error={errors.isPercentage}
                              />
                            }
                            label='Porcentaje'
                          />
                        </FormGroup>
                        <TextField
                          label='Descripción'
                          id='description-basic'
                          placeholder='Descripción...'
                          name='description'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.description}
                          value={(values && values.description) || ''}
                        />
                        <FormControl error={errors.type}>
                          <InputLabel id='type-coupon-select-label'>
                            Tipo de cupón
                          </InputLabel>
                          <Select
                            labelId='type-coupon-select-label'
                            id='type-coupon'
                            value={values.type}
                            onChange={handleChange('type')}
                          >
                            <MenuItem value=''>
                              <em>Seleccionar tipo</em>
                            </MenuItem>
                            <MenuItem value={'shipping'}>Envió</MenuItem>
                            <MenuItem value={'food'}>Comida</MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl style={{ paddingTop: 10 }}>
                          <TextField
                            id='expiration-date'
                            label='Fecha de expiración'
                            type='date'
                            InputLabelProps={{
                              shrink: true
                            }}
                            name='expiredAt'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.expiredAt}
                            size={'medium'}
                            value={(values && values.expiredAt) || ''}
                          />
                        </FormControl>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.isGenerator}
                              onChange={handleChange}
                              name='isGenerator'
                              error={errors.isGenerator}
                            />
                          }
                          label='Es maestro'
                        />
                        <TextField
                          label='Número de cupones'
                          id='numberOfCoupons-basic'
                          placeholder='10...'
                          name='numberOfCoupons'
                          type='number'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.numberOfCoupons}
                          value={values && values.numberOfCoupons}
                        />
                      </FormControl>
                      <Col size={5} offset={7} classes={'m-t-sm'}>
                        <button
                          type='submit'
                          className='btn btn-secondary'
                          disabled={loading}
                        >
                          Crear cupones
                        </button>
                      </Col>
                    </Panel>
                  </Form>
                </Col>
              </Row>
            )}
          />
        </Col>
        <Col size={6}>
          <Panel title='Lista de cupones creados.'>
            <ol>
              {newCouponsData.map((coupon, index) => (
                <React.Fragment>
                  <li key={index}>{coupon.coupon}</li>
                </React.Fragment>
              ))}
            </ol>
          </Panel>
        </Col>
      </Row>
      <Row classes={'m-b-sm'}>
        <Col size={12}>
          <Panel>
            <ReactTable
              data={couponsData}
              sortable={true}
              manual
              pages={pages}
              pageSize={20}
              showPageSizeOptions={false}
              onFetchData={(state, instance) => {
                const searchFilter =
                  state.filtered && state.filtered[0] && state.filtered[0].value
                    ? state.filtered[0].value
                    : ''
                const searchFilterTwo =
                  state.filtered && state.filtered[1] && state.filtered[1].value
                    ? state.filtered[1].value
                    : ''
                const searchFilterThree =
                  state.filtered && state.filtered[2] && state.filtered[2].value
                    ? state.filtered[2].value
                    : ''
                if (
                  (searchFilter.length > 3 && searchFilter.length !== 0) ||
                  (searchFilterTwo.length > 3 &&
                    searchFilterTwo.length !== 0) ||
                  (searchFilterThree.length > 3 &&
                    searchFilterThree.length !== 0)
                ) {
                  let url = `${BE_URL}v1/coupons/?${
                    state.filtered[0].id
                  }=${searchFilter}${
                    state.filtered[1]
                      ? `&${state.filtered[1].id}=${searchFilterTwo}`
                      : ''
                  }${
                    state.filtered[2]
                      ? `&${state.filtered[2].id}=${searchFilterThree}`
                      : ''
                  }`
                  axios.get(url).then(res => {
                    // Update react-table
                    setCouponsData(res.data.results)
                    var pages = Math.ceil(res.data.count / 20)
                    setPages(pages)
                  })
                  return
                }
                axios
                  .get(`${BE_URL}v1/coupons/?page=${state.page + 1}`)
                  .then(res => {
                    // Update react-table
                    setCouponsData(res.data.results)
                    var pages = Math.ceil(res.data.count / 20)
                    setPages(pages)
                  })
              }}
              columns={columns}
              defaultPageSize={20}
              filterable
              getTheadThProps={() => {
                return {
                  style: {
                    outline: 0,
                    whiteSpace: 'pre-line',
                    wordWrap: 'break-word'
                  }
                }
              }}
              getTdProps={() => {
                return {
                  style: {
                    whiteSpace: 'pre-line',
                    wordWrap: 'break-word',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }
                }
              }}
            />
          </Panel>
        </Col>
      </Row>
      <Row classes={'m-b-sm'}>
        <Col size={12}>
          <Row>
            <Col size={4}>
              <Panel>
                <Formik
                  initialValues={initialValues}
                  onSubmit={(values, actions) => {
                    searchCoupon(values.searchCoupon)
                  }}
                  enableReinitialize
                  render={({
                    values,
                    errors,
                    handleBlur,
                    handleChange,
                    loading
                  }) => (
                    <Form>
                      <FormControl fullWidth>
                        <TextField
                          id='search-coupon'
                          label='Busqueda de cupon'
                          type='text'
                          InputLabelProps={{
                            shrink: true
                          }}
                          name='searchCoupon'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.searchCoupon}
                          size={'medium'}
                          value={(values && values.searchCoupon) || ''}
                        />
                      </FormControl>
                      <Col size={4} offset={8} classes={'m-t-sm'}>
                        <Button
                          label='Buscar'
                          color='btn-success'
                          disabled={!values.searchCoupon}
                        />
                      </Col>
                    </Form>
                  )}
                />
              </Panel>
            </Col>
          </Row>
        </Col>
        <Col size={12}>
          <Panel title='Lista de cupones encontrados.'>
            {!_.isEmpty(renderSearchTable) && (
              <Col size={12}>
                <ExportDataCoupons data={renderSearchTable} />
              </Col>
            )}
            <ReactTable
              data={renderSearchTable}
              sortable={true}
              pageSize={20}
              columns={columnsCoupons}
            />
          </Panel>
        </Col>
      </Row>
    </Page>
  )
}

const mapDispatchToProps = dispatch => ({
  couponRegister: (data, cb) => dispatch(couponRegister(data, cb)),
  searchCoupon: data => dispatch(searchCoupon(data))
})
const mapStateToProps = ({ profile: { city, selectCity }, data }) => {
  return {
    city: city,
    selectCity: selectCity,
    newCoupons: data.coupons.newCoupons,
    couponFound: data.coupons.couponFound
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Coupons)
