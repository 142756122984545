import styled from 'styled-components'

export const Wrapper = styled.div`
  flex: 1;
`

export const SubWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
`

export const OrderWrap = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  ${({ column }) => column && 'flex-direction: column'};
`

export const OrderInfo = styled.div`
  flex: 1;
  display: flex;
  background: #f0f0f0;
`

export const Avatar = styled.div`
  background: url(${props =>
    props.src ? props.src : 'http://yumdeliver.com/img/yum-logo-circle.png'})
    no-repeat;
  background-size: cover;
  border-radius: 50%;
  min-width: 100px;
  min-height: 100px;
  max-width: 100px;
  max-height: 100px;
  border: 4px solid gray;
  padding-bottom: 30px;
`

export const Text = styled.p`
  font-size: ${({ size }) => size || '15px'};
  ${({ bold }) => bold && 'font-weight: bold'};
`

export const InfoWrap = styled.div`
  flex: ${({ flex }) => flex || 1};
  display: flex;
  padding: ${({ padding }) => padding || '20px'};
  justify-content: center;
  align-items: ${({ info }) => (info ? 'flex-start' : 'center')};
  flex-direction: ${({ row }) => (row ? 'row' : 'column')};

  & div {
    padding: 5px;
  }
`
export const TextWrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: ${({ row }) => (row ? 'row' : 'column')};
  align-items: ${({ info }) => (info ? 'flex-start' : 'flex-end')};
  padding: ${({ row }) => (row ? '0' : '10px 0')};
  && p {
    margin: 0;
  }

  ${({ row }) =>
    row &&
    `
    & p:first-child {
      padding: 0 50px 0 10px;
    }
  `};
`
export const Element = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  ${({ background }) => background && `background: ${background};`};
  ${({ align }) => align && `align-items: ${align}`};
`

/** ***** DISHES ********/
export const DishWrap = styled.div`
  flex: ${({ scroller }) => (scroller ? null : 1)};
  width: 100%;
  ${({ padding }) => padding && 'padding-left: 10px'};
  ${({ scroller }) =>
    scroller &&
    `
    overflow-y: scroll;
    height: 300px; 
  `};
`
export const DishItemWrap = styled.div`
  flex: 1;
  ${({ index }) =>
    index &&
    index % 2 !== 0 &&
    `
      background: #F0F0F0; 
      border-top: 1px solid #E8E8E8; 
    `};
`

export const DishItem = styled.div`
  display: flex;
  padding: ${({ padding }) => padding || '5px 10px'};
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  align-items: center;

  && p {
    margin: 0;
  }
`

export const DishQuantity = styled.div`
  display: flex;
  flex: 0.5;
  width: 10px;
  align-items: center;
  justify-content: center;
  border: 1px solid #ff5722;
  margin-right: 10px;

  && p {
    position: relative;
    top: 2px;
    margin: 0 auto;
  }
`
export const DishInfo = styled.div`
  flex: ${({ flex }) => (flex ? 1 : 10)};
  display: flex;
  width: 100%;
  flex-direction: row;

  ${({ padding }) => padding && 'padding-left: 25px'};

  && p {
    position: relative;
    top: 2px;
    margin: 0;
  }
`
export const DishDescription = styled.div`
  flex: ${({ flex }) => (flex ? 2 : 8)};
  ${({ flex }) =>
    flex &&
    `
    display: flex;
    justify-content: flex-end;
  `};
  ${({ padding }) => padding && 'padding: 0 10px'};
`
