import styled from 'styled-components'

export const Wrapper = styled.div`
  transition: all 0.15s linear;
  ${({ selected }) => selected && 'background: #EDEEED;'} &:hover {
    background: #edeeed;
  }
  padding: 5px;
  width: 100%;
`
export const WrapperDish = styled.div`
  display: flex;
  margin: 0.5em;
  align-items: center;
`

export const WrapperDescription = styled.div`
  flex: 8;
  display: flex;
  flex-direction: column;
  margin-left: 0.5em;
`
export const WrapperToggle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 0.5em;
`

export const DishImage = styled.div`
  background: url(${props =>
    props.src ? props.src : 'http://yumdeliver.com/img/yum-logo-circle.png'})
    no-repeat;
  background-size: cover;
  border-radius: 50%;
  min-width: 80px;
  min-height: 80px;
  max-width: 80px;
  max-height: 80px;
  border: 2px solid #f3993e;
`

export const Name = styled.span`
  color: #f3993e;
  font-size: 1.2em;
  font-weigth: 600;
`

export const Description = styled.span`
  color: #999a99;
`

export const Price = styled.span`
  color: #5e5f5e;
`
