import styled from 'styled-components'

export const Div = styled.div`
  flex: 1;
  text-align: center;
  align-content: center;
`

export const Logo = styled.img`
  width: 50px;
`

export const Text = styled.span`
  display: block;
  width: 150px;
  word-wrap: break-word;
`

export const Button = styled.button``

export const Icon = styled.i``
