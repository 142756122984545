import React from 'react'
import styled, { keyframes } from 'styled-components'

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`
const Loader = styled.div`
  animation: ${spin} 1s linear infinite;
  font-size: 3em;
  position: absolute;
`

export default props => (
  <Loader {...props}>
    <i className='fas fa-spinner' />
  </Loader>
)
