import React from 'react'
import ReactExport from 'react-data-export'
import { Button } from '../ui/'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet

const createData = data => {
  var restJson = {}
  var restCompleteJson = {}
  var dataArray = []
  var arrayElement = []
  var columns = [
    { title: 'Cupon' },
    { title: 'Orden' },
    { title: 'Fecha de orden' },
    { title: 'Redimido por' },
    { title: 'Restaurante' },
    { title: 'Credito' },
    { title: 'Tipo' },
    { title: 'Costo' },
    { title: 'Descuento' },
    { title: 'Pagado con descuento' }
  ]
  data.forEach(element => {
    let credit = `${element.discount.credit}${element.discount.percentage &&
      '%'}`
    let type = element.discount.type === 'food' ? 'Producto' : 'Envio'
    let cost =
      element.discount.type === 'food'
        ? element.discount.foodCost
        : element.discount.deliveryCost
    let discount = element.discount.discountValue
    let total = element.discount.amount
    var arrayData = [
      element.coupon,
      element.order,
      element.createdAt,
      element.redeemed,
      element.restaurant,
      credit,
      type,
      cost,
      discount,
      total
    ]
    arrayElement.push(arrayData)
    restJson['columns'] = columns
    restJson['data'] = arrayElement
    restCompleteJson = { ...restJson }
  })
  dataArray.push(restCompleteJson)
  return dataArray
}

const ExportDataCoupons = ({ data }) => {
  var dataToExcele = createData(data)
  return (
    <ExcelFile
      filename={'Cupones YUM'}
      element={
        <Button
          label='Exportar'
          size='btn-sm'
          color='btn-default'
          classes={'m-r-sm m-b-sm'}
        />
      }
    >
      <ExcelSheet dataSet={dataToExcele} name='Cupones' />
    </ExcelFile>
  )
}

export default ExportDataCoupons
