import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { TextField, MenuItem } from '@material-ui/core'
import Swal from 'sweetalert2'
import fb from '../../firebase'
import { Panel } from '../../components/ui/'
import { Row, Col, Page } from '../../components/ui/Layout'
import TableRestaurants from '../../components/restaurants'
import {
  bindRestaurants,
  toogleActive,
  acceptPaypalActive,
  deleteRestaurant,
  updateRestaurant,
  closeAllRestaurants
} from './actions'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import { GetCities } from '../adminSettings/actions'
import Modal from '../../components/Modal'
import AddRestaurantUser from './form'
import { createUser } from './../users/actions'

const restaurantsDB = fb.database().ref('restaurants')

class RestaurantHome extends Component {
  constructor (props) {
    super(props)
    const { superUser, city } = this.props.profile
    this.state = {
      showRestaurants: 'restaurantsActive',
      adminProps: {
        superUser: superUser,
        adminCity: city
      },
      modal: {
        title: '',
        onSave: null,
        onCancel: null,
        show: null,
        content: <p>todo</p>
      }
    }
    this.goToEditRestaurant = this.goToEditRestaurant.bind(this)
    this.goToDeleteRestaurant = this.goToDeleteRestaurant.bind(this)
    this._closeRestaurants = this._closeRestaurants.bind(this)
  }

  render () {
    const {
      modal: { title, onSave, onCancel, show, content, error }
    } = this.state
    const { updateRestaurant } = this.props
    const currentDay = moment().format('dddd')

    return (
      <Page>
        <Row>
          <Col size={12}>
            <Panel
              title={`Restaurantes - ${currentDay}`}
              options={
                <Row>
                  <Col size={2} offset={7}>
                    <Button
                      variant='contained'
                      color='secondary'
                      className={'m-r-sm right'}
                      endIcon={<Icon>close</Icon>}
                      onClick={this._closeRestaurants}
                    >
                      Cerrar todo
                    </Button>
                  </Col>
                  <Col size={2}>
                    <Button
                      variant='contained'
                      color='primary'
                      className={'m-r-sm right'}
                      endIcon={<Icon>add</Icon>}
                      onClick={this._addRestaurantModal}
                    >
                      Comercio
                    </Button>
                  </Col>
                </Row>
              }
            >
              <TextField
                id='select'
                label='Ver restaurantes:'
                value={this.state.showRestaurants}
                select
                onChange={this.selectRestaurant}
              >
                <MenuItem value='restaurantsActive'>Activos</MenuItem>
                <MenuItem value='restaurantsinActive'>Inactivos</MenuItem>
              </TextField>
              <TableRestaurants
                filter={this.state.showRestaurants}
                toogleActive={toogleActive}
                toogleOpen={updateRestaurant}
                acceptPaypalActive={acceptPaypalActive}
                onEdit={this.goToEditRestaurant}
                onDelete={this.goToDeleteRestaurant}
              />
            </Panel>
          </Col>
        </Row>
        <Modal
          title={title}
          onSave={onSave}
          onCancel={onCancel}
          show={show}
          content={content}
          error={error}
          navigate={this.props.history}
        />
      </Page>
    )
  }

  _addRestaurantModal = () => {
    this.setState({
      modal: {
        show: 'show',
        title: 'Nuevo Comercio',
        onSave: this._onSave,
        onCancel: this._onCancel,
        content: (
          <AddRestaurantUser
            close={this._onCancel}
            onSave={this._onSave}
            history={this.props.history}
            profile={this.props.profile}
            cities={this.props.cities}
          />
        )
      }
    })
  }

  _closeRestaurants = () => {
    const { profile: { city, selectCity } } = this.props
    Swal.fire({
      title: '¿Estas seguro de cerrar todos los comercios?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then(result => {
      if (result.value) {
        closeAllRestaurants(selectCity || city)
      }
    })
  }

  _onCancel = () => {
    this.setState({
      modal: {
        show: false
      }
    })
  }

  _onSaveCB = payload => {
    if (payload.error) {
      this.setState({
        modal: {
          ...this.state.modal,
          error: payload.error,
          show: 'show'
        }
      })
      return
    }
    this.setState({
      modal: {
        show: false
      }
    })
  }

  _onSave = user => {
    if (user.type !== 'admin') {
      delete user.permissions
    }
    this.props.createUser(user, this._onSaveCB, this.props.history)
  }

  componentDidMount () {
    const {
      GetCities,
      bindRestaurants,
      profile: { city, selectCity }
    } = this.props
    bindRestaurants(selectCity || city)
    GetCities()
  }
  UNSAFE_componentWillReceiveProps (nextProps) {
    const { bindRestaurants, profile: { selectCity } } = this.props
    const { profile: { selectCity: newSelectCity } } = nextProps
    if (selectCity !== newSelectCity) {
      bindRestaurants(newSelectCity)
    }
  }
  componentWillUnmount () {
    restaurantsDB.off()
  }
  selectRestaurant = e => {
    this.setState({ showRestaurants: e.target.value })
  }

  goToEditRestaurant (props) {
    const { history: { push } } = this.props
    const row = props.original._row
    const id = row._id
    push(`/restaurant/${id}/edit`, row)
  }

  goToDeleteRestaurant (props) {
    const row = props.row
    const id = row.active.id
    Swal.fire({
      title: '¿Estas seguro de eliminar?',
      text: 'Eliminar un restaurante es permanente!.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminalo!'
    }).then(result => {
      if (result.value && id) {
        this.props.deleteRestaurant(id, row.restaurantName)
      }
    })
  }
}

const mapStateToProps = state => ({
  profile: state.profile,
  cities: state.data.settings.cities
})

const mapDispatchToProps = dispatch => ({
  bindRestaurants: city => dispatch(bindRestaurants(city)),
  deleteRestaurant: (payload, name) =>
    dispatch(deleteRestaurant(payload, name)),
  GetCities: cb => dispatch(GetCities(cb, false)),
  createUser: (user, CB, history) => dispatch(createUser(user, CB, history)),
  updateRestaurant: payload => dispatch(updateRestaurant(payload))
})
export default connect(mapStateToProps, mapDispatchToProps)(RestaurantHome)
