import Moment from 'moment'
import { extendMoment } from 'moment-range'
const moment = extendMoment(Moment)

export const DiffTimeWithCurrent = createdAt => {
  const currentTime = moment()
  var timelapse =
    createdAt && currentTime ? currentTime.diff(createdAt, 'minute') : null
  return timelapse
}
