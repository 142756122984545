import React from 'react'
import FormInput from '../../components/FormInput'
import { Formik, Form } from 'formik'
import moment from 'moment'

const BannedUser = props => {
  const {
    foreignKey: uid,
    name,
    lastName,
    email,
    bannedNumber
  } = props.edit._original
  const { adminName, adminLN, adminID } = props.adminProps
  const timestamp = moment().valueOf()
  const bannedCount = props.edit._original.bannedNumber ? bannedNumber : 0

  return (
    <Formik
      onSubmit={(values, actions) => {
        props.onSave({
          ...values,
          timestamp,
          uid,
          adminID,
          adminLN,
          adminName,
          bannedCount
        })
      }}
      render={({ values, handleChange }) => (
        <Form>
          <div className='modal-body'>
            <div className='form-group'>
              <h2>{`${name} ${lastName || ''}`}</h2>
            </div>
            {email && (
              <div className='form-group'>
                <label>Email</label>
                <p>{email}</p>
              </div>
            )}
            <div className='form-group'>
              <label>Motivo del bloqueo</label>
              <FormInput
                name='subject'
                onChange={handleChange}
                value={values.subject}
              />
            </div>
          </div>
          <div className='modal-footer'>
            <button type='submit' className='btn btn-primary'>
              Guardar
            </button>
            <button
              onClick={props.close}
              type='button'
              className='btn btn-secondary'
              data-dismiss='modal'
            >
              Close
            </button>
          </div>
        </Form>
      )}
    />
  )
}

export default BannedUser
