import React from 'react'
import FormInput from '../../components/FormInput'
import { connect } from 'react-redux'
import { Formik, Form, FieldArray } from 'formik'
import * as Yup from 'yup'
import { isObject } from 'lodash'
import { UploadPhoto } from '../../components/upload'
import { Row, Col } from '../../components/ui/Layout'
import { addListToDish, removeListFromDish } from '../../actions/extras'
import { dishDelete } from './actions'
import {
  ExtrasCointainerDown,
  ExtrasCointainerPosition,
  ExtrasCointainerRequired,
  FormImage,
  SwitchGroup,
  Label
} from './styled'
import { Panel } from '../../components/ui/'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import _ from 'lodash'

const DishSchema = Yup.object().shape({
  name: Yup.string().required('Campo requerido'),
  price: Yup.string().required('Campo requerido'),
  description: Yup.string(),
  position: Yup.number('La posiion debe ser dada en números')
})

const DishFormik = props => {
  const initialValues = {
    name: '',
    price: '',
    description: '',
    position: '',
    is_dish: true,
    is_extra: false
  }
  const {
    extrasList,
    extraItems,
    selectedList,
    removeListFromDish,
    dishId,
    selectList,
    onSave,
    onEditDish,
    dishDelete,
    currentDish,
    clearDishes
  } = props
  const addExtraListToDish = e => {
    e.preventDefault()
    addListToDish(selectedList, dishId, extrasList, selectList)
  }
  return (
    <Formik
      validationSchema={DishSchema}
      enableReinitialize
      initialValues={(dishId && currentDish) || initialValues}
      onSubmit={(values, actions) => {
        dishId
          ? onEditDish({
            ...values,
            dishId
          })
          : onSave(
            {
              ...values
            },
            actions
          )
      }}
      render={({
        values,
        errors,
        handleBlur,
        handleChange,
        loading,
        setFieldValue,
        handleReset,
        resetForm,

        isValid
      }) => (
        <Form style={{ paddingLeft: 5, paddingRight: 5 }}>
          <Col size={4}>
            <FormImage>
              <UploadPhoto
                folder={`dishes/${dishId}/`}
                width='500'
                height='500'
                form='dish'
                initialPhoto={values && values.photo}
                margin='0px 0px 20px 0px'
                onUpload={e => {
                  setFieldValue('photo', e)
                }}
              >
                {values &&
                  !values.photo && (
                  <div style={{ fontSize: '3em' }}>
                    <i className='fas fa-cloud-upload-alt' />
                  </div>
                )}
              </UploadPhoto>
            </FormImage>
          </Col>
          <Col size={8}>
            <FormInput
              label='Nombre'
              placeholder='Nombre...'
              name='name'
              onChange={handleChange}
              onBlur={handleBlur}
              type='text'
              error={errors.name || ''}
              value={(values && values.name) || ''}
            />
            <FormInput
              label='Descripción'
              placeholder='Descripción...'
              name='description'
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.description}
              value={(values && values.description) || ''}
            />
            <FormInput
              label='Precio'
              placeholder='Precio...'
              name='price'
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.price}
              value={(values && values.price) || ''}
            />

            <FormInput
              label='Photo'
              type='hidden'
              placeholder='Photo...'
              name='photo'
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.photo}
              value={(values && values.photo) || ''}
            />
          </Col>
          {dishId && (
            <React.Fragment>
              <div style={{ paddingTop: 40 }}>
                <div>
                  <Label fSize='2em'>Lista de Complementos</Label>
                </div>
              </div>
              <Panel>
                <Row>
                  <Col size={4}>
                    <select
                      className='form-control'
                      onChange={key => selectList(key.target.value)}
                      name='select'
                      value={selectedList || ''}
                      style={{ width: 200 }}
                    >
                      <option value=''>Agrega una lista</option>
                      {!_.isEmpty(extrasList) &&
                        Object.keys(extrasList).map(key => {
                          return (
                            <option key={key} value={key}>
                              {extrasList[key].name}
                            </option>
                          )
                        })}
                    </select>
                  </Col>
                  {selectedList && (
                    <Col size={7}>
                      <a
                        onClick={e => {
                          addExtraListToDish(e)
                        }}
                      >
                        <i class='fa fa-plus btn-sm m-r-sm' />
                      </a>
                    </Col>
                  )}
                </Row>
                <Row>
                  <FieldArray
                    name='extras'
                    render={arrayHelpers => (
                      <Row
                        style={{
                          margin: 0
                        }}
                      >
                        {values &&
                          values.extras &&
                          Object.keys(values.extras)
                            .filter(
                              extra => isObject(values.extras[extra]) && extra
                            )
                            .map((extra, index) => {
                              return (
                                <Row
                                  style={{
                                    margin: 20,
                                    padding: 20,
                                    border: '1px solid #CCC'
                                  }}
                                  key={index}
                                >
                                  <Col size={12}>
                                    <p
                                      style={{
                                        margin: '-30px 0 0 10px',
                                        backgroundColor: '#FFF',
                                        width: 250,
                                        textAlign: 'center',
                                        fontSize: 20
                                      }}
                                    >
                                      {values.extras[extra].name}
                                    </p>
                                    <Button
                                      variant='contained'
                                      color='secondary'
                                      endIcon={<Icon>delete</Icon>}
                                      onClick={e => {
                                        e.preventDefault()
                                        let isLast =
                                          Object.keys(values.extras).length < 2
                                        removeListFromDish(
                                          extra,
                                          dishId,
                                          isLast
                                        )
                                      }}
                                      style={{
                                        margin: '-35px 0 0 10px',
                                        right: '15px',
                                        position: 'absolute',
                                        textAlign: 'center',
                                        backgroundColor: '#e74343'
                                      }}
                                      width='auto'
                                    >
                                      Eliminar
                                    </Button>
                                  </Col>
                                  <Col size={12}>
                                    <div style={{ padding: 0 }}>
                                      <Col size={3}>
                                        <FormInput
                                          label='Máximo'
                                          placeholder={
                                            values.extras[extra].maxItem
                                          }
                                          name={`extras.${extra}.maxItem`}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          error={
                                            errors.extras &&
                                            errors.extras[extra].maxItem
                                          }
                                          value={values.extras[extra].maxItem}
                                        />
                                      </Col>
                                      <Col size={3}>
                                        <FormInput
                                          label='Mínimo'
                                          placeholder={
                                            values.extras[extra].minItem
                                          }
                                          name={`extras.${extra}.minItem`}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          error={
                                            errors.extras &&
                                            errors.extras[extra].minItem
                                          }
                                          value={values.extras[extra].minItem}
                                        />
                                      </Col>
                                      <ExtrasCointainerDown>
                                        <ExtrasCointainerRequired
                                          style={{ textAlign: 'center' }}
                                        >
                                          <p style={{ fontWeight: 'bold' }}>
                                            ¿Obligatorio?
                                          </p>
                                          <input
                                            type='checkbox'
                                            name={`extras.${extra}.needed`}
                                            onChange={handleChange}
                                            checked={
                                              values.extras[extra].needed
                                            }
                                          />
                                        </ExtrasCointainerRequired>
                                        <ExtrasCointainerPosition
                                          style={{
                                            textAlign: 'center',
                                            width: '30%'
                                          }}
                                        >
                                          <FormInput
                                            label='Posición'
                                            name={`extras.${extra}.position`}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            type='number'
                                            error={
                                              errors.extras &&
                                              errors.extras[extra].position
                                            }
                                            value={
                                              values.extras[extra].position
                                            }
                                          />
                                        </ExtrasCointainerPosition>
                                      </ExtrasCointainerDown>
                                    </div>
                                    <Row>
                                      <Col size={12}>
                                        <ul style={{ listStyleType: 'circle' }}>
                                          <hr />
                                          {values.extras[extra].items && (
                                            <FieldArray
                                              name='items'
                                              render={arrayHelpers =>
                                                Object.keys(
                                                  values.extras[extra].items
                                                ).map((item, i) => {
                                                  return (
                                                    extraItems[item] && (
                                                      <li
                                                        key={i}
                                                        style={{
                                                          marginBottom: 15,
                                                          background:
                                                            i % 2 > 0
                                                              ? '#fff'
                                                              : '#ccc'
                                                        }}
                                                      >
                                                        <Col size={7}>
                                                          {extraItems[item]
                                                            ? extraItems[item]
                                                              .name
                                                            : ''}
                                                        </Col>
                                                        <Col size={1}>
                                                          <input
                                                            onChange={
                                                              handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            type='checkbox'
                                                            name={`extras.${extra}.items.${item}`}
                                                            checked={
                                                              values.extras[
                                                                extra
                                                              ].items[item]
                                                            }
                                                          />
                                                        </Col>
                                                        <Col size={2}>
                                                          {extraItems[item]
                                                            ? `$ ${
                                                              extraItems[item]
                                                                .price
                                                            }`
                                                            : ''}
                                                        </Col>
                                                      </li>
                                                    )
                                                  )
                                                })
                                              }
                                            />
                                          )}
                                        </ul>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              )
                            })}
                      </Row>
                    )}
                  />
                </Row>
              </Panel>
            </React.Fragment>
          )}
          <div className='modal-footer' style={{ border: 'none' }}>
            <SwitchGroup justify='flex-end'>
              <button
                type='button'
                onClick={() =>
                  currentDish && dishDelete(dishId, resetForm, clearDishes)
                }
                className='btn btn-danger'
                data-dismiss='modal'
                disabled={!currentDish}
              >
                Eliminar
              </button>
              <button
                type='reset'
                className='btn btn-warning'
                onClick={() => {
                  resetForm(initialValues)
                  clearDishes()
                }}
                disabled={loading}
              >
                Limpiar
              </button>
              <button
                type='submit'
                className='btn btn-info'
                disabled={loading || !isValid}
              >
                {currentDish ? 'Editar' : 'Guardar'}
              </button>
            </SwitchGroup>
          </div>
        </Form>
      )}
    />
  )
}

const mapStateToProps = ({ form, data: { ui: { loading } } }) => ({
  form,
  isLoading: loading
})

const mapDispatchToProps = dispatch => ({
  // addListToDish: (listId, dishId, extraList) => dispatch(addListToDish(listId, dishId, extraList)),
  removeListFromDish: (listId, dishId, isLast) =>
    dispatch(removeListFromDish(listId, dishId, isLast)),
  dishDelete: (dishId, resetForm, clearDishes) =>
    dispatch(dishDelete(dishId, resetForm, clearDishes))
})

export default connect(mapStateToProps, mapDispatchToProps)(DishFormik)
