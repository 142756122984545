import React from 'react'
import { Link } from 'react-router-dom'
import fb from '../../firebase'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { Row, Col, Page } from '../../components/ui/Layout'
import Modal from '../../components/Modal'
import { Panel, Button, Alert } from '../../components/ui'
import ReactTable from 'react-table'
import BannedUser from './bannedModal'
import matchSorter from 'match-sorter'
import { GetCities } from '../adminSettings/actions'
import {
  createUser,
  editUser,
  deleteUser,
  loadUsers,
  bannedUser,
  activateUser,
  phoneVerifyUser
} from './actions'
import axios from 'axios'
import { Icon, IconButton } from '@material-ui/core'
import Swal from 'sweetalert2'
import styled from 'styled-components'
import UserInfo from '../../components/userInfo'
import getEndpoint from '../../helpers/getEndpoint'

const WrapperActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const base_url = `${getEndpoint('REACT_APP_ENDPOINT_AWS')}`
const dbPartners = fb.database().ref('partners')
class Users extends React.Component {
  state = {
    adminProps: {
      adminName: this.props.name,
      adminLN: this.props.lastName,
      adminID: this.props.uid,
      superUser: this.props.superUser,
      adminCity: this.props.city
    },
    currentPageIndex: 1,
    pageSize: 15,
    lastQuery: false,
    pages: 0,
    modal: {
      title: 'Add User',
      onSave: null,
      onCancel: null,
      show: false,
      content: <p>todo</p>
    },
    loading: false,
    users: [],
    clients: {
      data: [],
      loading: false,
      pages: 0
    }
  }

  componentDidMount () {
    this.props.GetCities()

    const clientUpdates = fb.database().ref('shouldRefresh/clients')
    clientUpdates.on(
      'value',
      () =>
        this.state.client &&
        this.state.client.currentUrl &&
        axios.get(this.state.client.currentUrl).then(res => {
          // Update react-table
          this.setState({
            clients: {
              data: res.data.results,
              pages: Math.ceil(res.data.count / 20),
              loading: false,
              currentUrl: this.state.client.currentUrl
            }
          })
        })
    )
  }

  componentWillUnmount () {
    dbPartners.off()
  }

  render () {
    const {
      clients: { data, pages, loading: tableLoading },
      modal: { title, onSave, onCancel, show, content, error, edit }
    } = this.state
    const { ui: { loading, error: loadingError, message } } = this.props
    const columns = this.renderHeader(loading)
    const dataUser = this.renderOrder(data)
    return (
      <Page>
        <React.Fragment>
          {message && (
            <Alert
              title={loadingError ? 'Errores' : 'Operación completada...'}
              color={`alert-${loadingError ? 'danger' : 'info'}`}
            >
              {message}
            </Alert>
          )}
        </React.Fragment>
        <Row>
          <Col size={12}>
            <Panel
              title='Administradores'
              options={
                <Button
                  size='btn-sm'
                  color='btn-default'
                  icon='fa-user'
                  classes={'m-r-sm right'}
                  onClick={this._addUserModal}
                >
                  Add
                </Button>
              }
            >
              <ReactTable
                defaultPageSize={20}
                data={dataUser}
                loading={tableLoading}
                columns={columns}
                manual
                showPageSizeOptions={false}
                pages={pages}
                onFetchData={(state, instance) => {
                  const searchFilter =
                    state.filtered &&
                    state.filtered[0] &&
                    state.filtered[0].value
                      ? state.filtered[0].value
                      : ''
                  if (searchFilter.length < 3 && searchFilter.length !== 0) {
                    return
                  }
                  this.setState({ ridersProspects: { loading: true } })
                  const currentUrl = `${base_url}v1/clients?page=${state.page +
                    1}&search=${searchFilter}`
                  axios.get(currentUrl).then(res => {
                    // Update react-table
                    this.setState({
                      clients: {
                        data: res.data.results,
                        pages: Math.ceil(res.data.count / 20),
                        loading: false,
                        currentUrl
                      }
                    })
                  })
                }}
              />
            </Panel>
          </Col>
        </Row>
        <Modal
          edit={edit}
          title={title}
          onSave={onSave}
          onCancel={onCancel}
          show={show}
          content={content}
          error={error}
          navigate={this.props.history}
        />
      </Page>
    )
  }

  _addUserModal = () => {
    this.setState({
      modal: {
        show: 'show',
        title: 'Añadir Nuevo Usuario',
        onSave: this._onSave,
        onCancel: this._onCancel,
        content: (
          <UserInfo
            close={this._onCancel}
            onSave={this._onSave}
            adminProps={this.state.adminProps}
            cities={this.props.settings.cities}
          />
        )
      }
    })
  }

  _banModal = row => {
    this.setState({
      modal: {
        show: 'show',
        edit: row,
        title: 'Bloqueo de usuario',
        onCancel: this._onCancel,
        onSave: this._save,
        content: (
          <BannedUser
            edit={row}
            close={this._onCancel}
            onSave={this._save}
            adminProps={this.state.adminProps}
          />
        )
      }
    })
  }

  _onCancel = () => {
    this.setState({
      modal: {
        show: false
      }
    })
  }

  _onSaveCB = payload => {
    if (payload.error) {
      this.setState({
        modal: {
          ...this.state.modal,
          error: payload.error,
          show: 'show'
        }
      })
      return
    }
    this.setState({
      modal: {
        show: false
      }
    })
  }

  _onSave = user => {
    if (user.type !== 'admin') {
      delete user.permissions
    }
    this.props.createUser(user, this._onSaveCB, this.props.history)
  }

  _save = data => {
    bannedUser(data)
    this.setState({
      modal: { show: false }
    })
  }

  _onEdit = user => {
    if (user.type !== 'admin') {
      delete user.permissions
    }
    this.props.editUser(
      user,
      this.setState({
        modal: {
          show: false
        }
      })
    )
  }

  renderOrder (usersArray) {
    if (!isEmpty(usersArray)) {
      return usersArray.map((user, i) => {
        const avatar = user.avatar || false
        const foreignKey = user.foreign_key || 'No cuenta con id'
        const name = user.name || ''
        const lastName = user.last_name || ''
        const motherLastName = user.mother_last_name || ''
        const type = user.type_client
        const location = user.location || 'No cuenta con dirección'
        const phone = user.phone || 'Sin número teléfonico'
        const email = user.email || 'Sin correo electronico'
        const city = user.city
        const displayCityName =
          user.city && this.props.settings.cities
            ? this.props.settings.cities[user.city]
            : { name: 'Sin ciudad asignada' }
        const phoneVerify = user.phone_verify
        const banned = user.is_banned
        return {
          avatar,
          foreignKey,
          displayName: `${name} ${lastName} ${motherLastName}`,
          name: `${name}`,
          lastName: `${lastName}`,
          motherLastName: `${motherLastName}`,
          typeClient: type || 'user',
          location,
          phone,
          email,
          source: user,
          city,
          phoneVerify,
          displayCityName: displayCityName && displayCityName.name,
          banned
        }
      })
    }
  }

  renderHeader (loading) {
    return [
      {
        Header: 'ID',
        accessor: 'foreignKey',
        filterable: true,
        style: { display: 'flex', alignItems: 'center' },
        filterMethod: (filter, rows) => {
          return matchSorter(rows, filter.value, {
            keys: ['foreignKey']
          })
        }
      },
      {
        Header: 'Nombre',
        accessor: 'displayName',
        style: { display: 'flex', alignItems: 'center' }
      },
      {
        Header: 'Email',
        accessor: 'email',
        style: { display: 'flex', alignItems: 'center' }
      },
      {
        Header: 'Contraseña',
        accessor: 'password',
        width: 100
      },
      {
        Header: 'Teléfono',
        accessor: 'phone',
        style: { display: 'flex', alignItems: 'center' }
      },
      {
        Header: 'Ciudad',
        accessor: 'displayCityName',
        style: { display: 'flex', alignItems: 'center' }
      },
      {
        Header: 'Acciones',
        style: { display: 'flex', justifyContent: 'center' },
        Cell: props =>
          props && (
            <React.Fragment>
              {loading ? (
                'loading...'
              ) : (
                <React.Fragment>
                  <WrapperActions>
                    <Link
                      to={{
                        pathname: `/profile/${props.row.foreignKey}`,
                        state: { props: props.row }
                      }}
                    >
                      <IconButton
                        variant='contained'
                        // onClick={() => this._editUserModal(props.row)}
                        style={{ padding: 10 }}
                      >
                        <Icon
                          fontSize={'large'}
                          title={'Editar usuario'}
                          style={{ color: 'GoldenRod' }}
                        >
                          edit
                        </Icon>
                      </IconButton>
                    </Link>
                    {props.row._original.banned ? (
                      <IconButton
                        variant='contained'
                        onClick={() =>
                          this.props.activateUser(
                            props.row._original.foreign_key
                          )
                        }
                        style={{ padding: 10 }}
                      >
                        <Icon
                          fontSize={'large'}
                          title={'Desbanear usuario'}
                          style={{ color: 'tomato' }}
                        >
                          person_remove
                        </Icon>
                      </IconButton>
                    ) : (
                      <IconButton
                        variant='contained'
                        onClick={() => this._banModal(props.row)}
                        style={{ padding: 10 }}
                      >
                        <Icon
                          fontSize={'large'}
                          title={'Banear usuario'}
                          style={{ color: 'green' }}
                        >
                          how_to_reg
                        </Icon>
                      </IconButton>
                    )}
                  </WrapperActions>
                  <WrapperActions>
                    <IconButton
                      variant='contained'
                      disabled={props.row._original.phoneVerify ? true : null}
                      onClick={() =>
                        Swal.fire({
                          title: '¿Está seguro en actualizar?',
                          text: '¡Esto no podrá ser revertido!',
                          type: 'warning',
                          showCancelButton: true,
                          confirmButtonColor: '#3085d6',
                          cancelButtonColor: '#d33',
                          confirmButtonText: '¡Si, actualizalo!'
                        }).then(result => {
                          if (result.value) {
                            this.props.phoneVerifyUser(
                              props.row._original.foreignKey
                            )
                          }
                        })
                      }
                      style={{ padding: 10 }}
                    >
                      <Icon
                        fontSize={'large'}
                        title={
                          props.row._original.phoneVerify
                            ? 'Verificado'
                            : 'Verificar Teléfono'
                        }
                        style={{
                          color: props.row._original.phoneVerify
                            ? 'DarkGreen'
                            : 'tomato'
                        }}
                      >
                        {props.row._original.phoneVerify
                          ? 'stay_current_portrait'
                          : 'phonelink_erase'}
                      </Icon>
                    </IconButton>
                    <IconButton
                      variant='contained'
                      onClick={() =>
                        this.props.deleteUser(props.row._original.uid)
                      }
                      style={{ padding: 10 }}
                    >
                      <Icon
                        fontSize={'large'}
                        title={'Eliminar usuario'}
                        style={{ color: 'Chocolate' }}
                      >
                        delete
                      </Icon>
                    </IconButton>
                  </WrapperActions>
                </React.Fragment>
              )}
            </React.Fragment>
          )
      }
    ]
  }
}

const mapStateToProps = ({
  data: { users, ui, settings },
  profile: { type, uid, city, name, lastName, superUser, selectCity }
}) => ({
  type,
  uid,
  users,
  ui,
  city,
  selectCity,
  name,
  lastName,
  superUser,
  settings
})

const mapDispatchToProps = dispatch => ({
  loadUsers: () => dispatch(loadUsers()),
  createUser: (user, CB, history) => dispatch(createUser(user, CB, history)),
  deleteUser: uid => dispatch(deleteUser(uid)),
  editUser: user => dispatch(editUser(user)),
  // bannedUser: data => dispatch(bannedUser(data)),
  activateUser: id => dispatch(activateUser(id)),
  GetCities: cb => dispatch(GetCities(cb, false)),
  phoneVerifyUser: uid => dispatch(phoneVerifyUser(uid))
})

export default connect(mapStateToProps, mapDispatchToProps)(Users)
