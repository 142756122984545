import React from 'react'
import FormInput from '../../components/FormInput'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { FormLayout, TimeGroup, ScheduleElement } from './styled'

const TimeSchema = Yup.object().shape({
  waitTime: Yup.number()
    .integer('Sólo agregar minutos completos.')
    .positive('Sólo números positivos.')
    .required('Campo requerido')
})

const AutoFillTime = ({ waitTime, setFieldValue }) => {
  React.useEffect(
    () => {
      if (waitTime && waitTime > 0) {
        setFieldValue('waitTime', waitTime)
      }
    },
    [waitTime]
  )
  return null
}

const WaitTimeForm = props => {
  const { time, restId } = props
  return (
    <Formik
      validationSchema={TimeSchema}
      initialValues={{
        waitTime: time || 0
      }}
      onSubmit={(values, actions) => {
        props.saveTime({ ...values, restId })
      }}
      render={({ values, errors, handleBlur, handleChange, setFieldValue }) => (
        <Form
          style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
        >
          <AutoFillTime waitTime={time} setFieldValue={setFieldValue} />
          <FormLayout isWidth={true}>
            <TimeGroup justify='center'>
              <ScheduleElement>
                <FormInput
                  label='Minutos'
                  placeholder='Ej. 15'
                  name='waitTime'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.waitTime}
                  value={values.waitTime}
                  style={{ width: 50 }}
                />
              </ScheduleElement>
              <ScheduleElement
                style={{ justifyContent: 'center', paddingTop: '4%' }}
              >
                <TimeGroup justify='center'>
                  <button type='submit' className='btn btn-primary'>
                    {time ? 'Actualizar' : 'Guardar'}
                  </button>
                </TimeGroup>
              </ScheduleElement>
            </TimeGroup>
          </FormLayout>
        </Form>
      )}
    />
  )
}

export default WaitTimeForm
