import firebase from 'firebase'
import getEndpoint from './helpers/getEndpoint'

const options = {
  apiKey: getEndpoint('REACT_APP_API_KEY'),
  authDomain: getEndpoint('REACT_APP_AUTH_DOMAIN'),
  databaseURL: getEndpoint('REACT_APP_DATABASE_URL'),
  projectId: getEndpoint('REACT_APP_PROJECT_ID'),
  storageBucket: getEndpoint('REACT_APP_STORAGE_BUCKET'),
  messagingSenderId: getEndpoint('REACT_APP_MESSAGING_SENDER_ID')
}
export default firebase.initializeApp(options)
