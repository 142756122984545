import React from 'react'
import {
  Wrapper,
  Element,
  GridElement,
  Row,
  CoverImage,
  Categories,
  Icon,
  Star,
  Address,
  Text
} from './styled'
import moment from 'moment'

const RestaurantPreview = ({ data }) => {
  const dayOfTheWeek = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday'
  ]
  let currentDay = moment()
    .format('d')
    .toLowerCase()
  currentDay = dayOfTheWeek[currentDay]

  const schedule = data.schedule
  const closeHour =
    schedule && schedule[currentDay] && schedule[currentDay].close
  const openHour = schedule && schedule[currentDay] && schedule[currentDay].open
  return (
    <Wrapper>
      <Element>
        <CoverImage alt='cover' src={data && data.photo} />
        <h3 style={{ color: '#000' }}>{data && data.name}</h3>
        <Categories>{data && data.categories}</Categories>
        <Row margin='10px 0px' justify='center'>
          <Star className='far fa-star' />
          <span style={{ color: '#000', marginLeft: '5px' }}>4.5</span>
        </Row>
      </Element>
      <Element>
        <GridElement>
          <Icon className='fas fa-map-marker-alt' />
          <Address>{data && data.address && data.address.formatted}</Address>
        </GridElement>
      </Element>
      <Element row>
        <GridElement>
          <div style={{ width: '50%' }}>
            <Icon className='fas fa-phone' />
            <Text>{data && data.phone}</Text>
          </div>
          <div style={{ width: '50%' }}>
            <Icon className='far fa-clock' />
            <Text>
              {data && `${moment().format('dddd')}: ${openHour} - ${closeHour}`}
            </Text>
          </div>
        </GridElement>
      </Element>
    </Wrapper>
  )
}

export default RestaurantPreview
