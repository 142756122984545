import React from 'react'
import Input from '../ui/Input'

const FormInput = ({
  label,
  name,
  onChange,
  onBlur,
  placeholder,
  value,
  error,
  type,
  ...props
}) => (
  <div className='form-group'>
    {type !== 'hidden' && <label>{label}</label>}
    <Input
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      placeholder={placeholder}
      type={type || 'text'}
    />
    {type !== 'hidden' && <div className='form-error'>{error}</div>}
  </div>
)

export default FormInput
