import React from 'react'
import { withGoogleMap, GoogleMap as GoogleMapComponent } from "react-google-maps"
// 
// 
// for more info visite: https://tomchentw.github.io/react-google-maps/
// 
// 
const GoogleMap = withGoogleMap((props) => {
  const {
    children
  } = props
  return (
    <GoogleMapComponent {...props}>
      {children}
    </GoogleMapComponent>
  )
})

export default GoogleMap