import React from 'react'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import fb from '../../firebase'
import { Row, Col, Page } from '../../components/ui/Layout'
import { Panel } from '../../components/ui/'
import Swal from 'sweetalert2'
// import ReactTable from 'react-table'
// import styled from 'styled-components'
import _ from 'lodash'
const moment = extendMoment(Moment)
const finishedOrders = fb.database()

const timeStamp = Date.now()
const year = moment(timeStamp).format('YYYY')
const month = moment(timeStamp).format('MM')

class UserHistory extends React.Component {
  state = {
    texto: 'Proximamente'
  }

  //  ./ref(`/finishedOrders/${year}/${month}`)
  componentDidMount () {
    finishedOrders
      .ref(`finishedOrders/2019/11/`)
      .once('value')
      .then(snp => {
        const orders = snp.val()
        const ordersKeys = Object.keys(orders)
        this.setState({
          ordersKeys,
          orders
        })
      })
      .catch(err => {
        Swal.fire({
          position: 'center',
          type: 'error',
          title: `${err.message}`,
          showConfirmButton: false,
          timer: 2500
        })
      })
  }

  render () {
    const { ordersKeys, orders } = this.state
    return (
      <Page>
        <Row>
          <Col size={12}>
            <Panel>
              <ul>
                <li>{year}</li>
                <ul>
                  <li>{month}</li>
                  <ul>
                    {ordersKeys &&
                      ordersKeys.map(order => {
                        return _.groupBy(
                          Object.values(orders[order]),
                          orders[order].restaurant.name
                        )
                      })}
                  </ul>
                </ul>
              </ul>
              <button
                onClick={() =>
                  this.setState({
                    texto: 'Ya quedo'
                  })
                }
              >
                hola
              </button>
            </Panel>
          </Col>
        </Row>
      </Page>
    )
  }
}

export default UserHistory
