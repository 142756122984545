import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import MenuItem from '../widgets/MenuItem'
import _ from 'lodash'
import styled from 'styled-components'
import { getGeofences } from '../../containers/editGeofence/actions'
const Logo = styled.div`
  background: url(/img/yum_logo.svg) no-repeat center;
  background-size: contain;
  height: 45px;
  margin-top: 2em;
  @media (max-width: 768px) {
    margin-top: 0;
  }
`

class Menu extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false
    }
  }

  toggleShowHide () {
    this.setState({ open: !this.state.open })
  }

  renderAdminItems = (currentPage, data, profile) => (
    <React.Fragment>
      <MenuItem
        link='/'
        icon='fa-desktop'
        color='bg-success'
        linkText='Ordenes'
        currentPage={currentPage}
      >
        <SubMenuItem link='/orders' linkText='Ordenes activas' />
        <SubMenuItem link='/ordersComplete' linkText='Ordenes completadas' />
        <SubMenuItem link='/riders' linkText='Riders' />

        {/* <SubMenuItem link={"/ui/buttons"} linkText={"Buttons"} />
      <SubMenuItem link={"/ui/fontawesome"} linkText={"Font Awesome"} />
      <SubMenuItem link={"/ui/materialicons"} linkText={"Material Design Icons"} />
      <SubMenuItem link={"/ui/tables"} linkText={"Tables"} />
      <SubMenuItem link={"/ui/modals"} linkText={"Modals"} /> */}
      </MenuItem>
      <MenuItem
        link='/restaurants'
        icon='fa-utensils'
        color='bg-success'
        linkText='Restaurantes'
        currentPage={currentPage}
      />
      <MenuItem
        link='/payment'
        icon='fa-dollar-sign'
        color='bg-success'
        linkText='Pagos'
        currentPage={currentPage}
      >
        <SubMenuItem link='/payment' linkText='Reportes' />
        <SubMenuItem
          link='/reportes/restaurantes'
          linkText='Reporte Restaurantes'
        />
        <SubMenuItem link='/reportes/riders' linkText='Reporte Riders' />
      </MenuItem>
      <MenuItem
        link='/users'
        icon='fa-user'
        color='bg-success'
        linkText='Usuarios'
        currentPage={currentPage}
      />
      <MenuItem
        link='/coupons'
        icon='fa-ticket-alt'
        color='bg-success'
        linkText='Cupones'
        currentPage={currentPage}
      />
      {data.settings &&
        !_.isEmpty(data.settings.cities) && (
        <MenuItem
          link='/'
          icon='fa-globe'
          color='bg-success'
          linkText='Cobertura'
          currentPage={currentPage}
        >
          {profile.superUser
            ? Object.values(data.settings.cities).map(city => {
              if (city.geofence) {
                return (
                  <SubMenuItem
                    key={city.key}
                    link={`/editgeofence/${city.key}/${city.geofence}`}
                    linkText={`${city.name}`}
                  />
                )
              } else {
                return null
              }
            })
            : profile.city &&
                data.settings.cities &&
                data.settings.cities[profile.city] &&
                data.settings.cities[profile.city].geofence && (
              <SubMenuItem
                key={data.settings.cities[profile.city].key}
                link={`/editgeofence/${
                  data.settings.cities[profile.city].key
                }/${data.settings.cities[profile.city].geofence}`}
                linkText={`${data.settings.cities[profile.city].name}`}
              />
            )}
        </MenuItem>
      )}
      <MenuItem
        link='/settings'
        icon='fa-cogs'
        color='bg-success'
        linkText='Settings'
        currentPage={currentPage}
      />
    </React.Fragment>
  )

  render () {
    var classes = 'bg-black aside-md hidden-print'
    var navClasses = 'nav-primary'
    const { profile, currentPage, data } = this.props
    const { type } = profile
    if (!this.state.open) {
      classes += ' nav-xs'
      navClasses += ' hidden-xs'
    }
    return (
      <aside className={classes} id='nav'>
        <section className='vbox'>
          <header className='header bg-danger brand-header lock-header pos-stat clearfix'>
            <a
              href='#'
              className='btn btn-link visible-xs'
              onClick={() => this.toggleShowHide()}
              data-toggle='class:nav-off-screen,open'
              data-target='#nav,html'
            >
              <i className='fa fa-bars' />
            </a>
            <div className='text-center'>
              <Link to='/home'>
                <Logo />
              </Link>
            </div>
          </header>
          <section className='w-f scrollable'>
            <div className='slimScrollDiv'>
              <div
                className='slim-scroll'
                data-height='auto'
                data-disable-fade-out='true'
                data-distance='0'
                data-size='5px'
                data-color='#333333'
              >
                <nav className={navClasses}>
                  <ul className='nav'>
                    <MenuItem
                      link='/home'
                      icon='fa-home'
                      color='bg-danger'
                      linkText='Inicio'
                      currentPage={currentPage}
                    />
                    {type === 'admin' &&
                      this.renderAdminItems(currentPage, data, profile)}
                    {/* <MenuItem
                      link='/restaurants'
                      // badgeCount={8}
                      icon='fa-sitemap'
                      color='bg-success'
                      linkText='Restaurantes'
                      currentPage={currentPage}
                    /> */}
                    {/* <MenuItem
                      link={"/"}
                      icon="fa-database"
                      color="bg-success"
                      linkText="Data"
                      currentPage={currentPage}
                    >
                      <SubMenuItem link={"/data/grid"} linkText={"Grid"} />
                      <SubMenuItem link={"/data/forms"} linkText={"Forms"} />
                    </MenuItem>
                    <MenuItem
                      link={"/forms"}
                      icon="fa-tasks"
                      color="bg-success"
                      linkText="Forms"
                      currentPage={currentPage}
                    />
                    <MenuItem
                      link={"/charts"}
                      icon="fa-bar-chart-o"
                      color="bg-success"
                      linkText="Charts"
                      currentPage={currentPage}
                    />
                    <MenuItem
                      link={"/"}
                      icon="fa-rocket"
                      color="bg-success"
                      linkText="Apps"
                      currentPage={currentPage}
                    >
                      <SubMenuItem link={"/apps/boards"} linkText={"Boards"} />
                      <SubMenuItem link={"/apps/notes"} linkText={"Notes"} />
                      <SubMenuItem link={"/apps/maps"} linkText={"Maps"} />
                      <SubMenuItem link={"/apps/email"} linkText={"Email"} />
                    </MenuItem>
                    <MenuItem
                      link={"/widgets"}
                      icon="fa-share-alt-square"
                      color="bg-success"
                      linkText="Widgets"
                      currentPage={currentPage}
                    />
                    <MenuItem
                      link={"/analytics"}
                      icon="fa-line-chart"
                      color="bg-success"
                      linkText="Analytics"
                      currentPage={currentPage}
                    />
                    <MenuItem
                      link={"/docs"}
                      icon="fa-book"
                      color="bg-success"
                      linkText="Docs"
                      currentPage={currentPage}
                    /> */}
                  </ul>
                </nav>
              </div>
              <div className='slimScrollBar scrollBar' />
              <div className='slimScrollRail scrollRail' />
            </div>
          </section>
        </section>
      </aside>
    )
  }
}

class SubMenuItem extends Component {
  render () {
    var badge = this.props.badgeText ? (
      <b className='badge bg-danger pull-right'>{this.props.badgeText}</b>
    ) : null
    return (
      <li>
        {' '}
        <Link to={this.props.link} onClick={() => this.toggleShowHide()}>
          {' '}
          <i className='fa fa-angle-right' /> {badge}
          <span>{this.props.linkText}</span>{' '}
        </Link>{' '}
      </li>
    )
  }
}

const mapStateToProps = ({ profile, data }) => ({ profile, data })
const mapDistpatchToProps = dispatch => {
  return {
    getGeofences: geofence => dispatch(getGeofences(geofence))
  }
}
export default connect(mapStateToProps, mapDistpatchToProps)(Menu)
