/**
 * Copyright 2015-present, Lights in the Sky (3273741 NS Ltd.)
 * All rights reserved.
 *
 * This source code is licensed under the license found in the
 * LICENSE file in the root directory of this source tree.
 *
 * @providesModules UI
 */

import React from 'react'

export const Button = ({
  icon,
  label,
  size,
  color,
  classes,
  onClick,
  style,
  tooltip,
  rounded = false,
  children,
  disabled,
  type
}) => {
  const button_icon = icon ? (
    <i className={`${label ? 'm-r-xs' : ''} fa ${icon}`} />
  ) : null
  const button_label = <span className='text'>{children || label}</span>
  return (
    <button
      disabled={disabled}
      style={style}
      data-balloon={tooltip}
      onClick={onClick}
      className={`btn ${color} ${size} ${classes} ${
        rounded ? 'btn-rounded' : ''
      }`}
      type={type}
    >
      {button_icon}
      {button_label}
    </button>
  )
}

export const Fa = ({ icon, size, color, classes }) => (
  <i
    style={size ? { fontSize: size } : {}}
    className={`fa fa-2x fa-${icon} ${classes}`}
  />
)

export const I = ({ icon, size, color, classes, style }) => {
  let _classes = `material-icons ${classes}`
  let _style = Object.assign(
    {},
    {
      fontSize: size || 24,
      verticalAlign: 'middle',
      color: color || '#757575'
    },
    style
  )
  return (
    <i className={_classes} style={_style}>
      {icon}
    </i>
  )
}

export const Table = ({ data, classes }) => (
  <table className={`table ${classes}`}>
    <thead>
      <tr>
        {Object.keys(data[0]).map((key, i) => {
          return <th key={'row-' + key + '-' + i}>{key}</th>
        })}
      </tr>
    </thead>
    <tbody>
      {data.map((row, j) => {
        return (
          <tr key={'r-' + j}>
            {Object.keys(row).map((column, k) => {
              return <td key={'col-' + column + '-' + k}>{row[column]}</td>
            })}
          </tr>
        )
      })}
    </tbody>
  </table>
)

export const IStack = ({ icon, size, color, bg, width, height, classes }) => {
  let _style = {
    backgroundColor: bg || '#757575',
    width: width,
    height: height
  }

  return (
    <div className={`iconStack ${classes}`}>
      <span style={_style}>
        <I
          icon={icon}
          size={size}
          color={color}
          style={{ lineHeight: height + 'px' }}
        />
      </span>
    </div>
  )
}

export const Label = ({ color, classes, title }) => (
  <span className={`label ${color} ${classes}`}>{title}</span>
)

export const SearchBox = ({ val, onChange }) => (
  <form className='search-content' action='#' method='post'>
    <div className={'iconic-input'}>
      <i className='fa fa-search' />
      <input
        type='text'
        className='form-control'
        name='keyword'
        onChange={onChange}
        value={val}
        placeholder='Search...'
      />
    </div>
  </form>
)

const TitleHeader = ({ children, options }) =>
  children ? (
    <React.Fragment>
      <header className='panel-heading text-uc'>{children}</header>
      {options}
      <hr />
    </React.Fragment>
  ) : null

export const Panel = ({
  title,
  options,
  children,
  scrollHeight,
  classes,
  height,
  style
}) => {
  let baseStyle = { ...style }
  if (height) {
    baseStyle = { height: height, overflow: 'hidden', ...baseStyle }
  }
  return (
    <section
      style={
        scrollHeight
          ? Object.assign({}, baseStyle, { paddingBottom: 30 })
          : baseStyle
      }
      className='panel panel-default m-b-lg'
    >
      <TitleHeader options={options}>{title}</TitleHeader>
      <section
        style={
          scrollHeight
            ? {
              height: scrollHeight,
              overflowY: 'scroll'
            }
            : {}
        }
        className={`panel-body ${classes}`}
      >
        {children}
      </section>
    </section>
  )
}

export const PageHead = ({ title, subtitle, children, classes }) => (
  <div className={`page-head ${classes}`}>
    <h3 dangerouslySetInnerHTML={{ __html: title }} />
    <span className='sub-title'>{subtitle}</span>
    {children}
  </div>
)

export const Pager = ({
  currentPage,
  itemsPerPage,
  totalItems,
  onNext,
  onPrevious,
  onPage,
  classes,
  disabledPrev,
  disabledNext
}) => {
  let pages = totalItems / itemsPerPage

  let buttonArray = []
  for (var i = 0; i < pages; i++) {
    buttonArray.push(i)
  }

  const linkButtons = buttonArray.map(index => {
    return (
      <li
        key={'page-key-' + index}
        className='paginate_button '
        aria-controls='DataTables_Table_1'
        tabIndex='0'
      >
        <a href='javascript:void(0)' onClick={onPage}>
          {index + 1}
        </a>
      </li>
    )
  })

  return (
    <div className={`tbl-footer ${classes}`}>
      <div>
        <div className='text-right m-t-lg'>
          Showing {currentPage * itemsPerPage + 1} to{' '}
          {currentPage * itemsPerPage + itemsPerPage} of {totalItems} entries
        </div>
      </div>
      <div>
        <div
          className='dataTables_paginate paging_simple_numbers text-right'
          id='DataTables_Table_1_paginate'
        >
          <ul className='pagination' style={{ marginTop: 5 }}>
            <li
              className={`paginate_button previous ${disabledPrev}`}
              aria-controls='DataTables_Table_1'
              tabIndex='0'
              id='DataTables_Table_1_previous'
            >
              <a href='javascript:void(0)' onClick={onPrevious}>
                Anterior
              </a>
            </li>
            {linkButtons}
            <li
              className={`paginate_button next ${disabledNext}`}
              aria-controls='DataTables_Table_1'
              tabIndex='0'
              id='DataTables_Table_1_next'
            >
              <a href='javascript:void(0)' onClick={onNext}>
                Siguiente
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export const Alert = ({
  color,
  persistant,
  title,
  onClose,
  classes,
  children
}) => (
  <div className={`alert ${color}`}>
    {!persistant ? (
      <button
        type='button'
        onClick={onClose}
        className='close'
        data-dismiss='alert'
      >
        ×
      </button>
    ) : null}
    {title ? <h4>{title}</h4> : null}
    {children}
  </div>
)

// export UI;
