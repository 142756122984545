import fb from '../../firebase'
import Swal from 'sweetalert2'

const databaseRef = fb.database().ref()

export const getGeofences = (geofenceKey, history = false) => {
  const path = `/geofences/${geofenceKey}`
  Swal.fire({
    position: 'center',
    title: 'Cargando geocercas.',
    showConfirmButton: false,
    allowOutsideClick: () => !Swal.isLoading(),
    onBeforeOpen: () => {
      Swal.showLoading()
    }
  })
  return dispatch => {
    databaseRef
      .child(path)
      .once('value')
      .then(snapshot => {
        dispatch({ type: 'CLEAN_GEOFENCE' })
        Swal.close()
        var data = snapshot.val()
        var action = {
          type: 'GET_GEOFENCE',
          payload: data
        }
        dispatch(action)
      })
      .catch(err => {
        Swal.close()
        Swal.fire({
          position: 'center',
          type: 'error',
          title: `${err.message}`,
          showConfirmButton: false,
          timer: 2500
        })
        history && history.goBack()
      })
  }
}

export const updateGeofence = (polygons, callback) => {
  polygons.forEach(({ id, polygon }, i) => {
    const path = `/geofences/${id}/deliverArea`
    var mvcArray = polygon.getPath()
    let geofence = JSON.parse(JSON.stringify(mvcArray.getArray()))
    databaseRef
      .child(path)
      .set(geofence)
      .then(() => {
        i === polygons.length - 1 && callback()
      })
      .catch(err =>
        Swal.fire({
          position: 'center',
          type: 'error',
          title: `${err.message}`,
          showConfirmButton: false,
          timer: 2500
        })
      )
  })
}
