import React from 'react'
import FormInput from '../../components/FormInput'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { SwitchGroup, FormWrap } from './styled'

const ListSchema = Yup.object().shape({
  name: Yup.string().required('Campo requerido'),
  price: Yup.string().required('Campo requerido'),
  description: Yup.string()
})

const AddListItemForm = props => {
  const { onCancel } = props
  const initialValues =
    props.edit && props.edit._original ? { ...props.edit._original } : {}
  return (
    <Formik
      validationSchema={ListSchema}
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        if (props.edit) {
          props.onEdit(values)
          return
        }
        props.onSave(props.listId, values)
      }}
      render={({ values, errors, handleBlur, handleChange, loading }) => (
        <FormWrap>
          <Form style={{ width: '50%' }}>
            <div className='modal-body'>
              <FormInput
                placeholder='Nombre...'
                name='name'
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.name}
                value={values.name}
              />
              <FormInput
                placeholder='Precio...'
                name='price'
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.price}
                value={values.price}
              />
              <FormInput
                placeholder='Descripción...'
                name='description'
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.description}
                value={values.description}
              />
            </div>
            <SwitchGroup justify='flex-end' padding='0 20px 20px 20px'>
              <div style={{ paddingRight: 10 }}>
                <button
                  type='button'
                  className='btn btn-danger'
                  onClick={() => onCancel()}
                >
                  Cancelar
                </button>
              </div>
              <button type='submit' className='btn btn-info' disabled={loading}>
                Guardar
              </button>
            </SwitchGroup>
          </Form>
        </FormWrap>
      )}
    />
  )
}

export default AddListItemForm
