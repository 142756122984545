import React from 'react'
import ReactExport from 'react-data-export'
import { Button } from '../ui/'
import moment from 'moment'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet

const createData = data => {
  var restJson = {}
  var restCompleteJson = {}

  var dataArray = []
  var columns = [
    { title: 'Restaurante' },
    { title: 'Fecha' },
    { title: 'No. Order' },
    { title: 'Costo producto' },
    { title: 'Descuento' },
    { title: 'Total cobrado' },
    { title: 'Tipo de Pago' }
  ]
  data.forEach(element => {
    var orders = element.orders ? Object.values(element.orders) : []
    var name = element.restaurantName
    var arrayElement = []
    orders.map(order => {
      const { createdAt, subtotal, typePayment, uid, discount } = order
      var date = moment(createdAt).format('DD/MM/YYYY')
      var foodCost =
        discount && discount.type === 'food' ? discount.foodCost : subtotal
      var discountFood =
        discount && discount.type === 'food'
          ? discount.foodCost >= discount.discountValue
            ? discount.discountValue
            : discount.foodCost
          : 0
      var totalToPay = subtotal
      var arrayData = [
        name,
        date,
        uid,
        foodCost,
        discountFood,
        totalToPay,
        typePayment
      ]
      return arrayElement.push(arrayData)
    })
    restJson['columns'] = columns
    restJson['data'] = arrayElement
    restCompleteJson = { ...restJson }
    dataArray.push(restCompleteJson)
  })
  return dataArray
}

const ExportData = ({ data }) => {
  var dataToExcele = createData(data)
  return (
    <ExcelFile
      filename={'Restaurantes YUM'}
      element={
        <Button
          label='Exportar'
          size='btn-sm'
          color='btn-default'
          classes={'m-r-sm m-b-sm'}
        />
      }
    >
      <ExcelSheet dataSet={dataToExcele} name='Restaurantes' />
    </ExcelFile>
  )
}

export default ExportData
