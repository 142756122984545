// Internals
import React from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import getEndpoint from '../../helpers/getEndpoint'

// Actions
import { addPhoto, clearPhoto } from './actions'
//  Components
import { UploadPhoto as Up } from './styled'

class UploadPhotoC extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
    this.onDrop = this.onDrop.bind(this)
  }

  render () {
    const { children, initialPhoto, photo } = this.props
    return (
      <Up
        onDrop={this.onDrop}
        background={(photo && photo.preview) || initialPhoto}
      >
        {(photo && photo.preview) || initialPhoto ? null : children}
      </Up>
    )
  }

  componentWillReceiveProps (nextProps) {
    _.isEmpty(nextProps.initialPhoto) && this.setState({ final: '' })
  }

  onDrop (files) {
    const { folder, width, height, addPhoto, onUpload } = this.props
    const localFile = files[0]
    const image = {
      file: localFile,
      folder: folder,
      width: width,
      height: height,
      imageName: Date.now()
    }
    onUpload(image)
    addPhoto({
      preview: files[0].preview
    })
  }
}

UploadPhotoC.propTypes = {
  children: propTypes.element,
  folder: propTypes.string,
  onUpload: propTypes.func
}

const mapStateToProps = ({ ui, form }) => {
  return {
    photo: ui.photo
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addPhoto: photo => dispatch(addPhoto(photo)),
    clearPhoto: () => dispatch(clearPhoto())
  }
}

const UploadPhoto = connect(mapStateToProps, mapDispatchToProps)(UploadPhotoC)

export { UploadPhoto }
