import React, { forwardRef } from 'react'
import _ from 'lodash'
import Moment from 'moment'
import Swal from 'sweetalert2'
import { Formik, Form } from 'formik'
import { connect } from 'react-redux'
import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import { extendMoment } from 'moment-range'
import getEndpoint from '../../helpers/getEndpoint'
import {
  getOrdersComplete,
  getOrdersCompleteByValue
} from '../../helpers/reports'
import Modal from '../../components/Modal'
import { Panel } from '../../components/ui'
import FormInput from '../../components/FormInput'
import { Row, Col, Page } from '../../components/ui/Layout'
import DatePickerHeader from '../../components/datePickerHeader'
import SearchTable from './searchTable'

const BASE_URL = getEndpoint('REACT_APP_ENDPOINT')

const moment = extendMoment(Moment)

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`

const Logo = styled.img`
  width: 50px;
`

class OrdersComplete extends React.Component {
  constructor (props) {
    super(props)
    const startDay = moment()
      .startOf('week')
      .startOf('day')
      .add(1, 'day')
      .subtract(1, 'week')
    const endDay = moment(startDay)
      .add(6, 'day')
      .endOf('day')
    const yearRange = endDay.year()
    this.state = {
      typeData: 'default',
      results: {},
      modal: { show: false, content: null },
      initialValues: {
        order: '',
        email: '',
        startDay,
        endDay,
        yearRange
      }
    }
  }

  componentDidMount () {
    this.getOrdersByLastWeek('default')
  }

  componentWillUnmount () {}

  render () {
    const {
      typeData,
      results,
      modal: { show, content },
      initialValues
    } = this.state
    let label = typeData === 'order' ? 'Número de Orden' : 'Nombre'
    return (
      <Page>
        <Row>
          <Col size={12}>
            <Panel>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={(values, actions) => {
                  this._search(values, typeData)
                }}
                render={({
                  values,
                  handleBlur,
                  handleChange,
                  handleReset,
                  setFieldValue,
                  loading
                }) => {
                  return (
                    <Form>
                      <Row>
                        <Col size={12}>
                          <Row>
                            <Col size={3}>
                              <label>Tipo de dato:</label>
                              <select
                                className='form-control'
                                onChange={e => {
                                  handleReset()
                                  this._selectType(e.target.value)
                                }}
                              >
                                <option value={false}>
                                  Selecciona una opción.
                                </option>
                                <option value={'user'}>Usuario</option>
                                {/* <option value={'order'}>Orden</option> */}
                              </select>
                            </Col>
                          </Row>
                          <Row style={{ padding: 10 }}>
                            {typeData === 'order' && (
                              <Col size={4}>
                                <FormInput
                                  name={typeData}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type='text'
                                  label={label}
                                  value={values[typeData]}
                                />
                              </Col>
                            )}
                            {typeData === 'user' && (
                              <>
                                <Row style={{ marginBottom: 20 }}>
                                  <Col size={6} offset={4}>
                                    <h3>{`${values.startDay.format(
                                      'DD MMMM'
                                    )} al ${values.endDay.format(
                                      'DD MMMM'
                                    )} del ${values.yearRange}`}</h3>
                                  </Col>
                                </Row>
                                <Row style={{ marginBottom: 20 }}>
                                  <Col size={4} offset={4}>
                                    <FormInput
                                      placeholder='usuario@yumdelivery.com.mx'
                                      name='email'
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      label={'Email'}
                                      value={values.email}
                                      type='email'
                                    />
                                  </Col>
                                </Row>
                                <Col size={2} offset={4}>
                                  <DatePicker
                                    selected={values.startDay._d}
                                    onChange={date =>
                                      setFieldValue('startDay', moment(date))
                                    }
                                    selectsStart
                                    startDate={values.startDay._d}
                                    endDate={values.endDay._d}
                                    renderCustomHeader={props => {
                                      return <DatePickerHeader {...props} />
                                    }}
                                  />
                                </Col>
                                <Col size={2}>
                                  <DatePicker
                                    selected={values.endDay._d}
                                    onChange={date =>
                                      setFieldValue('endDay', moment(date))
                                    }
                                    selectsEnd
                                    startDate={values.startDay._d}
                                    endDate={values.endDay._d}
                                    minDate={values.startDay._d}
                                    renderCustomHeader={props => {
                                      return <DatePickerHeader {...props} />
                                    }}
                                  />
                                </Col>
                              </>
                            )}
                            {typeData === 'delivery' && (
                              <React.Fragment>
                                <Col size={4}>
                                  <FormInput
                                    name={'lastName'}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type='text'
                                    label={'Apellido Paterno'}
                                    value={values.lastName}
                                  />
                                </Col>
                                <Col size={4}>
                                  <FormInput
                                    name='phone'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    label={'Telefono'}
                                    value={values.phone}
                                    type='phone'
                                  />
                                </Col>
                              </React.Fragment>
                            )}
                          </Row>
                        </Col>
                      </Row>
                      <button
                        type='submit'
                        className='btn btn-info'
                        disabled={loading}
                      >
                        Buscar
                      </button>
                    </Form>
                  )
                }}
              />
            </Panel>
          </Col>
        </Row>
        <Row>
          <Col size={12}>
            {!_.isEmpty(results) && (
              <SearchTable
                data={results}
                type={typeData}
                contentModal={this.contentModal}
              />
            )}
          </Col>
        </Row>
        <Modal
          title='Detalles'
          onCancel={this.onCancel}
          show={show}
          content={content}
        />
      </Page>
    )
  }
  _selectType = value => {
    this.setState({
      typeData: value,
      results: {}
    })
  }
  getOrdersByLastWeek = async type => {
    const { profile: { city, selectCity } } = this.props
    const startDateUnix = moment()
      .subtract(1, 'week')
      .valueOf()
    const stopDateUnix = moment().valueOf()
    let currentCity = selectCity || city

    Swal.fire({
      position: 'center',
      title: 'Your request is loading',
      showConfirmButton: false,
      allowOutsideClick: () => !Swal.isLoading(),
      onBeforeOpen: () => {
        Swal.showLoading()
      }
    })
    const response = await getOrdersComplete(
      startDateUnix,
      stopDateUnix,
      currentCity
    )
    Swal.close()
    if (!_.isEmpty(response)) {
      return this.setState({
        results: response,
        typeData: type
      })
    }
    Swal.fire({
      position: 'center',
      type: 'error',
      title: `Tu petición no tuvo exito, intentalo de nuevo!.`,
      showConfirmButton: true
    })
  }
  _search = async (values, type) => {
    const startDateUnix = values.startDay.valueOf()
    const stopDateUnix = values.endDay.valueOf()
    Swal.fire({
      position: 'center',
      title: 'Your request is loading',
      showConfirmButton: false,
      allowOutsideClick: () => !Swal.isLoading(),
      onBeforeOpen: () => {
        Swal.showLoading()
      }
    })
    const value =
      type === 'order' ? values.order : type === 'user' ? values.email : null
    const response = await getOrdersCompleteByValue(
      value,
      type,
      startDateUnix,
      stopDateUnix
    )
    Swal.close()
    if (!_.isEmpty(response)) {
      return this.setState({
        results: response,
        typeData: type
      })
    }
    Swal.fire({
      position: 'center',
      type: 'error',
      title: `Tu petición no tuvo exito, intentalo de nuevo!.`,
      showConfirmButton: true
    })
  }

  contentModal = (type, data) => {
    var content = null
    if (type === 'orders') {
      const attendedBy = data.attendedBy || false
      const acceptedBy = data.acceptedBy || false
      const receivedBy = data.receivedBy || false
      const finishedBy = data.finishedBy || false

      var client = data.client || false
      if (client && data.location) {
        client['location'] = data.location
      }
      content =
        data.dishes &&
        data.dishes.map((dish, i) => {
          const { name, count, description, extras, indications, price } = dish
          const extrasList = extras && Object.values(extras)
          const hasExtras =
            !_.isEmpty(extrasList) && Array.isArray(extrasList[0])
          return (
            <ModalContent key={`dish_${i}`}>
              <Row>
                <Col size={12}>
                  <b>Platillo {i + 1}</b>
                </Col>
                <Col size={12}>
                  <span>{name}</span>
                </Col>
                <Col size={12}>
                  <span>
                    <b>Cantidad: </b>
                    {count}
                  </span>
                </Col>
                <Col size={12}>
                  <span>
                    <b>Precio: </b>
                    {`$${price}`}
                  </span>
                </Col>
                <Col size={12}>
                  <b>Descripción</b>
                </Col>
                <Col size={12}>
                  <span>{description}</span>
                </Col>
              </Row>
              <Row style={{ marginTop: 15 }}>
                <Col size={9} offset={1}>
                  {hasExtras && (
                    <Row>
                      <Col size={12}>
                        <p>
                          <b>Extra</b>
                        </p>
                      </Col>
                      <ol>
                        {extrasList.map((extra, index) => (
                          <React.Fragment key={`extra_${index}`}>
                            <Col size={12}>
                              <hr />
                            </Col>
                            <li key={index}>
                              <ul>
                                {extra.map((item, j) => (
                                  <li key={j}>
                                    {item.name}
                                    {item.price > 0 ? `- $${item.price}` : ''}
                                  </li>
                                ))}
                              </ul>
                            </li>
                          </React.Fragment>
                        ))}
                      </ol>
                    </Row>
                  )}
                </Col>
              </Row>
              <Row>
                <Col size={12}>
                  <Row>
                    {indications && (
                      <Col size={12}>
                        <b>Indicaciones</b>
                        <ul>
                          <li>{indications}</li>
                        </ul>
                      </Col>
                    )}
                    <Col size={12}>
                      <hr />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </ModalContent>
          )
        })
      var contentWithRider = (
        <div>
          <ModalContent>
            <Row>
              {acceptedBy && (
                <Col size={3} offset={1} classes={'bg-success m-r-sm'}>
                  <Row>
                    <Col size={12}>
                      <h6>Hora de Aceptado</h6>
                    </Col>
                    <Col size={12}>
                      <TimeCard data={acceptedBy} />
                    </Col>
                  </Row>
                </Col>
              )}
              {receivedBy && (
                <Col size={3} classes={'bg-success m-r-sm'}>
                  <Row>
                    <Col size={12}>
                      <h6>Hora de Recibido</h6>
                    </Col>
                    <Col size={12}>
                      <TimeCard data={receivedBy} />
                    </Col>
                  </Row>
                </Col>
              )}
              {finishedBy && (
                <Col size={3} classes={'bg-success m-r-sm'}>
                  <Row>
                    <Col size={12}>
                      <h6>Hora de Entregado</h6>
                    </Col>
                    <Col size={12}>
                      <TimeCard data={finishedBy} />
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </ModalContent>
          {attendedBy && <RiderContent rider={attendedBy} />}
          {client && <ClientInfo data={client} />}
          {content}
          {data.discount ? (
            <ModalContent>
              <Row>
                <Col size={6}>
                  <b style={{ fontSize: 16 }}>Envio:</b>
                </Col>
                <Col size={6}>
                  <span style={{ fontSize: 18 }}>
                    ${data.discount.deliveryCost}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col size={6}>
                  <b style={{ fontSize: 16 }}>Producto:</b>
                </Col>
                <Col size={6}>
                  <span style={{ fontSize: 18 }}>
                    ${data.discount.foodCost}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col size={6}>
                  <b style={{ fontSize: 16 }}>Subtotal:</b>
                </Col>
                <Col size={6}>
                  <span style={{ fontSize: 18 }}>
                    ${data.discount.deliveryCost + data.discount.foodCost}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col size={6}>
                  <b style={{ fontSize: 16 }}>
                    Descuento{' '}
                    {data.discount.type === 'shipping' ? 'Envio' : 'Producto'}:
                  </b>
                </Col>
                <Col size={6}>
                  <span style={{ fontSize: 18 }}>
                    ${data.discount.discountValue}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col size={6}>
                  <b style={{ fontSize: 18 }}>Total:</b>
                </Col>
                <Col size={6}>
                  <span style={{ fontSize: 20 }}>${data.orderTotal.total}</span>
                </Col>
              </Row>
            </ModalContent>
          ) : (
            <ModalContent>
              <Row>
                <Col size={6}>
                  <b style={{ fontSize: 16 }}>Envio:</b>
                </Col>
                <Col size={6}>
                  <span style={{ fontSize: 18 }}>
                    ${data.orderTotal.deliveryCost}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col size={6}>
                  <b style={{ fontSize: 16 }}>SubTotal:</b>
                </Col>
                <Col size={6}>
                  <span style={{ fontSize: 18 }}>
                    ${data.orderTotal.subtotal}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col size={6}>
                  <b style={{ fontSize: 18 }}>Total:</b>
                </Col>
                <Col size={6}>
                  <span style={{ fontSize: 20 }}>${data.orderTotal.total}</span>
                </Col>
              </Row>
            </ModalContent>
          )}
        </div>
      )
    } else if (type === 'user') {
      const {
        client: { clientData },
        orderTotal,
        createdAt,
        restaurant,
        status,
        cancelledComment
      } = data
      content = (
        <ModalContent>
          <Row>
            <Col size={12}>
              <h5>Fecha:</h5>
            </Col>
            <Col size={12}>{moment(createdAt).format('DD/MM/YY HH:mm')}</Col>
          </Row>
          <Row>
            <Col size={12}>
              <b>
                <h5>Datos de Cliente</h5>
              </b>
            </Col>
            {clientData && (
              <Col size={11}>
                <Row>
                  <Col size={12}>
                    <b>Nombre:</b>
                  </Col>
                  <Col size={3} offset={1}>
                    <span>{clientData.name}</span>
                  </Col>
                  <Col size={3}>
                    <span>{clientData.lastName}</span>
                  </Col>
                  <Col size={3}>
                    <span>{clientData.motherLastName}</span>
                  </Col>
                </Row>
                <Row>
                  <Col size={12}>
                    <b>Telefono:</b>
                  </Col>
                  <Col size={11} offset={1}>
                    <span>{clientData.phone}</span>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
          <hr />
          <Row>
            <Col size={12}>
              <h5>Restaurante:</h5>
            </Col>
            <Col size={12}>{restaurant.name || ''}</Col>
          </Row>
          <hr />
          {orderTotal && (
            <Row>
              <Col size={12}>
                <h5>Costos:</h5>
              </Col>
              <Col size={3} offset={1}>
                <b>Repartidor:</b>
                <span>
                  {orderTotal.deliveryCost ? orderTotal.deliveryCost : 0}
                </span>
              </Col>
              <Col size={3}>
                <b>Subtotal:</b>
                <span>{orderTotal.subtotal ? orderTotal.subtotal : 0}</span>
              </Col>
              <Col size={3}>
                <b>Total:</b>
                <span>{orderTotal.total ? orderTotal.total : 0}</span>
              </Col>
            </Row>
          )}
          <Row>
            <Col size={12}>
              <h5>Status:</h5>
            </Col>
            <Col size={11} offset={1}>
              <span>{status || ''}</span>
            </Col>
            {cancelledComment && (
              <Col size={11} offset={1}>
                <h5>Comentario de cancelación:</h5>
                <span>{cancelledComment} </span>
              </Col>
            )}
          </Row>
        </ModalContent>
      )
    } else {
    }
    this.setState({
      modal: {
        show: 'show',
        content: contentWithRider || content
      }
    })
  }

  onCancel = () => {
    this.setState({
      modal: {
        show: false,
        content: null
      }
    })
  }
}

const TimeCard = ({ data }) => {
  const timestamp =
    data.acceptedTime || data.finishedTime || data.receivedTime || false
  const date = timestamp ? moment(timestamp) : false
  return (
    <Row>
      {date && (
        <Col size={12}>
          <div style={{ textAlign: 'center' }}>
            <span>{date.format('DD-MM-YYYY')}</span>
            <br />
            <span>{date.format('hh:mm a')}</span>
          </div>
        </Col>
      )}
    </Row>
  )
}

const RiderContent = ({ rider }) => {
  return (
    <ModalContent>
      <Row classes={'m-b-sm'}>
        {rider.avatar && (
          <Col size={12}>
            <Logo src={rider.avatar} alt={'Avatar profiile'} />
          </Col>
        )}
        <Col size={12}>
          <b>Repartidor:</b>
        </Col>
        <Col size={12}>
          <span>
            {rider.name || ''} {rider.lastName || ''}
          </span>
        </Col>
        <Col size={12}>
          <b>Teléfono repartidor:</b>
        </Col>
        <Col size={12}>
          <span>{rider.phone || ''}</span>
        </Col>
      </Row>
    </ModalContent>
  )
}

const ClientInfo = ({ data }) => {
  var client = data.clientData ? data.clientData : data
  return (
    <ModalContent>
      <Row classes={'m-b-sm'}>
        <Col size={12}>
          <b>Cliente:</b>
        </Col>
        <Col size={12}>
          <span>
            {client.name || ''} {client.lastName || ''}{' '}
            {client.motherLastName || ''}
          </span>
        </Col>
        <Col size={12}>
          <b>Teléfono:</b>
        </Col>
        <Col size={12}>
          <span>{client.phone || ''}</span>
        </Col>
        <Col size={12}>
          <b>Correo:</b>
        </Col>
        <Col size={12}>
          <span>{client.email || ''}</span>
        </Col>
        <Col size={12}>
          <b>Dirección:</b>
        </Col>
        <Col size={12}>
          <span>
            {(data.location && data.location.formatted_address) || ''}
          </span>
        </Col>
      </Row>
    </ModalContent>
  )
}

const mapStateToProps = state => ({
  profile: state.profile
})
export default connect(mapStateToProps, null)(OrdersComplete)
