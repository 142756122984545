import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'

import {
  Wrapper,
  SubWrap,
  Avatar,
  Field,
  WrapForm,
  WrapField,
  Button,
  Select,
  Label,
  HelperText,
  OptionItem,
  ControlLabel,
  CheckGod,
  Typo
} from './styled'

const UserSchema = Yup.object().shape({
  name: Yup.string().required('Campo requerido'),
  lastName: Yup.string().required('Campo requerido'),
  motherLastName: Yup.string().required('Campo requerido'),
  email: Yup.string().required('Campo requerido'),
  phone: Yup.string().required('Campo requerido'),
  city: Yup.string().required('Campo requerido'),
  type: Yup.string().required('Campo requerido')
})

const EditUserSchema = Yup.object().shape({
  name: Yup.string().required('Campo requerido'),
  lastName: Yup.string().required('Campo requerido'),
  motherLastName: Yup.string().required('Campo requerido'),
  email: Yup.string().required('Campo requerido'),
  phone: Yup.string().required('Campo requerido')
})

const UserInfo = props => {
  const [edit, setEdit] = useState(!!props.editOnOrder || !!props.onSave)
  const { superUser, adminCity } = props.adminProps
  const userData =
    props.editOnOrder && props.editOnOrder._original.client.clientData
  const original = props.data && props.data._original
  const initialValues = !_.isEmpty(original)
    ? {
      city: original.city,
      email: original.email,
      foreignKey: original.foreignKey,
      lastName: original.lastName,
      motherLastName: original.motherLastName,
      name: original.name,
      phone: original.phone,
      type: original.typeClient,
      superUser: original.superUser || null
    }
    : props.editOnOrder && userData
      ? {
        email: userData.email,
        lastName: userData.lastName,
        motherLastName: userData.motherLastName,
        name: userData.name,
        phone: userData.phone,
        foreignKey: userData.uid,
        orderId: props.editOnOrder.idOrder,
        editOnOrder: true
      }
      : {
        type: 'user',
        city: adminCity
      }

  return (
    <Formik
      validationSchema={props.editOnOrder ? EditUserSchema : UserSchema}
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        if (props.onEdit || props.editOnOrder) {
          props.onEdit(values)
          setEdit(false)
          return
        }
        props.onSave(values)
      }}
      validateOnChange={false}
      validateOnBlur={false}
      render={({ values, errors, handleBlur, handleChange }) => (
        <Wrapper>
          <SubWrap
            style={{
              paddingTop: (props.editOnOrder || props.onSave) && 0
            }}
          >
            {!props.onSave && (
              <Avatar
                src={
                  (original && original.avatar) || (userData && userData.avatar)
                }
              />
            )}
            <WrapForm>
              <Form
                style={{
                  flex: 1,
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: 10
                }}
              >
                <WrapField>
                  <Field
                    id='name'
                    label='Nombre(s)'
                    disabled={!edit && true}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.name && '*Campo Requerido'}
                    error={errors.name}
                  />
                </WrapField>
                <WrapField>
                  <Field
                    id='lastName'
                    label='Apellido Paterno'
                    disabled={!edit && true}
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.lastName && '*Campo Requerido'}
                    error={errors.lastName}
                  />
                </WrapField>
                <WrapField>
                  <Field
                    id='motherLastName'
                    label='Apellido Materno'
                    disabled={!edit && true}
                    value={values.motherLastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.motherLastName && '*Campo Requerido'}
                    error={errors.motherLastName}
                  />
                </WrapField>
                <WrapField>
                  <Field
                    id='phone'
                    label='Teléfono'
                    disabled={!edit && true}
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.phone && '*Campo Requerido'}
                    error={errors.phone}
                  />
                </WrapField>
                <WrapField>
                  <Field
                    id='email'
                    label='Email'
                    disabled={!edit && true}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.email && '*Campo Requerido'}
                    error={errors.email}
                  />
                </WrapField>
                {!props.editOnOrder && (
                  <SubWrap style={{ padding: 0, width: '100%' }}>
                    <WrapField column>
                      <Label style={{ fontSize: 12 }}>Tipo de Usuario</Label>
                      <Select
                        id='type'
                        name='type'
                        disabled={!edit && true}
                        value={values.type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.type && '*Campo Requerido'}
                        error={errors.type}
                      >
                        <OptionItem style={{ fontSize: 15 }} value='user'>
                          Usuario Final
                        </OptionItem>
                        <OptionItem style={{ fontSize: 15 }} value='restaurant'>
                          Restaurant
                        </OptionItem>
                        <OptionItem style={{ fontSize: 15 }} value='delivery'>
                          Rider
                        </OptionItem>
                        <OptionItem style={{ fontSize: 15 }} value='admin'>
                          Admin
                        </OptionItem>
                      </Select>
                    </WrapField>

                    {values.type === 'admin' &&
                      superUser && (
                        <WrapField
                          style={{
                            justifyContent: 'flex-start',
                            paddingTop: 0
                          }}
                        >
                          <ControlLabel
                            control={
                              <CheckGod
                                id='superUser'
                                name='superUser'
                                color='primary'
                                disabled={!edit && true}
                                onChange={handleChange}
                              />
                            }
                            labelPlacement='start'
                            label={
                              <Typo
                                style={{
                                  fontSize: 13,
                                  fontWeight: 600,
                                  color: '#717171',
                                  marginLeft: 0
                                }}
                              >
                                Is God
                              </Typo>
                            }
                            style={{ marginLeft: 0, paddingTop: 15 }}
                          />
                        </WrapField>
                      )}
                    <WrapField
                      column
                      style={{
                        display: superUser && !values.superUser ? true : 'none'
                      }}
                    >
                      <Label style={{ fontSize: 12 }}>Ciudad</Label>
                      <Select
                        id='city'
                        name='city'
                        disabled={
                          superUser && !values.superUser && !edit && true
                        }
                        value={values.city}
                        onChange={handleChange}
                        helperText={errors.city && '*Campo Requerido'}
                        error={errors.city}
                      >
                        {Object.values(props.cities).map(city => {
                          return (
                            <OptionItem
                              key={city.key}
                              value={city.key}
                              style={{ fontSize: 15 }}
                            >
                              {city.name}
                            </OptionItem>
                          )
                        })}
                      </Select>
                      <HelperText style={{ fontSize: 12 }}>
                        ¿Para qué plaza será este usuario?
                      </HelperText>
                    </WrapField>
                  </SubWrap>
                )}
                <WrapField
                  padding={props.editOnOrder || props.onSave ? '60' : '30'}
                  style={{
                    position: (props.editOnOrder || props.onSave) && 'relative',
                    top: (props.editOnOrder || props.onSave) && -25
                  }}
                >
                  <Button
                    type={!edit ? 'button' : 'reset'}
                    className={!edit ? 'btn btn-primary' : 'btn btn-danger'}
                    onClick={
                      props.editOnOrder || props.onSave
                        ? props.close
                        : () => {
                          !edit ? setEdit(true) : setEdit(false)
                        }
                    }
                  >
                    {!edit ? 'Editar' : 'Cancelar'}
                  </Button>
                  <Button
                    type='submit'
                    className='btn btn-info'
                    disabled={!edit && true}
                  >
                    Guardar
                  </Button>
                </WrapField>
              </Form>
            </WrapForm>
          </SubWrap>
        </Wrapper>
      )}
    />
  )
}

export default UserInfo
