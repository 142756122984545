import fb from '../../firebase'
import { persistor } from '../../App.js'
import { GetCities } from '../../containers/adminSettings/actions'

fb.auth().languageCode = 'es'

export const login = ({ email, password }) => {
  return dispatch =>
    fb
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(res => {
        fb
          .database()
          .ref(`partners/${res.user.uid}`)
          .once('value', snp => {
            const userData = snp.val()
            if (!snp.exists()) {
              const error = {}
              error.message = 'Perfil de usuario no encontrado'
              return alert(error.message)
            }
            userData.uid = res.user.uid
            delete userData.password
            if (userData.superUser) {
              dispatch(GetCities())
            }
            dispatch({ type: 'LOGIN', payload: userData })
            document.getElementById('alertPlayer').loop = true
          })
      })
      .catch(err => alert(err.message))
}

export const logout = () => {
  return dispatch => {
    persistor.purge()
    fb.auth().signOut()
    dispatch({ type: 'LOGIN', payload: {} })
    dispatch({ type: 'GET_RESTAURANT', payload: {} })
    document.getElementById('alertPlayer').loop = false
  }
}
