import React from 'react'
import { Alert } from '../ui'
import { Row, Col } from '../ui/Layout'

export default ({ title, onCancel, content, show, error }) => (
  <div className={`modal ${show}`} tabIndex='-1' role='dialog'>
    <div className='modal-dialog' role='document'>
      <div className='modal-content'>
        <div className='modal-header'>
          <Row>
            <Col size={9}>
              <h5 className='modal-title'>{title}</h5>
            </Col>
            <Col size={3}>
              <button
                onClick={onCancel}
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </Col>
          </Row>
        </div>
        <React.Fragment>
          {error && (
            <Alert title='Error' color='alert-danger'>
              {error.message}
            </Alert>
          )}
        </React.Fragment>
        {content}
      </div>
    </div>
  </div>
)
