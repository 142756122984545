import styled from 'styled-components'

export const Wrapper = styled.div`
  flex: ${({ flex }) => flex || '1'};
  padding: 10px;
  ${({ bRight }) => bRight && 'border-right: 2px solid gray;'};
`

export const Covering = styled.div`
  display: flex;
  padding: ${({ padding }) => padding || '10px'};
  background: ${({ back }) => (back ? '#ea5a5a' : '#717171')};
  justify-content: center;
`

export const Label = styled.p`
  text-align: center;
  justify-content: center;
  font-size: ${({ fSize }) => fSize || '2em'};
  color: ${({ fSize }) => (fSize ? '#717171' : '#f3f4f7')};
  margin: 0;
`

export const SubWrap = styled.div`
  flex: ${({ flex }) => flex || '1'};
  ${({ background }) => background && `background: ${background}`};
  ${({ reduce }) => (reduce ? 'padding: 20px' : 'padding-top: 20px')};
`
