import React from 'react'

import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import {
  Wrapper,
  SubWrap,
  OrderWrap,
  OrderInfo,
  Avatar,
  Text,
  TextWrap,
  InfoWrap,
  Element,
  DishWrap,
  DishItem,
  DishItemWrap,
  DishQuantity,
  DishInfo,
  DishDescription
} from './styled'

const OrderPlaced = ({ build }) => {
  return (
    <Wrapper>
      {build ? (
        <h2 fSize={22}>Próximamente...</h2>
      ) : (
        <SubWrap>
          <h2>Último Pedido: #5487411</h2>
          <OrderWrap column>
            <OrderWrap>
              <OrderInfo border>
                <InfoWrap>
                  <Avatar />
                  <TextWrap>
                    <Text size={'18px'} bold>
                      Lonchaburguer's Hamburguesas y Snacks
                    </Text>
                  </TextWrap>
                </InfoWrap>
                <InfoWrap row info flex={4} padding={'20px 10px'}>
                  <Element>
                    <TextWrap info>
                      <Text bold>Pedido realizado el:</Text>
                      <Text>10/11/2020</Text>
                    </TextWrap>
                    <TextWrap info>
                      <Text bold>Dirección de entrega:</Text>
                      <Text>Av. Altos hornos 301, Indeco</Text>
                    </TextWrap>
                    <TextWrap info>
                      <Text bold>Referencia adicional: </Text>
                      <Text>ITVH, Depto de robótica</Text>
                    </TextWrap>
                  </Element>
                  <Element>
                    <TextWrap info>
                      <Text bold>Subtotal:</Text>
                      <Text>$220.00</Text>
                    </TextWrap>
                    <TextWrap info>
                      <Text bold>Envío:</Text>
                      <Text>$50.00</Text>
                    </TextWrap>
                    <TextWrap info>
                      <Text bold>Descuento aplicado:</Text>
                      <Text>{`BUENFIN024 (envio)\n$50.00`}</Text>
                    </TextWrap>
                    <TextWrap info>
                      <Text bold>Total:</Text>
                      <Text>$220.00</Text>
                    </TextWrap>
                  </Element>
                </InfoWrap>
              </OrderInfo>
            </OrderWrap>
            <OrderWrap column>
              <Element background={'#E8E8E8'} align={'center'}>
                <Text size={'25px'} bold style={{ margin: 0 }}>
                  Listado
                </Text>
              </Element>
              <DishWrap padding scroller>
                <Element>
                  <DishItemWrap index={0}>
                    <DishItem>
                      <DishQuantity>
                        <Text
                          style={{ position: 'relative', top: 2, margin: 0 }}
                        >
                          1
                        </Text>
                      </DishQuantity>
                      <DishInfo>
                        <DishDescription>
                          <Text>Hamburguesa Regia</Text>
                        </DishDescription>
                        <DishDescription flex>
                          <Text>$120</Text>
                        </DishDescription>
                      </DishInfo>
                    </DishItem>

                    {/* EXTRAS */}
                    <DishWrap key={0} style={{ paddingBottom: 10 }}>
                      <DishItem>
                        <Text>Extras</Text>
                      </DishItem>
                      <DishItem column key={0} padding={'0 10px'}>
                        <DishInfo padding flex>
                          <DishDescription padding>
                            <Text>Carne Angus 100% res</Text>
                          </DishDescription>
                          <DishDescription flex>
                            <Text>$30</Text>
                          </DishDescription>
                        </DishInfo>
                      </DishItem>
                    </DishWrap>

                    {/* INDICACIONES */}
                    <DishWrap>
                      <DishItem padding={'0 10px'}>
                        <Text>Indicaciones: </Text>
                      </DishItem>
                      <DishItem column>
                        <DishInfo padding flex>
                          <DishDescription padding>
                            <Text>Sin tomate</Text>
                          </DishDescription>
                        </DishInfo>
                      </DishItem>
                    </DishWrap>
                  </DishItemWrap>
                </Element>

                <Element>
                  <DishItemWrap index={1}>
                    <DishItem>
                      <DishQuantity>
                        <Text>1</Text>
                      </DishQuantity>
                      <DishInfo>
                        <DishDescription>
                          <Text>Salchipapas</Text>
                        </DishDescription>
                        <DishDescription flex>
                          <Text>$60</Text>
                        </DishDescription>
                      </DishInfo>
                    </DishItem>

                    {/* EXTRAS */}
                    <DishWrap key={0}>
                      <DishItem>
                        <Text>Extras</Text>
                      </DishItem>
                      <DishItem column key={0} padding={'0 10px'}>
                        <DishInfo padding flex>
                          <DishDescription padding>
                            <Text>Queso</Text>
                          </DishDescription>
                          <DishDescription flex>
                            <Text>$10</Text>
                          </DishDescription>
                        </DishInfo>
                      </DishItem>
                    </DishWrap>
                    <DishWrap key={0}>
                      <DishItem>
                        <Text>¿Convertir a papas gajo?</Text>
                      </DishItem>
                      <DishItem column key={0} padding={'0 10px'}>
                        <DishInfo padding flex>
                          <DishDescription padding>
                            <Text>Si</Text>
                          </DishDescription>
                        </DishInfo>
                      </DishItem>
                    </DishWrap>

                    {/* INDICACIONES */}
                    <DishWrap>
                      <DishItem padding={'0 10px'}>
                        <Text>Indicaciones: </Text>
                      </DishItem>
                      <DishItem column>
                        <DishInfo padding flex>
                          <DishDescription padding>
                            <Text>Sin sal</Text>
                          </DishDescription>
                        </DishInfo>
                      </DishItem>
                    </DishWrap>
                  </DishItemWrap>
                </Element>
              </DishWrap>
            </OrderWrap>
          </OrderWrap>
          <h2>Pedidos anteriores</h2>
          <OrderWrap column>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <TextWrap row info>
                  <Text>05/11/2020</Text>
                  <Text>Orden #4515484</Text>
                </TextWrap>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <OrderWrap column>
                  <OrderWrap>
                    <OrderInfo border>
                      <InfoWrap>
                        <Avatar />
                        <TextWrap>
                          <Text size={'18px'} bold>
                            Lonchaburguer's Hamburguesas y Snacks
                          </Text>
                        </TextWrap>
                      </InfoWrap>
                      <InfoWrap row info flex={4} padding={'20px 10px'}>
                        <Element>
                          <TextWrap info>
                            <Text bold>Pedido realizado el:</Text>
                            <Text>10/11/2020</Text>
                          </TextWrap>
                          <TextWrap info>
                            <Text bold>Dirección de entrega:</Text>
                            <Text>Av. Altos hornos 301, Indeco</Text>
                          </TextWrap>
                          <TextWrap info>
                            <Text bold>Referencia adicional: </Text>
                            <Text>ITVH, Depto de robótica</Text>
                          </TextWrap>
                        </Element>
                        <Element>
                          <TextWrap info>
                            <Text bold>Subtotal:</Text>
                            <Text>$220.00</Text>
                          </TextWrap>
                          <TextWrap info>
                            <Text bold>Envío:</Text>
                            <Text>$50.00</Text>
                          </TextWrap>
                          <TextWrap info>
                            <Text bold>Total:</Text>
                            <Text>$220.00</Text>
                          </TextWrap>
                          <TextWrap info>
                            <Text bold>Descuento aplicado:</Text>
                            <Text>{`BUENFIN024 (envio)\n$50.00`}</Text>
                          </TextWrap>
                        </Element>
                      </InfoWrap>
                    </OrderInfo>
                  </OrderWrap>
                  <OrderWrap column>
                    <Element background={'#E8E8E8'} align={'center'}>
                      <Text size={'25px'} bold style={{ margin: 0 }}>
                        Listado
                      </Text>
                    </Element>
                    <DishWrap padding scroller>
                      <Element>
                        <DishItemWrap index={0}>
                          <DishItem>
                            <DishQuantity>
                              <Text
                                style={{
                                  position: 'relative',
                                  top: 2,
                                  margin: 0
                                }}
                              >
                                1
                              </Text>
                            </DishQuantity>
                            <DishInfo>
                              <DishDescription>
                                <Text>Hamburguesa Regia</Text>
                              </DishDescription>
                              <DishDescription flex>
                                <Text>$120</Text>
                              </DishDescription>
                            </DishInfo>
                          </DishItem>

                          {/* EXTRAS */}
                          <DishWrap key={0} style={{ paddingBottom: 10 }}>
                            <DishItem>
                              <Text>Extras</Text>
                            </DishItem>
                            <DishItem column key={0} padding={'0 10px'}>
                              <DishInfo padding flex>
                                <DishDescription padding>
                                  <Text>Carne Angus 100% res</Text>
                                </DishDescription>
                                <DishDescription flex>
                                  <Text>$30</Text>
                                </DishDescription>
                              </DishInfo>
                            </DishItem>
                          </DishWrap>

                          {/* INDICACIONES */}
                          <DishWrap>
                            <DishItem padding={'0 10px'}>
                              <Text>Indicaciones: </Text>
                            </DishItem>
                            <DishItem column>
                              <DishInfo padding flex>
                                <DishDescription padding>
                                  <Text>Sin tomate</Text>
                                </DishDescription>
                              </DishInfo>
                            </DishItem>
                          </DishWrap>
                        </DishItemWrap>
                      </Element>

                      <Element>
                        <DishItemWrap index={1}>
                          <DishItem>
                            <DishQuantity>
                              <Text>1</Text>
                            </DishQuantity>
                            <DishInfo>
                              <DishDescription>
                                <Text>Salchipapas</Text>
                              </DishDescription>
                              <DishDescription flex>
                                <Text>$60</Text>
                              </DishDescription>
                            </DishInfo>
                          </DishItem>

                          {/* EXTRAS */}
                          <DishWrap key={0}>
                            <DishItem>
                              <Text>Extras</Text>
                            </DishItem>
                            <DishItem column key={0} padding={'0 10px'}>
                              <DishInfo padding flex>
                                <DishDescription padding>
                                  <Text>Queso</Text>
                                </DishDescription>
                                <DishDescription flex>
                                  <Text>$10</Text>
                                </DishDescription>
                              </DishInfo>
                            </DishItem>
                          </DishWrap>
                          <DishWrap key={0}>
                            <DishItem>
                              <Text>¿Convertir a papas gajo?</Text>
                            </DishItem>
                            <DishItem column key={0} padding={'0 10px'}>
                              <DishInfo padding flex>
                                <DishDescription padding>
                                  <Text>Si</Text>
                                </DishDescription>
                              </DishInfo>
                            </DishItem>
                          </DishWrap>

                          {/* INDICACIONES */}
                          <DishWrap>
                            <DishItem padding={'0 10px'}>
                              <Text>Indicaciones: </Text>
                            </DishItem>
                            <DishItem column>
                              <DishInfo padding flex>
                                <DishDescription padding>
                                  <Text>Sin sal</Text>
                                </DishDescription>
                              </DishInfo>
                            </DishItem>
                          </DishWrap>
                        </DishItemWrap>
                      </Element>
                    </DishWrap>
                  </OrderWrap>
                </OrderWrap>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </OrderWrap>
        </SubWrap>
      )}
    </Wrapper>
  )
}

export default OrderPlaced
