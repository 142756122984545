import React from 'react'
import { Formik, Form } from 'formik'
import { Row, Col } from '../../components/ui/Layout'
import { Button } from '../../components/ui'
import fb from '../../firebase'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import Swal from 'sweetalert2'

const citiesDB = fb.database().ref('cities')

const FormRidersSettings = props => {
  const initialValues = props.initialValues
    ? props.initialValues
    : { overloaded: false }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => {
        var updates = {}
        if (values) {
          updates[`/${props.city}/riders/`] = values
          citiesDB
            .update(updates)
            .then(() => {
              Swal.fire({
                position: 'center',
                type: 'success',
                title: `Datos actualizados`,
                showConfirmButton: false,
                timer: 2500
              })
            })
            .catch(err => {
              Swal.fire({
                position: 'center',
                type: 'error',
                title: `Error al actualizar los datos, por favor intentalo mas tarde.`,
                showConfirmButton: false,
                timer: 2500
              })
            })
        }
      }}
      enableReinitialize
      render={({ values, errors, handleBlur, handleChange, loading }) => (
        <Form>
          <div className='modal-body'>
            <Row>
              <Col size={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.overloaded}
                      onChange={handleChange}
                      name='overloaded'
                      color='primary'
                    />
                  }
                  labelPlacement='start'
                  label={
                    <Typography
                      style={{
                        fontSize: 13,
                        fontWeight: 600,
                        color: '#717171',
                        marginLeft: 0
                      }}
                    >
                      Sobrecarga de ordenes
                    </Typography>
                  }
                  style={{ marginLeft: 0, paddingTop: 15 }}
                />
              </Col>
            </Row>
            <div className='modal-footer'>
              <Button
                label='Editar'
                type='submit'
                color='btn-info'
                disabled={loading}
              />
            </div>
          </div>
        </Form>
      )}
    />
  )
}
export default FormRidersSettings
