import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Panel, Button } from '../../components/ui/'
import { getGeofences, updateGeofence } from './actions'
import Swal from 'sweetalert2'

class EditGeofence extends Component {
  constructor (props) {
    super(props)
    this.state = {
      coords: {
        lat: 17.9917882,
        lng: -92.9367095
      },
      geofences: {}
    }
    this._initMap = this._initMap.bind(this)
    this._saveChanges = this._saveChanges.bind(this)
    this.isInit = false
    this.polygons = []
  }
  componentDidMount () {
    const {
      getGeofences,
      match: { params: { city, geofence } },
      profile,
      history
    } = this.props
    getGeofences(geofence, history)
    if (!profile.superUser) {
      if (profile.city !== city) {
        history.goBack()
      }
    }
  }
  componentDidUpdate (prevProps) {
    const {
      getGeofences,
      match: { params: { geofence } },
      history
    } = this.props
    if (prevProps.location.pathname !== this.props.location.pathname) {
      getGeofences(geofence, history)
    }
  }
  UNSAFE_componentWillReceiveProps (nextProps) {
    let { geofences } = nextProps
    this.setState(
      {
        geofences,
        coords: {
          lat:
            geofences && geofences.deliverArea && geofences.deliverArea[0].lat,
          lng:
            geofences && geofences.deliverArea && geofences.deliverArea[0].lng
        }
      },
      () => {
        this._initMap()
      }
    )
    // if (geofences && Object.values(geofences).length > 0 && !this.isInit) {
    //   Object.keys(geofences).forEach(key => {
    //     geofences[key].id = key
    //     return geofences[key]
    //   })
    // }
  }
  render () {
    return (
      <Panel style={{ height: '100%' }}>
        <h1>Editar Geocerca</h1>
        <Button
          label='Guardar Cambios'
          color='btn-success'
          onClick={this._saveChanges}
        />
        <div id='map' style={{ height: 500, width: '100%', marginTop: 20 }} />
      </Panel>
    )
  }
  _saveChanges () {
    updateGeofence(this.polygons, () => {
      Swal.fire({
        position: 'center',
        type: 'success',
        title: `Tus cambios han sido guardados.`,
        showConfirmButton: false,
        timer: 2500
      })
    })
  }
  _initMap () {
    this.isInit = true
    const { coords, geofences } = this.state
    const { match: { params: { geofence } } } = this.props
    let div = document.getElementById('map')
    let mapOptions = {
      center: coords,
      zoom: 13,
      zoomControl: true,
      zoomControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_CENTER
      },
      streetViewControl: false,
      mapTypeControl: false,
      mapTypeId: 'roadmap'
    }
    const map = new window.google.maps.Map(div, mapOptions)

    var polygonOptions = {
      paths: geofences.deliverArea,
      strokeColor: '#FF5722',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF5722',
      fillOpacity: 0.35,
      editable: true,
      map
    }
    var polygon = new window.google.maps.Polygon(polygonOptions)
    this.polygons.push({ polygon, id: geofence })
    var deleteNode = function (mev) {
      if (mev.vertex != null) {
        polygon.getPath().removeAt(mev.vertex)
      }
    }
    polygon.setMap(map)

    var addNode = function (mev) {}

    window.google.maps.event.addListener(polygon, 'rightclick', deleteNode)
    window.google.maps.event.addListener(map, 'rightclick', addNode)
  }
}

function mapStateToProps (state) {
  return {
    geofences: state.geofence,
    profile: state.profile
  }
}

function mapDispatchToProps (dispatch) {
  return {
    getGeofences: (geofence, history) =>
      dispatch(getGeofences(geofence, history))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditGeofence)
