import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`

export const Element = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${({ row }) => (row ? 'row' : 'column')};
`
export const GridElement = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 0;
`

export const Row = styled.div`
  display: flex;
  justify-content: ${({ justify }) => justify || 'space-around'};
  ${({ margin }) => margin && `margin: ${margin};`} align-items: center;
`

export const CoverImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 4px solid #666;
`

export const Categories = styled.span`
  color: #ff5722;
  font-size: 1em;
`

export const Star = styled.i`
  color: #fd9628;
`

export const Icon = styled.i`
  color: #ff5722;
  font-size: 1.5em;
`

export const Address = styled.p`
  font-size: 0.9em;
  margin-left: 10px;
  margin-bottom: 0px;
`

export const Text = styled.span`
  font-size: 1em;
  margin-left: 10px;
`
