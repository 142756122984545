import React from 'react'
import { Formik, Form, Field } from 'formik'
import { renderField } from '../../components/ui/Input'
import { Button } from '../../components/ui'
import { UploadPhoto } from '../../components/upload'
import * as Yup from 'yup'
import Switch from '../../components/ui/Switch.js'
import { ButtonContainer } from './styled'

const phoneRegExp = /^(\(?\+?[0-9]*\)?)?[0-9_\-()]*$/

const Validation = Yup.object().shape({
  name: Yup.string().required('Campo requerido'),
  last_name: Yup.string().required('Campo requerido'),
  email: Yup.string().email('Invalid email'),
  phone: Yup.string().matches(phoneRegExp, 'Formato no valido')
})

let RiderForm = ({ initialValues, handleSubmit, deleteRider, ...props }) => {
  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={Validation}
      enableReinitialize
      render={({ handleSubmit, setFieldValue, values, errors, ...props }) => (
        <Form onSubmit={handleSubmit}>
          <UploadPhoto
            folder={`riders/${'dishId'}/`}
            width='200'
            height='200'
            form='rider'
            margin='0px 0px 20px 0px'
            onUpload={e => {
              setFieldValue('avatar', e)
            }}
            initialPhoto={values && values.avatar}
          >
            <div style={{ fontSize: '3em' }}>
              <i className='fas fa-cloud-upload-alt' />
            </div>
          </UploadPhoto>
          <Field
            name='name'
            component={renderField}
            type='text'
            label='Nombre'
            helperText={errors.name && '*Campo Requerido'}
            error={errors.name}
          />
          <Field
            name='last_name'
            component={renderField}
            type='text'
            label='Apellido Paterno'
            helperText={errors.last_name && '*Campo Requerido'}
            error={errors.last_name}
            {...props}
          />
          <Field
            name='mother_last_name'
            component={renderField}
            type='text'
            label='Apellido Materno'
            {...props}
          />
          <Field
            name='phone'
            component={renderField}
            type='text'
            label='Telefono'
            helperText={errors.phone && '*Campo Requerido'}
            error={errors.phone}
            {...props}
          />
          <Field
            name='email'
            component={renderField}
            type='email'
            label='Email'
            helperText={errors.email && '*Campo Requerido'}
            error={errors.email}
          />
          <label>Baneado</label>
          <Switch
            on={values.banned || false}
            onClick={() => setFieldValue('banned', !values.banned)}
          />
          <ButtonContainer>
            <Button label='Guardar' color='btn-info' type='submit' />
            <Button
              label='Eliminar'
              color='btn-danger'
              type='button'
              onClick={deleteRider}
              disabled
            />
          </ButtonContainer>
        </Form>
      )}
    />
  )
}

export default RiderForm
