import React, { Component } from 'react'
import { Router, Route, Redirect, Switch } from 'react-router-dom'
import { Provider, connect } from 'react-redux'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import store from './store'
import Menu from './components/menu'
import Header from './components/header'
import Home from './containers/home/'
import Login from './containers/login/'
import PartnerHome from './containers/partnersHome'
import RestaurantHome from './containers/restaurantsHome'
import SetMapLocation from './containers/setMapLocation'
import Payment from './containers/payment'
import PaymentRiders from './containers/paymentRiders'
import PaymentRestaurant from './containers/paymentRestaurant'
import EditGeofence from './containers/editGeofence'
import CreateGeofence from './containers/createGeofence'
import Orders from './containers/orders'
import OrdersComplete from './containers/ordersComplete'
import UserHistory from './containers/History'
import Users from './containers/users'
import EditRiders from './containers/ridersHome'
import Riders from './containers/ridersMap'
import Settings from './containers/adminSettings'
import Profile from './containers/profile'
import Coupons from './containers/coupons'

import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

import createBrowserHistory from 'history/createBrowserHistory'

const history = createBrowserHistory()
export let persistor = persistStore(store)

let AdminRoutes = () => (
  // here are all the routes for the admin
  <Switch>
    <Route exact path='/' render={props => <Redirect to='/orders' />} />
    <Route path='/home' component={Home} />
    <Route path='/restaurant/:id?/edit' component={PartnerHome} />
    <Route path='/payment' component={Payment} />
    <Route path='/reportes/restaurantes' component={PaymentRestaurant} />
    <Route path='/reportes/riders' component={PaymentRiders} />
    <Route path='/editgeofence/:city/:geofence' component={EditGeofence} />
    <Route path='/createGeofence/:city' component={CreateGeofence} />
    <Route path='/orders' component={Orders} />
    <Route path='/ordersComplete' component={OrdersComplete} />
    <Route exact path='/users' component={Users} />
    <Route path='/users/history/:uid?' component={UserHistory} />
    <Route path='/rider/:id?/edit' component={EditRiders} />
    <Route path='/riders' component={Riders} />
    <Route path='/settings' component={Settings} />
    <Route path='/restaurants' component={RestaurantHome} />
    <Route path='/profile/:id?' component={Profile} />
    <Route path='/coupons' component={Coupons} />
    <Route path='*' render={() => <Redirect to='/home' />} />
  </Switch>
)

let PartnerRoutes = () => (
  // here are all the routes for the restaurant
  <Switch>
    <Route exact path='/' component={PartnerHome} />
    <Route exact path='/ubicacion' component={SetMapLocation} />
  </Switch>
)

let Routes = () => (
  // here are all the routes for the restaurant
  <Switch>
    <Route exact path='/' render={props => <Redirect to='/login' />} />
    <Route path='/users/history/:uid?' component={UserHistory} />
  </Switch>
)

let AllRoutes = ({ profile }) => {
  if (profile && profile.type === 'admin') {
    return <Route path='/' render={props => <AdminRoutes {...props} />} />
  } else if (profile && profile.type === 'restaurant') {
    return <Route path='/' render={props => <PartnerRoutes {...props} />} />
  } else {
    return <Route path='/' render={props => <Routes {...props} />} />
  }
}

let PrivateHeader = ({ profile, ...props }) => {
  return profile && profile.type ? <Route {...props} /> : null
}

const mapStateToProps = ({ profile }) => ({ profile })

PrivateHeader = connect(mapStateToProps, null)(PrivateHeader)
AllRoutes = connect(mapStateToProps, null)(AllRoutes)

class App extends Component {
  constructor (props) {
    super(props)
    this.state = {
      user: null
    }
  }

  render () {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ReactNotification />
          <Router history={history}>
            <section className='vbox'>
              <section>
                <section className='hbox stretch'>
                  <Route exact path='/login' component={Login} />
                  <PrivateHeader path='/' component={Menu} />
                  <section id='content'>
                    <PrivateHeader path='/' component={Header} />
                    <Route path='/' component={AllRoutes} />
                  </section>
                </section>
              </section>
            </section>
          </Router>
        </PersistGate>
      </Provider>
    )
  }
}

export default App
