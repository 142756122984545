/**
 * Copyright 2015-present, Lights in the Sky (3273741 NS Ltd.)
 * All rights reserved.
 *
 * This source code is licensed under the license found in the
 * LICENSE file in the root directory of this source tree.
 *
 * @providesModule Dashboard
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Panel } from '../../components/ui/'
import { Row, Col, Page } from '../../components/ui/Layout'
import {
  setRiderDataToEdit,
  activateRider,
  cleanRider
} from '../ridersHome/actions'
import TableRiders from '../../components/riders'
import _ from 'lodash'
import { bindDealers, getOrdersByToday, deleteProspects } from './actions'
import ReactTable from 'react-table'
import matchSorter from 'match-sorter'
import axios from 'axios'
import getEndpoint from '../../helpers/getEndpoint'
import Swal from 'sweetalert2'

const base_url = getEndpoint('REACT_APP_ENDPOINT_AWS')

class Home extends Component {
  state = {
    restaurantProspects: {},
    ridersProspects: {},
    loading: false
  }
  constructor (props) {
    super(props)
    this.goToEditRiders = this.goToEditRiders.bind(this)
    this.goToActivateRiders = this.goToActivateRiders.bind(this)
    this.onCleanRider = this.onCleanRider.bind(this)
  }

  render () {
    const {
      indicators: { saleByMonth, saleCurrentWeek, saleLastWeek, totalOrder },
      profile: { city, selectCity }
    } = this.props
    const {
      restaurantProspects: { data, pages, loading },
      ridersProspects: { data: rdata, pages: rpages, loading: rloading }
    } = this.state
    const columnsRest = this.renderHeaderRestaurants()
    const columnsRider = this.renderHeaderRiders()
    const filterCity = selectCity || city
    return (
      <Page>
        <Row>
          <Col size={12}>
            <section className='panel panel-default m-b-lg'>
              <section className='panel-body no-padder'>
                <div className='col-md-2 summaryItem'>
                  <h1>{0}</h1>
                  <p>PROSPECTOS</p>
                </div>
                <div className='col-md-2 summaryItem'>
                  <h1>{0}</h1>
                  <p>RESTAURANTS</p>
                </div>
                <div
                  className='col-md-3 summaryItem'
                  data-toggle='tooltip'
                  data-placement='bottom'
                  title={`Semana anterior \n $ ${
                    saleLastWeek
                      ? saleLastWeek
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                      : 0
                  }`}
                >
                  <h1>
                    ${' '}
                    {saleCurrentWeek
                      ? saleCurrentWeek
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                      : 0}
                  </h1>
                  <p>INGRESO / SEMANA</p>
                </div>
                <div className='col-md-3 summaryItem'>
                  <h1>
                    ${' '}
                    {saleByMonth
                      ? saleByMonth
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                      : 0}
                  </h1>
                  <p>INGRESO / MES</p>
                </div>
                <div className='col-md-2 summaryItem'>
                  <h1>{totalOrder}</h1>
                  <p>ORDENES ENTREGADAS</p>
                </div>
              </section>
            </section>
          </Col>
        </Row>
        <Row>
          <Col size={12}>
            <Panel>
              <h2> Prospectos Restaurantes</h2>
              <ReactTable
                data={data}
                loading={loading}
                columns={columnsRest}
                manual
                pages={pages}
                pageSize={20}
                showPageSizeOptions={false}
                onFetchData={(state, instance) => {
                  let searchFilter =
                    state.filtered &&
                    state.filtered[0] &&
                    state.filtered[0].value
                      ? state.filtered[0].value
                      : ''
                  if (searchFilter.length < 3 && searchFilter.length !== 0) {
                    return
                  }
                  let filterCityRT = filterCity
                  // show the loading overlay
                  this.setState({ restaurantProspects: { loading: true } })
                  // fetch your data
                  const restaurantProspects = `${base_url}v1/restaurants/prospects/?page=${state.page +
                    1}&city=${filterCityRT}&search=${searchFilter}`
                  axios.get(restaurantProspects).then(res => {
                    // Update react-table
                    this.setState({
                      restaurantProspects: {
                        data: res.data.results,
                        pages: Math.ceil(res.data.count / 20),
                        loading: false
                      }
                    })
                  })
                }}
              />
            </Panel>
            <Panel>
              <h2> Prospectos Riders</h2>
              <ReactTable
                data={rdata}
                loading={rloading}
                columns={columnsRider}
                manual
                showPageSizeOptions={false}
                pages={rpages}
                onFetchData={(state, instance) => {
                  const searchFilter =
                    state.filtered &&
                    state.filtered[0] &&
                    state.filtered[0].value
                      ? state.filtered[0].value
                      : ''
                  if (searchFilter.length < 3 && searchFilter.length !== 0) {
                    return
                  }
                  this.setState({ ridersProspects: { loading: true } })
                  const ridersProspects = `${base_url}v1/riders/prospects/?page=${state.page +
                    1}&city=${filterCity}&search=${searchFilter}`
                  axios
                    .get(ridersProspects)
                    .then(res => {
                      // Update react-table
                      this.setState({
                        ridersProspects: {
                          data: res.data.results,
                          pages: Math.ceil(res.data.count / 20),
                          loading: false
                        }
                      })
                    })
                    .catch(err => {
                      console.log('err ', err)
                    })
                }}
              />
            </Panel>
            <Panel>
              <h2> Repartidores </h2>
              <TableRiders
                onEdit={this.goToEditRiders}
                onClean={this.onCleanRider}
                onActivate={this.goToActivateRiders}
              />
            </Panel>
          </Col>
        </Row>
      </Page>
    )
  }

  componentDidMount () {
    const {
      bindDealers,
      getOrdersByToday,
      profile: { city, selectCity }
    } = this.props
    bindDealers(selectCity || city)
    getOrdersByToday(selectCity || city)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const { profile: { selectCity } } = this.props
    const { profile: { selectCity: newSelectCity } } = nextProps
    const { bindDealers, getOrdersByToday } = this.props
    if (selectCity !== newSelectCity) {
      bindDealers(newSelectCity)
      getOrdersByToday(newSelectCity)
    }
  }

  goToEditRiders (props) {
    const { setRiderDataToEdit, history: { push } } = this.props
    const row = props.row
    const id = row.foreign_key
    push(`/rider/${id}/edit`, row)
    setRiderDataToEdit(row._original)
  }

  onCleanRider (props) {
    Swal.fire({
      title: '¿Estas seguro de limpiar las ordenes asignadas?',
      text: '¡Limpiar ordenes asignadas!.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Limpialo!'
    }).then(result => {
      const { cleanRider } = this.props
      const row = props.row
      const id = row.foreign_key
      if (result.value && id) {
        cleanRider(id)
      }
    })
  }

  goToActivateRiders (props) {
    const { row: { _original } } = props
    const { activateRider } = this.props
    activateRider(_original.foreign_key, _original.in_service)
  }

  renderRestaurants (restaurantsArray) {
    var renderArray = restaurantsArray
    if (!_.isEmpty(renderArray)) {
      return renderArray.map((rest, i) => {
        const uid = rest.foreign_key || 'No cuenta con id'
        const name = rest.name || ''
        const description = rest.description
        const city = rest.city || 'Sin ciudad asignada'
        const phone = rest.phone || 'Sin número teléfonico'
        const email = rest.email || 'Sin correo electronico'
        const created_at = rest.created_at || 'Sin fecha de alta'
        return {
          uid,
          name: name,
          description,
          city: city,
          phone,
          email: email,
          created_at,
          source: rest
        }
      })
    }
  }

  renderHeaderRestaurants () {
    return [
      {
        Header: 'ID',
        accessor: 'uid',
        filterable: true,
        filterMethod: (filter, rows) => {
          return matchSorter(rows, filter.value, {
            keys: ['uid']
          })
        }
      },
      {
        Header: 'Nombre',
        accessor: 'name',
        filterAll: true,
        filterMethod: (filter, rows) => {
          return matchSorter(rows, filter.value, {
            keys: ['name']
          })
        }
      },
      {
        Header: 'Descripción',
        accessor: 'description',
        filterable: false
      },
      {
        Header: 'Ciudad',
        accessor: 'city',
        filterAll: false
      },
      {
        Header: 'Email',
        accessor: 'email'
      },
      {
        Header: 'Teléfono',
        accessor: 'phone'
      },
      {
        Header: 'Fecha de alta',
        accessor: 'created_at'
      }
    ]
  }

  renderRiders (ridersArray) {
    var renderArray = ridersArray
    if (!_.isEmpty(renderArray)) {
      return renderArray.map((rider, i) => {
        const uid = rider.foreign_key || 'No cuenta con id'
        const name = rider.name || ''
        const last_name = rider.last_name || ''
        const city = rider.city || 'Sin ciudad asignada'
        const phone = rider.phone || 'Sin número teléfonico'
        const email = rider.email || 'Sin correo electronico'
        return {
          uid,
          name: name,
          last_name: last_name,
          city: city,
          phone,
          email: email,
          source: rider
        }
      })
    }
  }

  renderHeaderRiders () {
    return [
      {
        Header: 'ID',
        accessor: 'foreign_key',
        filterable: true,
        filterMethod: (filter, rows) => {
          return matchSorter(rows, filter.value, {
            keys: ['foreign_key']
          })
        }
      },
      {
        Header: 'Nombre',
        accessor: 'name'
      },
      {
        Header: 'Apellido',
        accessor: 'last_name'
      },
      {
        Header: 'Ciudad',
        accessor: 'city'
      },
      {
        Header: 'Email',
        accessor: 'email'
      },
      {
        Header: 'Teléfono',
        accessor: 'phone'
      }
    ]
  }
}

const mapStateToProps = ({
  data: { ridersProspects, prospects, restaurants, indicators },
  profile
}) => ({
  prospects,
  ridersProspects,
  restaurants,
  indicators,
  profile
})

const mapDispatchToProps = dispatch => ({
  bindDealers: city => dispatch(bindDealers(city)),
  setRiderDataToEdit: payload => dispatch(setRiderDataToEdit(payload)),
  cleanRider: payload => dispatch(cleanRider(payload)),
  activateRider: (id, inService) => dispatch(activateRider(id, inService)),
  getOrdersByToday: city => dispatch(getOrdersByToday(city)),
  deleteProspects: id => dispatch(deleteProspects(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
