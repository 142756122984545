// internals
import styled from 'styled-components'
// libraries
import Dropzone from 'react-dropzone'

export const UploadPhoto = styled(Dropzone)`
  border: 4px solid gray;
  border-radius: 50%;
  box-sizing: border-box;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  overflow: hidden;
  background: ${({ background }) => background && `url(${background})`};
  background-size: cover;
  background-position: top;
  &:hover {
    background: ${({ background }) =>
    background &&
      `url('/assets/img/edit_picture.png') no-repeat, url(${background})`};
    background-size: 20%, cover;
    background-position: 80% 80%, top;
  }
`
