// Internals
import React, { Component } from 'react'
import { connect } from 'react-redux'
import fb from '../../firebase'
import _ from 'lodash'
import Swal from 'sweetalert2'
// Components
import { Panel, Button } from '../../components/ui/'
import { Row, Page } from '../../components/ui/Layout'
import GoogleMap from '../../components/googleMap'
import { Marker } from 'react-google-maps'
// Actions
import {
  bindRestaurants,
  setRestaurantDataToEdit
} from '../partnersHome/actions'

const restaurantDB = fb.database().ref('restaurants')

class SetMapLocation extends Component {
  constructor (props) {
    super(props)
    this.state = {
      coords: {
        lat: 17.987383394211314,
        lng: -92.94093382133792
      }
    }
    this.onMarkerPositionChanged = this.onMarkerPositionChanged.bind(this)
    this.onClickSave = this.onClickSave.bind(this)
  }
  render () {
    const { coords } = this.state
    return (
      <Page>
        <Row>
          <Panel title='Seleccione la ubicación de su restaurante'>
            <Button
              label='Guardar'
              color='btn-info'
              style={{ marginBottom: '20px', float: 'right' }}
              onClick={this.onClickSave}
            />
            <GoogleMap
              defaultZoom={15}
              defaultCenter={coords}
              center={coords}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={
                <div style={{ height: `500px`, clear: 'both' }} />
              }
              mapElement={<div style={{ height: `100%` }} />}
            >
              <Marker
                position={coords}
                onDrag={this.onMarkerPositionChanged}
                draggable
              />
            </GoogleMap>
          </Panel>
        </Row>
      </Page>
    )
  }
  UNSAFE_componentWillReceiveProps (nextProps) {
    const { collectRestaurant, currentRestaurant, profile } = nextProps
    if (profile) {
      profile.uid &&
        currentRestaurant.length === 0 &&
        collectRestaurant(profile.uid)
    }
    currentRestaurant.length > 0 &&
      this.setState({ coords: currentRestaurant[0].location })
  }
  componentDidMount () {
    const {
      currentRestaurant,
      location: { state: { restId } },
      setRestaurantDataToEdit
    } = this.props
    if (currentRestaurant.length > 0) {
      let location = currentRestaurant[0].location
      this.setState({
        coords: location
      })
    }
    if (restId && currentRestaurant.length === 0) {
      const restaurantsDB = fb.database().ref('restaurants')
      restaurantsDB
        .child(restId)
        .once('value')
        .then(snp => {
          const dataRest = snp.val()
          let location = dataRest.location
          dataRest['_id'] = snp.key
          setRestaurantDataToEdit(dataRest)
          this.setState({
            coords: location
          })
        })
    }
  }
  onMarkerPositionChanged (event) {
    var lat = event.latLng.lat()
    var lng = event.latLng.lng()
    this.setState(({ coords }) => {
      coords.lat = lat
      coords.lng = lng
      return { coords }
    })
  }
  onClickSave (event) {
    const { coords } = this.state
    var { currentRestaurant, history: { replace } } = this.props
    const restaurants = currentRestaurant[0]
    var restaurantId = restaurants._id
    const url = `https://maps.google.com/maps/api/geocode/json?latlng=${
      coords.lat
    },${coords.lng}&key=AIzaSyCj5dAc8ji1iRF5qJVgeSvzwQfmMVEHifs`
    fetch(url)
      .then(res => {
        return res.json()
      })
      .then(response => {
        restaurants.address.coords = {
          latitude: coords.lat,
          longitude: coords.lng
        }
        restaurants.address.formatted = response.results[0].formatted_address
        restaurantDB
          .child(restaurantId)
          .update(_.omit(restaurants, ['_id']))
          .then(() => replace('/home'))
          .catch(err =>
            Swal.fire({
              position: 'center',
              type: 'error',
              title: `${err.message}`,
              showConfirmButton: false,
              timer: 2500
            })
          )
      })
  }
}

const mapStateToProps = ({ data }) => ({
  ...data
})

const mapDispatchToProps = dispatch => {
  return {
    collectRestaurant: uid => dispatch(bindRestaurants(uid)),
    setRestaurantDataToEdit: payload =>
      dispatch(setRestaurantDataToEdit(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SetMapLocation)
