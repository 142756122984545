import styled from 'styled-components'

export const Icon = styled.i`
  color: #717171;
  font-size: 20px;
`

export const WrapperItem = styled.div`
  display: flex;
  align-items: center;
`

export const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`
