import React from 'react'
import FormInput from '../../components/FormInput'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Label, SwitchGroup, FormWrap } from './styled'

const ListSchema = Yup.object().shape({
  name: Yup.string().required('Campo requerido'),
  needed: Yup.string().required('Campo requerido'),
  maxItem: Yup.string().required('Campo requerido'),
  minItem: Yup.string().required('Campo requerido')
})

const AddList = props => {
  const { onCancel } = props
  const initialValues =
    props.edit && props.edit._original
      ? {
        ...props.edit._original,
        needed: true,
        maxItem: 0,
        minItem: 0
      }
      : {
        needed: true,
        maxItem: 0,
        minItem: 0
      }
  return (
    <Formik
      validationSchema={ListSchema}
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        if (props.edit) {
          props.onEdit(values)
          return
        }
        props.onSave(values)
      }}
      render={({
        values,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        loading
      }) => (
        <FormWrap>
          <Form style={{ width: '50%' }}>
            <div className='modal-body'>
              <Label fSize='1.5em'>Nuevo Extra</Label>
              <FormInput
                placeholder='Nombre...'
                name='name'
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.name}
                value={values.name}
              />
              <FormInput
                type='hidden'
                label='Obligatorio...'
                name='needed'
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.needed}
                value={values.needed}
              />
              <FormInput
                type='hidden'
                placeholder='Minimo...'
                name='minItem'
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.minItem}
                value={0}
              />
              <FormInput
                type='hidden'
                placeholder='Maximo...'
                name='maxItem'
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.maxItem}
                value={0}
              />
            </div>
            <SwitchGroup justify='flex-end'>
              <div style={{ paddingRight: 10 }}>
                <button
                  type='button'
                  className='btn btn-danger'
                  onClick={() => onCancel()}
                >
                  Cancelar
                </button>
              </div>
              <button type='submit' className='btn btn-info' disabled={loading}>
                Guardar
              </button>
            </SwitchGroup>
          </Form>
        </FormWrap>
      )}
    />
  )
}

export default AddList
