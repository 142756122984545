import React from 'react'
import { Wrapper, SubWrap, Covering, Label } from './styles'

const Element = ({ bRight, component, label, back, flex, reduce }) => {
  return (
    <Wrapper bRight={bRight} flex={flex}>
      {label && (
        <Covering back={back}>
          <Label>{label}</Label>
        </Covering>
      )}
      {component && <SubWrap reduce={reduce}>{component}</SubWrap>}
    </Wrapper>
  )
}

export default Element
