import styled from 'styled-components'

export const Icon = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  object-fit: cover;
`

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
