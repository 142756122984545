import _ from 'lodash'
import Swal from 'sweetalert2'
import { store } from 'react-notifications-component'
import fb from '../../firebase'
import { bindRestaurants as getRestaurants } from '../restaurantsHome/actions'
import { stopLoading } from '../../actions/ui'
import getEndpoint from '../../helpers/getEndpoint'
import { clearPhoto } from '../../components/upload/actions'

const URL = getEndpoint('REACT_APP_ENDPOINT_API')
const URL_AWS = getEndpoint('REACT_APP_ENDPOINT_AWS')
const DJANGO_TOKEN = getEndpoint('REACT_APP_DJANGO_TOKEN')
const restaurantsDB = fb.database().ref('restaurants')
const dishesDB = fb.database().ref('dishes')
export const activeLoading = (msg = false) => {
  Swal.fire({
    position: 'center',
    title: msg || 'Tu petición se esta cargando',
    showConfirmButton: false,
    allowOutsideClick: () => !Swal.isLoading(),
    onBeforeOpen: () => {
      Swal.showLoading()
    }
  })
}

export const updatePromotion = (form, discountKey, onSuccess) => {
  return dispatch => {
    const url = discountKey
      ? `${URL}/editDiscountRestaurant`
      : `${URL}/createDiscountRestaurant`
    if (discountKey) form.discountKey = discountKey
    const body = JSON.stringify({ ...form })
    fetch(url, {
      method: discountKey ? 'PUT' : 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body
    })
      .then(res => res.json())
      .then(resJSON => {
        if (resJSON.success) {
          store.addNotification({
            title: 'Promociones',
            message: discountKey
              ? 'Se ha actualizado la promoción'
              : 'Se ha creado la promoción',
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          })
          const toUpdate = JSON.parse(body)
          delete toUpdate.uid
          dispatch({
            type: 'EDIT_CURRENT_RESTAURANTS',
            payload: {
              ...toUpdate
            },
            meta: {
              discountId: _.get(resJSON, 'discountId', false)
            }
          })
          dispatch(getRestaurants())
          if (_.isFunction(onSuccess)) onSuccess()
        }
      })
      .catch(err =>
        Swal.fire({
          position: 'center',
          type: 'error',
          title: `${err.message}`,
          showConfirmButton: false,
          timer: 2500
        })
      )
  }
}

export const bindRestaurants = uid => {
  return dispatch => {
    restaurantsDB
      .orderByChild('partner')
      .equalTo(uid)
      .once('value')
      .then(snapshot => {
        const data = snapshot.val()
        if (data) {
          Object.keys(data).forEach(id => {
            data[id]._id = id
            return data[id]
          })
          dispatch({
            type: 'GET_CURRENT_RESTAURANTS',
            payload: Object.values(data)
          })
        }
      })
    restaurantsDB
      .orderByChild('partner')
      .equalTo(uid)
      .on('child_changed', snp => {
        const data = snp.val()
        const dataArray = []
        if (data) {
          data._id = snp.key
          dataArray.push(data)
          dispatch({ type: 'GET_CURRENT_RESTAURANTS', payload: dataArray })
        }
      })
  }
}
export const updateRestaurantPopular = payload => {
  return dispatch => {
    const { data, cb, uid } = payload
    var createData = new FormData()
    createData.append('is_top', data.is_top)
    createData.append('top_position', data.top_position)
    const url = `${URL_AWS}v2/restaurants/${uid}/`
    fetch(url, {
      method: 'PATCH',
      headers: {
        Authorization: `token ${DJANGO_TOKEN}`
      },
      body: createData
    })
      .then(res => {
        if (res.status === 200) {
          cb()
          dispatch(stopLoading())
          return Swal.fire({
            position: 'center',
            type: 'success',
            title: '¡Los cambios se han guardado con éxito!',
            showConfirmButton: false,
            timer: 1500
          })
        }
        cb()
        dispatch(stopLoading())
        Swal.fire({
          position: 'center',
          type: 'error',
          title: `Estado: ${res.status}`,
          text: 'Ha ocurrido un error al editar el negocio.',
          showConfirmButton: false,
          timer: 2500
        })
      })
      .catch(err => {
        dispatch(stopLoading())
        Swal.fire({
          position: 'center',
          type: 'error',
          title: err.message || '',
          text: 'Ha ocurrido un error al editar el negocio.',
          showConfirmButton: false,
          timer: 1500
        })
        cb()
      })
  }
}

export const updateRestaurantInfo = payload => {
  const { data, city, isNewRegister, cb } = payload
  return dispatch => {
    var createData = new FormData()
    const phone = data.phone.split('-').reduce((a, b) => a + b)
    const address = {
      ...data.address,
      coords: {
        latitude: Number(data.address.coords.latitude),
        longitude: Number(data.address.coords.longitude)
      }
    }
    _.hasIn(data, '_id') && createData.append('foreign_key', data._id)
    _.hasIn(data, 'phone') && createData.append('phone', phone)
    _.hasIn(data, 'address') &&
      createData.append('address', JSON.stringify(address))
    createData.append('city', data.city || city)
    _.hasIn(data, 'acceptCash') &&
      createData.append('acceptCash', data.acceptCash || false)
    _.hasIn(data, 'acceptPaypal') &&
      createData.append('acceptPaypal', data.acceptPaypal || false)
    _.hasIn(data, 'acceptPickUp') &&
      createData.append('acceptPickUp', data.acceptPickUp || false)
    _.hasIn(data, 'accept_credit_card') &&
      createData.append('accept_credit_card', data.accept_credit_card)
    createData.append('active', data.active || false)
    createData.append('banned', data.banned || false)
    _.hasIn(data, 'short_description') &&
      createData.append('short_description', data.short_description)
    _.hasIn(data, 'comissionPercentage') &&
      createData.append('comissionPercentage', data.comissionPercentage)
    _.hasIn(data, 'name') && createData.append('name', data.name)
    _.hasIn(data, 'description') &&
      createData.append('description', data.description)
    _.hasIn(data, 'email') && createData.append('email', data.email)
    _.hasIn(data, 'partner') && createData.append('partner', data.partner)
    _.hasIn(data, 'schedule') &&
      createData.append('schedule', JSON.stringify(data.schedule))
    if (!_.isEmpty(data.photo) && !_.isEmpty(data.photo.file)) {
      createData.append('local_file', data.photo.file)
      createData.append('folder', data.photo.folder)
      createData.append('width', data.photo.width)
      createData.append('height', data.photo.height)
      createData.append('name_photo', Date.now())
    }
    createData.append('isProspect', false)
    const url = isNewRegister
      ? `${URL_AWS}v2/restaurants/`
      : `${URL_AWS}v2/restaurants/${data._id}/`
    const titleAlert = isNewRegister
      ? 'Su nuevo restaurante se ha guardado exitosamente. \nPor defecto, los pagos con paypal están activados'
      : '¡Los cambios se han guardado con éxito!'
    const titleErrorAlert = isNewRegister
      ? 'Ha ocurrido un error al crear el negocio.'
      : 'Ha ocurrido un error al editar el negocio.'

    fetch(url, {
      method: isNewRegister ? 'POST' : 'PATCH',
      headers: {
        Authorization: `token ${DJANGO_TOKEN}`
      },
      body: createData
    })
      .then(res => {
        if (res.status === 200) {
          cb()
          dispatch(stopLoading())
          return Swal.fire({
            position: 'center',
            type: 'success',
            title: titleAlert,
            showConfirmButton: false,
            timer: 1500
          })
        }
        cb()
        dispatch(stopLoading())
        Swal.fire({
          position: 'center',
          type: 'error',
          title: `Estado: ${res.status}`,
          text: titleErrorAlert,
          showConfirmButton: false,
          timer: 2500
        })
      })
      .catch(err => {
        dispatch(stopLoading())
        Swal.fire({
          position: 'center',
          type: 'error',
          title: err.message || '',
          text: titleErrorAlert,
          showConfirmButton: false,
          timer: 1500
        })
        cb()
      })
  }
}

// Update current restaurant when edit as admin
export const setRestaurantDataToEdit = payload => {
  return dispatch => {
    dispatch({ type: 'GET_CURRENT_RESTAURANTS', payload: [payload] })
    const idRest = payload._id
    // It work when de restaurant data have not photo
    if (idRest) {
      restaurantsDB.child(idRest).on('child_added', snp => {
        const data = snp.val()
        const dataKey = snp.key
        if (dataKey === 'photo') {
          dispatch({
            type: 'EDIT_CURRENT_RESTAURANTS',
            key: dataKey,
            payload: data
          })
        }
      })
      restaurantsDB.child(idRest).on('child_changed', snp => {
        const data = snp.val()
        const dataKey = snp.key
        dispatch({
          type: 'EDIT_CURRENT_RESTAURANTS',
          key: dataKey,
          payload: data
        })
      })
    }
  }
}

export const addDish = (dish, restaurant, _clearDishes) => dispatch => {
  activeLoading('Agregando platillo...')
  var formData = new FormData()
  // DISH
  formData.append('name', dish.name)
  formData.append('description', dish.description)
  formData.append('price', dish.price)
  formData.append('restaurant', restaurant) // Restaurant id
  formData.append(
    'is_dish',
    _.capitalize(dish.is_dish) ||
      (!dish.is_dish && !dish.is_extra && _.capitalize(true))
  )
  formData.append(
    'is_extra',
    _.capitalize(dish.is_extra) || _.capitalize(false)
  )
  if (!_.isEmpty(dish.photo)) {
    formData.append('local_file', dish.photo.file)
    formData.append('folder', dish.photo.folder)
    formData.append('width', dish.photo.width)
    formData.append('height', dish.photo.height)
    formData.append('name_photo', Date.now())
  }
  return fetch(`${URL_AWS}v2/dishes/`, {
    method: 'POST',
    headers: {
      Authorization: `token ${DJANGO_TOKEN}`
    },
    body: formData
  })
    .then(res => {
      return res.json()
    })
    .then(resJSON => {
      if (resJSON.success) {
        Swal.fire({
          position: 'center',
          type: 'success',
          title: `¡El platillo ha sido creado!`,
          showConfirmButton: false,
          timer: 2500
        })
        dispatch(clearPhoto())
        _clearDishes()
        return true
      } else {
        Swal.fire({
          position: 'center',
          type: 'error',
          title: `${
            resJSON.detail
              ? resJSON.detail
              : `${resJSON.code_error}: ${resJSON.message}`
          }`,
          showConfirmButton: false,
          timer: 1500
        })
        return false
      }
    })
    .catch(err => {
      Swal.fire({
        position: 'center',
        type: 'error',
        title: `Error: ${err}`,
        showConfirmButton: false,
        timer: 2500
      })
      return false
    })
}

export const dishEdit = async (dish, _clearDishes) => {
  var formData = new FormData()
  // DISH
  _.hasIn(dish, 'name') && formData.append('name', dish.name)
  _.hasIn(dish, 'description') &&
    formData.append('description', dish.description)
  _.hasIn(dish, 'price') && formData.append('price', dish.price)
  _.hasIn(dish, 'is_active') && formData.append('is_active', dish.is_active)
  if (!_.isEmpty(dish.photo) && !_.isEmpty(dish.photo.file)) {
    formData.append('local_file', dish.photo.file)
    formData.append('folder', dish.photo.folder)
    formData.append('width', dish.photo.width)
    formData.append('height', dish.photo.height)
    formData.append('name_photo', Date.now())
  }
  if (_.isEmpty(dish)) {
    return
  }
  return fetch(`${URL_AWS}v2/dishes/${dish.dishId}/`, {
    method: 'PUT',
    headers: {
      Authorization: `token ${DJANGO_TOKEN}`
    },
    body: formData
  })
    .then(res => {
      if (res.status === 200) {
        _clearDishes && _clearDishes()
        clearPhoto()
        Swal.fire({
          position: 'center',
          type: 'success',
          title: '¡El platillo se ha editado con éxito!',
          showConfirmButton: false,
          timer: 2500
        })
        if (!_.isEmpty(dish.extras)) {
          dishesDB.child(dish.dishId).update({ extras: dish.extras })
        }
      } else {
        Swal.fire({
          position: 'center',
          type: 'error',
          title: `Estado: ${res.status}`,
          text: `Ha ocurrido un error al editar el platillo.`,
          showConfirmButton: false,
          timer: 2500
        })
      }

      return res.json()
    })
    .then(result => {
      return result
    })
    .catch(e => {
      Swal.fire({
        position: 'center',
        type: 'error',
        title: 'Ha ocurrido un error al editar el platillo',
        text: `${e.message}`,
        showConfirmButton: false,
        timer: 2500
      })
    })
}

export const dishDelete = (dish, resetForm, clearDishes) => {
  return dispatch => {
    Swal.fire({
      title: '¿Estas seguro de eliminar el platillo?',
      text: 'Una ves hecho esto no habra manera de recuperarlo',
      showCancelButton: true,
      confirmButtonColor: '#03A9F4',
      cancelButtonColor: '#ea5a5a',
      confirmButtonText: 'Si, eliminalo'
    }).then(result => {
      if (result.value) {
        fetch(`${URL_AWS}v2/dishes/${dish}/`, {
          method: 'DELETE',
          headers: {
            Authorization: `token ${DJANGO_TOKEN}`
          }
        })
          .then(res => res.json())
          .then(resJSON => {
            if (resJSON.success) {
              resetForm({
                name: '',
                price: '',
                description: '',
                position: '',
                is_dish: true,
                is_extra: false
              })
              clearDishes()
              return Swal.fire({
                position: 'center',
                type: 'success',
                title: '¡El platillo ha sido eliminado!',
                showConfirmButton: false,
                timer: 1500
              })
            } else {
              Swal.fire({
                position: 'center',
                type: 'error',
                title: `Error ${resJSON.error_code}: ${resJSON.message}`,
                showConfirmButton: false,
                timer: 1500
              })
            }
          })
          .catch(err =>
            Swal.fire({
              position: 'center',
              type: 'error',
              title: `Error: ${err}`,
              showConfirmButton: false,
              timer: 1500
            })
          )
      }
      dispatch({ type: 'CLEAR_CURRENT_DISH' })
    })
  }
}

export const waitTime = props => {
  const { restId, waitTime } = props
  return distpatch => {
    let updates = {}
    updates[`/${restId}/waitTime`] = waitTime

    restaurantsDB
      .update(updates)
      .then(() =>
        Swal.fire({
          position: 'center',
          type: 'success',
          title: `Tiempo de espera agregado.`,
          showConfirmButton: false,
          timer: 1500
        })
      )
      .catch(error => {
        Swal.fire({
          position: 'center',
          type: 'error',
          title: 'Ha ocurrido un error',
          text: `Error: ${error}`,
          showConfirmButton: false,
          timer: 1500
        })
      })
  }
}
