import React from 'react'
import styled from 'styled-components'

export const Row = ({ children, classes, mason, style }) => (
  <div style={style} className={`row ${classes} ${mason ? `mason_row_3` : ``}`}>
    {children}
  </div>
)

export const Col = ({ children, size, offset, classes }) => {
  let colSize = ''
  if (typeof size === 'object') {
    colSize = Object.keys(size)
      .map(k => {
        return `col-${k}-${size[k]} `
      })
      .join(' ')
  } else {
    colSize = `col-md-${size}`
  }

  let offsetSize = ''
  if (typeof offset === 'object') {
    offsetSize = Object.keys(offset)
      .map(k => {
        return `col-${k}-offset-${offset[k]} `
      })
      .join(' ')
  } else {
    offsetSize = `col-md-offset-${offset}`
  }

  return (
    <div className={`${colSize} ${classes} ${offset ? `${offsetSize}` : ''}`}>
      {children}
    </div>
  )
}

export const Page = ({ children, height, classes }) => (
  <section className='vbox' style={height ? { height: height } : {}}>
    <section className='scrollable'>
      <div className='container full content-body'>{children}</div>
    </section>
  </section>
)

export const Scroller = ({ children, height }) => (
  <div className='scrollable' style={{ height: height }}>
    {children}
  </div>
)

export const EditButtons = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
`
