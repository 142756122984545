import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Marker, InfoWindow } from 'react-google-maps'
import fb from '../../firebase'
import { Panel } from '../../components/ui/'
import { Row, Page } from '../../components/ui/Layout'
import GoogleMap from '../../components/googleMap'
import { Icon, IconContainer } from './styled'
import { getGeofences } from '../../helpers/geofences'
const {
  MarkerClusterer
} = require('react-google-maps/lib/components/addons/MarkerClusterer')

const riderDb = fb.database().ref('partners')

const IconMarker = ({ url, name, onClick, visible }) => {
  return (
    <IconContainer>
      <Icon className={!visible && 'hidden'} src={url} />
      <a onClick={e => onClick(e)}>
        <h6>{name}</h6>
      </a>
    </IconContainer>
  )
}

class ridersMap extends Component {
  constructor (props) {
    super(props)
    const { profile: { city, selectCity }, cities } = props
    const cityProp = selectCity || city
    this.state = {
      geofence: cities[cityProp].geofence
    }
  }

  componentDidMount () {
    this.state.geofence && this.props.getGeofences(this.state.geofence)
    const { profile: { city, selectCity } } = this.props
    const cityProp = selectCity || city
    riderDb
      .orderByChild('type')
      .equalTo('delivery')
      .on('value', snp => {
        let riders = snp.val()
        var riderCity = Object.values(riders)
          .filter(item => item.city === cityProp)
          .filter(rider => rider.inService && !rider.banned)
        this.setState({ riders: riderCity })
      })
  }
  UNSAFE_componentWillReceiveProps (nextProps) {
    const { profile: { city, selectCity }, cities } = this.props
    const { profile: { selectCity: newSelectCity } } = nextProps
    const currentCity = selectCity || city
    if (newSelectCity && currentCity !== newSelectCity) {
      riderDb.off()
      this.setState(
        {
          geofence: cities[newSelectCity].geofence
        },
        () => {
          riderDb
            .orderByChild('type')
            .equalTo('delivery')
            .on('value', snp => {
              let riders = snp.val()
              Object.keys(riders).forEach(key => {
                riders[key].uid = key
              })
              var riderCity = Object.values(riders)
                .filter(item => item.city === newSelectCity)
                .filter(rider => rider.inService && !rider.banned)
              this.setState({ riders: riderCity })
            })
          this.props.getGeofences(cities[newSelectCity].geofence)
        }
      )
    }
  }

  setRiders = riders => {
    if (riders) {
      return riders.map((rider, i) => {
        if (rider.tracking) {
          const {
            avatar,
            lastName,
            tracking: { latitude: lat, longitude: lng },
            name
          } = rider
          const coords = {
            lat,
            lng
          }
          return (
            <Marker
              key={`rider_${i}`}
              options={{
                zIndex: 10000
              }}
              position={coords}
              title={`${name} ${lastName}`}
              visible={false}
              noRedraw={true}
            >
              <InfoWindow>
                <IconMarker
                  url={avatar}
                  name={`${name} ${lastName}`}
                  onClick={e => {
                    e.preventDefault()
                    this.toggleImg(i)
                  }}
                  visible={this.state[`rider${i}`]}
                />
              </InfoWindow>
            </Marker>
          )
        }
        return null
      })
    } else {
      return null
    }
  }

  render () {
    let { riders } = this.state
    const { geofenceData } = this.props
    return (
      <Page>
        <Row>
          <Panel title='Ubicación actual de los riders'>
            {geofenceData &&
              geofenceData.deliverArea && (
              <GoogleMap
                defaultZoom={13}
                defaultCenter={
                  geofenceData.center || geofenceData.deliverArea[0]
                }
                center={geofenceData.center || geofenceData.deliverArea[0]}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={
                  <div style={{ height: `500px`, clear: 'both' }} />
                }
                mapElement={<div style={{ height: `100%` }} />}
              >
                {
                  <MarkerClusterer
                    averageCenter
                    enableRetinaIcons
                    gridSize={100}
                    maxZoom={13}
                  >
                    {this.setRiders(riders)}
                  </MarkerClusterer>
                }
              </GoogleMap>
            )}
          </Panel>
        </Row>
      </Page>
    )
  }

  toggleImg (key) {
    const visible = !this.state[`rider${key}`]
    this.setState({ [`rider${key}`]: visible })
  }
}

const mapStateToProps = state => {
  return {
    cities: state.data.settings.cities,
    profile: state.profile,
    geofenceData: state.geofence
  }
}
function mapDispatchToProps (dispatch) {
  return {
    getGeofences: geofence => dispatch(getGeofences(geofence))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ridersMap)
