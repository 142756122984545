const isStaging = process.env.REACT_APP_VERCEL_GIT_COMMIT_REF === 'staging'

const enviroment =
  process.env.REACT_APP_VERCEL_GIT_COMMIT_REF &&
  isStaging &&
  process.env.REACT_APP_VERCEL_GIT_COMMIT_REF.toUpperCase()

const endpoint = envVar => {
  const key = enviroment ? `${envVar}_${enviroment}` : envVar
  return process.env[key]
}

export default endpoint
