import _ from 'lodash'

export const ui = (state = { loading: false }, { type, payload }) => {
  switch (type) {
    case 'START_LOADING':
      return {
        loading: true
      }
    case 'STOP_LOADING':
      return {
        loading: false,
        error: payload ? payload.error : null,
        message: payload ? payload.message : ''
      }
    case 'CLEAN_ERROR':
      return {
        loading: false,
        error: null,
        message: null
      }
    case 'SET_PLAY':
      return {
        shouldPlay: payload
      }
    case 'ADD_PHOTO':
      return {
        ...state,
        photo: payload
      }
    case 'CLEAR_PHOTO':
      return {
        ...state,
        photo: {
          uploading: false
        }
      }
    default:
      return state
  }
}

export const extrasList = (state = [], { type, payload }) => {
  switch (type) {
    case 'SET_EXTRAS_LIST':
      return { ...payload }
    case 'CLEAR_EXTRAS_LIST':
      return {
        state
      }
    default:
      return state
  }
}

export const users = (state = {}, { type, payload }) => {
  switch (type) {
    case 'SET_USERS_LIST':
      return { ...payload }
    default:
      return state
  }
}

export const prospects = (state = {}, { type, payload }) => {
  switch (type) {
    case 'GET_PROSPECTS':
      return { ...payload }
    default:
      return state
  }
}

export const ridersProspects = (state = {}, { type, payload }) => {
  switch (type) {
    case 'GET_RIDERS_PROSPECTS':
      return { ...payload }
    default:
      return state
  }
}

export const availableRiders = (state = [], { type, payload }) => {
  switch (type) {
    case 'FETCH_AVAILABLE_RIDERS':
      return [...payload]
    default:
      return state
  }
}

export const dealers = (state = {}, { type, payload }) => {
  switch (type) {
    case 'GET_DEALERS_LOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'GET_DEALERS_SUCCESS':
      return {
        ...state,
        isLoading: false
      }
    case 'GET_DEALERS':
      return {
        ...payload
      }
    default:
      return state
  }
}

export const restaurants = (state = {}, { type, payload, key, meta }) => {
  switch (type) {
    case 'GET_RESTAURANTS_LOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'GET_RESTAURANTS_SUCCESS':
      return {
        ...state,
        isLoading: false
      }
    case 'GET_RESTAURANTS_FAILURE':
      return {
        ...state,
        errorMessage: payload,
        isLoading: true
      }
    case 'GET_RESTAURANTS':
      return {
        ...payload
      }
    case 'UPDATE_RESTAURANT_WITH_DISCOUNT':
      if (meta.restaurantId && meta.discountId) {
        return {
          ...state,
          [meta.restaurantId]: {
            ...state[[meta.restaurantId]],
            discounts: {
              [meta.discountId]: {
                ...payload
              }
            }
          }
        }
      }
      return {
        ...state
      }
    case 'UPDATE_RESTAURANTS':
      const restaurants = { ...state }
      restaurants[key] = { ...restaurants[key], ...payload }
      return restaurants
    default:
      return state
  }
}

export const currentRestaurant = (
  state = [{}],
  { type, payload, key, meta }
) => {
  switch (type) {
    case 'GET_CURRENT_RESTAURANTS':
      return [...payload]
    case 'EDIT_CURRENT_RESTAURANTS':
      const stateObjRest = { ...state }
      if (!_.isUndefined(meta) && meta.discountId) {
        stateObjRest[0].discounts = {
          [meta.discountId]: {
            ...payload
          }
        }
        return stateObjRest
      } else {
        stateObjRest[0][key] = payload
        return stateObjRest
      }
    default:
      return state
  }
}

export const currentDish = (state = null, { type, payload, key }) => {
  switch (type) {
    case 'CLEAR_CURRENT_DISH':
      return null
    case 'SET_CURRENT_DISH':
      return payload
    default:
      return state
  }
}

export const currentRider = (state = [], { type, payload, key }) => {
  // Condition added to remove "photo" from state, to use avatar instead.
  if (state.photo) {
    state.photo = undefined
  }
  switch (type) {
    case 'GET_CURRENT_RIDER':
      return {
        ...state,
        ...payload
      }
    case 'EDIT_CURRENT_RIDER':
      const stateObjRest = { ...state }
      stateObjRest[key] = payload
      return {
        ...state,
        ...stateObjRest
      }
    default:
      return state
  }
}

export const profile = (state = { user: false }, { type, payload }) => {
  switch (type) {
    case 'LOGIN':
      return payload
    case 'LOGOUT':
      return {}
    case 'SELECT_USER_CITY':
      return { ...state, selectCity: payload }
    default:
      return state
  }
}

export const geofence = (state = {}, { type, payload }) => {
  switch (type) {
    case 'CLEAN_GEOFENCE':
      return (state = {})
    case 'GET_GEOFENCE':
      return payload
    default:
      return state
  }
}

export const indicators = (state = {}, { type, payload }) => {
  switch (type) {
    case 'GET_INDICATORS':
      return payload
    default:
      return state
  }
}

export const settings = (
  state = { cities: {}, settingsCity: {}, riderSettingsCity: {} },
  { type, payload }
) => {
  switch (type) {
    case 'SET_CITIES':
      const city = {}
      city[payload.key] = { ...payload }
      return {
        ...state,
        cities: { ...state.cities, ...city }
      }
    case 'SET_RIDERS_SETTINGS':
      return {
        ...state,
        riderSettingsCity: { ...state.riderSettingsCity, ...payload }
      }
    case 'SET_SETTINGS':
      return {
        ...state,
        settingsCity: { ...state.settingsCity, ...payload }
      }
    case 'CLEAN_SETTINGS':
      return {
        ...state,
        settingsCity: {}
      }
    default:
      return state
  }
}

export const coupons = (
  state = {
    newCoupons: []
  },
  { type, payload }
) => {
  switch (type) {
    case 'SET_NEW_COUPONS':
      return {
        ...state,
        newCoupons: payload
      }
    case 'SEARCH_COUPON':
      return {
        ...state,
        couponFound: payload
      }
    default:
      return state
  }
}
