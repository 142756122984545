import React, { useState, useEffect } from 'react'
import { Container, Wrapper, SubWrap, Text, CouponWrap } from './styled'
import _ from 'lodash'

import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CouponCard from '../couponCard'
import getEndpoint from '../../helpers/getEndpoint'

const endpoint = getEndpoint('REACT_APP_ENDPOINT_AWS')

const Coupons = ({ userID }) => {
  const [validCoupons, setValid] = useState([])
  const [expiredCoupons, setExpired] = useState([])

  useEffect(
    () => {
      const coupons = async () => {
        const couponsURL = `${endpoint}v1/coupons/?foreign_key=${userID}`
        const response = await fetch(couponsURL, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
        const data = await response.json()
        if (data.results) {
          var validData = data.results.filter(item => !item.expired)
          var expiredData = data.results.filter(item => item.expired)
          setValid(validData)
          setExpired(expiredData)
        }
      }
      coupons()
    },
    [validCoupons]
  )

  return (
    <Container>
      <Wrapper>
        <SubWrap style={{ padding: 0 }}>
          {_.isEmpty(validCoupons) &&
            _.isEmpty(expiredCoupons) && (
            <SubWrap>
              <Text fSize={'22px'}>
                  Este usuario no ha redimido cupones...
              </Text>
            </SubWrap>
          )}
          {!_.isEmpty(validCoupons) &&
            validCoupons.length > 0 && (
            <SubWrap>
              <Text fSize={'30px'}>Cupones disponibles</Text>
              {validCoupons.map(item => {
                return (
                  <ExpansionPanel key={`valid_${item.coupon}`}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel1a-content'
                      id='panel1a-header'
                    >
                      <Text bold style={{ margin: 0 }}>
                        {`Cupón: ${item.coupon}`}
                      </Text>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0 }}>
                      <CouponWrap>
                        <CouponCard
                          coupon={item.coupon}
                          description={item.description}
                          date={item.expired_at}
                        />
                      </CouponWrap>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                )
              })}
            </SubWrap>
          )}

          {!_.isEmpty(expiredCoupons) &&
            expiredCoupons.length > 0 && (
            <SubWrap>
              <Text fSize={'30px'}>Cupones Expirados</Text>
              {expiredCoupons.map(item => {
                return (
                  <ExpansionPanel key={`valid_${item.coupon}`}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel1a-content'
                      id='panel1a-header'
                    >
                      <Text bold style={{ margin: 0 }}>
                        {`Cupón: ${item.coupon}`}
                      </Text>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0 }}>
                      <CouponWrap>
                        <CouponCard
                          coupon={item.coupon}
                          description={item.description}
                          date={item.expired_at}
                        />
                      </CouponWrap>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                )
              })}
            </SubWrap>
          )}
        </SubWrap>
      </Wrapper>
    </Container>
  )
}

export default Coupons
