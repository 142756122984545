import fb from '../../firebase'
import Swal from 'sweetalert2'
import axios from 'axios'
import store from '../../store'
import getEndpoint from '../../helpers/getEndpoint'

const base_url = `${getEndpoint('REACT_APP_ENDPOINT_AWS')}`

const settingsDB = fb.database().ref('settings')
const citiesDB = fb.database().ref('cities')

export const EditSettingsAction = (type, value, cityId = false, cb, loader) => {
  return dispatch => {
    var update = {}
    var updateNew = {}
    var node
    var nodeMessage

    switch (type) {
      case 'whatsapp':
        node = 'settings/phoneSupport'
        nodeMessage = 'Whatsapp'
        update[`/-LQukR2f4E_Ro3uPdGIx/whatsapp`] = value
        break
      case 'riderActives':
        node = 'riders/actives'
        nodeMessage = 'Datos de Riders activos'
        break
      default:
        break
    }
    if (cityId) {
      updateNew[`/${cityId}/${node}`] = value
    } else {
      updateNew[`/-M7J2ddkl8uNazS6MUkl/${node}`] = value
    }
    // We must remove

    settingsDB.update(update).catch(err => {
      Swal.fire({
        position: 'center',
        type: 'error',
        title: `Error al actualizar los datos, por favor intentalo mas tarde.`,
        showConfirmButton: false,
        timer: 2500
      })
      loader()
    })
    citiesDB
      .update(updateNew)
      .then(() => {
        Swal.fire({
          position: 'center',
          type: 'success',
          title: `Se ha actualizado ${nodeMessage} a ${value}`,
          showConfirmButton: false,
          timer: 2500
        })
        cb()
        loader()
      })
      .catch(err => {
        Swal.fire({
          position: 'center',
          type: 'error',
          title: `Error al actualizar los datos, por favor intentalo mas tarde.`,
          showConfirmButton: false,
          timer: 2500
        })
        loader()
      })
  }
}

// >>>>>>>>>>>
// ORDERS EVENTS
// >>>>>>>>>>>

const ordersDb = fb.database().ref('testing_orders/orders')
const dbScheduled = fb.database().ref('scheduledOrders')

export const fetchRiders = searchTerm => dispatch => {
  const { profile: { city, selectCity } } = store.getState()
  const finalCity = selectCity || city
  const url = `${base_url}v1/riders?search=${searchTerm}&city=${finalCity}&inService=true`
  axios.get(url).then(res => {
    dispatch({ type: 'FETCH_AVAILABLE_RIDERS', payload: res.data.results })
  })
}

export const bindOrders = (city, ordersState, callBack) => {
  var orders
  var alertOrders = []
  ordersDb.off()
  dbScheduled.off()
  ordersDb
    .orderByChild('restaurant/city')
    .equalTo(city)
    .once('value')
    .then(snp => {
      orders = snp.val()
      if (orders) {
        alertOrders = Object.keys(orders).filter(
          key => orders[key].status === 'pending_to_delivery' && key
        )
        callBack(orders, alertOrders)
      } else {
        callBack(orders, alertOrders) // DO WE NEED THIS?
      }
    })

  ordersDb
    .orderByChild('restaurant/city')
    .equalTo(city)
    .on('child_changed', snap => {
      const key = snap.key
      const orders = { ...ordersState }
      orders[key] = snap.val()
      alertOrders = Object.keys(orders).filter(
        key => orders[key].status === 'pending_to_delivery' && key
      )
      callBack(orders, alertOrders)
    })
  ordersDb
    .orderByChild('restaurant/city')
    .equalTo(city)
    .on('child_removed', snap => {
      callBack(null, {}, snap.key)
    })
  ordersDb
    .orderByChild('restaurant/city')
    .equalTo(city)
    .on('child_added', snap => {
      const key = snap.key
      const orders = { ...(ordersState || {}) }
      orders[key] = snap.val()
      alertOrders = Object.keys(orders).filter(
        key => orders[key].status === 'pending_to_delivery' && key
      )
      callBack(orders, alertOrders)
    })

  // ScheduleOrders
  dbScheduled
    .orderByChild('restaurant/city')
    .equalTo(city)
    .on('child_added', snap => {
      const key = snap.key
      const orders = { ...(ordersState || []) }
      orders[key] = snap.val()
      orders[key].isScheduled = true
      alertOrders = Object.keys(orders).filter(
        key => orders[key].status === 'pending_to_delivery' && key
      )
      callBack(orders, alertOrders)
    })
  dbScheduled
    .orderByChild('restaurant/city')
    .equalTo(city)
    .on('child_removed', snap => {
      callBack(null, {}, snap.key)
    })
}

export const editClientDataOrder = data => {
  return dispatch => {
    const { email, name, lastName, motherLastName, phone, orderId } = data

    let updates = {}
    updates[`/${orderId}/client/email`] = email
    updates[`/${orderId}/client/name`] = name
    updates[`/${orderId}/client/lastName`] = lastName
    updates[`/${orderId}/client/motherLastName`] = motherLastName
    updates[`/${orderId}/client/phone`] = phone

    ordersDb
      .update(updates)
      .then(() =>
        Swal.fire({
          position: 'center',
          type: 'success',
          title: `Datos actualizados correctamente`,
          showConfirmButton: false,
          timer: 1500
        })
      )
      .catch(error => {
        Swal.fire({
          position: 'center',
          type: 'error',
          title: 'Ha ocurrido un error',
          text: `Error: ${error}`,
          showConfirmButton: false,
          timer: 1500
        })
      })
  }
}
