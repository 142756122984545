import React from 'react'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import { Row, Col, Page } from '../../components/ui/Layout'
import { Panel, Button } from '../../components/ui/'
import _ from 'lodash'
import moment from 'moment'
import ReportView from '../../components/reportTable'
import ExportData from '../../components/exportData'
import Swal from 'sweetalert2'
import DatePickerHeader from '../../components/datePickerHeader'
import { getReports } from '../../helpers/reports'

moment.locale('es')

class PaymentRestaurant extends React.Component {
  constructor (props) {
    super(props)
    const startDay = moment()
      .startOf('week')
      .startOf('day')
      .add(1, 'day')
      .subtract(1, 'week')
    const endDay = moment(startDay)
      .add(6, 'day')
      .endOf('day')
    const yearRange = endDay.year()
    this.state = {
      data: {},
      startDay,
      endDay,
      yearRange
    }
  }

  render () {
    const { data, startDay, endDay, yearRange } = this.state
    const arrayData = (!_.isEmpty(data) && Object.values(data)) || []
    return (
      <Page>
        <Row>
          <Col size={12}>
            <Panel title={'Reportes Restaurantes'}>
              <Row style={{ marginBottom: 20 }}>
                <Col size={6} offset={4}>
                  <h3>{`${startDay.format('DD MMMM')} al ${endDay.format(
                    'DD MMMM'
                  )} del ${yearRange}`}</h3>
                </Col>
              </Row>
              <Row style={{ height: 200 }}>
                <Col size={9} offset={3}>
                  <Row style={{ flex: 1 }}>
                    <Col size={3}>
                      <DatePicker
                        selected={startDay._d}
                        onChange={e =>
                          this._handleChange(moment(e), 'startDay')
                        }
                        renderCustomHeader={props => {
                          return <DatePickerHeader {...props} />
                        }}
                      />
                    </Col>
                    <Col size={3}>
                      <DatePicker
                        selected={endDay._d}
                        onChange={e => this._handleChange(moment(e), 'endDay')}
                        renderCustomHeader={props => {
                          return <DatePickerHeader {...props} />
                        }}
                      />
                    </Col>
                    <Col size={2}>
                      <Button
                        label='Generar reportes'
                        size='btn-sm'
                        color='btn-default'
                        classes={'m-r-sm m-b-sm'}
                        onClick={() => {
                          this._getReports()
                        }}
                      />
                    </Col>
                    {!_.isEmpty(arrayData) && (
                      <Col size={2}>
                        <ExportData data={arrayData} />
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
              <Row />

              {!_.isEmpty(arrayData) &&
                arrayData.map((restaurant, index) => {
                  return (
                    <Row key={`${index}-${restaurant.uid}`}>
                      <Col size={12}>
                        <Panel>
                          <ReportView data={restaurant} />
                        </Panel>
                      </Col>
                    </Row>
                  )
                })}
            </Panel>
          </Col>
        </Row>
      </Page>
    )
  }

  _handleChange = (date, type) => {
    const period = {}
    period[type] = date
    const { yearRange } = this.state
    var newYear = yearRange
    if (type === 'endDay') {
      newYear = moment(date).year()
    }
    this.setState({
      ...period,
      yearRange: newYear
    })
  }
  _getReports = async () => {
    const { startDay, endDay } = this.state
    const { profile: { city, selectCity } } = this.props
    let currentCity = selectCity || city
    Swal.fire({
      position: 'center',
      title: 'Estamos cargando tu petición.',
      showConfirmButton: false,
      allowOutsideClick: () => !Swal.isLoading(),
      onBeforeOpen: () => {
        Swal.showLoading()
      }
    })
    const startDateUnix = moment(startDay).valueOf()
    const stopDateUnix = moment(endDay).valueOf()
    var reportPromise = await getReports(
      startDateUnix,
      stopDateUnix,
      currentCity
    )
    this.setState({
      data: reportPromise
    })
    Swal.close()
    _.isEmpty(reportPromise) &&
      Swal.fire({
        position: 'center',
        type: 'error',
        title: `Tu petición no tuvo exito, intentalo de nuevo con otro rango de fechas.`,
        showConfirmButton: true
      })
  }
}

const mapStateToProps = state => {
  return {
    profile: state.profile
  }
}

export default connect(mapStateToProps, null)(PaymentRestaurant)
