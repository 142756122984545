import Swal from 'sweetalert2'
import getEndpoint from '../helpers/getEndpoint'
import fb from '../firebase'

const couponsURL = getEndpoint('REACT_APP_ENDPOINT_AWS')
const database = fb.database()

export const couponRegister = (data, cb) => {
  const finalData = { ...data }
  if (finalData.isGenerator) {
    finalData.maxRedemption = finalData.numberOfCoupons
    finalData.numberOfCoupons = 1
  } else {
    finalData.maxRedemption = 1
  }
  return dispatch => {
    Swal.fire({
      position: 'center',
      title: 'Se están generando cupones...',
      showConfirmButton: false,
      allowOutsideClick: () => !Swal.isLoading(),
      onBeforeOpen: () => {
        Swal.showLoading()
      }
    })

    fetch(`${couponsURL}v2/coupons/`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: 'token fb27db8ffa96bfdde92622ebf38ff9b738f694b0'
      },
      body: JSON.stringify(finalData)
    })
      .then(res => {
        Swal.close()
        let response = res.json()
        if ((res.status > 200) & (res.status < 400)) {
          Swal.fire({
            position: 'center',
            type: 'success',
            title: `Datos creados con éxito.`,
            showConfirmButton: false,
            timer: 2500
          })
          cb()
          response.then(data =>
            dispatch({ type: 'SET_NEW_COUPONS', payload: data })
          )
        } else if (res.status === 400) {
          response.then(data => {
            const listErrors = Object.values(data[0]).map(error => error)
            Swal.fire({
              position: 'center',
              type: 'error',
              title: `Error: ${Object.values(listErrors)}`,
              showConfirmButton: false,
              timer: 2500
            })
          })
        }
      })
      .catch(error => {
        Swal.close()
        Swal.fire({
          position: 'center',
          type: 'error',
          title: `Error: ${error}`,
          showConfirmButton: false,
          timer: 2500
        })
      })
  }
}

export const searchCoupon = value => {
  return dispatch => {
    Swal.fire({
      position: 'center',
      title: 'Buscando Cupón...',
      showConfirmButton: false,
      allowOutsideClick: () => !Swal.isLoading(),
      onBeforeOpen: () => {
        Swal.showLoading()
      }
    })
    database
      .ref('historyOrders')
      .orderByChild('discount/coupon')
      .startAt(value)
      .endAt(value + '\uf8ff')
      .limitToLast(1000)
      .once('value')
      .then(snp => {
        if (!snp.exists()) {
          Swal.close()
          Swal.fire({
            position: 'center',
            title: 'Cupón no encontrado...',
            showConfirmButton: false
          })
          return
        }
        Swal.close()
        dispatch({ type: 'SEARCH_COUPON', payload: Object.values(snp.val()) })
      })
      .catch(err => console.log('.startAtz ee', err))
  }
}
