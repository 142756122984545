import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Panel, Button } from '../../components/ui/'
import { Row, Col } from '../../components/ui/Layout'
import Divider from '@material-ui/core/Divider'
import { createGeofenceAction } from './actions'
import Paper from '@material-ui/core/Paper'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import Swal from 'sweetalert2'
import _ from 'lodash'
import { GetCities } from '../adminSettings/actions'

let map
let bounds = new window.google.maps.LatLngBounds()
let sub_area
let coordinates = []
let color = [
  '#FF0000',
  '#4286f4',
  '#ffff00',
  '#ff00b2',
  '#bb00ff',
  '#00ffff',
  '#26ff00',
  '#00ff87'
]

class CreateGeofence extends Component {
  constructor (props) {
    super(props)
    this.state = {
      coords: {
        lat: 17.9917882,
        lng: -92.9367095
      },
      geofences: {},
      coordinates: []
    }
    this._initMap = this._initMap.bind(this)
    this._saveChanges = this._saveChanges.bind(this)
    this.isInit = false
    this.renderCoordinate = this.renderCoordinate.bind(this)
  }
  componentDidMount () {
    // this._initMap()
  }

  render () {
    return (
      <Panel style={{ height: '100%' }}>
        <h1>Crear Geocerca</h1>
        <Row>
          <Col size={6}>
            <Paper>
              <AsyncTypeahead
                align='justify'
                id='geofence_input'
                selected={this.state.selected}
                labelKey='display_name'
                onSearch={_.debounce(this._handleSearch.bind(this), 2000)}
                onChange={this._handleChange.bind(this)}
                options={this.state.options}
                placeholder='Buscar ciudad, ej: villahermosa o huatulco...'
                renderMenuItemChildren={(option, props, index) => (
                  <div>
                    <span>{option.display_name}</span>
                  </div>
                )}
              />
            </Paper>
          </Col>
        </Row>
        <Row style={{ paddingTop: 20 }}>
          <Col size={6}>
            <Divider style={{ marginBottom: 20 }} />
            <Button
              label='Crear Poligono'
              color='btn-success'
              onClick={this._saveChanges}
            />
          </Col>
        </Row>
        <div id='map' style={{ height: 500, width: '100%', marginTop: 20 }} />
      </Panel>
    )
  }
  _saveChanges () {
    const {
      cities,
      history: { replace },
      match: { params: { city } },
      dispatch
    } = this.props
    if (!_.isEmpty(sub_area)) {
      createGeofenceAction(city, cities[city].name, sub_area, () => {
        Swal.fire({
          position: 'center',
          type: 'success',
          title: `Datos creados con éxito.`,
          showConfirmButton: false,
          timer: 2500,
          onAfterClose: () => {
            dispatch(GetCities())
            replace('/home')
          }
        })
      })
    } else {
      Swal.fire({
        position: 'center',
        type: 'error',
        title: `No hay datos de geocerca para crear`,
        showConfirmButton: false,
        timer: 2500
      })
    }
  }

  UNSAFE_componentWillReceiveProps () {
    this._initMap()
  }
  _handleChange (option) {
    this._initMap()
    this.renderToMaps(option)
  }
  _handleSearch (query) {
    if (!query) {
      return
    }
    fetch(
      `https://nominatim.openstreetmap.org/search.php?q=${query}&polygon_geojson=1&format=json`
    )
      .then(resp => resp.json())
      .then(data => {
        let filterGeoJsonType = data.filter(function (data) {
          return (
            data.geojson.type === 'MultiPolygon' ||
            data.geojson.type === 'Polygon'
          )
        })
        this.setState({ options: filterGeoJsonType })
      })
  }

  renderToMaps (selectedOptions) {
    selectedOptions &&
    selectedOptions[0] &&
    selectedOptions[0].geojson &&
    selectedOptions[0].geojson.type === 'Polygon'
      ? this.renderCoordinate(selectedOptions[0].geojson.coordinates[0])
      : this.renderCoordinate(selectedOptions[0].geojson.coordinates[0][0])
    if (coordinates.length > 1) {
      sub_area = new window.google.maps.Polygon({
        paths: coordinates,
        strokeColor: color[1],
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: color[1],
        fillOpacity: 0.35,
        editable: true
      })

      map.setOptions({ maxZoom: 15 })
      map.fitBounds(bounds)

      this.setState({
        coordinates
      })

      var deleteNode = function (mev) {
        if (mev.vertex != null) {
          sub_area.getPath().removeAt(mev.vertex)
        }
      }
      window.google.maps.event.addListener(sub_area, 'rightclick', deleteNode)

      sub_area.setMap(map)
      coordinates = []
    }
  }
  renderCoordinate (paths) {
    coordinates = []
    let position = 0
    paths.map(location => {
      if (position % 10 === 0) {
        coordinates.push({ lat: location[1], lng: location[0] })
        bounds.extend({ lat: location[1], lng: location[0] })
      }
      position++
      return true
    })
  }

  _initMap () {
    let div = document.getElementById('map')
    map = new window.google.maps.Map(div, {
      center: { lat: 17.9917882, lng: -92.9367095 },
      zoom: 10,
      zoomControl: true,
      zoomControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_CENTER
      },
      scrollwheel: false,
      streetViewControl: false,
      mapTypeControl: false,
      mapTypeId: 'roadmap'
    })
  }
}

const mapStateToProps = ({ data }) => {
  return {
    cities: data.settings.cities
  }
}

export default connect(mapStateToProps, null)(CreateGeofence)
