import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import fb from '../../firebase'
import { Panel, Button as Btn } from '../../components/ui/'
import { Row, Col } from '../../components/ui/Layout'
import RestaurantForm from './restaurantForm'
import AddListForm from './addListForm'
import AddListItemForm from './addListItemForm'
// import PromotionForm from './PromotionForm'
import PopularForm from './PopularForm'
import DishFormik from './dishFormFormik'
import DishItem from '../../components/dishItem'
import RestaurantPreview from '../../components/restaurantPreview'
import { Switch, Button, Icon, IconButton } from '@material-ui/core'
import {
  updateRestaurantInfo,
  updateRestaurantPopular,
  addDish,
  waitTime,
  dishEdit
} from './actions'
import {
  addExtraList,
  addExtraItem,
  updateItem,
  deleteExtraItem,
  deleteListItem,
  updateExtraLabel
} from '../../actions/extras'
import styled from 'styled-components'
import {
  SortableContainer,
  SortableHandle,
  SortableElement,
  arrayMove
} from 'react-sortable-hoc'
import {
  WrapperItem,
  Wrapper,
  SubWrap,
  Covering,
  Element,
  Label,
  TimeGroup
} from './styled'
import Swal from 'sweetalert2'
import WaitTimeForm from './waitTimeForm'
import getEndpoint from '../../helpers/getEndpoint'
import _ from 'lodash'

const DJANGO_TOKEN = getEndpoint('REACT_APP_DJANGO_TOKEN')
const REACT_APP_ENDPOINT_AWS = getEndpoint('REACT_APP_ENDPOINT_AWS')

const restaurantsDB = fb.database().ref('restaurants')
const extrasListDB = fb.database().ref('extrasList')
const extrasItemsDB = fb.database().ref('extraItems')
const dishesDB = fb.database().ref('dishes')

const scheduleLocal = [
  {
    day: 'monday',
    open: '08:00',
    close: '08:00',
    day_off: false
  },
  {
    day: 'tuesday',
    open: '08:00',
    close: '08:00',
    day_off: false
  },
  {
    day: 'wednesday',
    open: '08:00',
    close: '08:00',
    day_off: false
  },
  {
    day: 'thursday',
    open: '08:00',
    close: '08:00',
    day_off: false
  },
  {
    day: 'friday',
    open: '08:00',
    close: '08:00',
    day_off: false
  },
  {
    day: 'saturday',
    open: '08:00',
    close: '08:00',
    day_off: false
  },
  {
    day: 'sunday',
    open: '08:00',
    close: '08:00',
    day_off: false
  }
]

const DragIcon = SortableHandle(() => <Icon fontSize='large'>fastfood</Icon>)

const DragHandle = styled(DragIcon)`
  &:hover {
    cursor: grab;
  }
  &:active {
    cursor: grabbing;
  }
`

const SortableItem = SortableElement(
  ({ value, keyFB, keyId, selectDish, toggleActiveDish, currentDish }) => {
    return (
      <WrapperItem>
        <DragHandle />
        <DishItem
          {...value}
          value={value}
          action={selectDish}
          selected={currentDish}
          keyId={keyId}
        />
        <Switch
          checked={value.active}
          onChange={() => {
            toggleActiveDish(keyFB, !value.active)
          }}
          name='checkedA'
          color='primary'
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </WrapperItem>
    )
  }
)

const SortableList = SortableContainer(
  ({ dishes, selectDish, toggleActiveDish, currentDish }) => {
    return (
      <ol style={{ margin: 0, padding: 0 }}>
        {Object.values(dishes).map((value, index) => {
          return (
            <SortableItem
              index={index}
              key={`item-${index}`}
              keyId={value.dishId}
              keyFB={value.dishId}
              value={{ ...value }}
              selectDish={selectDish}
              toggleActiveDish={toggleActiveDish}
              currentDish={currentDish}
            />
          )
        })}
      </ol>
    )
  }
)

const Home = props => {
  const dispatch = useDispatch()
  const [currentDish, setCurrentDish] = useState(null)
  const [currentListKey, setCurrentListKey] = useState(null)
  const [addList, setAddList] = useState(false)
  const [addListItem, setAddListItem] = useState(false)
  const [addListItemToEdit, setAddListItemToEdit] = useState(false)
  const [addListItemToEditData, setAddListItemToEditData] = useState({})
  const [extraCollapse, setExtraCollapse] = useState(true)
  const [disabledBtn, setDisabledBtn] = useState(true)
  const [dataCurrentRestaurant, setDataCurrentRestaurant] = useState({})
  const [dishesCurrentRestaurant, setDishesCurrentRestaurant] = useState({})
  const [dataExtrasList, setDataExtrasList] = useState({})
  const [dataExtraItems, setDataExtraItems] = useState({})
  const [isLoadingRestaurant, setIsLoadingRestaurant] = useState(false)
  const [isLoadingDishes, setIsLoadingDishes] = useState(false)
  const [isLoadingExtras, setIsLoadingExtras] = useState(false)
  const [dishesSectionEnable, setDishesSectionEnable] = useState(false)
  const [addListItemIndex, setAddListItemIndex] = useState(false)
  const [isNewRegister, setIsNewRegister] = useState(false)
  const [extrasListItemArray, setExtrasListItemArray] = useState([])
  const profile = useSelector(state => state.profile)
  const updateRestaurant = payload => dispatch(updateRestaurantInfo(payload))
  const updatePopular = payload => dispatch(updateRestaurantPopular(payload))
  const { match: { params: { id: restId }, path } } = props
  useEffect(
    () => {
      !_.isEmpty(dataExtrasList) &&
        setExtrasListItemArray(Object.keys(dataExtrasList))
    },
    [dataExtrasList]
  )

  const getRestaurantData = () => {
    const URL = `${REACT_APP_ENDPOINT_AWS}v2/restaurants/${restId}/`
    fetch(URL, {
      method: 'GET',
      headers: {
        Authorization: `token ${DJANGO_TOKEN}`,
        'content-type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(async result => {
        if (result.success === false) {
          if (result.message === 'Restaurant not found') {
            setIsNewRegister(true)
          }
          return false
        }
        result['_id'] = result.foreign_key
        if (!_.hasIn(result, 'schedule') || isEmpty(result.schedule)) {
          result['schedule'] = scheduleLocal
        }
        setDataCurrentRestaurant(result)
      })
      .catch(err => {
        Swal.fire({
          title: err && err.message ? err.message : 'Error al obtener datos',
          type: 'error',
          timer: 2000,
          showConfirmButton: false
        })
      })
  }

  useEffect(
    async () => {
      setIsLoadingRestaurant(true)
      const response = await getRestaurantData()
      setIsLoadingRestaurant(false)
    },
    [restId]
  )

  useEffect(() => {
    return () => {
      Object.keys(dishesCurrentRestaurant).forEach(dish => {
        dishesDB.child(dish).off()
      })
      Object.keys(dataExtrasList).forEach(extra => {
        extrasListDB.child(extra).off()
      })
      !_.isEmpty(dataCurrentRestaurant && dataCurrentRestaurant._id) &&
        restaurantsDB.child(dataCurrentRestaurant._id).off()
    }
  }, [])

  const _saveTime = props => {
    dispatch(waitTime(props))
  }

  const _showAddListForm = () => {
    setAddList(true)
    setExtraCollapse(false)
  }

  const _showAddListItemForm = index => {
    setAddListItem(true)
    setAddListItemIndex(index)
  }

  const _hideAddListItemForm = () => {
    setAddListItem(false)
  }

  const _hideAddListForm = () => {
    setAddList(false)
  }

  const _onSaveItem = (listId, listItem) => {
    dispatch(
      addExtraItem(
        {
          items: listItem,
          listId
        },
        _hideAddListItemForm
      )
    )
  }

  const _onSave = payload => {
    const { match: { params: { id: restId } } } = props
    dispatch(
      addExtraList(
        {
          restaurantId: restId,
          ...payload
        },
        _hideAddListForm
      )
    )
  }

  const _toggleActiveDish = async (dishId, active) => {
    setIsLoadingDishes(true)
    const dish = {
      is_active: active,
      dishId
    }
    const res = await dishEdit(dish)
    if (res.success) {
      const oldDishesCurrentRestaurant = dishesCurrentRestaurant
      oldDishesCurrentRestaurant[dishId] = {
        ...res.dish,
        id: dishId,
        dishId: dishId
      }
      setDishesCurrentRestaurant(oldDishesCurrentRestaurant)
      setIsLoadingDishes(false)
    }
  }

  //= ====================
  // Extra methods
  const selectDish = newCurrentDish => {
    if (currentDish && currentDish.id === newCurrentDish.id) {
      dishesDB.child(currentDish.id).off()
      return setCurrentDish(null)
    }

    let extrasArray = {}
    dishesDB
      .child(newCurrentDish.id)
      .child('extras')
      .on('child_added', snapshot => {
        const extraData = snapshot.val()
        const extraKey = snapshot.key
        let oldCurrentDishState = {}
        const oldDishesCurrentRestaurant = dishesCurrentRestaurant
        extrasArray = {
          ...extrasArray,
          [extraKey]: extraData
        }
        oldCurrentDishState = {
          ...newCurrentDish,
          extras: { ...extrasArray }
        }
        oldDishesCurrentRestaurant[newCurrentDish.id] = {
          ...oldDishesCurrentRestaurant[newCurrentDish.id],
          extras: { [extraKey]: extraData }
        }
        setDishesCurrentRestaurant(oldDishesCurrentRestaurant)
        setCurrentDish(oldCurrentDishState)
      })

    dishesDB.child(newCurrentDish.id).on('child_changed', snapshot => {
      const dishData = snapshot.val()
      const dishKey = snapshot.key
      let oldCurrentDishState = {}
      const oldDishesCurrentRestaurant = dishesCurrentRestaurant
      oldCurrentDishState = {
        ...newCurrentDish,
        [dishKey]: dishData
      }
      oldDishesCurrentRestaurant[newCurrentDish.id] = {
        ...oldDishesCurrentRestaurant[newCurrentDish.id],
        [dishKey]: dishData
      }
      setDishesCurrentRestaurant(oldDishesCurrentRestaurant)
      setCurrentDish(oldCurrentDishState)
    })

    dishesDB.child(newCurrentDish.id).on('child_removed', snapshot => {
      setIsLoadingDishes(true)
      const oldDishesCurrentRestaurant = dishesCurrentRestaurant
      delete oldDishesCurrentRestaurant[newCurrentDish.id]
      setIsLoadingDishes(false)
      setDishesCurrentRestaurant(oldDishesCurrentRestaurant)
    })
    // Listen Dishes
    if (!newCurrentDish.extras) {
      return setCurrentDish(newCurrentDish)
    }
  }

  const setNewIndex = () => {
    activeLoading('Procesando cambios...')
    const URL = `${REACT_APP_ENDPOINT_AWS}v2/restaurants/order_dishes/${restId}/`
    const body = {
      order_dishes: Object.keys(dishesCurrentRestaurant)
    }
    fetch(URL, {
      method: 'PUT',
      headers: {
        Authorization: `token ${DJANGO_TOKEN}`,
        'content-type': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then(res => res.json())
      .then(resJSON => {
        if (resJSON.status < 200 || resJSON.status >= 400) {
          Swal.fire({
            title: 'Error al actualizar los datos',
            type: 'error',
            timer: 2000,
            showConfirmButton: false
          })
        }
        return Swal.fire({
          title: 'Datos actualizados correctamente',
          type: 'success',
          timer: 2000,
          showConfirmButton: false
        })
      })
      .catch(err => {
        Swal.fire({
          title: 'Error al actualizar los datos',
          type: 'error',
          timer: 2000,
          showConfirmButton: false
        })
      })
  }

  const activeLoading = (msg = false) => {
    Swal.fire({
      position: 'center',
      title: msg || 'Tu petición se esta cargando',
      onBeforeOpen: () => {
        Swal.showLoading()
      },
      showConfirmButton: false
    })
  }

  const _clearDishes = () => {
    setCurrentDish(null)
  }

  const _addDish = async (value, actions) => {
    const response = await dispatch(
      addDish(value, dataCurrentRestaurant._id, _clearDishes)
    )
    if (response) {
      restaurantsDB
        .child(dataCurrentRestaurant._id)
        .child('dishes')
        .once('value', snapshot => {
          const data = snapshot.val()
          const lastDish = data[data.length - 1]
          const dishesObject = {}
          dishesDB
            .child(lastDish)
            .once('value')
            .then(snapshot => {
              if (snapshot.exists()) {
                const dishData = snapshot.val()
                dishData['id'] = snapshot.key
                dishesObject[snapshot.key] = dishData
              }
              setDishesCurrentRestaurant({
                ...dishesCurrentRestaurant,
                ...dishesObject
              })
            })
        })
      actions.resetForm()
      return _clearDishes()
    }
  }

  const _onEditDish = async dish => {
    const res = await dishEdit(dish, _clearDishes)
  }

  const _selectList = key => setCurrentListKey(key)

  // checar el indexado desde aqui
  const onSortEnd = ({ oldIndex, newIndex }, currentRestaurant) => {
    const dishesArray = Object.values(dishesCurrentRestaurant)
    const newArrayMove = arrayMove(dishesArray, oldIndex, newIndex)
    const newObjectDishes = {}
    newArrayMove.forEach(item => {
      newObjectDishes[item.id] = item
    })
    try {
      setDishesCurrentRestaurant(newObjectDishes)
      setDisabledBtn(false)
    } catch (error) {
      return false
    }
  }

  const _loadDishes = () => {
    setIsLoadingDishes(true)
    setDishesSectionEnable(true)
    let itemProcessed = 0
    const dishesObject = {}
    if (!_.isEmpty(dataCurrentRestaurant.dishes_fk)) {
      dataCurrentRestaurant.dishes_fk.forEach(item => {
        dishesDB
          .child(item)
          .once('value')
          .then(snapshot => {
            if (snapshot.exists()) {
              const dishData = snapshot.val()
              dishData['id'] = snapshot.key
              dishesObject[snapshot.key] = dishData
            }
            itemProcessed++
            if (dataCurrentRestaurant.dishes_fk.length === itemProcessed) {
              setDishesCurrentRestaurant({
                ...dishesCurrentRestaurant,
                ...dishesObject
              })
              setIsLoadingDishes(false)
            }
          })
          .catch(() => setIsLoadingDishes(false))
      })
    } else {
      setIsLoadingDishes(false)
    }

    extrasListDB
      .orderByChild('restaurantId')
      .equalTo(restId)
      .on('child_added', snapshot => {
        const payload = snapshot.val()
        const itemObj = {}
        const itemEditObj = {}
        // setIsLoadingExtras(true)
        if (payload === null) {
          return setIsLoadingExtras(false)
        }

        let itemProcessedAdded = 0
        const payloadExtrasList = {}
        const arrayAdded = []

        extrasListDB
          .child(snapshot.key)
          .child('items')
          .on('child_added', snp => {
            arrayAdded.push(snp.key)
            extrasItemsDB.child(snp.key).on('child_changed', element => {
              setDataExtraItems(prevState => {
                itemEditObj[snp.key] = {
                  ...prevState[snp.key],
                  [element.key]: element.val()
                }
                return { ...prevState, ...itemEditObj }
              })
            })
            extrasItemsDB.child(snp.key).on('child_removed', element => {
              setDataExtraItems(prevState => {
                var newState = prevState
                delete newState[snp.key]
                return { ...prevState, ...newState }
              })
            })

            extrasItemsDB.child(snp.key).on('child_added', element => {
              setDataExtraItems(prevState => {
                itemEditObj[snp.key] = {
                  ...prevState[snp.key],
                  [element.key]: element.val()
                }
                return { ...prevState, ...itemEditObj }
              })
            })

            extrasItemsDB
              .child(snp.key)
              .once('value')
              .then(element => {
                const item = element.val()
                itemObj[element.key] = item
                itemProcessedAdded++
                if (arrayAdded.length === itemProcessedAdded) {
                  setDataExtraItems(prevState => ({ ...prevState, ...itemObj }))
                }
              })
          })

        payloadExtrasList[snapshot.key] = {
          ...payload
        }
        setDataExtrasList(prevState => ({ ...prevState, ...payloadExtrasList }))
      })

    // Get child changed by Extras List
    extrasListDB
      .orderByChild('restaurantId')
      .equalTo(restId)
      .on('child_changed', snapshot => {
        const payload = snapshot.val()
        const itemObj = {}
        const payloadExtrasList = {}
        const arrayAdded = []
        // setIsLoadingExtras(true)
        let itemProcessed = 0
        let itemsLength = 0
        if (payload === null) {
          return setIsLoadingExtras(false)
        }

        if (_.isEmpty(payload['items'])) {
          return setIsLoadingExtras(false)
        }

        itemsLength = itemsLength + Object.keys(payload['items']).length

        payloadExtrasList[snapshot.key] = {
          ...payload
        }
        setDataExtrasList(prevState => ({ ...prevState, ...payloadExtrasList }))
      })
    extrasListDB
      .orderByChild('restaurantId')
      .equalTo(restId)
      .on('child_removed', snapshot => {
        const payload = snapshot.val()
        setDataExtrasList(prevState => {
          var newState = prevState
          delete newState[snapshot.key]
          setExtrasListItemArray(Object.keys(newState))
          return newState
        })
      })
    extrasListDB
      .orderByChild('restaurantId')
      .equalTo(restId)
      .once('value', snapshot => {
        const payload = snapshot.val()
        setDataExtrasList(payload)
      })
  }

  useEffect(
    () => {
      Object.values(dishesCurrentRestaurant).forEach(item => {
        if (currentDish && currentDish.id === item.id) {
          setCurrentDish(item)
        }
      })
    },
    [dishesCurrentRestaurant]
  )

  useEffect(
    () => {
      if (
        !_.hasIn(dataCurrentRestaurant, 'name') &&
        !_.hasIn(dataCurrentRestaurant, 'description')
      ) {
        setIsNewRegister(true)
      } else {
        setIsNewRegister(false)
      }
    },
    [dataCurrentRestaurant]
  )

  useEffect(
    () => {
      if (isNewRegister) {
        dataCurrentRestaurant['schedule'] = scheduleLocal
        setDataCurrentRestaurant(dataCurrentRestaurant)
      }
    },
    [isNewRegister]
  )

  useEffect(
    () => {
      !isLoadingRestaurant
        ? Swal.close()
        : activeLoading('Cargando comercio...')
    },
    [isLoadingRestaurant]
  )

  useEffect(
    () => {
      !isLoadingExtras ? Swal.close() : activeLoading('Cargando extras...')
    },
    [isLoadingExtras]
  )

  useEffect(
    () => {
      !isLoadingDishes ? Swal.close() : activeLoading('Cargando platillo...')
    },
    [isLoadingDishes]
  )

  return (
    <Wrapper>
      <SubWrap border='2px solid gray'>
        <Element flex='2'>
          <RestaurantForm
            restId={restId}
            initialValues={dataCurrentRestaurant}
            handleSubmit={e => {
              if (isNewRegister) e['partner'] = restId
              if (!_.hasIn(e, '_id')) {
                e['_id'] = restId
              }
              updateRestaurant({
                data: e,
                isNewRegister,
                city: profile.selectCity || profile.city,
                cb: getRestaurantData
              })
            }}
            typeUser={profile && profile.type}
            isLoading={isLoadingRestaurant}
          />
        </Element>
        <Element>
          <Covering>
            <div style={{ backgroundColor: '#717171' }}>
              <Label>Vista Previa</Label>
            </div>
            <RestaurantPreview data={dataCurrentRestaurant} />
          </Covering>
          <Covering>
            <div style={{ backgroundColor: '#ea5a5a' }}>
              <Label>Populares</Label>
            </div>
            <PopularForm
              handleSubmit={e => {
                updatePopular({
                  data: e,
                  uid: restId,
                  cb: setIsLoadingRestaurant
                })
              }}
              initialValues={dataCurrentRestaurant}
            />
          </Covering>
          <Covering>
            <div style={{ backgroundColor: '#ea5a5a' }}>
              <Label>Tiempo de espera</Label>
            </div>
            <WaitTimeForm
              saveTime={_saveTime}
              time={dataCurrentRestaurant.waitTime}
              restId={dataCurrentRestaurant._id}
            />
          </Covering>
        </Element>
      </SubWrap>
      {!dishesSectionEnable ? (
        <Wrapper>
          <Element flex='1'>
            <Covering>
              <Button
                variant='contained'
                color='primary'
                endIcon={<Icon>download</Icon>}
                onClick={_loadDishes}
                width={1 / 5}
                disabled={isNewRegister}
              >
                Cargar Platillos
              </Button>
            </Covering>
          </Element>
        </Wrapper>
      ) : (
        <>
          <SubWrap border='2px solid gray'>
            <Element>
              <Covering>
                <div style={{ backgroundColor: '#717171' }}>
                  <Label>Extras</Label>
                </div>
                <div
                  style={{
                    paddingTop: 20,
                    width: '100%',
                    justifyContent: 'space-around',
                    display: 'inline-flex'
                  }}
                >
                  <Button
                    variant='contained'
                    color='primary'
                    endIcon={<Icon>add</Icon>}
                    onClick={_showAddListForm}
                    width={1 / 5}
                  >
                    Agregar Extra
                  </Button>
                  <Button
                    variant='contained'
                    color='primary'
                    endIcon={
                      <Icon>
                        {extraCollapse ? 'expand_more' : 'expand_less'}
                      </Icon>
                    }
                    onClick={e => {
                      e.preventDefault()
                      setExtraCollapse(!extraCollapse)
                    }}
                  >
                    {extraCollapse ? 'Ver Extras' : 'Ocultar Extras'}
                  </Button>
                </div>
              </Covering>
              <div>
                <Panel
                  style={{
                    transition: 'height .5s ease',
                    boxShadow: 'none'
                  }}
                  toggle
                  height={extraCollapse ? '0px' : 'auto'}
                >
                  {addList && (
                    <Covering>
                      <AddListForm
                        onSave={_onSave}
                        onCancel={_hideAddListForm}
                      />
                    </Covering>
                  )}
                  <Covering padding='30px 0 0 0'>
                    <ul
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        listStyleType: 'none'
                      }}
                    >
                      {// TODO make this hiddable
                        extrasListItemArray.map((list, i) => {
                          return (
                            <li key={i}>
                              <Row
                                style={{
                                  fontWeight: 600,
                                  fontSize: 18,
                                  borderBottom: '1px solid lightgray',
                                  diplay: 'flex',
                                  justifyContent: 'center',
                                  backgroundColor: 'lightgray'
                                }}
                              >
                                <TimeGroup justify='space-between' padding='0 5%'>
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    {addListItemToEdit === list ? (
                                      <input
                                        onChange={e =>
                                          setAddListItemToEditData({
                                            name: e.target.value
                                          })
                                        }
                                        value={addListItemToEditData.name}
                                      />
                                    ) : (
                                      <p style={{ paddingTop: 10 }}>
                                        {dataExtrasList &&
                                        dataExtrasList[list] &&
                                        dataExtrasList[list].name}
                                      </p>
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <IconButton
                                      onClick={() => _showAddListItemForm(list)}
                                    >
                                      <Icon>add</Icon>
                                    </IconButton>
                                    {addListItemToEdit === list ? (
                                      <IconButton
                                        onClick={() => {
                                          const newData = {
                                            ...addListItemToEditData
                                          }
                                          dispatch(
                                            updateExtraLabel(list, newData)
                                          )
                                          setAddListItemToEdit(false)
                                        }}
                                      >
                                        <Icon>check_circle</Icon>
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        onClick={() => {
                                          setAddListItemToEdit(list)
                                          setAddListItemToEditData({
                                            name: dataExtrasList[list].name
                                          })
                                        }}
                                      >
                                        <Icon>edit</Icon>
                                      </IconButton>
                                    )}

                                    <IconButton
                                      onClick={() =>
                                        dispatch(
                                          deleteListItem(
                                            list,
                                            dataExtrasList[list].items
                                          )
                                        )
                                      }
                                    >
                                      <Icon style={{ color: 'red' }}>delete</Icon>
                                    </IconButton>
                                  </div>
                                </TimeGroup>
                              </Row>

                              {addListItem &&
                              addListItemIndex === list && (
                                <AddListItemForm
                                  listId={list}
                                  onSave={_onSaveItem}
                                  onCancel={_hideAddListItemForm}
                                />
                              )}

                              <Row style={{ backgroundColor: '#F6F6F6' }}>
                                <Col size={4}>
                                  <Label fSize='1em' style={{ color: 'black' }}>
                                  Nombre
                                  </Label>
                                </Col>
                                <Col size={2}>
                                  <Label fSize='1em' style={{ color: 'black' }}>
                                  Precio ($)
                                  </Label>
                                </Col>
                                <Col size={4}>
                                  <Label fSize='1em' style={{ color: 'black' }}>
                                  Descripción
                                  </Label>
                                </Col>
                                <Col size={2}>
                                  <Label fSize='1em' style={{ color: 'black' }}>
                                  Acciones
                                  </Label>
                                </Col>
                              </Row>
                              {dataExtrasList[list].items &&
                              Object.keys(dataExtrasList[list].items).map(
                                (item, i) => {
                                  if (dataExtraItems[item]) {
                                    return (
                                      <Row
                                        key={i}
                                        style={{
                                          backgroundColor:
                                            i % 2 === 0 ? '#F6F6F6' : '#EEEEEE'
                                        }}
                                      >
                                        <Col size={4}>
                                          {addListItemToEdit === item ? (
                                            <input
                                              onChange={e =>
                                                setAddListItemToEditData({
                                                  name: e.target.value
                                                })
                                              }
                                              value={addListItemToEditData.name}
                                            />
                                          ) : (
                                            dataExtraItems[item].name
                                          )}
                                        </Col>
                                        <Col size={2}>
                                          {addListItemToEdit === item ? (
                                            <input
                                              onChange={e =>
                                                setAddListItemToEditData({
                                                  price: e.target.value
                                                })
                                              }
                                              value={
                                                addListItemToEditData.price
                                              }
                                            />
                                          ) : (
                                            <div
                                              style={{
                                                display: 'flex',
                                                justifyContent: 'center'
                                              }}
                                            >
                                              {dataExtraItems[item].price}
                                            </div>
                                          )}
                                        </Col>
                                        <Col size={4}>
                                          {addListItemToEdit === item ? (
                                            <input
                                              onChange={e =>
                                                setAddListItemToEditData({
                                                  description: e.target.value
                                                })
                                              }
                                              value={
                                                addListItemToEditData.description
                                              }
                                            />
                                          ) : (
                                            dataExtraItems[item].description
                                          )}
                                        </Col>
                                        <Col size={2}>
                                          <TimeGroup justify='center'>
                                            {addListItemToEdit === item ? (
                                              <IconButton
                                                size='small'
                                                onClick={() => {
                                                  const newData = {
                                                    ...addListItemToEditData
                                                  }
                                                  dispatch(
                                                    updateItem(item, newData)
                                                  )
                                                  setAddListItemToEdit(false)
                                                }}
                                              >
                                                <Icon>check_circle</Icon>
                                              </IconButton>
                                            ) : (
                                              <IconButton
                                                size='small'
                                                onClick={() => {
                                                  setAddListItemToEdit(item)
                                                  setAddListItemToEditData({
                                                    name:
                                                      dataExtraItems[item].name,
                                                    price:
                                                      dataExtraItems[item]
                                                        .price,
                                                    description:
                                                      dataExtraItems[item]
                                                        .description
                                                  })
                                                }}
                                              >
                                                <Icon>edit</Icon>
                                              </IconButton>
                                            )}
                                            <IconButton
                                              size='small'
                                              onClick={() =>
                                                dispatch(
                                                  deleteExtraItem(item, list)
                                                )
                                              }
                                            >
                                              <Icon>delete</Icon>
                                            </IconButton>
                                          </TimeGroup>
                                        </Col>
                                      </Row>
                                    )
                                  }
                                  return null
                                }
                              )}
                            </li>
                          )
                        })}
                    </ul>
                  </Covering>
                </Panel>
              </div>
            </Element>
          </SubWrap>
          <SubWrap>
            <Element>
              <Covering>
                <div style={{ backgroundColor: '#717171' }}>
                  <Label>Nuevo Platillo</Label>
                </div>
              </Covering>
              <DishFormik
                dishId={currentDish && currentDish.id}
                currentDish={currentDish}
                onSave={_addDish}
                onEditDish={_onEditDish}
                selectList={_selectList}
                selectedList={currentListKey}
                clearDishes={_clearDishes}
                extrasList={dataExtrasList}
                extraItems={dataExtraItems}
              />
            </Element>
            <Element>
              <Covering>
                <div style={{ backgroundColor: '#717171' }}>
                  <Label>Vista Previa</Label>
                </div>
              </Covering>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: 10
                }}
              >
                <Btn
                  label='Guardar'
                  color='btn-info'
                  type='button'
                  disabled={disabledBtn}
                  onClick={() => setNewIndex()}
                />
              </div>
              <Panel
                scrollHeight={750}
                style={{ boxShadow: 'none', margin: 30 }}
              >
                {!_.isEmpty(dishesCurrentRestaurant) && (
                  <SortableList
                    dishes={dishesCurrentRestaurant}
                    selectDish={selectDish}
                    toggleActiveDish={_toggleActiveDish}
                    currentDish={currentDish}
                    useDragHandle={true}
                    onSortEnd={props => onSortEnd(props, dataCurrentRestaurant)}
                    lockAxis='y'
                  />
                )}
              </Panel>
            </Element>
          </SubWrap>
        </>
      )}
    </Wrapper>
  )
}

export default Home
