import React from 'react'
// import { Link } from 'react-router-dom'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'

export const Profile = ({ toggle, open, profile, avatar, onLogout }) => {
  return (
    <li className={`dropdown ${open && 'open'}`}>
      <IconButton variant='contained'>
        <a
          href='#toggle'
          onClick={toggle}
          className='dropdown-toggle'
          data-toggle='dropdown'
        >
          {profile && !profile.photoUrl ? (
            <Icon fontSize={'large'} title={'Changelog'}>
              account_circle
            </Icon>
          ) : (
            <img src={profile ? avatar : ''} alt='' />
          )}
          <b className='caret' />
        </a>
      </IconButton>
      <ul className='dropdown-menu animated fadeInRight'>
        <span className='arrow top' />
        {/* <li>
          <Link to='/home'>Perfil</Link>
        </li>
        <li>
          <Link to="/account">Account</Link>
        </li>
        <li>
          <Link to="/settings">Settings</Link>
        </li>
        <li className="divider"></li>
      */}
        <li>
          <a href='#logout' onClick={onLogout}>
            Logout
          </a>
        </li>
      </ul>
    </li>
  )
}
