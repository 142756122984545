import React from 'react'
import ReactTable from 'react-table'
import { Row, Col } from '../ui/Layout'
import { Panel } from '../ui/'
import matchSorter from 'match-sorter'
import moment from 'moment'
import styled from 'styled-components'
import Modal from '../Modal'
import _ from 'lodash'

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`

const Logo = styled.img`
  width: 50px;
`

const ReportTable = ({ data, contentModal }) => {
  const dataRestaurant = data.orders
    ? renderOrder(Object.values(data.orders).reverse())
    : []
  const columns = renderHeader(contentModal)
  return (
    <ReactTable
      data={dataRestaurant}
      columns={columns}
      defaultPageSize={10}
      filterable
      loading={data.length === 0}
      getTheadThProps={() => {
        return {
          style: {
            outline: 0,
            whiteSpace: 'pre-line',
            wordWrap: 'break-word'
          }
        }
      }}
      getTdProps={() => {
        return {
          style: {
            whiteSpace: 'pre-line',
            wordWrap: 'break-word',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }
        }
      }}
    />
  )
}

const renderOrder = orders => {
  return orders.map((order, i) => {
    let keyOrder = order.uid
    let date = moment(order.createdAt).format('DD/MM/YYYY')
    const { typePayment, subtotal, deliveryCost, total, client } = order
    const { discount } = order
    var totalToPay = discount ? discount.foodCost : subtotal
    const name = `${client.name || ''} ${client.lastName ||
      ''} ${client.motherLastName || ''}`

    var discountFood = discount ? subtotal.toFixed(2) : 0

    return {
      date,
      name,
      keyOrder,
      discount,
      deliveryCost: (deliveryCost && deliveryCost.toFixed(2)) || 0,
      subtotal: (totalToPay && totalToPay.toFixed(2)) || 0,
      discountFood,
      totalToPay: (total && total.toFixed(2)) || 0,
      typePayment
    }
  })
}

const renderHeader = contentModal => {
  return [
    {
      Header: 'Fecha',
      accessor: 'date',
      filterAll: true,
      filterMethod: (filter, rows) => {
        return matchSorter(rows, filter.value, {
          keys: ['date']
        })
      },
      width: 100,
      maxWidth: 100
    },
    {
      Header: 'No. Orden/Pedido',
      accessor: 'keyOrder',
      width: 200,
      maxWidth: 200,
      filterAll: true,
      filterMethod: (filter, rows) => {
        return matchSorter(rows, filter.value, {
          keys: ['keyOrder']
        })
      },
      Cell: props => {
        return (
          props.value && (
            <a
              href='#'
              onClick={e => {
                e.preventDefault()
                contentModal(props.value)
              }}
              style={{ cursor: 'pointer' }}
            >
              <span>{` ${props.value}`}</span>
            </a>
          )
        )
      }
    },
    {
      Header: 'Nombre',
      accessor: 'name',
      width: 200,
      maxWidth: 200,
      filterAll: true,
      filterMethod: (filter, rows) => {
        return matchSorter(rows, filter.value, {
          keys: ['name']
        })
      }
    },
    {
      Header: 'Tipo de Pago',
      accessor: 'typePayment',
      filterAll: true,
      width: 100,
      maxWidth: 100,
      filterMethod: (filter, rows) => {
        return matchSorter(rows, filter.value, {
          keys: ['typePayment']
        })
      }
    },
    {
      Header: 'Envio de la orden',
      accessor: 'deliveryCost'
    },
    {
      Header: 'Costo de producto',
      accessor: 'subtotal',
      width: 100,
      maxWidth: 100
    },
    {
      Header: 'Descuento',
      accessor: 'discount',
      Cell: props => {
        return (
          props.value && (
            <Row>
              <Col>
                <span>Cupón</span>
              </Col>
              <Col>
                <strong>{props.value.coupon}</strong>
              </Col>
              <Col>
                <span>Tipo</span>
              </Col>
              <Col>
                <strong>
                  {props.value.type === 'shipping' ? 'Envio' : 'Comida'}
                </strong>
              </Col>
              <Col>
                <span>Credito</span>
              </Col>
              <Col>
                <strong>
                  {props.value.percentage
                    ? `${props.value.credit}%`
                    : `$${props.value.credit}`}
                </strong>
              </Col>
              <Col>
                <span>Descuento</span>
              </Col>
              <Col>
                <strong>{`$${props.value.discountValue}`}</strong>
              </Col>
            </Row>
          )
        )
      }
    },
    {
      Header: 'Total cobrado',
      accessor: 'discountFood'
    },

    {
      Header: 'Total pagado',
      accessor: 'totalToPay',
      Cell: props =>
        props.value && (
          <Col>
            <strong>{props.value}</strong>
          </Col>
        )
    }
  ]
}

const DataInfo = ({ data }) => {
  return (
    <React.Fragment>
      <Row>
        <Col size={6}>
          <h3 style={{ color: 'black' }}>Detalles</h3>
        </Col>
      </Row>
      <Row>
        <Col size={2}>
          <span>Nombre del negocio</span>
        </Col>
        <Col size={2}>
          <strong>{data.restaurantName}</strong>
        </Col>
        <Col size={3} offset={4}>
          <span>{data.name}</span>
        </Col>
      </Row>
      <Row>
        <Col size={2}>
          <span>Nombre del banco</span>
        </Col>
        <Col size={2}>
          <strong>{data.bank}</strong>
        </Col>
        <Col size={3} offset={4}>
          <span style={{ color: 'black' }}>RFC: </span>
          <strong>{data.rfc}</strong>
        </Col>
      </Row>
      <Row>
        <Col size={2}>
          <span>Cuenta</span>
        </Col>
        <Col size={2}>
          <strong>{data.bankAccount}</strong>
        </Col>
        <Col size={3} offset={4}>
          <span style={{ color: 'black' }}>Saldo Anterior </span>
          <strong>{`$ ${data.debt || 0}`}</strong>
        </Col>
      </Row>
      <Row>
        <Col size={2}>
          <span>CLABE</span>
        </Col>
        <Col size={2}>
          <strong>{data.CLABE}</strong>
        </Col>
      </Row>
      <Row>
        <Col size={2}>
          <span>Porcentaje</span>
        </Col>
        <Col size={2}>
          <strong>{data.percentage}</strong>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col size={9}>
          <Row>
            <Col size={7}>
              <span style={{ color: 'black' }}>
                Pedidos de Efectivo cobrados
              </span>
            </Col>
            <Col size={5}>
              <strong>{`$ ${(data.cashAll && data.cashAll.toFixed(2)) ||
                0}`}</strong>
            </Col>
          </Row>
          <Row>
            <Col size={7}>
              <span style={{ color: 'black' }}>
                Comisiones generadas por pagos en efectivos
              </span>
            </Col>
            <Col size={5}>
              <strong>{`$ ${(data.comissionByCash &&
                data.comissionByCash.toFixed(2)) ||
                0}`}</strong>
            </Col>
          </Row>
          <Row>
            <Col size={7}>
              <span style={{ color: 'black' }}>
                IVA de Comisiones generadas por pagos en efectivo
              </span>
            </Col>
            <Col size={5}>
              <strong>{`$ ${(data.taxCash && data.taxCash.toFixed(2)) ||
                0}`}</strong>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col size={7}>
              <span style={{ color: 'black' }}>
                Pedidos PayPal cobrados por cuenta y orden de terceros
              </span>
            </Col>
            <Col size={5}>
              <strong>{`$ ${(data.paypalAll && data.paypalAll.toFixed(2)) ||
                0}`}</strong>
            </Col>
          </Row>
          <Row>
            <Col size={7}>
              <span style={{ color: 'black' }}>
                Comisiones generadas por pagos por PAYPAL
              </span>
            </Col>
            <Col size={5}>
              <strong>{`$ ${(data.comissionByPaypal &&
                data.comissionByPaypal.toFixed(2)) ||
                0}`}</strong>
            </Col>
          </Row>
          <Row>
            <Col size={7}>
              <span style={{ color: 'black' }}>
                IVA de Comisiones generadas por PAYPAL
              </span>
            </Col>
            <Col size={5}>
              <strong>{`$ ${(data.taxPaypal && data.taxPaypal.toFixed(2)) ||
                0}`}</strong>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col size={7}>
              <span style={{ color: 'black' }}>
                Pedidos de Tarjeta(Paycode) cobrados por cuenta y orden de
                terceros
              </span>
            </Col>
            <Col size={5}>
              <strong>{`$ ${(data.cardAll && data.cardAll.toFixed(2)) ||
                0}`}</strong>
            </Col>
          </Row>
          <Row>
            <Col size={7}>
              <span style={{ color: 'black' }}>
                Comisiones generadas por pagos por Tarjeta(Paycode)
              </span>
            </Col>
            <Col size={5}>
              <strong>{`$ ${(data.comissionByCard &&
                data.comissionByCard.toFixed(2)) ||
                0}`}</strong>
            </Col>
          </Row>
          <Row>
            <Col size={7}>
              <span style={{ color: 'black' }}>
                IVA de Comisiones generadas por Tarjeta(Paycode)
              </span>
            </Col>
            <Col size={5}>
              <strong>{`$ ${(data.taxCard && data.taxCard.toFixed(2)) ||
                0}`}</strong>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col size={7}>
              <span style={{ color: 'black' }}>Total de descuentos PAYPAL</span>
            </Col>
            <Col size={5}>
              <strong>{`$ ${(data.discountPaypalAll &&
                data.discountPaypalAll.toFixed(2)) ||
                0}`}</strong>
            </Col>
          </Row>
          <Row>
            <Col size={7}>
              <span style={{ color: 'black' }}>
                Total de descuentos EFECTIVO
              </span>
            </Col>
            <Col size={5}>
              <strong>{`$ ${(data.discountCashAll &&
                data.discountCashAll.toFixed(2)) ||
                0}`}</strong>
            </Col>
          </Row>
          <Row>
            <Col size={7}>
              <span style={{ color: 'black' }}>
                Total de descuentos Tarjeta(Paycode)
              </span>
            </Col>
            <Col size={5}>
              <strong>{`$ ${(data.discountCardAll &&
                data.discountCardAll.toFixed(2)) ||
                0}`}</strong>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col size={7}>
              <span style={{ color: 'black' }}>Facturable</span>
            </Col>
            <Col size={5}>
              <strong>{`$ ${(data.billable && data.billable.toFixed(2)) ||
                0}`}</strong>
            </Col>
          </Row>
          <Row>
            <Col size={7}>
              <span style={{ color: 'black' }}>Total Neto</span>
            </Col>
            <Col size={5}>
              <strong>{`$ ${(data.totalNet && data.totalNet.toFixed(2)) ||
                0}`}</strong>
            </Col>
          </Row>
        </Col>
        <Col size={3}>
          <Row>
            <Col size={12}>
              <h4 style={{ color: 'black' }}>Saldo Actual </h4>
              <strong
                style={{
                  fontSize: 34,
                  color: `${data.yumDebt ? 'green' : 'tomato'}`
                }}
              >{`$ ${data.total &&
                data.total
                  .toFixed(2)
                  .toString()
                  .replace('-', '')}`}</strong>
              {!data.yumDebt ? (
                <h5>*Saldo en contra</h5>
              ) : (
                <h5>*Saldo a favor</h5>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
    </React.Fragment>
  )
}

const TimeCard = ({ data }) => {
  const timestamp =
    data.acceptedTime || data.finishedTime || data.receivedTime || false
  const date = timestamp ? moment(timestamp) : false
  return (
    <Row>
      {date && (
        <Col size={12}>
          <div style={{ textAlign: 'center' }}>
            <span>{date.format('DD-MM-YYYY')}</span>
            <br />
            <span>{date.format('hh:mm a')}</span>
          </div>
        </Col>
      )}
    </Row>
  )
}
const ClientInfo = ({ data }) => {
  return (
    <ModalContent>
      <Row classes={'m-b-sm'}>
        <Col size={12}>
          <b>Cliente:</b>
        </Col>
        <Col size={12}>
          <span>
            {data.name || ''} {data.lastName || ''} {data.motherLastName || ''}
          </span>
        </Col>
        <Col size={3}>
          <b>Teléfono:</b>
        </Col>
        <Col size={3}>
          <span>{data.phone || ''}</span>
        </Col>
        <Col size={3}>
          <b>Correo:</b>
        </Col>
        <Col size={3}>
          <span>{data.email || ''}</span>
        </Col>
      </Row>
    </ModalContent>
  )
}

const RiderContent = ({ rider }) => {
  return (
    <ModalContent>
      <Row classes={'m-b-sm'}>
        {rider.avatar && (
          <Col size={12}>
            <Logo src={rider.avatar} alt={'Avatar profiile'} />
          </Col>
        )}
        <Col size={12}>
          <b>Repartidor:</b>
        </Col>
        <Col size={12}>
          <span>
            {rider.name || ''} {rider.lastName || ''}
          </span>
        </Col>
        <Col size={3}>
          <b>Teléfono:</b>
        </Col>
        <Col size={3}>
          <span>{rider.phone || ''}</span>
        </Col>
      </Row>
    </ModalContent>
  )
}

class ReportView extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      modal: {
        show: false,
        content: null
      }
    }
    this.contentModal = this.contentModal.bind(this)
    this.onCancel = this.onCancel.bind(this)
  }

  render () {
    const { data } = this.props
    const { modal: { show, content } } = this.state

    return (
      <Panel title={data.restaurantName}>
        <DataInfo data={data} />
        <ReportTable data={data} contentModal={this.contentModal} />
        <Col size={5} offset={5}>
          <h4>{`Total Comisiones: $ ${(data.comissionTotal &&
            data.comissionTotal.toFixed(2)) ||
            0}`}</h4>
        </Col>
        <Col size={2}>
          <Row>
            <Col size={12}>
              <span style={{ color: 'black' }}>Total Efectivo </span>
              <strong>{`$ ${(data.cashAll && data.cashAll.toFixed(2)) ||
                0}`}</strong>
            </Col>
            <Col size={12}>
              <span style={{ color: 'black' }}>Total Paypal </span>
              <strong>{`$ ${(data.paypalAll && data.paypalAll.toFixed(2)) ||
                0}`}</strong>
            </Col>
            <Col size={12}>
              <span style={{ color: 'black' }}>Total Tarjeta </span>
              <strong>{`$ ${(data.cardAll && data.cardAll.toFixed(2)) ||
                0}`}</strong>
            </Col>
          </Row>
        </Col>
        <Modal
          title='Detalles del orden'
          onCancel={this.onCancel}
          show={show}
          content={content}
        />
      </Panel>
    )
  }

  contentModal (dishesKey) {
    const show = 'show'
    const { data } = this.props
    const dishes = data.orders[dishesKey].dishes
    const order = data.orders[dishesKey]
    const attendedBy = data.orders[dishesKey].attendedBy || false
    const acceptedBy = data.orders[dishesKey].acceptedBy || false
    const receivedBy = data.orders[dishesKey].receivedBy || false
    const finishedBy = data.orders[dishesKey].finishedBy || false
    const client = data.orders[dishesKey].client || false
    const content = dishes.map((dish, i) => {
      const { name, count, description, extras, indications } = dish
      const extrasList = extras && Object.values(extras)
      const hasExtras = !_.isEmpty(extrasList) && Array.isArray(extrasList[0])
      return (
        <ModalContent key={i}>
          <Row>
            <Col size={12}>
              <b>Platillo {i + 1}</b>
            </Col>
            <Col size={12}>
              <span>{name}</span>
            </Col>
            <Col size={12}>
              <span>
                <b>Cantidad: </b>
                {count}
              </span>
            </Col>
            <Col size={12}>
              <b>Descripción</b>
            </Col>
            <Col size={12}>
              <span>{description}</span>
            </Col>
          </Row>
          <Row style={{ marginTop: 15 }}>
            <Col size={9} offset={1}>
              {hasExtras && (
                <Row>
                  <Col size={12}>
                    <p>
                      <b>Extra</b>
                    </p>
                  </Col>
                  <ol>
                    {extrasList.map((extra, index) => (
                      <React.Fragment>
                        <Col size={12}>
                          <hr />
                        </Col>
                        <li key={index}>
                          <ul>
                            {extra.map((item, j) => (
                              <li key={j}>
                                {item.name}
                                {item.price > 0 ? `- $${item.price}` : ''}
                              </li>
                            ))}
                          </ul>
                        </li>
                      </React.Fragment>
                    ))}
                  </ol>
                </Row>
              )}
            </Col>
          </Row>
          <Row>
            <Col size={12}>
              <Row>
                {indications && (
                  <Col size={12}>
                    <b>Indicaciones</b>
                    <ul>
                      <li>{indications}</li>
                    </ul>
                  </Col>
                )}
                <Col size={12}>
                  <hr />
                </Col>
              </Row>
            </Col>
          </Row>
        </ModalContent>
      )
    })
    var contentWithRider = (
      <div>
        <ModalContent>
          <Row>
            {acceptedBy && (
              <Col size={3} offset={1} classes={'bg-success m-r-sm'}>
                <Row>
                  <Col size={12}>
                    <h6>Hora de Aceptado</h6>
                  </Col>
                  <Col size={12}>
                    <TimeCard data={acceptedBy} />
                  </Col>
                </Row>
              </Col>
            )}
            {receivedBy && (
              <Col size={3} classes={'bg-success m-r-sm'}>
                <Row>
                  <Col size={12}>
                    <h6>Hora de Recibido</h6>
                  </Col>
                  <Col size={12}>
                    <TimeCard data={receivedBy} />
                  </Col>
                </Row>
              </Col>
            )}
            {finishedBy && (
              <Col size={3} classes={'bg-success m-r-sm'}>
                <Row>
                  <Col size={12}>
                    <h6>Hora de Entregado</h6>
                  </Col>
                  <Col size={12}>
                    <TimeCard data={finishedBy} />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </ModalContent>
        {attendedBy && <RiderContent rider={attendedBy} />}
        {client && <ClientInfo data={client} />}
        {content}
        <ModalContent>
          <Row>
            <Col size={2}>
              <b style={{ fontSize: 16 }}>Envio:</b>
            </Col>
            <Col size={2}>
              <span style={{ fontSize: 18 }}>${order.deliveryCost}</span>
            </Col>
          </Row>
          <Row>
            <Col size={2}>
              <b style={{ fontSize: 16 }}>SubTotal:</b>
            </Col>
            <Col size={2}>
              <span style={{ fontSize: 18 }}>${order.subtotal}</span>
            </Col>
          </Row>
          <Row>
            <Col size={2}>
              <b style={{ fontSize: 18 }}>Total:</b>
            </Col>
            <Col size={2}>
              <span style={{ fontSize: 20 }}>${order.total}</span>
            </Col>
          </Row>
        </ModalContent>
      </div>
    )
    this.setState({
      modal: {
        show,
        content: contentWithRider
      }
    })
  }
  onCancel = () => {
    this.setState({
      modal: {
        show: false,
        content: null
      }
    })
  }
}

export default ReportView
