import styled from 'styled-components'

export const Card = styled.div`
  width: 100%;
  height: auto;
  padding: 10px;
  display: flex;
  border-radius: ${({ size }) => (size ? size * 0.1 : '10px')};
  box-shadow: 0px 2px 4px #a6a4a1;
  background: #fff;
  flex-direction: row;
`

export const ViewColumn = styled.div`
  flex: ${({ size }) => (size || '1')};
  justify-content: center;
`

export const Text = styled.p`
  font-size: ${({ fSize }) => (fSize || '12px')};
  ${({ bold }) => bold && 'font-weight: 600'};
  margin-bottom: ${({ mBottom }) => (mBottom || '5px')};
`
