import React from 'react'
import { Row, Col, Page } from '../../components/ui/Layout'
import { Panel } from '../../components/ui/'
import ReactTable from 'react-table'
import { connect } from 'react-redux'
import {
  updateRestaurantPosition,
  resetPassword
} from '../../containers/restaurantsHome/actions'
import Switch from '../../components/switch'
import Loader from '../../components/loading'
import IconButton from '@material-ui/core/IconButton'
import VpnKey from '@material-ui/icons/VpnKey'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import EditIcon from '@material-ui/icons/Edit'
import matchSorter from 'match-sorter'
import { Logo } from './styled'
import moment from 'moment'
import _ from 'lodash'
import fb from '../../firebase'
import Swal from 'sweetalert2'
import getEndpoint from '../../helpers/getEndpoint'
const firebaseUrl = getEndpoint('REACT_APP_DATABASE_URL')

const restaurantsDB = fb.database().ref('restaurants')

const dayOfTheWeek = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
]

class Restaurants extends React.Component {
  state = {
    openLoading: false,
    activeLoading: false,
    loaderPaypal: false,
    id: ''
  }

  render () {
    const {
      filter,
      data: { restaurants, restaurants: { isLoading } }
    } = this.props
    let arrayRestaurants = restaurants ? Object.values(restaurants) : []
    const type = filter === 'restaurantsActive'
    arrayRestaurants = arrayRestaurants.filter(r => r.active === type)
    const orderRest = _.orderBy(
      arrayRestaurants,
      ['position', 'name'],
      ['asc']
    ).filter(item => item.active !== undefined && item)

    const data = this.renderOrder(orderRest)
    const columns = this.renderHeader()
    return (
      <Page>
        <Row>
          <Col size={12}>
            <Panel>
              <ReactTable
                data={data}
                columns={columns}
                defaultPageSize={10}
                filterable
                loading={isLoading}
                getTheadThProps={() => {
                  return {
                    style: {
                      outline: 0,
                      whiteSpace: 'pre-line',
                      wordWrap: 'break-word'
                    }
                  }
                }}
                getTdProps={(state, rowInfo) => {
                  return {
                    style: {
                      whiteSpace: 'pre-line',
                      wordWrap: 'break-word',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: rowInfo && !rowInfo.original.haveAddress && '#FFF',
                      backgroundColor:
                        rowInfo && !rowInfo.original.haveAddress && 'tomato'
                    }
                  }
                }}
              />
            </Panel>
          </Col>
        </Row>
      </Page>
    )
  }

  setId = idRest => {
    this.setState({
      id: idRest
    })
  }

  loaderCallback = (loading, type) => {
    this.setState({
      [type]: loading
    })
  }

  setOpenLoading = () => {
    this.setState({
      openLoading: !this.state.openLoading
    })
  }

  renderOrder (restaurantArray) {
    return restaurantArray.map((restaurant, i) => {
      const { position } = restaurant
      const name = restaurant.name || ''
      const photo = (restaurant.photo && restaurant.photo) || ''
      const objRestaurant = { name: name, photo: photo, id: restaurant._id }
      const schedule = restaurant.schedule
      let currentDay = moment()
        .format('d')
        .toLowerCase()
      currentDay = dayOfTheWeek[currentDay]

      const closeHour =
        schedule && schedule[currentDay] && schedule[currentDay].close
      const openHour =
        schedule && schedule[currentDay] && schedule[currentDay].open
      const isOpen = {
        isOpen: restaurant.isOpen ? restaurant.isOpen : false,
        id: restaurant._id
      }
      const active = {
        active: restaurant.active ? restaurant.active : false,
        id: restaurant._id
      }
      const acceptPaypal = {
        acceptPaypal: restaurant.acceptPaypal ? restaurant.acceptPaypal : false,
        id: restaurant._id
      }

      const phone = restaurant.phone
      const email = restaurant.email
      const addressRestaurant =
        (restaurant.address && restaurant.address.formatted) || ''

      const haveSchedule = !!(closeHour && openHour)
      const haveAddress = !!(
        restaurant.address &&
        restaurant.address.coords &&
        !restaurant.address.coords.lat &&
        !restaurant.address.coords.lng
      )
      return {
        restaurantName: objRestaurant.name,
        restaurantData: {
          address: addressRestaurant,
          name: restaurant.name,
          email: email,
          phone: phone
        },
        objRestaurant,
        openHour,
        closeHour,
        isOpen,
        phone,
        active,
        acceptPaypal,
        email,
        addressRestaurant,
        position,
        haveSchedule,
        haveAddress,
        partner: restaurant.partner,
        _row: restaurant
      }
    })
  }

  renderHeader () {
    const {
      toogleActive,
      toogleOpen,
      acceptPaypalActive,
      onEdit,
      onDelete,
      updateRestaurantPosition,
      resetPassword
    } = this.props
    return [
      {
        Header: 'Restaurante',
        accessor: 'restaurantName',
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: ['restaurantName']
          }),
        Cell: props => (
          <div>
            <Logo
              alt={'Sin logo'}
              src={
                props.original.objRestaurant &&
                props.original.objRestaurant.photo
              }
              className={'col-md-4'}
            />
            <a
              href={`${firebaseUrl}/restaurants/${
                props.original.objRestaurant.id
              }`}
              target='_blank'
              rel='noopener noreferrer'
            >
              {props.original.objRestaurant.name}
            </a>
          </div>
        ),
        filterAll: true,
        width: 230,
        maxWidth: 250
      },
      {
        Header: 'Datos generales',
        accessor: 'restaurantData',
        filterMethod: (filter, rows) => {
          return matchSorter(rows, filter.value, {
            keys: [
              'restaurantData.email',
              'restaurantData.address',
              'restaurantData.phone'
            ]
          })
        },
        Cell: props => (
          <div>
            <div
              style={{
                padding: '10px'
              }}
            >
              {props.value.email}
            </div>
            <div
              style={{
                padding: '10px'
              }}
            >
              {props.value.phone}
            </div>
            <div
              style={{
                padding: '10px'
              }}
            >
              {props.value.address}
            </div>
          </div>
        ),
        filterAll: true,
        width: 230,
        maxWidth: 250
      },
      {
        Header: 'Datos del usuario',
        accessor: 'partner',
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: ['partner']
          }),
        filterAll: true
      },
      {
        Header: 'Abierto',
        accessor: 'isOpen',
        filterable: false,
        style: {
          display: 'flex',
          justifyContent: 'center',
          width: '50px',
          maxWidth: '80px'
        },
        Cell: props => {
          if (this.state.openLoading && this.state.id === props.value.id) {
            return (
              <Row>
                <Col size={12}>
                  <Loader style={{ fontSize: 24, top: -15 }} />
                </Col>
              </Row>
            )
          } else {
            return (
              props.value && (
                <Switch
                  on={props.value.isOpen}
                  onChange={e => {
                    this.setOpenLoading()
                    toogleOpen({
                      data: {
                        is_open: e
                      },
                      uid: props.value.id,
                      cb: this.setOpenLoading
                    })
                    this.setId(props.value.id)
                  }}
                />
              )
            )
          }
        }
      },
      {
        Header: 'Horario',
        accessor: 'isOpen',
        filterable: false,
        style: {
          display: 'flex',
          justifyContent: 'center',
          width: '50px',
          maxWidth: '80px'
        },
        Cell: props =>
          props.value && (
            <div
              style={{
                width: '100%',
                borderRadius: 15,
                padding: 15,
                backgroundColor: `${
                  !props.original.openHour || !props.original.closeHour
                    ? '#FFAFAF'
                    : '#FFFFFF'
                }`
              }}
            >
              <p>
                <b>Abre:</b>
                {` ${props.original.openHour}`}
              </p>
              <p>
                <b>Cierra:</b>
                {` ${props.original.closeHour}`}
              </p>
            </div>
          )
      },
      {
        Header: 'Restaurante activo',
        accessor: 'active',
        filterable: false,
        style: { display: 'flex', justifyContent: 'center' },
        Cell: props => {
          if (this.state.activeLoading && this.state.id === props.value.id) {
            return (
              <Row>
                <Col size={12}>
                  <Loader style={{ fontSize: 24, top: -15 }} />
                </Col>
              </Row>
            )
          } else {
            return (
              props.value && (
                <Switch
                  on={props.value.active}
                  onChange={e => [
                    toogleActive(
                      props.value.id,
                      !props.value.active,
                      this.loaderCallback
                    ),
                    this.setId(props.value.id)
                  ]}
                />
              )
            )
          }
        }
      },
      {
        Header: 'Paypal',
        accessor: 'acceptPaypal',
        filterable: false,
        style: {
          display: 'flex',
          justifyContent: 'center',
          width: '50px',
          maxWidth: '80px'
        },
        Cell: props => {
          if (this.state.loaderPaypal && this.state.id === props.value.id) {
            return (
              <Row>
                <Col size={12}>
                  <Loader style={{ fontSize: 24, top: -15 }} />
                </Col>
              </Row>
            )
          } else {
            return (
              props.value && (
                <Switch
                  on={props.value.acceptPaypal}
                  onChange={e => [
                    acceptPaypalActive(
                      props.value.id,
                      !props.value.acceptPaypal,
                      this.loaderCallback
                    ),
                    this.setId(props.value.id)
                  ]}
                />
              )
            )
          }
        }
      },
      {
        Header: 'Acciones',
        filterable: false,
        style: { display: 'flex', justifyContent: 'center' },
        Cell: props =>
          props && (
            <React.Fragment>
              <div
                style={{
                  flex: 1
                }}
              >
                <IconButton
                  onClick={() => {
                    onEdit(props)
                  }}
                  color='default'
                  aria-label='Edit'
                  component='span'
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => resetPassword(props.original.partner.id)}
                  color='default'
                  aria-label='Reset Password'
                  component='span'
                >
                  <VpnKey />
                </IconButton>
                <IconButton
                  onClick={() => onDelete(props)}
                  color='secondary'
                  aria-label='Delete Restaurant'
                  component='span'
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </div>
              <div
                style={{
                  flex: 1
                }}
              >
                <input
                  name='position'
                  size='2'
                  placeholder={props.row._original.position}
                  onBlur={val => {
                    var position = val.target.value
                    const restaurantId = props.row.active.id
                    updateRestaurantPosition({
                      position,
                      restaurantId: restaurantId
                    })
                  }}
                />
              </div>
            </React.Fragment>
          )
      }
    ]
  }
}
const mapStateToProps = state => {
  return {
    ...state
  }
}
const mapDispatchToProps = dispatch => {
  return {
    resetPassword: id => dispatch(resetPassword(id)),
    updateRestaurantPosition: payload =>
      dispatch(updateRestaurantPosition(payload))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Restaurants)
