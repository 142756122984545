import React from 'react'
import ReactExport from 'react-data-export'
import { Button } from '../ui'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet

const createData = data => {
  var restJson = {}
  var restCompleteJson = {}

  var dataArray = []
  var columns = [
    { title: 'Nombre' },
    { title: 'Teléfono' },
    { title: 'Email' },
    { title: 'Total Paypal ' },
    { title: 'Total Efectivo' },
    { title: 'Total Tarjeta' },
    { title: 'Total Descuento' },
    { title: 'Saldo Actual' }
  ]
  var arrayElement = []
  data.forEach(rider => {
    const {
      attendedBy: { name, phone, email, lastName, motherLastName },
      cashAll,
      paypalAll,
      cardAll,
      total,
      discountAll
    } = rider
    var fullname = `${name || ''} ${lastName || ''} ${motherLastName || ''}`
    var arrayData = [
      fullname,
      phone,
      email,
      paypalAll,
      cashAll,
      cardAll,
      discountAll,
      total
    ]
    return arrayElement.push(arrayData)
  })
  restJson['columns'] = columns
  restJson['data'] = arrayElement
  restCompleteJson = { ...restJson }
  dataArray.push(restCompleteJson)
  return dataArray
}

const ExportDataRider = ({ data }) => {
  var dataToExcele = createData(data)
  return (
    <ExcelFile
      filename={'Repartidores YUM'}
      element={
        <Button
          label='Exportar'
          size='btn-sm'
          color='btn-default'
          classes={'m-r-sm m-b-sm'}
        />
      }
    >
      <ExcelSheet dataSet={dataToExcele} name='Repartidores' />
    </ExcelFile>
  )
}

export default ExportDataRider
