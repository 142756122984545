import styled from 'styled-components'
import {
  TextField,
  Select as InputSelect,
  InputLabel,
  FormHelperText,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Typography
} from '@material-ui/core'

export const Wrapper = styled.div`
  flex: 1;
`

export const SubWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
`

export const Avatar = styled.div`
  background: url(${props =>
    props.src ? props.src : 'http://yumdeliver.com/img/yum-logo-circle.png'})
    no-repeat;
  background-size: cover;
  border-radius: 50%;
  min-width: 190px;
  min-height: 190px;
  max-width: 190px;
  max-height: 190px;
  border: 4px solid gray;
  padding-bottom: 30px;
`
export const Field = styled(TextField)`
  width: 100%;
  & .MuiFormLabel-root {
    font-size: 18px;
    margin-bottom: ${({ error }) => (error ? '5px' : '10px')};
  }
  & #standard-basic-helper-text {
    ${({ error }) => error && 'color: #FF0000'};
    font-size: 12px;
  }
  & #standard-basic {
    font-size: 15px;
  }
  & .MuiInputBase-input {
    font-size: 15px;
  }
  & .MuiFormHelperText-root.Mui-error {
    font-size: 12px;
  }
`

export const WrapForm = styled.div`
  display: flex;
  justifycontent: center;
  alignitems: center;
  width: 100%;
  paddingtop: 30px;
`

export const WrapField = styled.div`
  flex: 1;
  width: ${({ padding }) => (padding ? '50%' : '80%')};
  padding: ${({ padding }) => (padding ? `${padding}px 0 0 0` : '10px 0')};
  display: flex;
  justify-content: space-around;
  ${({ column }) => column && 'flex-direction: column'};
`

export const Button = styled.button`
  font-size: 15px;
`

export const Select = styled(InputSelect)`
  width: 100%;

  & .MuiSelect-select.MuiSelect-select {
    font-size: 15px;
  }
`

export const Label = styled(InputLabel)`
  & .MuiInputLabel-root {
    font-size: 12px;
  }
`

export const HelperText = styled(FormHelperText)``

export const OptionItem = styled(MenuItem)``

export const ControlLabel = styled(FormControlLabel)``

export const CheckGod = styled(Checkbox)``

export const Typo = styled(Typography)``
