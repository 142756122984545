import fb from '../../firebase'
import Swal from 'sweetalert2'

const databaseRef = fb.database().ref()

export const createGeofenceAction = (city, name, polygons, callback) => {
  var update = {}
  var mvcArray = polygons.getPath()
  let geofence = JSON.parse(JSON.stringify(mvcArray.getArray()))
  update[`/geofences/${city}/deliverArea`] = geofence
  update[`/cities/${city}/geofences/${city}`] = true
  update[`/geofences/${city}/name`] = name

  databaseRef
    .update(update)
    .then(() => {
      callback()
    })
    .catch(err =>
      Swal.fire({
        position: 'center',
        type: 'error',
        title: `${err.message}`,
        showConfirmButton: false,
        timer: 2500
      })
    )
}
