import styled from 'styled-components'

/** *********  index.js  ***********/

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  height: auto;
`
export const SubWrap = styled.div`
  display: flex;
  width: 95%;
  height: auto;
  flex-direction: row;
  border-bottom: ${({ border }) => border && border};
`
export const Covering = styled.div`
  padding: ${({ padding }) => padding || '20px'};
`
export const Element = styled.div`
  flex: ${({ flex }) => flex || '1'};
  border-right: ${({ borderR }) => borderR && borderR};
`

export const Icon = styled.i`
  color: #717171;
  font-size: 20px;
`

export const WrapperItem = styled.div`
  display: flex;
  align-items: center;
`

export const ExtrasCointainerDown = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const ExtrasCointainerRequired = styled.div`
  margin-right: 20px;
`

export const ExtrasCointainerPosition = styled.div`
  width: 50px;
`

export const Gmap = styled.div`
  width: 100%;
  height: 350px;
  background-color: red;
`

/** *********  restaurantForm.js  ***********/

export const FormLayout = styled.div`
  width: ${({ isWidth }) => (isWidth ? '90%' : '100%')};
`
export const FormWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: stretch;
`
export const FormElement = styled.div`
  flex: 1;
  height: 100%;
  padding: 20px;
  ${({ bRight }) => bRight && 'border-right: 2px solid gray;'};
`
export const Label = styled.p`
  text-align: center;
  font-size: ${({ fSize }) => fSize || '2em'};
  color: ${({ fSize }) => (fSize ? '#717171' : '#f3f4f7')};
`
export const FormImage = styled.div`
  display: flex;
  justify-content: center;
  padding: 25px 0 25px 0;
`
export const FormSwitchs = styled.div`
  flex: 1;
`
export const SwitchGroup = styled.div`
  display: flex;
  padding: ${({ padding }) => padding || '5px'};
  justify-content: ${({ justify }) => justify && justify};
`
export const SwitchElement = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`
export const TimeGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${({ justify }) => justify && justify};
  padding: ${({ padding }) => padding && padding};
`
export const ScheduleElement = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 5px;
`

export const ScheduleRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 5px;
`
