import React from 'react'
import {
  DishImage,
  Name,
  Description,
  Price,
  Wrapper,
  WrapperDish,
  WrapperDescription
} from './styled'

export default ({
  value,
  photo,
  active,
  description,
  name,
  price,
  keyId,
  action,
  selected
}) => {
  return (
    <Wrapper
      selected={selected && selected.dishId === keyId}
      onClick={() => action({ ...value })}
    >
      <WrapperDish>
        <DishImage src={photo} />
        <WrapperDescription>
          <Name>{name}</Name>
          <Description>{description}</Description>
          <Price>{price}</Price>
        </WrapperDescription>
      </WrapperDish>
    </Wrapper>
  )
}
