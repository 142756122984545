import moment from 'moment'
import Swal from 'sweetalert2'
import fb from '../../firebase'
import _ from 'lodash'
import getEndpoint from '../../helpers/getEndpoint'

const base_url = getEndpoint('REACT_APP_ENDPOINT_AWS')
const BE_URL = getEndpoint('REACT_APP_DATABASE_URL')
const db = fb.database().ref('prospects')
const restaurantsDB = fb.database().ref('restaurants')
const partnersDB = fb.database().ref('partners')
const month =
  moment().month() < 10 ? `0${moment().month() + 1}` : moment().month() + 1
const finishedOrdersDB = fb
  .database()
  .ref(`finishedOrders/${moment().year()}/${month}`)

export const bindProspects = (city, page) => {
  return dispatch => {
    const url = `${base_url}/restaurants/?is_prospect=True`
    fetch(url)
      .then(res => {
        if (res.status === 200) {
          res
            .json()
            .then(data => {
              const payload = data
              dispatch({ type: 'GET_PROSPECTS', payload })
            })
            .catch(err =>
              Swal.fire({
                position: 'center',
                type: 'error',
                title: `Hubo un error: ${err}`,
                showConfirmButton: false,
                timer: 2500
              })
            )
        } else {
          Swal.fire({
            position: 'center',
            type: 'error',
            title: 'Error en el fetch prospectos',
            showConfirmButton: false,
            timer: 2500
          })
        }
      })
      .catch(error => {
        Swal.fire({
          position: 'center',
          type: 'error',
          title: 'Hubo un error al consultar los datos.',
          showConfirmButton: false,
          timer: 2500
        })
      })
  }
}

export const getRiderProspects = (city, page) => {
  return dispatch => {
    const url = `${base_url}/riders`
    fetch(url)
      .then(res => {
        if (res.status === 200) {
          res
            .json()
            .then(data => {
              const payload = data
              dispatch({ type: 'GET_RIDERS_PROSPECTS', payload })
            })
            .catch(err =>
              Swal.fire({
                position: 'center',
                type: 'error',
                title: `Hubo un error: ${err}`,
                showConfirmButton: false,
                timer: 2500
              })
            )
        } else {
          Swal.fire({
            position: 'center',
            type: 'error',
            title: 'Error en el fetch rider prospects',
            showConfirmButton: false,
            timer: 2500
          })
        }
      })
      .catch(error =>
        Swal.fire({
          position: 'center',
          type: 'error',
          title: 'Hubo un error al consultar los datos.',
          showConfirmButton: false,
          timer: 2500
        })
      )
  }
}

const generatePassword = email => {
  return `${email.substring(0, 4)}${Math.floor(Math.random() * 9999)}`
}

export const acceptRestaurant = (prospect, city) => {
  // todo is this used anymore?
  const { email } = prospect
  const password = generatePassword(email)
  prospect.password = password

  if (!_.isEmpty(city)) {
    fb
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(res => {
        moveToRestaurant(res.user.uid, prospect, false, city)
      })
      .catch(err => {
        if (err.code === 'auth/email-already-in-use') {
          Swal.fire({
            position: 'center',
            type: 'error',
            title: `${err.message}`,
            showConfirmButton: false,
            timer: 3500
          })
          // THIS LOOKED OLD, THAT'S WHY I REMOVE IT
          // const url =
          //   processenv.NODE_ENV === 'development'
          //     ? `https://us-central1-yum-dev-9a00f.cloudfunctions.net/apiAdmin/users/${email}/bind/`
          //     : `https://us-central1-yum-deliver.cloudfunctions.net/apiAdmin/users/${email}/bind/`
          fetch(BE_URL, {
            method: 'POST',
            body: JSON.stringify({})
          })
            .then(response =>
              response.json().then(data => {
                if (data.error) {
                  Swal.fire({
                    position: 'center',
                    type: 'error',
                    title: `${data.error}`,
                    showConfirmButton: false,
                    timer: 2500
                  })
                  return
                }
                moveToRestaurant(data.data, prospect, true, city) // This wont have prospect, because is setted up already
              })
            )
            .catch(error =>
              Swal.fire({
                position: 'center',
                type: 'error',
                title: `${error.message}`,
                showConfirmButton: false,
                timer: 2500
              })
            )
        }
      })
  } else {
    Swal.fire({
      position: 'center',
      type: 'error',
      title: `Este administrador no cuenta con ciudad asignada.`,
      showConfirmButton: false,
      timer: 2500
    })
  }
}

const removeProspect = prospectId => {
  return db
    .child(prospectId)
    .remove()
    .then(res => {})
    .catch(err =>
      Swal.fire({
        position: 'center',
        type: 'error',
        title: `${err.message}`,
        showConfirmButton: false,
        timer: 2500
      })
    )
}

const createPartner = (id, partner, prospectId) => {
  return partnersDB
    .child(id)
    .update(partner)
    .then(res => {
      Swal.fire(
        'Usuario creado!',
        'El usuario fue creado con exito.',
        'success'
      )
      removeProspect(prospectId)
    })
    .catch(err =>
      Swal.fire({
        position: 'center',
        type: 'error',
        title: `${err.message}`,
        showConfirmButton: false,
        timer: 2500
      })
    )
}

const moveToRestaurant = (id, prospect, onlyMove, city) => {
  const address = {
    formated: prospect.restaurantAddress
  }
  const restaurant = {
    categories: prospect.categories,
    description: prospect.description,
    name: prospect.restaurantName,
    email: prospect.restaurantEmail,
    partner: id,
    phone: prospect.restaurantPhone,
    schedule: prospect.schedule,
    acceptPaypal: true,
    acceptCash: true,
    address: prospect.address || address,
    havePhoto: false,
    city
  }
  // If there is no prospect on the paramenter, this info is setted already, and no need to set it again
  let partner = false

  if (!onlyMove) {
    partner = {
      email: prospect.email,
      name: prospect.name,
      lastName: prospect.lastName,
      phone: prospect.phone,
      type: 'restaurant',
      password: prospect.password,
      city
    }
  }
  /**
   * Tempate types Example
   *
   * TYPE OPTIONS:
   *   'SEND_DEALER_PASSWORD': {
   *     description: will send a email with default password for the dealers
   *     context: { // accepts
   *       email: 'String'
   *       password: 'String'
   *     }
   *   }
   *   'SEND_RESTAURANT_PASSWORD':{
   *     description: will send a email with default password for the restaurants
   *     context: {
   *       password: 'String'
   *     }
   *   }
   *   'LETTER_FOR_RESTAURANT': {
   *     description: will send a email with welcome letter for the restaurants
   *     context: {
   *       partner: 'String'
   *       restaurantName:'String'
   *     }
   *   }
   */

  const data = {
    from: 'YUM To Go <info@yumdeliver.com>',
    to: prospect.email,
    subject: '¡Bienvenido a YUM Delivery!',
    template: {
      type: 'SEND_RESTAURANT_PASSWORD',
      context: {
        password: prospect.password
      }
    }
  }
  fetch(BE_URL, {
    method: 'POST',
    body: JSON.stringify(data)
  }).catch(err => {
    Swal.fire({
      position: 'center',
      type: 'error',
      title: 'El usuario no recibio el correo.',
      showConfirmButton: true
    })
  })
  return restaurantsDB
    .push(restaurant)
    .then(res => {
      removeProspect(prospect._id)
      partner && createPartner(id, partner, prospect._id)
      Swal.fire(
        'Restaurante creado!',
        'El restaurant fue creado con exito.',
        'success'
      )
    })
    .catch(err =>
      Swal.fire({
        position: 'center',
        type: 'error',
        title: `${err.message}`,
        showConfirmButton: false,
        timer: 2500
      })
    )
}

export const bindDealers = city => {
  return dispatch => {
    dispatch({
      type: 'GET_DEALERS_LOADING'
    })
    partnersDB
      .orderByChild('type')
      .equalTo('delivery')
      .once(
        // PATCH need to be sovled
        'value',
        snapshot => {
          var data = snapshot.val() || {}
          Object.keys(data).forEach(key => {
            if (data[key].city === city) {
              data[key].id = key
            } else {
              delete data[key]
            }
            return true
          })
          dispatch({
            type: 'GET_DEALERS_SUCCESS'
          })
          dispatch({
            type: 'GET_DEALERS',
            payload: data
          })
        },
        () => {
          dispatch({
            type: 'GET_DEALERS_SUCCESS'
          })
        }
      )
  }
}

export const acceptRider = (prospect, city) => {
  const {
    email,
    birthday,
    lastName,
    name,
    phone,
    vehicle,
    model,
    _id
  } = prospect
  const password = generatePassword(email)

  fb
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then(userCredential => {
      var uid = userCredential.user.uid
      var data = {
        from: 'Yum Delivery <info@yumdeliver.com>',
        to: email,
        subject: '¡Bienvenido a Yum Delivery!',
        template: {
          type: 'SEND_DEALER_PASSWORD',
          context: {
            email,
            password
          }
        }
      }
      fetch(BE_URL, {
        method: 'POST',
        body: JSON.stringify(data)
      }).catch(err =>
        Swal.fire({
          position: 'center',
          type: 'error',
          title: `${err.message}`,
          showConfirmButton: false,
          timer: 2500
        })
      )
      fb
        .database()
        .ref('/partners')
        .child(uid)
        .set({
          email,
          birthday,
          name,
          lastName,
          phone,
          password: password,
          vehicle,
          model,
          inService: false,
          type: 'delivery',
          city
        })
        .then(() => {
          Swal.fire('Creado!', 'El repartidor fue creado con exito.', 'success')
          fb
            .database()
            .ref('/prospects')
            .child(_id)
            .remove()
        })
    })
    .catch(err => {
      Swal.fire({
        position: 'center',
        type: 'error',
        title: `${err.message}`,
        showConfirmButton: false,
        timer: 2500
      })
    })
}

export const getOrdersByToday = city => {
  return dispatch => {
    const startDate = moment()
      .startOf('week')
      .startOf('day')
      .add(1, 'day')
    const endDate = moment(startDate)
      .add(6, 'day')
      .endOf('day')
    const startLastDate = moment()
      .startOf('week')
      .startOf('day')
      .subtract(1, 'week')
      .add(1, 'day')
    const endLastDate = moment(startLastDate)
      .add(6, 'day')
      .endOf('day')
    const today = moment().format('MM-DD-YYYY')

    finishedOrdersDB
      .orderByChild('status')
      .equalTo('delivered')
      .on('value', snp => {
        const ordersArray = snp.val() ? Object.values(snp.val()) : []
        const orders = ordersArray.filter(
          order => order.restaurant.city === city
        )
        const totalOrder = orders.filter(
          order => order.createdAt >= moment(today).valueOf()
        ).length
        let saleCurrentWeek = 0
        orders
          .filter(order => {
            const range = moment().range(startDate, endDate)
            return range.contains(moment(order.createdAt))
          })
          .forEach(order => {
            saleCurrentWeek += order.orderTotal ? order.orderTotal.subtotal : 0
          })

        let saleLastWeek = 0
        orders
          .filter(order => {
            const range = moment().range(startLastDate, endLastDate)
            return range.contains(moment(order.createdAt))
          })
          .forEach(order => {
            saleLastWeek += order.orderTotal ? order.orderTotal.subtotal : 0
          })

        let saleByMonth = 0
        orders.forEach(order => {
          saleByMonth += order.orderTotal ? order.orderTotal.subtotal : 0
        })
        dispatch({
          type: 'GET_INDICATORS',
          payload: {
            totalOrder,
            saleCurrentWeek,
            saleLastWeek,
            saleByMonth
          }
        })
      })
  }
}

export const deleteProspects = id => {
  let url = getEndpoint('REACT_APP_ENDPOINT_API')
  url = `${url}/removeProspect/${id}`
  fetch(url, {
    method: 'DELETE'
  })
    .then(() => {
      Swal.fire('Eliminado!', 'Prospecto eliminado con éxito.', 'success')
    })
    .catch(() =>
      Swal.fire({
        position: 'center',
        type: 'error',
        title: 'Ocurrió un error al eliminar el prospecto, inténtelo de nuevo',
        showConfirmButton: false,
        timer: 2500
      })
    )
}
