import React, { useState } from 'react'
import FormInput from '../../components/FormInput'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormHelperText from '@material-ui/core/FormHelperText'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import getEndpoint from '../../helpers/getEndpoint'
import axios from 'axios'

const BASE_URL = `${getEndpoint('REACT_APP_ENDPOINT_AWS')}`
const TOKEN = `${getEndpoint('REACT_APP_DJANGO_TOKEN')}`

const UserSchema = Yup.object().shape({
  name: Yup.string().required('Campo requerido'),
  lastName: Yup.string().required('Campo requerido'),
  email: Yup.string().required('Campo requerido'),
  type: Yup.string().required('Campo requerido')
})

const AddRestaurantUser = props => {
  const [partners, setPartners] = useState([{ name: 'Buscando socio...' }])
  const [assignedPartner, setAssignedPartner] = useState(null)
  const { profile: { city, selectCity, type }, history } = props
  const finalCity = selectCity || city
  const initialValues = {
    type: 'restaurant',
    city: finalCity
  }
  const getPartner = e => {
    const value = e.target.value
    const url = `${BASE_URL}v2/partner/?search=${value}&city=${finalCity}`
    const config = {
      headers: {
        Authorization: `Token ${TOKEN}`
      }
    }
    axios.get(url, config).then(res => {
      setPartners(res.data.results)
    })
  }
  return (
    <Grid container style={{ padding: 20 }}>
      <Grid item xs={12}>
        <Autocomplete
          id={`select_partners`}
          options={partners}
          getOptionLabel={option =>
            `${option.name} ${option.last_name ||
              ''} ${option.mother_last_name || ''} ${option.email || ''}`
          }
          onChange={(event, value) => {
            if (value) {
              setAssignedPartner(value.foreign_key)
            }
          }}
          renderInput={params => {
            return (
              <TextField
                {...params}
                onChange={getPartner}
                label={'Buscar socio'}
                variant='outlined'
              />
            )
          }}
        />
      </Grid>
      <Formik
        validationSchema={assignedPartner ? {} : UserSchema}
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          if (assignedPartner) {
            return history.push(`restaurant/${assignedPartner}/edit`)
          }
          props.onSave(values)
        }}
        validateOnChange={false}
        validateOnBlur={false}
        render={({ values, errors, handleBlur, handleChange, loading }) => (
          <Form>
            {assignedPartner === null && (
              <div className='modal-body'>
                <FormInput
                  label='Nombre'
                  placeholder='Nombre...'
                  name='name'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.name}
                  value={values.name}
                />
                <FormInput
                  label='Apellido Paterno'
                  placeholder='Apellido Paterno...'
                  name='lastName'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.lastName}
                  value={values.lastName}
                />
                <FormInput
                  label='Apellido Materno'
                  placeholder='Apellido Materno...'
                  name='motherLastName'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.motherLastName}
                  value={values.motherLastName}
                />
                <FormInput
                  label='Email'
                  placeholder='Email...'
                  name='email'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.email}
                  value={values.email}
                />
                <FormInput
                  label='Telefono'
                  placeholder='Telefono...'
                  name='phone'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.phone}
                  value={values.phone}
                />
                <div className='form-group'>
                  <div style={{ display: 'none' }}>
                    <FormInput
                      label='Tipo de Usuario'
                      name='type'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.type}
                      value={values.type}
                    />
                  </div>
                  <div>{errors.type}</div>
                  <div
                    style={{
                      display: type === 'admin' ? true : 'none'
                    }}
                  >
                    <InputLabel
                      style={{
                        fontSize: 13,
                        fontWeight: 600,
                        color: '#717171',
                        paddingTop: 15
                      }}
                      htmlFor='plaza-label'
                    >
                      Plaza
                    </InputLabel>
                    <Select
                      name='city'
                      value={values.city}
                      onChange={handleChange}
                      style={{ width: '100%', fontSize: 13 }}
                    >
                      {Object.values(props.cities).map(city => {
                        return (
                          <MenuItem
                            key={city.key}
                            value={city.key}
                            style={{ fontSize: 13 }}
                          >
                            {city.name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                    <FormHelperText
                      style={{
                        fontSize: 13
                      }}
                    >
                      ¿Para qué plaza será este usuario?
                    </FormHelperText>
                  </div>
                </div>
              </div>
            )}
            <div className='modal-footer'>
              <div
                style={{
                  float: 'left'
                }}
              >
                <button
                  type='submit'
                  className='btn btn-info'
                  disabled={loading}
                >
                  Crear
                </button>
              </div>
            </div>
          </Form>
        )}
      />
    </Grid>
  )
}

export default AddRestaurantUser
