import axios from 'axios'
import getEndpoint from '../../helpers/getEndpoint'
const BASE_URL = getEndpoint('REACT_APP_ENDPOINT')
const body = {
  uid: 'YakhQyWVFGPD34rqD1I5rzxAFcK2',
  location: {
    formatted_address:
      '1a. de Las Palmas 17, Miguel Hidalgo I Etapa, 86103 Villahermosa, Tab., México',
    additional_information: '',
    coords: {
      id: 561,
      latitude: 17.9792991,
      longitude: -92.946483
    }
  },
  user: {
    type: 'user',
    phoneVerify: true,
    phone: '9933600021',
    name: 'Jona2',
    motherLastName: 'Hernandez',
    lastName: 'blanco',
    fcmToken:
      'cZX3LA1xWpo:APA91bHr8zRZAdb9ba_QGtehB7w171PIXfpTYd6fiN_Qyx3wWrBOQ9pjhOlZUUQ3Cjlhs6R6Xg-eob05KKD-xJbYkPRlg4A9xm7CXMe5d6CWUyIROpzUDNJt2TjEkecoK1THAE08un4t',
    email: 'zlatanjon@kondosoft.com',
    city: '-M7J2ddkl8uNazS6MUkl',
    isEmpty: false,
    isLoaded: true
  },
  currentRestaurant: {
    watchTutorial: true,
    riderService: false,
    photo:
      'https://com-kondosoft-imaginary.s3-us-west-1.amazonaws.com/YD/restaurants/-LMAgUilGqTBs9QXuvMl/1589827860054.jpg',
    email: 'jonathan@kondosoft.com',
    description: 'Comida economica',
    comissionPercentage: '18%',
    closeHour: '22:00',
    city: '-M7J2ddkl8uNazS6MUkl',
    watchNewTutorial: true,
    phone: '9931175435',
    openNotified: false,
    isOpen: true,
    schedule: {
      wednesday: {
        open: '10:00',
        close: '22:00'
      },
      tuesday: {
        open: '9:00',
        close: '22:00'
      },
      thursday: {
        open: '10:00',
        close: '22:00'
      },
      sunday: {
        open: 'Descanso',
        close: 'Descanso'
      },
      saturday: {
        open: '10:00',
        close: '22:00'
      },
      monday: {
        open: '10:00',
        close: '22:00'
      },
      friday: {
        open: '10:00',
        close: '22:00'
      }
    },
    address: {
      formatted:
        'Juan Estrada Torre local 5, Primero de Mayo, 86190 Villahermosa, Tab.',
      coords: {
        longitude: -92.9398812,
        latitude: 17.9769971
      }
    },
    active: true,
    acceptPaypal: true,
    acceptCash: true,
    position: '200',
    pendingClients: {
      JpKELoAC2PgwI3ZtWYKhNweG2mu1: {
        uid: 'JpKELoAC2PgwI3ZtWYKhNweG2mu1',
        phone: '9933914229',
        name: 'miriam',
        lastName: 'mansilla',
        fcmToken:
          'esRYfJLaiT0:APA91bE4LzfAVFVHSnVdS3EU3nE_xiksdK4Jc1Nimd39U22klEII1-UBKc2xvxqNGrAyWPYklXTtMIDuu4CEuyNKWIswRi2g21yss25FdAAMPhQNmDkn7IM9lylxVmkhCotOUIdZWVmA',
        email: 'miriammansillay2000@hotmail.com'
      },
      '81RwX2SNh3QUY6hzfjCkJw5JEOB3': {
        uid: '81RwX2SNh3QUY6hzfjCkJw5JEOB3',
        phone: '',
        name: 'Ovidio',
        lastName: 'Mendizábal Magaña',
        fcmToken:
          'c5B3FvJZVX8:APA91bEjctpYHwHJMy30kzYGxRLmwqYAtP13gRI_0crZ_FnGFvHmSzkI_KoYwABxgxpyGlOdDBwPeZ6_P-IyBI6eCW0sfwCbUSmCSnxhQLxnPiXpc2Nzo_1gNa7ExvAQql2hBCvw15ko',
        email: 'ovidioadolfo@gmail.com'
      }
    },
    partner: 'fp18y7bTeoeskbIDo1O9B5T4ivv2',
    openHour: '11:00',
    name: 'El Almuerzo - comercio para pruebas',
    closeNotified: false,
    categories: 'Pizzeria',
    banned: false,
    id: '-LMAgUilGqTBs9QXuvMl',
    foreign_key: '-LMAgUilGqTBs9QXuvMl'
  },
  payload: [
    {
      price: '100',
      photo:
        'https://com-kondosoft-imaginary.s3-us-west-1.amazonaws.com/YD/dishes/-LMF5AFa5gP0gZI6EASB.jpg',
      name: 'Comida Completa - Paquete',
      extras: {
        '-LykCaZLZHzgGSyQBtJP': {
          restaurantId: '-LMAgUilGqTBs9QXuvMl',
          needed: true,
          name: 'Un solo y gratis',
          minItem: '1',
          maxItem: '1',
          items: {
            '-LyjWWCr2RtUo_dAHiRn': true,
            '-LyjWSR1sYTzem-bT7-8': true,
            '-LyjWO6jei2jEpsBZ80F': true
          },
          id: '-LyjWHpdMDtKDywwNRPn'
        }
      },
      dishId: '-LMF5AFa5gP0gZI6EASB',
      description:
        '1 Pizza clasica de pepperoni + 1 Palitos de pan +1 Refresco de 2 lt (Sabor sujeto a disponibilidad)',
      active: true,
      key: '1',
      id: '1',
      extraKey: '1-00'
    },
    {
      itemId: '-LyjWSR1sYTzem-bT7-8',
      price: '0',
      name: 'Champinñon',
      description: '0',
      extraName: 'Un solo y gratis',
      key: 0,
      minItem: 1,
      extra: '1-00'
    }
  ],
  orderTotal: {
    deliveryCost: 40,
    subtotal: 100,
    total: 140
  },
  payType: 'cash',
  discount: false,
  pickUp: false,
  platform: 'web',
  isDevelop: true
}

export const createOrderTest = () => {
  axios.post(`${BASE_URL}/orders/payment`, body).then(response => {
    if (response.status === 200) {
      return response.data
    }
  })
}
