import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Profile } from '../widgets/Profile'
import { logout } from '../../containers/login/actions'
import { TextField, MenuItem } from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import { Row } from '../ui/Layout'
import { GetCities } from '../../containers/adminSettings/actions'
import { SelectCity } from './actions'
import Modal from '../Modal'

class Header extends Component {
  constructor (props) {
    super(props)
    const currentSelectCity = props.selectCity
    this.state = {
      dropdown: false,
      currentCity: currentSelectCity || props.city,
      openModal: false,
      content: null
    }
    this.onLogout = this.onLogout.bind(this)
    this.selectCity = this.selectCity.bind(this)
    this.contentModal = this.contentModal.bind(this)
  }

  toggleProfile = () => {
    this.setState({
      dropdown: !this.state.dropdown
    })
  }

  selectCity = e => {
    this.setState({ currentCity: e.target.value })
    setTimeout(() => {
      this.props.SelectCity(this.state.currentCity)
    }, 500)
  }

  render () {
    const { email, name, superUser, settings } = this.props
    return (
      <div className='lock-header subheader m-b-lg'>
        <div className='container full'>
          <div className='m-b-lg'>
            <div className='pull-left w50 m-t-xs'>
              <h3 className='m-b-none'>YUM Administrador</h3>
              <small className='text-muted'>{`Bienvenido ${name || ''}`}</small>
              <br />
              {email && <small className='text-muted'>{email}</small>}
            </div>
            <div
              className='pull-right w50 text-right m-t-xs'
              style={{ flexDirection: Row, display: 'flex' }}
            >
              <div className='pull-right w100 m-t-xs'>
                {superUser ? (
                  <div className='pull-left w50 m-t-lg'>
                    <TextField
                      id='select'
                      value={this.state.currentCity}
                      select
                      onChange={this.selectCity}
                    >
                      {Object.values(settings.cities).map(city => {
                        return (
                          <MenuItem
                            key={city.key}
                            value={city.key}
                            style={{ fontSize: 15 }}
                          >
                            {city.name}
                          </MenuItem>
                        )
                      })}
                    </TextField>
                  </div>
                ) : (
                  <div className='pull-left w50 m-t-lg' />
                )}
                <div className='pull-left w50 m-t-xs'>
                  <div className='pull-left w100'>
                    <div className='pull-left w50 m-t-md'>
                      <IconButton
                        variant='contained'
                        onClick={this.contentModal}
                      >
                        <Icon fontSize={'large'} title={'Changelog'}>
                          live_help_icon
                        </Icon>
                      </IconButton>
                    </div>
                    <div className='pull-left w50 m-t-md'>
                      <ul className='nav nav-user'>
                        <Profile
                          toggle={this.toggleProfile}
                          open={this.state.dropdown}
                          onLogout={this.onLogout}
                          profile={{}}
                        />
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          title='Changelog'
          onCancel={() => {
            this.setState({
              openModal: false,
              content: null
            })
          }}
          show={this.state.openModal}
          content={this.state.content}
        />
      </div>
    )
  }
  contentModal () {
    const content = (
      <div>
        <div className='w100 m-l-md'>
          <h3>Versión</h3>
          <span>[1.1.1] - 30-11-2020</span>
        </div>
        <div className='w100 m-l-md m-b-md'>
          <h3>Agregado</h3>
          <ul>
            <li>Se agrega nuevo diseño de perfil de usuarios.</li>
            <li>Se agregan reportes de ventas total('/reports').</li>
          </ul>
        </div>
        <div className='w100 m-l-md m-b-md'>
          <h3>Modificado</h3>
          <ul>
            <li>
              Se corrige reporte exportado de restaurantes que mostraban
              descuentos erroneos.
            </li>
            <li>Se corrige problema para crear cupones sencillos.</li>
            <li>Se corrige fecha de vencimiento de cupones.</li>
          </ul>
        </div>
      </div>
    )
    this.setState({
      openModal: 'show',
      content
    })
  }
  onLogout () {
    this.props.logout()
    this.props.history.replace('/login')
  }
}

const mapStateToProps = ({
  data: { settings },
  profile: { city, superUser, selectCity, name, lastName, email }
}) => ({
  settings,
  city,
  superUser,
  selectCity,
  name: `${name || ''} ${lastName || ''}`,
  email
})

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
  GetCities: cb => dispatch(GetCities(cb, false)),
  SelectCity: selectCity => dispatch(SelectCity(selectCity))
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
