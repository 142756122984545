import Swal from 'sweetalert2'
import fb from '../../firebase'
import _ from 'lodash'
const citiesDB = fb.database().ref('cities')

export const GetCities = (_setCities, loader = false) => {
  var msg = 'Cargando ciudades'
  loader && activeLoading(msg)
  return dispatch => {
    citiesDB
      .once('value', snp => {
        snp.forEach(element => {
          let key = element.key
          let item = element.val()
          let citiesArray = {}
          citiesArray['name'] = item.name
          citiesArray['key'] = key
          citiesArray['geofence'] = item.geofences
            ? Object.keys(item.geofences)[0]
            : false
          dispatch({ type: 'SET_CITIES', payload: citiesArray })
        })
      })
      .then(() => {
        _setCities && _setCities()
        Swal.close()
      })
      .catch(err => {
        Swal.close()
      })
  }
}

export const GetSetting = id => {
  activeLoading()
  return dispatch => {
    dispatch({ type: 'CLEAN_SETTINGS' })
    citiesDB
      .child(id)
      .once('value', snp => {
        snp.forEach(element => {
          let settings = {}
          let riderSettings = {}

          if (element.key === 'settings') {
            settings[id] = element.val()
          } else if (element.key === 'riders') {
            riderSettings[id] = element.val()
            dispatch({ type: 'SET_RIDERS_SETTINGS', payload: riderSettings })
          } else {
            settings = null
          }
          dispatch({ type: 'SET_SETTINGS', payload: settings })
        })
      })
      .then(() => {
        Swal.close()
      })
      .catch(err => {
        Swal.close()
      })
  }
}

const activeLoading = (msg = false) => {
  Swal.fire({
    position: 'center',
    title: msg || 'Tu petición se esta cargando',
    showConfirmButton: false,
    allowOutsideClick: () => !Swal.isLoading(),
    onBeforeOpen: () => {
      Swal.showLoading()
    }
  })
}

export const UpdateActiveEvent = data => {
  const { city, event, currentActiveEvent } = data
  return dispatch => {
    let updates = {}
    updates[`/${city}/settings/events/${event.id}/isActive`] = !event.isActive
    !_.isEmpty(currentActiveEvent) &&
      (updates[
        `/${city}/settings/events/${currentActiveEvent}/isActive`
      ] = false)
    citiesDB
      .update(updates)
      .then(() =>
        Swal.fire({
          position: 'center',
          type: 'success',
          title: `El evento se ha actualizado correctamente`,
          showConfirmButton: false,
          timer: 1500
        })
      )
      .catch(error => {
        Swal.fire({
          position: 'center',
          type: 'error',
          title: 'Ha ocurrido un error al actualizar el evento',
          text: `Error: ${error}`,
          showConfirmButton: false,
          timer: 1500
        })
      })
  }
}
