import styled from 'styled-components'

export const WrapContainer = styled.div`
  padding: 0;
  ${({ haveTop }) =>
    haveTop &&
    `
        position: 'relative';
        top: -30px;
    `};
`
export const Container = styled.div`
  display: flex;
  ${({ haveWidth }) => haveWidth && `width: ${haveWidth}`};
  ${({ isRow }) =>
    isRow &&
    `
    flex-direction: row;
    justify-content: space-between;
  `};
`
export const Text = styled.p`
  display: flex;
  font-size: ${({ size }) => size || '12px'};
  ${({ align }) => align && 'align-items: center'};
  ${({ haveBold }) => haveBold && `font-weight: ${haveBold}`};
  ${({ info }) => info && `margin: 0`};
  ${({ havePadding }) => havePadding && `padding: ${havePadding}`};
  ${({ color }) => color && `color: ${color}`};
`
export const Element = styled.div`
  display: flex;
  flex: ${({ haveFlex }) => haveFlex || 1};
  ${({ background }) => background && `background: ${background}`};
  ${({ havePadding }) => havePadding && `padding: ${havePadding}`};
  ${({ isColumn }) =>
    isColumn &&
    `
    flex-direction: column;
    justify-content: space-between;
  `};
`
