import React, { Component } from 'react'
import { Button, Panel } from '../../components/ui'
import { Row, Col, Page } from '../../components/ui/Layout'
import Input from '../../components/ui/Input'
import { connect } from 'react-redux'
import { login } from './actions'

class Login extends Component {
  constructor (props) {
    super(props)
    this.state = {
      email: null,
      password: null,
      rememberMe: null
    }
  }

  render () {
    const { login, history: { replace }, profile: { type } } = this.props

    return (
      <Page>
        <Row>
          <Col size={12}>
            <Panel title='Bienvenido a YUM'>
              <form
                onSubmit={e => {
                  e.preventDefault()
                  login(this.state)
                }}
              >
                <div className='form-group'>
                  <label>Email</label>
                  <Input
                    onChange={e => this.changeInput('email', e.target.value)}
                    icon='fa fa-user'
                    format='email'
                    errorMessage='please verify your email.'
                    placeholder='email'
                  />
                </div>
                <div className='form-group'>
                  <label>Password</label>
                  <Input
                    onChange={e => this.changeInput('password', e.target.value)}
                    icon='fa fa-key'
                    required={true}
                    placeholder='password'
                    errorMessage='password is required'
                    format='password'
                  />
                </div>
                <div className='checkbox'>
                  <label>
                    {' '}
                    <input
                      onChange={e =>
                        this.changeInput('rememberMe', e.target.value)
                      }
                      type='checkbox'
                    />{' '}
                    Remember me{' '}
                  </label>
                </div>
                <Button type='submit' label='Entrar' color='btn-warning' />
              </form>
            </Panel>
          </Col>
        </Row>
      </Page>
    )
  }

  changeInput = (input, value) => {
    const newState = this.state
    newState[input] = value
    this.setState(newState)
  }
}

const mapStateToProps = ({ profile }) => ({ profile })

const mapDispatchToProps = dispatch => ({
  login: user => dispatch(login(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
