import _ from 'lodash'
import fb from '../firebase'
import Swal from 'sweetalert2'

const extrasListDB = fb.database().ref('extrasList')
const extrasItemsDB = fb.database().ref('extraItems')
const dishesDB = fb.database()

export const bindExtrasList = rId => {
  return dispatch => {
    extrasListDB
      .orderByChild('restaurantId')
      .equalTo(rId)
      .on('value', snapshot => {
        const payload = snapshot.val()
        dispatch({
          type: 'SET_EXTRAS_LIST',
          payload
        })
      })
  }
}

export const removeListFromDish = (listId, dishId, isLast) => dispatch => {
  if (window.confirm('Esta seguro?')) {
    if (isLast) {
      dishesDB.ref(`dishes/${dishId}/extras`).set(false)
      return
    }
    dishesDB.ref(`dishes/${dishId}/extras/${listId}`).remove()
  }
}

export const addListToDish = (listId, dishId, extrasList, cb) => {
  const newList = {
    ...extrasList[listId],
    id: listId
  }
  let itemsAry = []
  if (!_.isEmpty(newList.items)) {
    itemsAry = Object.keys(newList.items)
    itemsAry.forEach(item => {
      newList.items[item] = true
    })
  }
  dishesDB.ref(`dishes/${dishId}/extras/`).push(newList, error => {
    if (error === null) {
      cb('')
      return Swal.fire({
        position: 'center',
        type: 'success',
        title: '¡La lista de extras se agregó correctamente!',
        showConfirmButton: false,
        timer: 2500
      })
    }
    return Swal.fire({
      position: 'center',
      type: 'error',
      title: error.message
        ? `${error.message}`
        : `Error al intentar agregar la lista de extras`,
      showConfirmButton: false,
      timer: 2500
    })
  })
}

export const deleteExtraItem = (item, list) => dispatch => {
  if (window.confirm('Esta acción no se podra deshacer...')) {
    extrasListDB.child(`${list}/items/${item}`).remove()
    extrasItemsDB.child(`${item}`).remove()
  }
}

export const deleteListItem = (list, items) => (dispatch, getState) => {
  if (window.confirm('Esta acción no se podra deshacer...')) {
    extrasListDB.child(`${list}`).remove()
    if (items) {
      _.keysIn(items).forEach(item => extrasItemsDB.child(`${item}`).remove())
    }
  }
}

export const addExtraList = (payload, CB) => dispatch => {
  extrasListDB
    .push(payload)
    .then(res => CB())
    .catch(err => {})
}

export const updateItem = (index, data) => (dispatch, getState) => {
  extrasItemsDB
    .child(index)
    .update({ ...data })
    .catch(err =>
      Swal.fire({
        position: 'center',
        type: 'error',
        title: `${err.message}`,
        showConfirmButton: false,
        timer: 2500
      })
    )
}

export const updateExtraLabel = (index, data) => dispatch => {
  const { name } = data
  var updates = {}
  updates[`${index}/name`] = name
  extrasListDB
    .update(updates)
    .then(() => {
      Swal.fire({
        position: 'center',
        type: 'success',
        title: '¡Los datos han sido actualizados correctamente!',
        showConfirmButton: false,
        timer: 2500
      })
    })
    .catch(err =>
      Swal.fire({
        position: 'center',
        type: 'error',
        title: `${err.message}`,
        showConfirmButton: false,
        timer: 2500
      })
    )
}

export const addExtraItem = (payload, CB) => dispatch => {
  const { items, listId } = payload
  extrasItemsDB
    .push({ ...items })
    .then(res => {
      const item = {}
      item[res.key] = items.name || true
      extrasListDB
        .child(listId)
        .child('items')
        .update(item)
        .then(res => CB())
        .catch(err =>
          Swal.fire({
            position: 'center',
            type: 'error',
            title: `${err.message}`,
            showConfirmButton: false,
            timer: 2500
          })
        )
    })
    .catch(err =>
      Swal.fire({
        position: 'center',
        type: 'error',
        title: `${err.message}`,
        showConfirmButton: false,
        timer: 2500
      })
    )
}
