import React from 'react'
import FormInput from '../../components/FormInput'
import { Formik, Form } from 'formik'
import { Row, Col } from '../../components/ui/Layout'
import { Button, Panel } from '../../components/ui'
import * as Yup from 'yup'

const CitySchema = Yup.object().shape({
  name: Yup.string().required('Campo requerido'),
  baseCost: Yup.number().required('Campo requerido'),
  costKm: Yup.number().required('Campo requerido'),
  costKmLimit: Yup.number().required('Campo requerido'),
  distanceMeter: Yup.number().required('Campo requerido'),
  distanceMeterLimit: Yup.number().required('Campo requerido')
})

const FormSettings = props => {
  const initialValues = props.initialValues
    ? props.initialValues
    : {
      name: '',
      baseCost: '',
      costKm: '',
      costKmLimit: '',
      distanceMeter: '',
      distanceMeterLimit: '',
      schedule: {
        monday: {
          open: '8:00',
          close: '23:00'
        },
        tuesday: {
          open: '8:00',
          close: '23:00'
        },
        wednesday: {
          open: '8:00',
          close: '23:00'
        },
        thursday: {
          open: '8:00',
          close: '23:00'
        },
        friday: {
          open: '8:00',
          close: '23:00'
        },
        saturday: {
          open: '8:00',
          close: '23:00'
        },
        sunday: {
          open: '8:00',
          close: '23:00'
        }
      }
    }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        const { resetForm } = actions
        if (props.edit) {
          props.onEdit(values)
          return
        }
        props.onSave(values, resetForm)
      }}
      validationSchema={CitySchema}
      enableReinitialize
      render={({ values, errors, handleBlur, handleChange, loading }) => (
        <Form>
          <div className='modal-body'>
            <Row>
              <Col size={4}>
                <FormInput
                  placeholder='Ciudad'
                  name='name'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.name}
                  value={values.name}
                  type='text'
                  label='Ciudad'
                />
                <FormInput
                  placeholder='Costo Base'
                  name='baseCost'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.baseCost}
                  value={values.baseCost}
                  type='number'
                  label='Costo Base'
                />
                <FormInput
                  placeholder='Costo por kilometro'
                  name='costKm'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.costKm}
                  value={values.costKm}
                  type='number'
                  label='Costo por kilometro'
                />
                <FormInput
                  placeholder='Kilometro limite'
                  name='costKmLimit'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.costKmLimit}
                  value={values.costKmLimit}
                  type='number'
                  label='Kilometro limite'
                />
                <FormInput
                  placeholder='Distancia base en mts'
                  name='distanceMeter'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.distanceMeter}
                  value={values.distanceMeter}
                  type='number'
                  label='Distancia base en mts'
                />
                <FormInput
                  placeholder='Distancia limite en mts'
                  name='distanceMeterLimit'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.distanceMeterLimit}
                  value={values.distanceMeterLimit}
                  type='number'
                  label='Distancia limite en mts'
                />
              </Col>
            </Row>
            <Row>
              <Col size={12}>
                <label>Apertura: </label>
              </Col>
            </Row>
            <Row>
              <Panel>
                <Col size={12}>
                  <Row>
                    <Col size={2}>
                      <FormInput
                        placeholder='8:00'
                        name='schedule.monday.open'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.schedule.monday.open}
                        type='text'
                        label='Lunes'
                      />
                    </Col>
                    <Col size={2}>
                      <FormInput
                        placeholder='8:00'
                        name='schedule.tuesday.open'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.schedule.tuesday.open}
                        type='text'
                        label='Martes'
                      />
                    </Col>
                    <Col size={2}>
                      <FormInput
                        placeholder='8:00'
                        name='schedule.wednesday.open'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.schedule.wednesday.open}
                        type='text'
                        label='Miercoles'
                      />
                    </Col>
                    <Col size={2}>
                      <FormInput
                        placeholder='8:00'
                        name='schedule.thursday.open'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.schedule.thursday.open}
                        type='text'
                        label='Jueves'
                      />
                    </Col>
                    <Col size={2}>
                      <FormInput
                        placeholder='8:00'
                        name='schedule.friday.open'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.schedule.friday.open}
                        type='text'
                        label='Viernes'
                      />
                    </Col>
                    <Col size={2}>
                      <FormInput
                        placeholder='8:00'
                        name='schedule.saturday.open'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={
                          values.schedule.saturday &&
                          values.schedule.saturday.open
                        }
                        type='text'
                        label='Sabado'
                      />
                    </Col>
                    <Col size={2}>
                      <FormInput
                        placeholder='8:00'
                        name='schedule.sunday.open'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={
                          values.schedule.sunday && values.schedule.sunday.open
                        }
                        type='text'
                        label='Domingo'
                      />
                    </Col>
                  </Row>
                </Col>
              </Panel>
            </Row>
            <Row>
              <Col size={12}>
                <label>Cierre: </label>
              </Col>
            </Row>
            <Row>
              <Panel>
                <Col size={12}>
                  <Row>
                    <Col size={2}>
                      <FormInput
                        placeholder='8:00'
                        name='schedule.monday.close'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.schedule.monday.close}
                        type='text'
                        label='Lunes'
                      />
                    </Col>
                    <Col size={2}>
                      <FormInput
                        placeholder='8:00'
                        name='schedule.tuesday.close'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.schedule.tuesday.close}
                        type='text'
                        label='Martes'
                      />
                    </Col>
                    <Col size={2}>
                      <FormInput
                        placeholder='8:00'
                        name='schedule.wednesday.close'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.schedule.wednesday.close}
                        type='text'
                        label='Miercoles'
                      />
                    </Col>
                    <Col size={2}>
                      <FormInput
                        placeholder='8:00'
                        name='schedule.thursday.close'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.schedule.thursday.close}
                        type='text'
                        label='Jueves'
                      />
                    </Col>
                    <Col size={2}>
                      <FormInput
                        placeholder='8:00'
                        name='schedule.friday.close'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.schedule.friday.close}
                        type='text'
                        label='Viernes'
                      />
                    </Col>
                    <Col size={2}>
                      <FormInput
                        placeholder='8:00'
                        name='schedule.saturday.close'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={
                          values.schedule.saturday &&
                          values.schedule.saturday.close
                        }
                        type='text'
                        label='Sabado'
                      />
                    </Col>
                    <Col size={2}>
                      <FormInput
                        placeholder='8:00'
                        name='schedule.sunday.close'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={
                          values.schedule.sunday && values.schedule.sunday.close
                        }
                        type='text'
                        label='Domingo'
                      />
                    </Col>
                  </Row>
                </Col>
              </Panel>
            </Row>
            <div className='modal-footer'>
              <Button
                label='Editar'
                type='submit'
                color='btn-info'
                disabled={loading}
              />
            </div>
          </div>
        </Form>
      )}
    />
  )
}
export default FormSettings
