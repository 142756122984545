import React from 'react'
import { months } from '../../helpers/date'
import moment from 'moment'
moment.locale('es')

const DatePickerHeader = ({ date, decreaseMonth, increaseMonth }) => {
  return (
    <div
      style={{
        margin: 10,
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <div
        style={{
          marginRight: 10
        }}
      >
        <button type='button' onClick={decreaseMonth}>
          {'<'}
        </button>
      </div>
      <strong>{`${months[moment(date).month()]} ${moment(
        date
      ).year()}`}</strong>
      <div
        style={{
          marginLeft: 10
        }}
      >
        <button type='button' onClick={increaseMonth}>
          {'>'}
        </button>
      </div>
    </div>
  )
}

export default DatePickerHeader
