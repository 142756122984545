export const addPhoto = payload => {
  return {
    type: 'ADD_PHOTO',
    payload
  }
}
export const clearPhoto = () => {
  return {
    type: 'CLEAR_PHOTO'
  }
}
