import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

// Actions
import { editUser } from '../users/actions'

// Components
import Element from '../../components/element'
import UserInfo from '../../components/userInfo'
import OrderPlaced from '../../components/orderPlaced'
import Coupons from '../../components/coupons'

class Profile extends Component {
  constructor (props) {
    super(props)
    this.state = {
      adminProps: {
        superUser: this.props.superUser,
        adminCity: this.props.city
      }
    }
  }

  render () {
    const data = this.props.location.state.props
    return (
      <Wrapper>
        <Element
          back
          bRight
          label={'Datos personales'}
          component={
            <UserInfo
              data={data}
              adminProps={this.state.adminProps}
              cities={this.props.settings.cities}
              onEdit={this._onEdit}
            />
          }
          flex={1.2}
        />
        <Element
          label={'Ordenes realizadas'}
          bRight
          component={<OrderPlaced build />}
          flex={1.3}
          reduce
        />
        <Element
          back
          label={'Cupones redimidos'}
          component={<Coupons userID={data.foreignKey} />}
          reduce
        />
      </Wrapper>
    )
  }

  _onEdit = user => {
    this.props.editUser(user, this.props.history)
  }
}

const mapStateToProps = ({
  data: { settings },
  profile: { city, superUser }
}) => ({
  city,
  superUser,
  settings
})

const mapDispatchToProps = dispatch => ({
  editUser: (user, history) => dispatch(editUser(user, history))
})

export default connect(mapStateToProps, mapDispatchToProps)(Profile)

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  margin: 10px;
`
