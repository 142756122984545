import React from 'react'
import { Formik, Form, Field, FieldArray } from 'formik'
import { renderField } from '../../components/ui/Input'
import { Button } from '../../components/ui'
import { UploadPhoto } from '../../components/upload'
import '../../index.css'
import * as Yup from 'yup'
import Switch from '../../components/ui/Switch.js'
import {
  Label,
  FormLayout,
  FormWrap,
  FormImage,
  FormElement,
  FormSwitchs,
  SwitchGroup,
  SwitchElement,
  TimeGroup,
  ScheduleElement,
  ScheduleRow
} from './styled'
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-google-places-autocomplete'
import _ from 'lodash'
import Swal from 'sweetalert2'

const phoneRegExp = /^(\(?\+?[0-9]*\)?)?[0-9_\-()]*$/

const Validation = Yup.object().shape({
  name: Yup.string().required('Campo requerido'),
  description: Yup.string().required('Campo requerido'),
  addressFormated: Yup.string(),
  email: Yup.string().email('Invalid email'),
  short_description: Yup.string(),
  phone: Yup.string().matches(phoneRegExp, 'Formato no valido')
})

const days = [
  {
    key: 'monday',
    name: 'Lunes'
  },
  {
    key: 'tuesday',
    name: 'Martes'
  },
  {
    key: 'wednesday',
    name: 'Miercoles'
  },
  {
    key: 'thursday',
    name: 'Jueves'
  },
  {
    key: 'friday',
    name: 'Viernes'
  },
  {
    key: 'saturday',
    name: 'Sabado'
  },
  {
    key: 'sunday',
    name: 'Domingo'
  }
]

let RestaurantForm = ({
  handleSubmit,
  isLoading,
  initialValues,
  typeUser,
  restId
}) => {
  var hours = [
    { id: 0, name: '08:00' },
    { id: 1, name: '08:30' },
    { id: 2, name: '09:00' },
    { id: 3, name: '09:30' },
    { id: 4, name: '10:00' },
    { id: 5, name: '10:30' },
    { id: 6, name: '11:00' },
    { id: 7, name: '11:30' },
    { id: 8, name: '12:00' },
    { id: 9, name: '12:30' },
    { id: 10, name: '13:00' },
    { id: 11, name: '13:30' },
    { id: 12, name: '14:00' },
    { id: 13, name: '14:30' },
    { id: 14, name: '15:00' },
    { id: 15, name: '15:30' },
    { id: 16, name: '16:00' },
    { id: 17, name: '16:30' },
    { id: 18, name: '17:00' },
    { id: 19, name: '17:30' },
    { id: 20, name: '18:00' },
    { id: 21, name: '18:30' },
    { id: 22, name: '19:00' },
    { id: 23, name: '19:30' },
    { id: 24, name: '20:00' },
    { id: 25, name: '20:30' },
    { id: 26, name: '21:00' },
    { id: 27, name: '21:30' },
    { id: 28, name: '22:00' },
    { id: 29, name: '22:30' },
    { id: 30, name: '23:00' },
    { id: 31, name: '23:30' },
    { id: 32, name: 'Descanso' }
  ]
  const { address } = initialValues
  const formattedValues = { ...initialValues }
  if (typeof address === 'string') {
    formattedValues['address'] = {
      formatted: address
    }
  }

  function validateSelects (value) {
    if (_.isEmpty(value)) {
      return Swal.fire({
        position: 'center',
        type: 'error',
        title:
          '¡Todos los campos de horarios son requeridos, seleccione una Hora o Descanso!',
        showConfirmButton: false,
        timer: 3000
      })
    }
  }

  return (
    <FormLayout>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleSubmit}
        initialValues={{
          comissionPercentage: '18%',
          acceptPaypal: false,
          acceptCash: true,
          accept_credit_card: false,
          banned: false,
          ...formattedValues
        }}
        validationSchema={Validation}
        enableReinitialize
        render={({
          handleSubmit,
          setFieldValue,
          handleChange,
          handleBlur,
          values,
          errors,
          ...props
        }) => (
          <FormWrap>
            <Form
              onSubmit={handleSubmit}
              style={{ display: 'flex', width: '100%' }}
            >
              <FormElement>
                <div style={{ backgroundColor: '#ea5a5a' }}>
                  <Label>Datos Generales</Label>
                </div>
                <FormImage>
                  <UploadPhoto
                    folder={`restaurants/${restId}/`}
                    width='300'
                    height='300'
                    form='restaurant'
                    margin='0px 0px 20px 0px'
                    onUpload={e => {
                      setFieldValue('photo', e)
                    }}
                  >
                    <div style={{ fontSize: '3em' }}>
                      <i className='fas fa-cloud-upload-alt' />
                    </div>
                  </UploadPhoto>
                </FormImage>
                <Field
                  name='name'
                  component={renderField}
                  type='text'
                  label='Nombre del restaurante'
                  error={errors.name || ''}
                  value={(values && values.name) || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Field
                  name='description'
                  component={renderField}
                  type='text'
                  label='Descripción'
                  error={errors.description || ''}
                  value={(values && values.description) || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Field
                  name='address.formatted'
                  component={renderField}
                  type='text'
                  label='Dirección'
                  error={(errors.address && errors.address.formatted) || ''}
                  value={
                    (values && values.address && values.address.formatted) || ''
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Field
                  name='email'
                  component={renderField}
                  type='email'
                  label='Email'
                  error={errors.email || ''}
                  value={(values && values.email) || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Field
                  name='phone'
                  component={renderField}
                  type='text'
                  label='Telefono'
                  required
                  error={errors.phone || ''}
                  value={(values && values.phone) || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Field
                  name='short_description'
                  component={renderField}
                  type='text'
                  label='Descripcion corta'
                  error={errors.short_description || ''}
                  value={(values && values.short_description) || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {typeUser === 'admin' && (
                  <div>
                    <Field
                      name='comissionPercentage'
                      component={renderField}
                      type='text'
                      label='Porcentaje de comisión'
                      error={errors.comissionPercentage || ''}
                      value={(values && values.comissionPercentage) || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormSwitchs>
                      <SwitchGroup>
                        <SwitchElement>
                          <label>Acepta efectivo</label>
                          <Switch
                            on={values.acceptCash || false}
                            onClick={() =>
                              setFieldValue('acceptCash', !values.acceptCash)
                            }
                          />
                        </SwitchElement>
                        <SwitchElement>
                          <label>Acepta paypal</label>
                          <Switch
                            on={values.acceptPaypal || false}
                            onClick={() =>
                              setFieldValue(
                                'acceptPaypal',
                                !values.acceptPaypal
                              )
                            }
                          />
                        </SwitchElement>
                      </SwitchGroup>
                      <SwitchGroup>
                        <SwitchElement>
                          <label>Acepta tarjeta</label>
                          <Switch
                            on={values.accept_credit_card || false}
                            onClick={() =>
                              setFieldValue(
                                'accept_credit_card',
                                !values.accept_credit_card
                              )
                            }
                          />
                        </SwitchElement>
                        <SwitchElement>
                          <label>Baneado</label>
                          <Switch
                            on={values.banned || false}
                            onClick={() =>
                              setFieldValue('banned', !values.banned)
                            }
                          />
                        </SwitchElement>
                      </SwitchGroup>
                    </FormSwitchs>
                  </div>
                )}
              </FormElement>

              <FormElement bRight>
                <div style={{ backgroundColor: '#ea5a5a' }}>
                  <Label>Horarios</Label>
                </div>
                <div style={{ paddingTop: 15, paddingBottom: 15 }}>
                  <TimeGroup>
                    <ScheduleElement>
                      <Label fSize='1.8em'>Día</Label>
                    </ScheduleElement>
                    <ScheduleElement>
                      <Label fSize='1.8em'>Apertura</Label>
                    </ScheduleElement>
                    <ScheduleElement>
                      <Label fSize='1.8em'>Cierre</Label>
                    </ScheduleElement>
                  </TimeGroup>
                </div>
                {days.map(item => {
                  return (
                    <TimeGroup>
                      <ScheduleElement>
                        <Label fSize='1.5em'>{item.name}</Label>
                      </ScheduleElement>
                      {
                        <FieldArray
                          name='schedule'
                          render={() => (
                            <div>
                              {values &&
                                values.schedule &&
                                values.schedule.map((itemSchedule, index) => {
                                  if (itemSchedule.day === item.key) {
                                    if (!itemSchedule.day_off) {
                                      return (
                                        <ScheduleRow key={index}>
                                          <ScheduleElement>
                                            <Field
                                              component='select'
                                              name={`schedule.${index}.open`}
                                              className='btn btn-default col-md-12'
                                              validate={validateSelects}
                                            >
                                              {hours.map(hour => {
                                                return (
                                                  <option
                                                    key={hour.id}
                                                    value={hour.name}
                                                    className=''
                                                  >
                                                    {hour.name}
                                                  </option>
                                                )
                                              })}
                                            </Field>
                                          </ScheduleElement>
                                          <ScheduleElement>
                                            <Field
                                              component='select'
                                              name={`schedule[${index}].close`}
                                              className='btn btn-default col-md-12'
                                              validate={validateSelects}
                                            >
                                              {hours.map(hour => {
                                                return (
                                                  <option
                                                    key={hour.id}
                                                    value={hour.name}
                                                    className=''
                                                  >
                                                    {hour.name}
                                                  </option>
                                                )
                                              })}
                                            </Field>
                                          </ScheduleElement>
                                        </ScheduleRow>
                                      )
                                    } else {
                                      return (
                                        <ScheduleRow key={index}>
                                          <ScheduleElement>
                                            <Field
                                              component='select'
                                              name={`schedule.${index}.open`}
                                              className='btn btn-default col-md-12'
                                              validate={validateSelects}
                                            >
                                              {hours.map(hour => {
                                                return (
                                                  <option
                                                    key={hour.id}
                                                    value={hour.name}
                                                    className=''
                                                  >
                                                    {hour.name}
                                                  </option>
                                                )
                                              })}
                                            </Field>
                                          </ScheduleElement>
                                          <ScheduleElement>
                                            <Field
                                              component='select'
                                              name={`schedule.${index}.close`}
                                              className='btn btn-default col-md-12'
                                              validate={validateSelects}
                                            >
                                              {hours.map(hour => {
                                                return (
                                                  <option
                                                    key={hour.id}
                                                    value={hour.name}
                                                    className=''
                                                  >
                                                    {hour.name}
                                                  </option>
                                                )
                                              })}
                                            </Field>
                                          </ScheduleElement>
                                        </ScheduleRow>
                                      )
                                    }
                                  }
                                })}
                            </div>
                          )}
                        />
                      }
                    </TimeGroup>
                  )
                })}
                <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                  <div style={{ backgroundColor: '#ea5a5a' }}>
                    <Label>Ubicación</Label>
                  </div>
                </div>
                <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                  <GooglePlacesAutocomplete
                    placeholder={''}
                    initialValue={
                      (values && values.address && values.address.formatted) ||
                      ''
                    }
                    renderInput={props => {
                      return (
                        <Field
                          name='address.formatted'
                          component={renderField}
                          type='text'
                          label='Ubicación en google maps'
                          required
                          error={
                            (errors.address && errors.address.formatted) || ''
                          }
                          onChange={handleChange}
                          onBlur={e =>
                            setFieldValue('address.formatted', e.target.value)
                          }
                          {...props}
                        />
                      )
                    }}
                    onSelect={e => {
                      geocodeByAddress(e.description)
                        .then(results => getLatLng(results[0]))
                        .then(({ lat, lng }) => {
                          setFieldValue('address.coords.latitude', lat)
                          setFieldValue('address.coords.longitude', lng)
                        })
                      setFieldValue('address.formatted', e.description)
                    }}
                  />
                  <Field
                    name='address.coords.latitude'
                    component={renderField}
                    type='text'
                    label='latitud'
                    required
                    error={
                      (errors.address &&
                        errors.address.coords &&
                        errors.address.coords.latitude) ||
                      ''
                    }
                    value={
                      (values &&
                        values.address &&
                        values.address.coords &&
                        values.address.coords.latitude) ||
                      ''
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Field
                    name='address.coords.longitude'
                    component={renderField}
                    type='text'
                    label='Longitud'
                    required
                    error={
                      (errors.address &&
                        errors.address.coords &&
                        errors.address.coords.longitude) ||
                      ''
                    }
                    value={
                      (values &&
                        values.address &&
                        values.address.coords &&
                        values.address.coords.longitude) ||
                      ''
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>

                <Label fSize='1em'>
                  Favor de verificar sus datos antes de guardar la información*
                </Label>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    label={isLoading ? 'Guardando...' : 'Guardar'}
                    color='btn-info'
                    type='submit'
                    disabled={isLoading}
                  />
                </div>
              </FormElement>
            </Form>
          </FormWrap>
        )}
      />
    </FormLayout>
  )
}

export default RestaurantForm
