export const startLoading = payload => ({
  type: 'START_LOADING',
  payload
})

export const stopLoading = payload => ({
  type: 'STOP_LOADING',
  payload
})

export const cleanError = payload => ({
  type: 'CLEAN_ERROR',
  payload
})
