import React, { Component } from 'react'
import { connect } from 'react-redux'
import fb from '../../firebase'
import FormSettings from './settingsForm'
import FormRidersSettings from './settingsRidersForm'
import { GetCities, GetSetting, UpdateActiveEvent } from './actions'
import { Row, Col, Page } from '../../components/ui/Layout'
import { Panel } from '../../components/ui/'
import Modal from '../../components/Modal'
import _ from 'lodash'
import Swal from 'sweetalert2'
import {
  Button,
  Icon,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Switch,
  FormControlLabel
} from '@material-ui/core'
import { WrapContainer, Container, Text, Element } from './styled'

const citiesDB = fb.database().ref('cities')

class Settings extends Component {
  constructor (props) {
    super(props)
    this.state = {
      cities: {},
      showForm: false,
      value: false,
      modal: {
        show: '',
        content: `<div></div>`
      },
      cityName: '',
      events: '',
      citySelected: '',
      typeEvent: '',
      activeEvent: ''
    }
    this._createCityModal = this._createCityModal.bind(this)
  }
  UNSAFE_componentWillMount () {
    this.props.GetCities(this._setCities)
  }

  UNSAFE_componentWillReceiveProps () {
    let city = this.state.citySelected

    const eventsDB =
      !_.isEmpty(city) && fb.database().ref(`cities/${city}/settings/events`)

    !_.isEmpty(eventsDB) &&
      eventsDB.on('value', snp => {
        let events = snp.val()
        !_.isEmpty(events) &&
          Object.keys(events).map(id => {
            events[id].id = id
            return events[id]
          })

        let activeEvent

        let listEvents = events
        !_.isEmpty(listEvents) &&
          (activeEvent = Object.values(listEvents).find(
            event => event.isActive === true
          ))

        this.setState({
          events: events,
          activeEvent: activeEvent
        })
      })
  }

  _setCities = () => {
    const { cities, profile } = this.props
    this.setState(
      {
        cities: { ...this.state.cities, ...cities }
      },
      () => {
        profile &&
          profile.city &&
          !profile.superUser &&
          this._setCity(profile.city)
      }
    )
  }

  _setCity = value => {
    this.props.GetSetting(value)
    if (value !== '0') {
      let data =
        !_.isEmpty(this.props.settings[value]) &&
        this.props.settings[value].events
      let listEvents = !_.isEmpty(data)
        ? Object.keys(data).map(id => {
          data[id].id = id
          return data[id]
        })
        : []

      this.setState({
        showForm: true,
        value,
        cityName: !_.isEmpty(this.state.cities)
          ? this.state.cities[value].name
          : '',
        events: listEvents,
        citySelected:
          !_.isEmpty(this.state.cities) && this.state.cities[value].key,
        typeEvent: '',
        activeEvent: ''
      })
    } else {
      this.setState({
        showForm: false,
        value: false,
        cityName: ''
      })
    }
  }

  _onEdit = payload => {
    const { value } = this.state
    var update = {}
    var cityName = payload.name
    delete payload.name
    Swal.fire({
      position: 'center',
      title: 'Se estan actualizando la ciudad...',
      showConfirmButton: false,
      allowOutsideClick: () => !Swal.isLoading(),
      onBeforeOpen: () => {
        Swal.showLoading()
      }
    })
    update[`${value}/settings`] = payload
    update[`${value}/name`] = cityName
    citiesDB
      .update(update)
      .then(async () => {
        Swal.close()
        await Swal.fire({
          position: 'center',
          type: 'success',
          title: `Datos actualizados con éxito.`,
          showConfirmButton: false,
          timer: 2500
        })
        await this.props.GetCities(this._setCities)
      })
      .catch(err => {
        Swal.close()
        Swal.fire({
          position: 'center',
          type: 'error',
          title: `Error al actualizar los datos, por favor intentalo mas tarde.`,
          showConfirmButton: false,
          timer: 2500
        })
      })
  }
  _onSave = (payload, resetForm) => {
    var createJson = {}
    Swal.fire({
      position: 'center',
      title: 'Se esta creando la ciudad...',
      showConfirmButton: false,
      allowOutsideClick: () => !Swal.isLoading(),
      onBeforeOpen: () => {
        Swal.showLoading()
      }
    })
    createJson['name'] = payload.name
    delete payload.name
    createJson['settings'] = { ...payload }

    citiesDB
      .push(createJson)
      .then(async res => {
        this.setState({
          modal: {
            show: ''
          }
        })
        Swal.close()
        await Swal.fire({
          position: 'center',
          type: 'success',
          title: `Datos creados con éxito.`,
          showConfirmButton: false,
          timer: 2500
        })
        await this.props.GetCities(this._setCities)
      })
      .catch(err => {
        Swal.close()
        this.setState({
          modal: {
            show: ''
          }
        })
        Swal.fire({
          position: 'center',
          type: 'error',
          title: `Error al actualizar los datos, por favor intentalo mas tarde.`,
          showConfirmButton: false,
          timer: 2500
        })
      })
  }

  _createCityModal = () => {
    const show = 'show'
    this.setState({
      modal: {
        show: show,
        content: null
      }
    })
  }

  _onCancel = () => {
    this.setState({
      modal: {
        show: '',
        content: null
      }
    })
  }

  _getEvent = value => {
    !_.isEmpty(value) &&
      this.setState({
        typeEvent: value
      })
  }

  _renderEvents () {
    const { typeEvent, events } = this.state
    return Object.values(events)
      .filter(event => event.type === typeEvent)
      .map(event => {
        let level
        if (event.level) {
          switch (event.level) {
            case 'moderate':
              level = 'Moderado'
              break
            case 'high':
              level = 'Alto'
              break
            default:
              level = 'Bajo'
              break
          }
        }
        return (
          <Container
            style={{
              flexDirection: 'column',
              paddingBottom: 5
            }}
          >
            <Element>
              <Element haveFlex={2.5}>
                <Text size={'14px'} align haveBold={500} info>
                  {event.name}
                </Text>
              </Element>
              <Element haveFlex={2}>
                <Text size={'14px'} align haveBold={500} info>
                  {level}
                </Text>
              </Element>
              <Element haveFlex={1}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={event.isActive}
                      color='primary'
                      onChange={e =>
                        this._switchActiveEvent(event.id, event.isActive)
                      }
                    />
                  }
                  label={event.isActive ? 'Desactivar' : 'Activar'}
                />
              </Element>
            </Element>
          </Container>
        )
      })
  }

  _switchActiveEvent = (id, isActive) => {
    const { activeEvent } = this.state

    let event = {
      id,
      isActive
    }

    let currentActiveEvent

    !_.isEmpty(activeEvent) &&
      activeEvent.id !== id &&
      (currentActiveEvent = activeEvent.id)

    const data = {
      city: this.state.citySelected,
      event,
      currentActiveEvent
    }

    this.props.UpdateActiveEvent(data)
  }

  render () {
    const {
      modal: { show },
      cityName,
      value: cityUid,
      events,
      activeEvent
    } = this.state
    const initialValues = !_.isEmpty(this.props.settings)
      ? this.props.settings[cityUid]
      : false
    const initialRidersValues = !_.isEmpty(this.props.riderSettings)
      ? this.props.riderSettings[cityUid]
      : false
    const { history: { replace }, profile } = this.props

    if (initialValues) initialValues['name'] = cityName
    return (
      <React.Fragment>
        <Modal
          title='Crear Ciudad'
          onCancel={this._onCancel}
          show={show}
          content={<FormSettings onSave={this._onSave} />}
        />
        <Page>
          {profile.superUser && (
            <Row>
              <Col size={12}>
                <Panel title='Selecciona una ciudad o plaza'>
                  <Col size={3}>
                    <select
                      className='form-control'
                      onChange={e => this._setCity(e.target.value)}
                    >
                      <option value={0}>Seleccionar Ciudad</option>
                      {!_.isEmpty(this.state.cities) &&
                        Object.values(this.state.cities).map(city => {
                          return (
                            <option key={city.key} value={city.key}>
                              {_.capitalize(city.name)}
                            </option>
                          )
                        })}
                    </select>
                  </Col>
                  <Col size={2}>
                    <Button
                      variant='contained'
                      color='primary'
                      endIcon={<Icon>add</Icon>}
                      onClick={() => this._createCityModal()}
                    >
                      Ciudad
                    </Button>
                  </Col>
                </Panel>
              </Col>
            </Row>
          )}
          <Row>
            {this.state.showForm && (
              <Row>
                <Col size={12}>
                  <Col size={8}>
                    <Panel title='Generales de ciudad'>
                      <FormSettings
                        onEdit={this._onEdit}
                        edit={true}
                        initialValues={initialValues}
                      />
                    </Panel>
                  </Col>
                  <Col size={4}>
                    <Row>
                      <Panel title='Crea o edita una geocerca'>
                        {this.state.cities[cityUid] &&
                        this.state.cities[cityUid].geofence ? (
                            <Col size={12}>
                              <Button
                                variant='contained'
                                color='primary'
                                endIcon={<Icon>map</Icon>}
                                onClick={() =>
                                  replace(
                                    `/editGeofence/${cityUid}/${
                                      this.state.cities[cityUid].geofence
                                    }`
                                  )
                                }
                              >
                              Editar Geocerca
                              </Button>
                            </Col>
                          ) : (
                            <Col size={12}>
                              <Button
                                variant='contained'
                                color='primary'
                                endIcon={<Icon>map</Icon>}
                                onClick={() =>
                                  replace(`/createGeofence/${cityUid}`)
                                }
                              >
                              Agregar Geocerca
                              </Button>
                            </Col>
                          )}
                      </Panel>
                    </Row>
                    {this.state.cities[cityUid] && (
                      <Row>
                        <Panel title='Generales de repartidores'>
                          <FormRidersSettings
                            initialValues={initialRidersValues}
                            city={cityUid}
                          />
                        </Panel>
                      </Row>
                    )}
                    {this.state.cities[cityUid] &&
                      !_.isEmpty(events) && (
                      <Row>
                        <Panel title='Eventos'>
                          <WrapContainer haveTop>
                            <FormControl
                              style={{
                                width: '45%',
                                position: 'relative',
                                top: -20,
                                marginBottom: 10
                              }}
                            >
                              <InputLabel
                                id='demo-simple-select-label'
                                style={{ fontSize: 15 }}
                              >
                                  Tipo de evento
                              </InputLabel>
                              <Select
                                labelId='demo-simple-select-label'
                                id='demo-simple-select'
                                onChange={e => this._getEvent(e.target.value)}
                              >
                                <MenuItem
                                  style={{ fontSize: 15 }}
                                  value='service'
                                >
                                    Servicio
                                </MenuItem>
                                <MenuItem
                                  style={{ fontSize: 15 }}
                                  value='weather'
                                >
                                    Climático
                                </MenuItem>
                                <MenuItem
                                  style={{ fontSize: 15 }}
                                  value='others'
                                >
                                    Otros
                                </MenuItem>
                                <MenuItem
                                  style={{ fontSize: 15 }}
                                  value='holiday'
                                >
                                    Festivo
                                </MenuItem>
                              </Select>
                            </FormControl>
                            {!_.isEmpty(this.state.typeEvent) &&
                                this._renderEvents()}

                            <Container
                              style={{
                                flexDirection: 'column',
                                backgroundColor: '#E3E3E3'
                              }}
                            >
                              {!_.isEmpty(activeEvent) ? (
                                <div>
                                  <Element isColumn havePadding={'5px 0'}>
                                    <Text
                                      size={'20px'}
                                      align
                                      haveBold={'bold'}
                                      info
                                      color={'#333647'}
                                    >
                                      {` ${
                                        activeEvent.type === 'service'
                                          ? 'Servicio'
                                          : activeEvent.type === 'weather'
                                            ? 'Climático'
                                            : activeEvent.type === 'others'
                                              ? 'Otros'
                                              : 'Festivo'
                                      }`}
                                    </Text>
                                    <Text
                                      size={'14px'}
                                      align
                                      info
                                      havePadding={'10px 0 5px 0'}
                                      haveBold={'600'}
                                    >
                                      {`Tipo: `}
                                      <Text
                                        size={'14px'}
                                        align
                                        info
                                        havePadding={'0 0 0 5px'}
                                        haveBold={'500'}
                                      >
                                        {` ${
                                          activeEvent.type === 'service'
                                            ? 'Servicio'
                                            : activeEvent.type === 'weather'
                                              ? 'Climático'
                                              : activeEvent.type === 'others'
                                                ? 'Otros'
                                                : 'Festivo'
                                        }`}
                                      </Text>
                                    </Text>
                                  </Element>
                                  <Element>
                                    <Element haveFlex={2.5}>
                                      <Text
                                        size={'14px'}
                                        align
                                        haveBold={500}
                                        info
                                      >
                                        {activeEvent.name}
                                      </Text>
                                    </Element>
                                    <Element haveFlex={2}>
                                      <FormControl
                                        style={{
                                          width: '70%',
                                          position: 'relative',
                                          top: -5
                                        }}
                                      >
                                        <InputLabel
                                          id='select-label'
                                          style={{ fontSize: 15 }}
                                        >
                                            Prioridad
                                        </InputLabel>
                                        <Select
                                          labelId='select-label'
                                          id='level'
                                          onChange={e =>
                                            this._changeLevel(
                                              activeEvent.id,
                                              e.target.value
                                            )
                                          }
                                          value={activeEvent.level}
                                        >
                                          <MenuItem
                                            style={{ fontSize: 15 }}
                                            value='low'
                                          >
                                              Baja
                                          </MenuItem>
                                          <MenuItem
                                            style={{ fontSize: 15 }}
                                            value='moderate'
                                          >
                                              Media
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </Element>
                                    <Element haveFlex={1}>
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            checked={activeEvent.isActive}
                                            color='primary'
                                            onChange={e =>
                                              this._switchActiveEvent(
                                                activeEvent.id,
                                                activeEvent.isActive
                                              )
                                            }
                                          />
                                        }
                                        label='Desactivar'
                                      />
                                    </Element>
                                  </Element>
                                </div>
                              ) : (
                                <Element havePadding={'10px'}>
                                  <Text
                                    size={'18px'}
                                    align
                                    haveBold={'bold'}
                                    info
                                    color={'#333647'}
                                  >
                                      No hay ningun evento activo.
                                  </Text>
                                </Element>
                              )}
                            </Container>
                          </WrapContainer>
                        </Panel>
                      </Row>
                    )}
                  </Col>
                </Col>
              </Row>
            )}
          </Row>
        </Page>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ profile, data, geofence }) => {
  return {
    profile,
    cities: data.settings.cities,
    settings: data.settings.settingsCity,
    riderSettings: data.settings.riderSettingsCity
  }
}

const mapDispatchToProps = dispatch => {
  return {
    GetCities: cb => dispatch(GetCities(cb, true)),
    GetSetting: id => dispatch(GetSetting(id)),
    UpdateActiveEvent: data => dispatch(UpdateActiveEvent(data))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Settings)
