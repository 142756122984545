import React from 'react'
import { IconButton, Icon } from '@material-ui/core'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import { Row, Col } from '../../components/ui/Layout'
import { Panel } from '../../components/ui'
import ReactTable from 'react-table'
import styled from 'styled-components'
import Swal from 'sweetalert2'
import matchSorter from 'match-sorter'
import _ from 'lodash'
import fb from '../../firebase'
import getEndpoint from '../../helpers/getEndpoint'

const db = fb.database().ref('testing_orders/orders')
const dbHistory = fb.database().ref('historyOrders')
const moment = extendMoment(Moment)

const URL_AWS = getEndpoint('REACT_APP_ENDPOINT_AWS')
const DJANGO_TOKEN = getEndpoint('REACT_APP_DJANGO_TOKEN')

const WrapperTr = styled.div`
  display: flex;
  flex-direction: column;
`

const statusList = {
  pending_to_delivery: {
    value: 'Pendiente de repartidor'
  },
  take_some_time: {
    value: 'En preparación'
  },
  preparation_time: {
    value: 'Preparado'
  },
  on_way_to_delivery_the_order: {
    value: 'En camino a repartir'
  },
  delivered: {
    value: 'Por calificar'
  },
  with_rider_assigned: {
    value: 'Repartidor Asignado'
  },
  order_cancelled: {
    value: 'Orden Cancelada'
  }
}

const SearchTable = props => {
  const { data, type, contentModal } = props
  const columns =
    type === 'order'
      ? renderHeader(contentModal)
      : type === 'user'
        ? renderHeaderUser(contentModal)
        : type === 'default' && renderHeaderAllOrders(contentModal)
  const renderOrders =
    type === 'order'
      ? renderOrder(data)
      : type === 'user'
        ? renderUser(data)
        : type === 'default' && renderAllOrders(data)
  return (
    <Panel>
      <ReactTable
        data={renderOrders}
        columns={columns}
        defaultPageSize={5}
        filterable
        loading={data.length === 0}
        getTheadThProps={() => {
          return {
            style: {
              outline: 0,
              whiteSpace: 'pre-line',
              wordWrap: 'break-word'
            }
          }
        }}
        getTdProps={() => {
          return {
            style: { whiteSpace: 'pre-line', wordWrap: 'break-word' }
          }
        }}
      />
    </Panel>
  )
}

const renderOrder = orders => {
  return Object.keys(orders).map((orderKey, i) => {
    var order = orders[orderKey]
    let cancelledComment = order.cancelledComment || false
    let client = order.client || false
    let createdAt =
      order.createdAt && moment(order.createdAt).format('DD/MM/YY HH:mm')
    let dishes = order.dishes
    let restaurant = order.restaurant
      ? {
        name: order.restaurant.name || '',
        phone: order.restaurant.phone || ''
      }
      : ''
    let status = order.status
    let rider = order.attendedBy || false
    let orderTotal = order.orderTotal || false
    const amount = order.amount || false
    let discount = order.discount || false
    return {
      idOrder: orderKey,
      client,
      cancelledComment,
      createdAt,
      subtotal: (orderTotal && orderTotal.subtotal.toFixed(2)) || 0,
      envio: (orderTotal && orderTotal.deliveryCost.toFixed(2)) || 0,
      total: (orderTotal && orderTotal.total.toFixed(2)) || 0,
      dishes,
      restaurant,
      status,
      rider,
      orderTotal,
      amount,
      discount
    }
  })
}
const renderHeader = contentModal => {
  return [
    {
      Header: '# orden',
      accessor: 'idOrder',
      width: 200,
      maxWidth: 200,
      style: { display: 'flex', justifyContent: 'center' },
      filterable: false,
      Cell: props => {
        return (
          <WrapperTr>
            <Row>
              <Col size={12}>
                <span> {(props.value && props.value) || ''} </span>
              </Col>
              <Col size={12}>
                <b>Status</b>
                <br />
                <b>
                  {props.original.status
                    ? statusList[props.original.status].value
                    : ''}
                </b>
              </Col>
            </Row>
            {props.original &&
              props.original.cancelledComment && (
              <div>
                <br />
                <b>Comentario de cancelación</b>
                <br />
                <span>{props.original.cancelledComment}</span>
              </div>
            )}
            <br />
            {props.original &&
              props.original.discount && (
              <div>
                <br />
                <b>Descuento</b>
                <br />
                <span>{props.original.discount.type}</span>
              </div>
            )}
          </WrapperTr>
        )
      }
    },
    {
      Header: 'Fecha',
      accessor: 'createdAt',
      width: 150,
      maxWidth: 150,
      filterable: false,
      Cell: props => {
        return (
          <WrapperTr>
            <span> {(props.value && props.value) || ''}</span>
          </WrapperTr>
        )
      }
    },
    {
      Header: 'Datos de Cliente',
      accessor: 'client',
      width: 200,
      maxWidth: 200,
      filterable: false,
      Cell: props =>
        props.value && (
          <div>
            <span>
              <b>Nombre: </b>
              {`${props.value.clientData.name || ''} ${props.value.clientData
                .lastName || ''} ${props.value.clientData.motherLastName ||
                ''}`}
            </span>
            <br />
            <span>
              <b>Email: </b> {props.value && props.value.clientData.email}
            </span>
            <br />
            <span>
              <b>Teléfono: </b> {props.value && props.value.clientData.phone}
            </span>
            <br />
            <span>
              <b>ID: </b> {props.value && props.value.clientId}
            </span>
            <br />
          </div>
        )
    },
    {
      Header: 'Platillos',
      accessor: 'orderTotal',
      width: 200,
      maxWidth: 200,
      filterable: false,
      Cell: props =>
        props.value ? (
          <div>
            <span>
              <b>Rider: </b>
              {props.value ? `$ ${props.value.deliveryCost}` : ''}
              <br />
              <b>SubTotal: </b>
              {props.value ? `$ ${props.value.subtotal}` : ''}
              <br />
              <b>Total: </b>
              {props.value ? `$ ${props.value.total}` : ''}
              <br />
            </span>
            <button
              href='#'
              onClick={e => {
                e.preventDefault()
                contentModal('orders', props.original)
              }}
            >
              <span>
                <b>Ver detalles de la orden</b>
                <i className='fa fa-plus-circle btn-sm m-r-sm' />
              </span>
            </button>
          </div>
        ) : (
          <div>
            <span>
              <b>Por pagar: </b> {props.original.amount}
            </span>
          </div>
        )
    },
    {
      Header: 'Restaurante / Teléfono',
      accessor: 'restaurant',
      width: 200,
      maxWidth: 200,
      filterable: false,
      Cell: props =>
        props.value && (
          <div>
            <span>
              <b>Restaurante: </b>
              {props.value.name}
            </span>
            <br />
            <span>
              <b>Telefono: </b> {props.value.phone}
            </span>
            <br />
          </div>
        )
    },
    {
      Header: 'Repartidor',
      accessor: 'rider',
      width: 200,
      maxWidth: 200,
      filterable: false,
      Cell: props =>
        props.value && !props.value.msg ? (
          <div>
            <span>
              <b>Nombre: </b>
              {`${props.value.name} ${props.value.lastName}`}
            </span>
            <br />
            <span>
              <b>Teléfono: </b> {props.value.phone}
            </span>
            <br />
            <span>
              <b>Id: </b> {props.value.deliveryId}
            </span>
            <br />
          </div>
        ) : (
          <div>
            <span>
              <b>Nombre: </b>
              Sin rider asignado
            </span>
          </div>
        )
    },
    {
      Header: 'Acciones',
      filterable: false,
      width: 100,
      maxWidth: 100,
      style: { display: 'flex', justifyContent: 'center' }
    }
  ]
}

const renderUser = users => {
  return Object.keys(users).map((userKey, i) => {
    var user = users[userKey]
    const { clientData, orders } = user
    return {
      clientId: userKey,
      client: {
        ...clientData
      },
      orders
    }
  })
}

const renderHeaderUser = contentModal => {
  return [
    {
      Header: 'ID Cliente',
      accessor: 'clientId',
      filterable: false,
      Cell: props => (
        <WrapperTr>
          <span> {(props.value && props.value) || ''} </span>
        </WrapperTr>
      )
    },
    {
      Header: 'Datos de Usuario',
      accessor: 'client',
      filterMethod: (filter, rows) => {
        return matchSorter(rows, filter.value, {
          keys: [
            'client.name',
            'client.lastName',
            'client.motherLastName',
            'client.email',
            'client.phone'
          ]
        })
      },
      filterAll: true,
      Cell: props =>
        props.value && (
          <WrapperTr>
            <span>
              <b>Nombre:</b>
              {`${props.value.name || ''} ${props.value.lastName || ''} ${props
                .value.motherLastName || ''}`}
            </span>
            <span>
              <b>Email:</b> {props.value.email}
            </span>
            <span>
              <b>Teléfono:</b> {props.value.phone}
            </span>
          </WrapperTr>
        )
    },
    {
      Header: 'Ordenes',
      accessor: 'orders',
      filterable: false,
      Cell: props =>
        props.value && (
          <ul style={{ listStyleType: 'none' }}>
            {Object.keys(props.value).map(orderKey => {
              return (
                <a
                  href='javascript:void(0)'
                  key={orderKey}
                  onClick={e => {
                    e.preventDefault()
                    getOrderInfo(props.value[orderKey], contentModal)
                  }}
                >
                  <li>{orderKey}</li>
                </a>
              )
            })}
          </ul>
        )
    }
  ]
}

const renderAllOrders = orders => {
  const arrayOrders = Object.values(orders)
  return _.orderBy(arrayOrders, ['createdAt'], ['desc']).map(element => {
    const { location } = element
    var order = element
    var keyOrder = element.id || element.orderNumber
    let date = moment(order.createdAt).format('DD/MM/YYYY')
    var newStatus =
      order.status === 'order_cancelled'
        ? 'Orden Cancelada'
        : order.status === 'delivered' ? 'Entregado' : ''
    var statusComment =
      order.status === 'order_cancelled' ? order.cancelledComment : ''

    const {
      payType,
      orderTotal,
      client,
      dishes,
      attendedBy,
      acceptedBy,
      receivedBy,
      finishedBy,
      restaurant,
      restaurantId,
      discount,
      card,
      isRefunded
    } = order
    const name = `${client.clientData.name || ''} ${client.clientData
      .lastName || ''} ${client.clientData.motherLastName || ''}`

    const ordersCost = {
      orderTotal,
      discount
    }
    return {
      date,
      statusData: {
        status: newStatus,
        statusComment
      },
      name,
      keyOrder,
      typePayment:
        payType === 'cash'
          ? 'Efectivo'
          : payType === 'paypal' ? 'Paypal' : 'Tarjeta',
      ordersCost,
      dishes,
      attendedBy,
      acceptedBy,
      receivedBy,
      finishedBy,
      location,
      restaurant: restaurant.name,
      restaurantId: restaurantId,
      client,
      orderTotal,
      restaruant_info: restaurant,
      card,
      isRefunded
    }
  })
}

const renderHeaderAllOrders = contentModal => {
  return [
    {
      Header: 'Fecha',
      accessor: 'date',
      style: { display: 'flex', justifyContent: 'center' },
      filterAll: true,
      filterMethod: (filter, rows) => {
        return matchSorter(rows, filter.value, {
          keys: ['date']
        })
      },
      Cell: props => {
        return (
          props && (
            <WrapperTr>
              <span>{props.value}</span>
            </WrapperTr>
          )
        )
      },
      width: 150,
      maxWidth: 150
    },
    {
      Header: 'Estado',
      accessor: 'statusData',
      style: { display: 'flex', justifyContent: 'center' },
      filterAll: true,
      filterMethod: (filter, rows) => {
        return matchSorter(rows, filter.value, {
          keys: ['statusData.status']
        })
      },
      Cell: props => {
        return (
          props && (
            <WrapperTr>
              <span>{props.value.status}</span>
              {props.value.statusComment && (
                <span>{props.value.statusComment}</span>
              )}
            </WrapperTr>
          )
        )
      },
      width: 200,
      maxWidth: 200
    },
    {
      Header: 'No. Orden/Pedido',
      accessor: 'keyOrder',
      style: { display: 'flex', justifyContent: 'center' },
      width: 120,
      maxWidth: 120,
      filterAll: true,
      filterMethod: (filter, rows) => {
        return matchSorter(rows, filter.value, {
          keys: ['keyOrder']
        })
      },
      Cell: props => {
        return (
          props.value && (
            <WrapperTr>
              <a
                href='javascript:void(0)'
                onClick={e => {
                  e.preventDefault()
                  contentModal('orders', props.original)
                }}
                style={{ cursor: 'pointer' }}
              >
                <span>{` ${props.value}`}</span>
              </a>
            </WrapperTr>
          )
        )
      }
    },
    {
      Header: 'Restaurante',
      accessor: 'restaurant',
      style: { display: 'flex', justifyContent: 'center' },
      width: 150,
      maxWidth: 200,
      filterAll: true,
      filterMethod: (filter, rows) => {
        return matchSorter(rows, filter.value, {
          keys: ['restaurant']
        })
      },
      Cell: props => {
        return (
          props && (
            <WrapperTr>
              <span>{props.value}</span>
            </WrapperTr>
          )
        )
      }
    },
    {
      Header: 'Nombre',
      accessor: 'name',
      style: { display: 'flex', justifyContent: 'center' },
      width: 150,
      maxWidth: 200,
      filterAll: true,
      filterMethod: (filter, rows) => {
        return matchSorter(rows, filter.value, {
          keys: ['name']
        })
      },
      Cell: props => {
        return (
          props && (
            <WrapperTr>
              <span>{props.value}</span>
            </WrapperTr>
          )
        )
      }
    },
    {
      Header: 'Monto',
      accessor: 'ordersCost',
      style: { display: 'flex' },
      filterAll: true,
      filterMethod: (filter, rows) => {
        return matchSorter(rows, filter.value, {
          keys: ['subtotal']
        })
      },
      Cell: props => {
        return (
          props && (
            <WrapperTr>
              <span>
                {props.value.discount ? (
                  <React.Fragment>
                    <b>Costo envió: </b>
                    {props.value.orderTotal
                      ? `$ ${props.value.discount.deliveryCost}`
                      : ''}
                    <br />
                    <b>Costo producto: </b>
                    {props.value.orderTotal
                      ? `$ ${props.value.discount.foodCost}`
                      : ''}
                    <br />
                    <b>Subtotal: </b>
                    {props.value.orderTotal
                      ? `$ ${props.value.discount.deliveryCost +
                          props.value.discount.foodCost}`
                      : ''}
                    <br />
                    <b>
                      Descuento{' '}
                      {props.value.discount.type === 'shipping'
                        ? 'envió'
                        : 'producto'}:{' '}
                    </b>
                    {`$ -${props.value.discount.discountValue}`}
                    <br />
                    <b>Total: </b>
                    {props.value.orderTotal
                      ? `$ ${props.value.orderTotal.total}`
                      : ''}
                    <br />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <b>Costo envió: </b>
                    {props.value.orderTotal
                      ? `$ ${props.value.orderTotal.deliveryCost}`
                      : ''}
                    <br />
                    <b>Costo producto: </b>
                    {props.value.orderTotal
                      ? `$ ${props.value.orderTotal.subtotal}`
                      : ''}
                    <br />
                    <b>Total: </b>
                    {props.value.orderTotal
                      ? `$ ${props.value.orderTotal.total}`
                      : ''}
                    <br />
                  </React.Fragment>
                )}
              </span>
            </WrapperTr>
          )
        )
      }
    },
    {
      Header: 'Tipo de Pago',
      accessor: 'typePayment',
      style: { display: 'flex', justifyContent: 'center' },
      filterAll: true,
      filterMethod: (filter, rows) => {
        return matchSorter(rows, filter.value, {
          keys: ['typePayment']
        })
      },
      width: 100,
      maxWidth: 200,
      Cell: props => {
        return (
          props && (
            <WrapperTr>
              <span>{props.value}</span>
            </WrapperTr>
          )
        )
      }
    },
    {
      Header: 'Acciones',
      filterable: false,
      width: 100,
      maxWidth: 100,
      style: { display: 'flex', justifyContent: 'center' },
      Cell: props =>
        props && (
          <WrapperTr>
            <div>
              <IconButton onClick={() => returnActiveOrder(props)}>
                <Icon
                  fontSize={'large'}
                  title={'Regresar Orden a activas'}
                  style={{ color: '#03a9f4' }}
                >
                  cached
                </Icon>
              </IconButton>
            </div>
            {props.original &&
              props.original.typePayment &&
              props.original.card &&
              props.original.typePayment === 'Tarjeta' && (
              <div>
                <IconButton onClick={() => refundOrder(props)}>
                  <Icon
                    fontSize={'large'}
                    title={
                      props.original.isRefunded
                        ? 'Ya reembolsado'
                        : 'Reembolsar'
                    }
                    style={{
                      color: props.original.isRefunded
                        ? 'MediumSeaGreen'
                        : 'Tomato'
                    }}
                  >
                    {props.original.isRefunded ? `price_check` : `money_off`}
                  </Icon>
                </IconButton>
              </div>
            )}
          </WrapperTr>
        )
    }
  ]
}

const returnActiveOrder = props => {
  const { original: { keyOrder } } = props

  Swal.fire({
    title: '¿Está seguro en regresar la orden a Ordenes Activas?',
    text: `Se eliminará cualquier repartidor asignado.`,
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, Regresala!'
  }).then(async result => {
    if (result.value) {
      let updates = {}
      dbHistory.child(keyOrder).once('value', snp => {
        const data = snp.val()
        updates = { ...data }
        updates['client'] = data.client.clientData
        updates['times'] = null
        updates['attendedBy'] = null
        if (data.status === 'delivered' && !_.isEmpty(data.acceptedBy)) {
          updates['status'] = 'on_way_to_delivery_the_order'
        } else if (
          data.status === 'order_cancelled' &&
          !_.isEmpty(data.acceptedBy)
        ) {
          updates['cancelledComment'] = null
          updates['status'] = 'on_way_to_delivery_the_order'
        } else if (
          data.status === 'order_cancelled' &&
          _.isEmpty(data.acceptedBy)
        ) {
          updates['cancelledComment'] = null
          updates['status'] = 'pending_to_delivery'
        }
        db
          .child(keyOrder)
          .update(updates)
          .then(() => {
            Swal.fire(
              'La Orden se ha enviado con éxito!',
              'La información de la orden continuará en esta pantalla y se actualizará cuando vuelva a ser terminada.',
              'success'
            )
          })
          .catch(err => {
            Swal.fire({
              position: 'center',
              type: 'error',
              title: `${err.message}`,
              showConfirmButton: false,
              timer: 2500
            })
          })
      })
    }
  })
}

const refundOrder = props => {
  const { original: { keyOrder, card, restaruant_info } } = props
  Swal.fire({
    title: '¿Está seguro en reembolsar el costo total de la orden?',
    text: `Recuerda que solo se podra reembolsar ordenes dentro de las primeras 24 horas en las que fue ralizada la transacción.`,
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, reembolsar'
  }).then(async result => {
    if (result.value) {
      const data = {}
      data['track_code'] = card.track_code
      data['city'] = restaruant_info.city
      const url = `${URL_AWS}v2/payment/refund/`
      fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `token ${DJANGO_TOKEN}`,
          'content-type': 'application/json'
        },
        body: JSON.stringify(data)
      })
        .then(res => {
          return res.json()
        })
        .then(result => {
          if (result && result.success) {
            const update = {}
            update['isRefunded'] = true
            update['status'] = 'order_cancelled'
            dbHistory.child(keyOrder).update(update)
            return Swal.fire({
              position: 'center',
              type: 'success',
              text: '¡Se ha reembolsado la orden con éxito!',
              showConfirmButton: false,
              timer: 2500
            })
          }
          Swal.fire({
            position: 'center',
            type: 'error',
            text: `Resultado: ${result && result.message}`,
            title: 'Ha ocurrido un error al intentar reembolsar la orden.',
            showConfirmButton: false,
            timer: 2500
          })
        })
        .catch(err => {
          Swal.fire({
            position: 'center',
            type: 'error',
            text: err.message || '',
            title: 'Ha ocurrido un error al intentar reembolsar la orden.',
            showConfirmButton: false,
            timer: 2500
          })
        })
    }
  })
}

const getOrderInfo = (order, contentModal) => {
  Swal.fire({
    position: 'center',
    title: 'Your request is loading',
    showConfirmButton: false,
    allowOutsideClick: () => !Swal.isLoading(),
    onBeforeOpen: () => {
      Swal.showLoading()
    }
  })
  contentModal('user', order)
  Swal.close()
}
export default SearchTable
