import styled from 'styled-components'

export const Div = styled.div`
  flex: 1;
  /*text-align: center;*/
  align-content: left;
`

export const Logo = styled.img`
  width: 58px;
  padding-right: 10px;
`

export const Text = styled.span`
  text-align: center;
`

export const Button = styled.button``

export const Icon = styled.i``
