import React from 'react'

import { Card, ViewColumn, Text } from './styled'

const CouponCard = ({ used, coupon, description, date }) => {
  return (
    <Card>
      <ViewColumn size={4}>
        <Text fSize={10}>{`Cupón: ${coupon}`}</Text>
        <Text fSize={18} mBottom={15} bold>
          {description}
        </Text>
        <Text>{used ? `Usado el ${date}` : `Vto. ${date}`}</Text>
      </ViewColumn>
    </Card>
  )
}

export default CouponCard
