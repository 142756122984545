import React from 'react'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import Moment from 'moment'
import formatCurrency from 'format-currency'
import { extendMoment } from 'moment-range'
import fb from '../../firebase'
import DatePicker from 'react-datepicker'
import { Row, Col, Page } from '../../components/ui/Layout'
import { Panel, Button } from '../../components/ui/'
import DatePickerHeader from '../../components/datePickerHeader'
import _ from 'lodash'

const db = fb.database().ref()
const moment = extendMoment(Moment)

class Payment extends React.Component {
  constructor (props) {
    super(props)
    const startDate = moment().startOf('day')
    const stopDate = moment(startDate).endOf('day')
    this.state = {
      startDate,
      stopDate,
      startDateUnix: startDate.valueOf(),
      stopDateUnix: stopDate.valueOf(),
      historyOrders: {},
      ordersTotal: 0,
      totalRider: 0,
      totalRestaurant: 0,
      yum: 0
    }
  }

  //= ==========
  getRidersExcel = data => {
    const keys = Object.keys(data)
    const riders = keys.map(k => {
      const ts = -1 * k
      const thisD = new Date(ts)
      const date = moment(thisD).format('DD')

      if (data[k].attendedBy) {
        const riderName = `${data[k].attendedBy.name} ${
          data[k].attendedBy.lastName
        }`
        const riderId = `${data[k].attendedBy.deliveryId}`
        const deliveryCost = parseInt(`${data[k].orderTotal.deliveryCost}`)
        const cancelledComment = data[k].cancelledComment

        const obj = {
          date,
          riderName,
          riderId,
          deliveryCost,
          cancelledComment
        }
        return obj
      }
      return []
    })
    const byRider = _.groupBy(riders, 'riderId')
    const getTotals = rider => {
      var deliveryTotal = 0
      return rider.map((order, index, ary) => {
        if (order) {
          deliveryTotal = deliveryTotal + order.deliveryCost
          var totalServices = index + 1
          if (ary.length === totalServices) {
            return {
              fecha: order.date,
              riderID: order.riderId,
              nombre: order.riderName,
              monto: deliveryTotal,
              pedidos: totalServices
            }
            // return `${order.date}\t ${order.riderId}\t ${order.riderName}\t ${deliveryTotal}\t ${totalServices}\n`
          }
          return null
        }
        return null
      })
    }

    const allServices = Object.values(byRider).map(rider => {
      const byDate = _.groupBy(rider, 'date')
      return Object.values(byDate).map(
        date => (date ? getTotals(date).filter(x => x) : null)
      )
    })

    this.setState({
      excel: _.flattenDeep(allServices)
    })
  }
  //= ==========

  startLoading = () => {
    Swal.fire({
      position: 'center',
      title: 'Cargando datos...',
      showConfirmButton: false,
      allowOutsideClick: () => !Swal.isLoading(),
      onBeforeOpen: () => {
        Swal.showLoading()
      }
    })
  }

  async componentDidMount () {
    await this._getHistoryOrders()
  }

  getAbsoluteMonths = momentDate => {
    return momentDate.month() + momentDate.year() * 12
  }
  _getHistoryOrders = async () => {
    this.startLoading()
    const { startDateUnix, stopDateUnix } = this.state
    const { profile: { selectCity, city } } = this.props
    const currentCity = selectCity || city
    const momentStartDateUnix = moment(startDateUnix)
    const momentStopDateUnix = moment(stopDateUnix)
    const startAtMonth = moment(startDateUnix).month()
    const startAtYear = moment(startDateUnix).year()
    const stopAtMonth = moment(stopDateUnix).month()
    const stopAtYear = moment(stopDateUnix).year()

    const formatMonth = {
      0: '01',
      1: '02',
      2: '03',
      3: '04',
      4: '05',
      5: '06',
      6: '07',
      7: '08',
      8: '09',
      9: '10',
      10: '11',
      11: '12'
    }

    var startMonths = this.getAbsoluteMonths(momentStartDateUnix)
    var endMonths = this.getAbsoluteMonths(momentStopDateUnix)

    var monthDifference = endMonths - startMonths

    if (monthDifference > 0) {
      const arrayMonths = _.range(monthDifference)
      const referencesArray = []
      const referenceStop = db
        .child(`finishedOrders/${stopAtYear}/${formatMonth[stopAtMonth]}`)
        .orderByChild('createdAt')
        .startAt(startDateUnix)
        .endAt(stopDateUnix)
        .once('value')
      referencesArray.push(referenceStop)
      arrayMonths.forEach(item => {
        let momentStopDateUnixClone = momentStopDateUnix.clone()
        let stopDateSubtract = momentStopDateUnixClone.subtract(
          item + 1,
          'month'
        )
        let stopDateMonth = stopDateSubtract.month()
        let stopDateYear = stopDateSubtract.year()
        const referenceStart = db
          .child(`finishedOrders/${stopDateYear}/${formatMonth[stopDateMonth]}`)
          .orderByChild('createdAt')
          .startAt(startDateUnix)
          .endAt(stopDateUnix)
          .once('value')
        referencesArray.push(referenceStart)
      })

      return Promise.all(referencesArray)
        .then(result => {
          var historyOrders = {}
          _.range(referencesArray.length).forEach(resultIndex => {
            if (result[resultIndex].exists()) {
              const orderData = result[resultIndex].val()
              Object.keys(orderData).forEach(key => {
                if (
                  orderData[key].restaurant.city === currentCity &&
                  orderData[key].status !== 'order_cancelled' &&
                  key !== 'undefined'
                ) {
                  historyOrders[key] = orderData[key]
                }
              })
            }
          })
          this.getRidersExcel(historyOrders)
          this.setState(
            {
              historyOrders,
              ordersTotal: Object.keys(historyOrders).length
            },
            () => {
              this._getAmount(historyOrders)
            }
          )
        })
        .finally(() => Swal.close())
        .catch(err => {
          Swal.close()
        })
    }
    return db
      .child(`finishedOrders/${startAtYear}/${formatMonth[startAtMonth]}`)
      .orderByChild('createdAt')
      .startAt(startDateUnix)
      .endAt(stopDateUnix)
      .once('value')
      .then(snapshot => {
        var historyOrders = {}
        if (!_.isEmpty(snapshot.exists())) {
          return Swal.fire({
            position: 'center',
            title: 'No se encontraron datos',
            text: 'Intenta con otro rango de fechas, por favor.',
            showConfirmButton: true
          })
        }
        snapshot.forEach(item => {
          const orderData = item.val()
          if (
            orderData.restaurant.city === currentCity &&
            orderData.status !== 'order_cancelled'
          ) {
            historyOrders[item.key] = orderData
          }
        })
        this.getRidersExcel(historyOrders)
        this.setState(
          {
            historyOrders,
            ordersTotal: Object.keys(historyOrders).length
          },
          () => {
            this._getAmount(historyOrders)
            Swal.close()
          }
        )
      })
  }

  _getAmount = data => {
    var amount = {
      restaurant: 0,
      rider: 0
    }
    Object.keys(data).forEach(item => {
      if (data[item].discount) {
        amount['restaurant'] =
          amount['restaurant'] + data[item].discount.foodCost
        amount['rider'] = amount['rider'] + data[item].discount.deliveryCost
      } else {
        amount['restaurant'] =
          amount['restaurant'] + data[item].orderTotal.subtotal
        amount['rider'] = amount['rider'] + data[item].orderTotal.deliveryCost
      }
    })
    this.setState({
      totalRestaurant: amount['restaurant'],
      totalRider: amount['rider'],
      yum: amount['restaurant'] + amount['rider']
    })
  }

  render () {
    const { ordersTotal, totalRider, totalRestaurant, yum } = this.state
    return (
      <Page>
        <Row>
          <Col size={12}>
            <Panel>
              <Row style={{ height: 200 }}>
                <Col size={5}>
                  <Row style={{ flex: 1 }}>
                    <Col size={5} offset={1}>
                      {`Periodo desde `}
                      <DatePicker
                        style={{ flex: 1 }}
                        selected={this.state.startDate._d}
                        onChange={e =>
                          this.handleChangeDate(
                            moment(e),
                            'startDate',
                            'startDateUnix'
                          )
                        }
                        renderCustomHeader={props => {
                          return <DatePickerHeader {...props} />
                        }}
                      />
                    </Col>
                    <Col size={6}>
                      {` hasta `}
                      <DatePicker
                        style={{ flex: 1 }}
                        selected={this.state.stopDate._d}
                        onChange={e =>
                          this.handleChangeDate(
                            moment(e),
                            'stopDate',
                            'stopDateUnix'
                          )
                        }
                        renderCustomHeader={props => {
                          return <DatePickerHeader {...props} />
                        }}
                      />
                    </Col>
                    <Col size={6} offset={6}>
                      <Button
                        label='Generar datos'
                        size='btn-sm'
                        color='btn-default'
                        classes={'m-r-sm m-b-sm m-t-sm'}
                        onClick={async () => {
                          await this._getHistoryOrders()
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col size={7}>
                  <section className='panel panel-default m-b-lg'>
                    <section className='panel-body no-padder'>
                      <Row>
                        <Col size={12} classes={'summaryItem'}>
                          <h1>{ordersTotal}</h1>
                          <p>TOTAL DE SERVICIOS</p>
                        </Col>
                        <Col size={4} classes={'summaryItem'}>
                          <h1>{`$${formatCurrency(totalRider.toFixed(2))}`}</h1>
                          <p>RIDER</p>
                        </Col>
                        <Col size={4} classes={'summaryItem'}>
                          <h1>{`$${formatCurrency(
                            totalRestaurant.toFixed(2)
                          )}`}</h1>
                          <p>RESTAURANTS</p>
                        </Col>
                        <Col size={4} classes={'summaryItem'}>
                          <h1>{`$${formatCurrency(yum.toFixed(2))}`}</h1>
                          <p>YUM</p>
                        </Col>
                      </Row>
                    </section>
                    <section>
                      <Row>
                        {this.state.excel && (
                          <table>
                            <tr>
                              <th>Fecha</th>
                              <th>Id</th>
                              <th>Nombre</th>
                              <th>Monto</th>
                              <th>Pedidos</th>
                            </tr>
                            {this.state.excel.map(r => (
                              <tr>
                                <th>{`${r.fecha}\t`}</th>
                                <th>{`${r.riderID}\t`}</th>
                                <th>{r.nombre}</th>
                                <th>{r.monto}</th>
                                <th>{r.pedidos}</th>
                              </tr>
                            ))}
                          </table>
                        )}
                      </Row>
                    </section>
                  </section>
                </Col>
              </Row>
            </Panel>
          </Col>
        </Row>
      </Page>
    )
  }

  handleChangeDate (date, key, keyUnix) {
    const period = {}
    period[key] = date
    period[keyUnix] = date.valueOf()
    this.setState({
      ...period
    })
  }
}

const mapStateToProps = state => {
  return {
    profile: state.profile
  }
}

export default connect(mapStateToProps, null)(Payment)
