import Swal from 'sweetalert2'
import fb from '../../firebase'
import getEndpoint from '../../helpers/getEndpoint'
import _ from 'lodash'

const BASE_URL = getEndpoint('REACT_APP_ENDPOINT')

const restaurantsDB = fb.database().ref('restaurants')
const partnersDB = fb.database().ref('partners')
const DJANGO_TOKEN = getEndpoint('REACT_APP_DJANGO_TOKEN')
const URL_AWS = getEndpoint('REACT_APP_ENDPOINT_AWS')

const activeLoading = (msg = false) => {
  Swal.fire({
    position: 'center',
    title: msg || 'Tu petición se esta cargando',
    showConfirmButton: false,
    allowOutsideClick: () => !Swal.isLoading(),
    onBeforeOpen: () => {
      Swal.showLoading()
    }
  })
}

export const bindRestaurants = city => {
  activeLoading('Cargando restaurantes')
  restaurantsDB.off()
  return dispatch => {
    dispatch({ type: 'GET_RESTAURANTS_LOADING' })
    restaurantsDB
      .orderByChild('city')
      .equalTo(city)
      .once('value')
      .then(snapshot => {
        Swal.close()
        if (snapshot.val()) {
          const data = snapshot.val()
          Object.keys(data).forEach(id => {
            if (data[id].partner === undefined) {
              restaurantsDB.child(id).update({ active: false })
            }
            data[id]._id = id
            if (id) {
              if (data[id].partner) {
                partnersDB
                  .child(data[id].partner)
                  .once('value')
                  .then(res => {
                    data[id].userEmail = res.val()
                      ? res.val().email
                      : 'nouser@mail.com'
                  })
                  .catch(err =>
                    Swal.fire({
                      position: 'center',
                      type: 'error',
                      title: `${err.message}`,
                      showConfirmButton: false,
                      timer: 2500
                    })
                  )
              }
            }
          })
          dispatch({ type: 'GET_RESTAURANTS_SUCCESS' })
          dispatch({ type: 'GET_RESTAURANTS', payload: data })
        } else {
          dispatch({ type: 'GET_RESTAURANTS', payload: {} })
          dispatch({ type: 'GET_RESTAURANTS_SUCCESS' })
        }
      })
      .catch(err => {
        dispatch({ type: 'GET_RESTAURANTS_FAILURE', payload: err.message })
      })
    restaurantsDB
      .orderByChild('city')
      .equalTo(city)
      .on('child_changed', snp => {
        const data = snp.val()
        const key = snp.key
        dispatch({ type: 'UPDATE_RESTAURANTS', payload: data, key })
      })
  }
}

export const updateRestaurantPosition = payload => {
  const { position, restaurantId } = payload
  return (dispatch, getState) => {
    restaurantsDB
      .child(restaurantId)
      .update({
        position
      })
      .then(res =>
        Swal.fire({
          position: 'center',
          type: 'success',
          title: `Restaurante actualizado`,
          showConfirmButton: false,
          timer: 2500
        })
      )
      .catch(err =>
        Swal.fire({
          position: 'center',
          type: 'error',
          title: `${err.message}`,
          showConfirmButton: false,
          timer: 2500
        })
      )
  }
}

export const deleteRestaurant = (prospectId, name) => {
  return dispatch => {
    return restaurantsDB
      .child(prospectId)
      .remove()
      .then(async res => {
        await Swal.fire(
          'Eliminado!',
          `El restaurante ${name} ha sido eliminado`,
          'success'
        )
        window.location.reload() // TODO remove this in favor of a binded resource
      })
      .catch(err =>
        Swal.fire({
          position: 'center',
          type: 'error',
          title: `${err.message}`,
          showConfirmButton: false,
          timer: 2500
        })
      )
  }
}

export const toogleActive = (id, isActive, cb) => {
  cb(true, 'activeLoading')
  const url = `${BASE_URL}/partners/${id}/toogleActive/${isActive}`
  fetch(url, {
    method: 'POST'
  })
    .then(response =>
      response.json().then(resJSON => {
        cb(false, 'activeLoading')
        if (resJSON.success === false) {
          Swal.fire({
            position: 'center',
            type: 'error',
            title: `El restaurante no se puede activar, ${
              resJSON.data.message
            }`,
            showConfirmButton: false,
            timer: 2500
          })
        }
      })
    )
    .catch(err => {
      cb(false, 'activeLoading')
      Swal.fire({
        position: 'center',
        type: 'error',
        title: `${err.message}`,
        showConfirmButton: false,
        timer: 2500
      })
    })
}

export const acceptPaypalActive = (id, acceptPaypal, cb) => {
  let updates = {}
  updates[`/${id}/acceptPaypal/`] = acceptPaypal
  cb(true, 'loaderPaypal')
  restaurantsDB
    .update(updates)
    .then(() => {
      cb(false, 'loaderPaypal')
    })
    .catch(err => {
      cb(false, 'loaderPaypal')
      Swal.fire({
        position: 'center',
        type: 'error',
        title: `${err.message}`,
        showConfirmButton: false,
        timer: 2500
      })
    })
}

export const resetPassword = id => dispatch => {
  const url = `${URL}/../partners/${id}/resetPassword/`
  fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'default'
  })
    .then(response => {
      return response.json().then(responseJSON => {
        if (responseJSON.success) {
          var win = window.open(responseJSON.data.link, '_blank')
          win.focus()
        }
      })
    })
    .catch(err =>
      Swal.fire({
        position: 'center',
        type: 'error',
        title: `${err.message}`,
        showConfirmButton: false,
        timer: 2500
      })
    )
}

export const updateRestaurant = payload => {
  return dispatch => {
    const { data, cb, uid } = payload
    var createData = new FormData()
    Object.keys(data).forEach(item => {
      createData.append(
        item,
        data[item] ? _.capitalize(data[item]) : data[item]
      )
    })
    const url = `${URL_AWS}v2/restaurants/${uid}/`
    try {
      fetch(url, {
        method: 'PATCH',
        headers: {
          Authorization: `token ${DJANGO_TOKEN}`
        },
        body: createData
      })
        .then(res => {
          if (res.status === 200) {
            cb()
            return Swal.fire({
              position: 'center',
              type: 'success',
              title: '¡Los cambios se han guardado con éxito!',
              showConfirmButton: false,
              timer: 1500
            })
          }
          cb()
          Swal.fire({
            position: 'center',
            type: 'error',
            title: `Estado: ${res.status}`,
            text: 'Ha ocurrido un error al editar el comercio.',
            showConfirmButton: false,
            timer: 2500
          })
        })
        .catch(err => {
          cb()
          Swal.fire({
            position: 'center',
            type: 'error',
            title: err.message || '',
            text: 'Ha ocurrido un error al editar el comercio.',
            showConfirmButton: false,
            timer: 1500
          })
        })
    } catch (error) {
      cb()
      Swal.fire({
        position: 'center',
        type: 'error',
        title: error.message || '',
        text: 'Ha ocurrido un error al editar el comercio.',
        showConfirmButton: false,
        timer: 1500
      })
    }
  }
}

export const closeAllRestaurants = city => {
  const url = `${URL_AWS}v2/restaurants/close/${city}/`
  activeLoading('Cerrando restaurantes')
  try {
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `token ${DJANGO_TOKEN}`
      }
    })
      .then(res => {
        if (res.status === 200) {
          return res.json()
        }
        return false
      })
      .then(result => {
        if (result) {
          return Swal.fire({
            position: 'center',
            type: 'success',
            title: `Restaurantes cerrados`,
            showConfirmButton: false,
            timer: 2500
          })
        }
        return Swal.fire({
          position: 'center',
          type: 'error',
          title: 'Error',
          text: 'Ha ocurrido un error al cerrar los comercios.',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch(error => {
        Swal.fire({
          position: 'center',
          type: 'error',
          title: error.message || '',
          text: 'Ha ocurrido un error al cerrar los comercios.',
          showConfirmButton: false,
          timer: 1500
        })
      })
  } catch (error) {
    Swal.fire({
      position: 'center',
      type: 'error',
      title: error.message || '',
      text: 'Ha ocurrido un error al cerrar los comercios.',
      showConfirmButton: false,
      timer: 1500
    })
  }
}
