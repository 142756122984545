import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex: 1;
`
export const Wrapper = styled.div`
  flex: 1;
  padding-top: 15px;
`
export const SubWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;
`
export const Text = styled.p`
  font-size: ${({ fSize }) => fSize || '15px'};
  ${({ bold }) => bold && 'font-weight: 500'};
`
export const CouponWrap = styled.div`
  display: flex;
  width: 100%;
  padding: 5px 20px 20px 20px;
  align-items: center;
`
