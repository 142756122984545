import { startLoading, stopLoading, cleanError } from '../../actions/ui'
import fb from '../../firebase'
import Swal from 'sweetalert2'
import getEndpoint from '../../helpers/getEndpoint'

const URL_AWS = getEndpoint('REACT_APP_ENDPOINT_AWS')
const DJANGO_TOKEN = getEndpoint('REACT_APP_DJANGO_TOKEN')

const partnersDB = fb.database().ref('partners')

const createUserUrl = `${getEndpoint('REACT_APP_ENDPOINT_API')}/createUser`
const deleteUserUrl = `${getEndpoint('REACT_APP_ENDPOINT_API')}/deleteUser`

const getUser = () => {
  return partnersDB
    .limitToLast(10)
    .once('value')
    .then(snp => {
      const rawUsers = snp.val()
      const users = Object.keys(rawUsers).map(user => {
        return {
          uid: user,
          ...rawUsers[user]
        }
      })
      const admins = users.filter(admin => admin.type === 'admin')
      const finalUsers = users.filter(
        user =>
          user.type !== 'admin' ||
          user.type !== 'restaurant' ||
          user.type !== 'delivery'
      )
      const restaurants = users.filter(
        restaurant => restaurant.type === 'restaurant'
      )
      const riders = users.filter(rider => rider.type === 'delivery')

      const payload = {
        users,
        admins,
        finalUsers,
        restaurants,
        riders
      }
      return payload
    })
}

export const loadUsers = () => dispatch => {
  getUser().then(res =>
    dispatch({
      type: 'SET_USERS_LIST',
      payload: res
    })
  )
}

export const editUser = (user, history) => {
  return dispatch => {
    const {
      city,
      email,
      foreignKey,
      lastName,
      motherLastName,
      name,
      phone,
      type,
      superUser,
      editOnOrder
    } = user

    let updates = {}
    updates[`/${foreignKey}/email`] = email
    updates[`/${foreignKey}/lastName`] = lastName
    updates[`/${foreignKey}/motherLastName`] = motherLastName
    updates[`/${foreignKey}/name`] = name
    updates[`/${foreignKey}/phone`] = phone
    if (!editOnOrder) {
      updates[`/${foreignKey}/city`] = city
      updates[`/${foreignKey}/type`] = type
      updates[`/${foreignKey}/superUser`] = superUser || null
    }

    partnersDB
      .update(updates)
      .then(() =>
        Swal.fire({
          position: 'center',
          type: 'success',
          title: `Datos de usuario actualizados correctamente`,
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          if (!editOnOrder) {
            setTimeout(() => {
              history.replace('/users')
            }, 1000)
          }
        })
      )
      .catch(error => {
        Swal.fire({
          position: 'center',
          type: 'error',
          title: 'Ha ocurrido un error',
          text: `Error: ${error}`,
          showConfirmButton: false,
          timer: 1500
        })
      })
  }
}

export const bannedUser = data => {
  const {
    uid,
    timestamp,
    subject,
    adminName,
    adminLN,
    adminID,
    bannedCount
  } = data

  const bannedInfo = {
    subject,
    admin: {
      name: `${adminName} ${adminLN || ''}`,
      key: adminID
    },
    timestamp
  }
  const updateData = new FormData()
  updateData.append('banned', true)
  updateData.append('bannedInfo', JSON.stringify(bannedInfo))
  updateData.append('bannedNumber', bannedCount + 1)
  const url = `${URL_AWS}v2/clients/${uid}/`
  fetch(url, {
    method: 'PATCH',
    headers: {
      Authorization: `token ${DJANGO_TOKEN}`
    },
    body: updateData
  })
    .then(res => {
      return res.json()
    })
    .then(result => {
      console.log('Result when banning user', result)
    })
    .catch(err => {
      console.log('Error banning user', err)
    })
  // partnersDB
  //   .update(updates)
  //   .then(() =>
  //     Swal.fire({
  //       position: 'center',
  //       type: 'success',
  //       title: `Usuario bloqueado`,
  //       showConfirmButton: false,
  //       timer: 1500
  //     })
  //   )
  //   .catch(error => {
  //     Swal.fire({
  //       position: 'center',
  //       type: 'error',
  //       title: 'Ha ocurrido un error',
  //       text: `Error: ${error}`,
  //       showConfirmButton: false,
  //       timer: 1500
  //     })
  //   })
}

export const activateUser = id => {
  return dispatch => {
    Swal.fire({
      title: '¿Estás seguro de desbloquear a este Usuario?',
      type: 'warning',
      showCancelButton: true
    }).then(result => {
      if (result.value) {
        partnersDB
          .child(id)
          .update({ banned: false })
          .then(() =>
            Swal.fire({
              position: 'center',
              type: 'success',
              title: `Usuario Desbloqueado`,
              showConfirmButton: false,
              timer: 1500
            })
          )
          .catch(error => {
            Swal.fire({
              position: 'center',
              type: 'error',
              title: 'Ha ocurrido un error',
              text: `Error: ${error}`,
              showConfirmButton: false,
              timer: 1500
            })
          })
      }
    })
  }
}

export const deleteUser = uid => dispatch => {
  if (window.confirm('Si borras el usuario no hay manera de recuperarlo')) {
    dispatch(startLoading())
    const body = JSON.stringify({
      uid
    })
    fetch(deleteUserUrl, {
      method: 'POST',
      header: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      body
    })
      .then(res =>
        res.json().then(resjson => {
          getUser().then(res => {
            dispatch(stopLoading({ message: 'Se elimino el usuario...' }))
            setTimeout(() => dispatch(cleanError()), 5000)
            return dispatch({
              type: 'SET_USERS_LIST',
              payload: res
            })
          })
        })
      )
      .catch(err => {
        Swal.fire({
          position: 'center',
          type: 'error',
          title: 'Se elimino el usuario con errores',
          text: `Error: ${err}`,
          showConfirmButton: false,
          timer: 1500
        })
      })
  }
}

export const createUser = (user, CB, history) => {
  return (dispatch, getStore) => {
    const { profile: { type, uid } } = getStore()
    const { superUser } = user
    const body = JSON.stringify({
      currentUser: {
        type,
        uid
      },
      newUser: {
        ...user
      }
    })
    fetch(createUserUrl, {
      method: 'POST',
      header: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      body
    })
      .then(res => {
        return res.json()
      })
      .then(user => {
        getUser()
        if (user.type === 'restaurant') {
          history.push(`restaurant/${user.uid}/edit`)
        }
        if (user && user.code) {
          CB({ error: user })
          return
        }
        CB({ error: null })

        if (user.type === 'admin' && superUser) {
          let updates = {}
          updates[`/${user.uid}/superUser`] = superUser
          partnersDB
            .update(updates)
            .then(() =>
              Swal.fire({
                position: 'center',
                type: 'success',
                title: `Datos agregados con éxito`,
                showConfirmButton: false,
                timer: 1500
              })
            )
            .catch(error => {
              Swal.fire({
                position: 'center',
                type: 'error',
                title: 'Ha ocurrido un error',
                text: `Error: ${error}`,
                showConfirmButton: false,
                timer: 1500
              })
            })
        }
        Swal.fire({
          position: 'center',
          type: 'success',
          title: `Usuario creado con éxito`,
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch(error => {
        Swal.fire({
          position: 'center',
          type: 'error',
          title: 'Ha ocurrido un error',
          text: `Error: ${error}`,
          showConfirmButton: false,
          timer: 1500
        })
        const jsonError = JSON.stringify(
          error,
          Object.getOwnPropertyNames(error)
        )
        CB({ error: JSON.parse(jsonError) })
      })
  }
}

export const phoneVerifyUser = uid => {
  return dispatch => {
    let updates = {}
    updates[`/${uid}/phoneVerify`] = true

    partnersDB
      .update(updates)
      .then(() =>
        Swal.fire({
          position: 'center',
          type: 'success',
          title: `Teléfono Verificado`,
          showConfirmButton: false,
          timer: 1500
        })
      )
      .catch(error => {
        Swal.fire({
          position: 'center',
          type: 'error',
          title: 'Ha ocurrido un error',
          text: `Error: ${error}`,
          showConfirmButton: false,
          timer: 1500
        })
      })
  }
}
