import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { connect } from 'react-redux'
import { fetchRiders } from './actions'
import Swal from 'sweetalert2'

const SelectRider = ({
  availableRiders,
  assignRider,
  orderId,
  status,
  selectedRider,
  dispatch
}) => {
  return (
    <Autocomplete
      pk={orderId}
      id={`select${orderId}`}
      options={availableRiders || [{ name: 'Buscando riders...' }]}
      getOptionLabel={option =>
        `${option.name} ${option.last_name || ''} ${option.mother_last_name ||
          ''}`
      }
      onChange={(event, value) => {
        if (value) {
          Swal.fire({
            title: 'Asignar repartidor',
            text: `Estas de acuerdo en asignar a ${value.name} ${
              value.last_name
            }?`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0398db',
            confirmButtonText: 'De acuerdo'
          }).then(result => {
            if (result.value) {
              assignRider(orderId, status, value.foreign_key)
            }
          })
        }
      }}
      renderInput={params => {
        return (
          <TextField
            {...params}
            onClick={() =>
              (!availableRiders || availableRiders.length === 0) &&
              dispatch(fetchRiders(''))
            }
            onChange={e => dispatch(fetchRiders(e.target.value))}
            label={
              selectedRider.dataRider.name
                ? `${selectedRider.dataRider.name} ${
                  selectedRider.dataRider.lastName
                }`
                : 'Asignar repartidor'
            }
            variant='outlined'
          />
        )
      }}
    />
  )
}

const MapStateToProps = ({ data: { availableRiders } }) => ({ availableRiders })

export default connect(MapStateToProps)(SelectRider)
