import React from 'react'
import fb from '../../firebase'
import { Row, Col, Page } from '../../components/ui/Layout'
import { Panel } from '../../components/ui/'
import ReactTable from 'react-table'
import { connect } from 'react-redux'
import { Div, Logo, Text } from './styled'
import { IconButton, Icon } from '@material-ui/core'
import BanModal from './banModal'
import Modal from '../../components/Modal'
import { bannedRider, desbanRider } from '../../containers/ridersHome/actions'
import axios from 'axios'
import getEndpoint from '../../helpers/getEndpoint'

const base_url = getEndpoint('REACT_APP_ENDPOINT_AWS')
const db = fb.database().ref('partners')

class TableRiders extends React.Component {
  state = {
    adminProps: {
      adminName: this.props.profile.name,
      adminLN: this.props.profile.lastName,
      adminID: this.props.profile.uid
    },
    riders: {
      data: [],
      loading: false,
      pages: 0
    },
    modal: {
      title: '',
      onSave: null,
      onCancel: null,
      show: false,
      content: <p>todo</p>
    }
  }

  componentWillUnmount () {
    db.off()
  }

  render () {
    const {
      riders: { data, loading, pages },
      modal: { title, onSave, onCancel, show, content }
    } = this.state
    const { profile: { city, selectCity } } = this.props
    const filterCity = selectCity || city
    const columns = this.renderHeader()
    return (
      <Page>
        <Row>
          <Col size={12}>
            <Panel>
              <ReactTable
                data={data}
                loading={loading}
                manual
                pages={pages}
                pageSize={20}
                showPageSizeOptions={false}
                onFetchData={(state, instance) => {
                  let searchFilter =
                    state.filtered &&
                    state.filtered[0] &&
                    state.filtered[0].value
                      ? state.filtered[0].value
                      : ''
                  if (searchFilter.length < 3 && searchFilter.length !== 0) {
                    return
                  }
                  // show the loading overlay
                  this.setState({ restaurantProspects: { loading: true } })
                  // fetch your data
                  axios
                    .get(
                      `${base_url}v1/riders?page=${state.page +
                        1}&city=${filterCity}&search=${searchFilter}`
                    )
                    .then(res => {
                      // Update react-table
                      this.setState({
                        riders: {
                          data: res.data.results,
                          pages: Math.ceil(res.data.count / 20),
                          loading: false
                        }
                      })
                    })
                }}
                columns={columns}
                defaultPageSize={20}
                filterable
                getTheadThProps={() => {
                  return {
                    style: {
                      outline: 0,
                      whiteSpace: 'pre-line',
                      wordWrap: 'break-word'
                    }
                  }
                }}
                getTdProps={() => {
                  return {
                    style: {
                      whiteSpace: 'pre-line',
                      wordWrap: 'break-word',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }
                  }
                }}
              />
            </Panel>
          </Col>
        </Row>
        <Modal
          title={title}
          onSave={onSave}
          onCancel={onCancel}
          show={show}
          content={content}
          navigate={this.props.history}
        />
      </Page>
    )
  }

  renderHeader () {
    const { onEdit, onActivate, onClean } = this.props
    return [
      {
        Header: 'Foto',
        accessor: 'avatar',
        filterable: true,
        Cell: props => {
          return (
            <Div>
              <Logo alt={'Sin foto'} src={props && props.value} />
            </Div>
          )
        }
      },
      {
        Header: 'Nombre',
        accessor: 'name'
      },
      {
        Header: 'Apellidos',
        accessor: 'last_name'
      },
      {
        Header: 'Correo electronico',
        accessor: 'email',
        width: 200,
        maxWidth: 200
      },
      {
        Header: 'Teléfono',
        accessor: 'phone'
      },
      {
        Header: 'ID',
        accessor: 'foreign_key',
        width: 200,
        maxWidth: 200,
        Cell: props => (
          <Div>
            <Text>{props.value}</Text>
          </Div>
        )
      },
      {
        Header: 'Contraseña',
        accessor: 'password',
        width: 100,
        maxWidth: 100
      },
      {
        Header: 'Acciones',
        accessor: 'isVisible',
        width: 200,
        maxWidth: 200,
        Cell: props =>
          props && (
            <React.Fragment>
              <IconButton
                onClick={() => onClean(props)}
                aria-label='Edit'
                component='span'
              >
                <Icon
                  fontSize={'large'}
                  title={'Limpiar ordenes'}
                  style={{ color: 'GoldenRod' }}
                >
                  backspace
                </Icon>
              </IconButton>
              <IconButton
                onClick={() => onEdit(props)}
                aria-label='Edit'
                component='span'
              >
                <Icon
                  fontSize={'large'}
                  title={'Editar usuario'}
                  style={{ color: 'Chocolate' }}
                >
                  edit
                </Icon>
              </IconButton>
              <IconButton
                onClick={() => onActivate(props)}
                aria-label='Activate'
                component='span'
              >
                {props.original.in_service ? (
                  <Icon
                    fontSize={'large'}
                    title='Desactivar usuario'
                    color='primary'
                  >
                    check_circle
                  </Icon>
                ) : (
                  <Icon
                    fontSize={'large'}
                    title='Activar usuario'
                    color='error'
                  >
                    cancel
                  </Icon>
                )}
              </IconButton>
              {props.original.banned ? (
                <IconButton
                  onClick={() =>
                    this.props.desbanRider(props.original.foreign_key)
                  }
                >
                  <Icon
                    fontSize={'large'}
                    title={'Desbloquear usuario'}
                    style={{ color: 'red' }}
                  >
                    person_remove
                  </Icon>
                </IconButton>
              ) : (
                <IconButton onClick={() => this._banModal(props.original)}>
                  <Icon
                    fontSize={'large'}
                    title={'Bloquear usuario'}
                    style={{ color: 'green' }}
                  >
                    how_to_reg
                  </Icon>
                </IconButton>
              )}
            </React.Fragment>
          )
      }
    ]
  }

  _banModal = data => {
    this.setState({
      modal: {
        show: 'show',
        title: 'Bloqueo de Rider',
        onCancel: this._onCancel,
        onSave: this._save,
        content: (
          <BanModal
            close={this._onCancel}
            onSave={this._save}
            adminProps={this.state.adminProps}
            riderProps={data}
          />
        )
      }
    })
  }

  _onCancel = () => {
    this.setState({
      modal: {
        show: false
      }
    })
  }

  _save = data => {
    this.props.bannedRider(data)
    this.setState({
      modal: { show: false }
    })
  }
}
const mapStateToProps = state => {
  return {
    ...state
  }
}
const mapDispatchToProps = dispatch => ({
  bannedRider: data => dispatch(bannedRider(data)),
  desbanRider: id => dispatch(desbanRider(id))
})
export default connect(mapStateToProps, mapDispatchToProps)(TableRiders)
